import React from 'react'
import HeaderBanner from '../../Components/HeaderBanner/HeaderBanner'
import NavBar from '../../Components/NavBar/NavBar'

import Membership from '../../Components/Membership/Membership'
import Experience from '../../Components/Experience/Experience'
import Banner from '../../Components/Banner/Banner'
import Footer from '../../Components/Footer/Footer'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

class HomePage extends React.Component {
  componentDidMount() {
    document.body.classList.add('home-page')
  }

  render() {
    const color = { color: '#ffffff' }
    return (
      <React.Fragment>
        <Helmet>
          <meta property="og:url" content="https://www.cnchire.com.au" />
          <meta property="og:title" content="Caravan and Camping Hire" />
          <meta
            property="og:description"
            content="Caravan and Camping Hire AUS | Peer to Peer private Caravan Hire"
          />
          <meta
            property="og:image"
            content="https://cdn.cnchire.com.au/colored-badge.png"
          />
        </Helmet>
        <NavBar linkTextColor={color} />
        <HeaderBanner />
        <Membership />

        <Experience />
        <Banner />
        {/*<SubscriptionBanner />*/}
        <Footer />
      </React.Fragment>
    )
  }
}
export default withRouter(HomePage)
