import React from 'react'
import { withRouter } from 'react-router'
import './SendPrivateMessage.scss'
import * as messageService from '../../../../../Services/messages'

class SendPrivateMessageComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: this.props.user,
      sent: false,
    }
  }

  //createMessage
  sendMessageHandler() {
    const params = {
      subject: this.state.inputSubject,
      body: this.state.inputMessage,
      to: this.state.user.id,
      from: this.state.me.id,
    }

    messageService.createMessage(params).then((result) => {
      if (result.data && result.data.data) {
        this.setState({ sent: true })
      }
    })
  }

  clickCancel() {
    console.log('clickCancel ???????????')
    this.props.clickCancel()
  }

  render() {
    return (
      <>
        {this.state.sent === true ? (
          <>
            <div className="reply-message-container">
              <div className="row">
                <div className="col-10">
                  <button
                    className="btn btn-secondary"
                    onClick={() => this.clickCancel()}
                  >
                    Back
                  </button>
                </div>
                <div className="col-2 text-right"></div>
              </div>

              <div className="message-sent">
                <h2>Your message has been sent!</h2>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="reply-message-container">
              <div className="row">
                <div className="col-10">
                  <button
                    className="btn btn-secondary"
                    onClick={() => this.clickCancel()}
                  >
                    Back
                  </button>
                </div>
                <div className="col-2 text-right"></div>
              </div>

              <div>
                <div className="row read-message-content">
                  <div className="col-12">
                    <br />
                    <div>
                      {' '}
                      <b>
                        Send to: {this.state.user.firstName}{' '}
                        {this.state.user.lastName}{' '}
                      </b>{' '}
                    </div>
                    <div>
                      {' '}
                      <b>Subject:</b>{' '}
                      <input
                        name="inputSubject"
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.inputSubject}
                      />{' '}
                    </div>
                    <div>
                      {' '}
                      <b>Message:</b> <br />
                      <textarea
                        name="inputMessage"
                        onChange={this.handleChange}
                        defaultValue={this.state.inputMessage}
                      ></textarea>
                    </div>

                    <div>
                      {' '}
                      <button
                        className="btn btn-primary"
                        onClick={() => this.sendMessageHandler()}
                      >
                        Send
                      </button>
                      &nbsp;{' '}
                      <button
                        className="btn btn-secondary"
                        onClick={() => this.clickCancel()}
                      >
                        Cancel
                      </button>{' '}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2"></div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export default withRouter(SendPrivateMessageComponent)
