import React, { useState, useEffect } from 'react'
import Pagination from '@material-ui/lab/Pagination'

import './ProductList.scss'
import ProductCard from '../ProductCard/ProductCard'

const ProductList = ({ productList }) => {
  const [productCompilation, setProductCompilation] = useState([])
  const [totalPageCount, setTotalPageCount] = useState(1)

  useEffect(() => {
    setProductCompilation(productList[0])
    if (productList && productList.length > 0) {
      const totalPages = Math.ceil(productList.length / 12)
      setTotalPageCount(totalPages)
    }
  }, [productList])

  // Get current posts
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(12)
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  let itemsToRender
  let currentPosts

  if (productCompilation) {
    currentPosts = productList.slice(indexOfFirstPost, indexOfLastPost)
    itemsToRender = currentPosts.map((item, index) => {
      const image =
        item.imagePath.search(',') > -1
          ? item.imagePath.split(',')[0]
          : item.imagePath
      return (
        <ProductCard
          productName={item.productName}
          productPrice={item.price}
          productImage={image}
          productLink=""
          key={index}
          productId={item.id}
        />
      )
    })
  }

  const handleListChange = (event, page) => {
    event.preventDefault()
    setCurrentPage(page)
    currentPosts = productList.slice(indexOfFirstPost, indexOfLastPost)
    itemsToRender = currentPosts.map((item, index) => {
      const image =
        item.imagePath.search(',') > -1
          ? item.imagePath.split(',')[0]
          : item.imagePath
      return (
        <ProductCard
          productName={item.productName}
          productPrice={item.price}
          productImage={image}
          productLink=""
          key={index}
          productId={item.id}
        />
      )
    })
  }

  return (
    <React.Fragment>
      <div className="productListContainer">
        <div className="productsPageDescriptionBox">
          <span className="productsPageTitle">Braking And Towing Products</span>
          <span className="productsPageSecondary">
            {'Looking for RV, Caravan & Camping Products and Accessories?'}
          </span>
          <span className="productsPageSecondary">
            {
              'You will find a great range of quality products including braking and towing products at Caravan And Camping Hire’s own 10,000 product online store all available through RV 4X4 '
            }
          </span>
        </div>
        <div className="productsContainer">{itemsToRender}</div>
        <Pagination
          count={totalPageCount}
          variant="outlined"
          shape="rounded"
          onChange={handleListChange}
          size={'large'}
        />
      </div>
    </React.Fragment>
  )
}

export default ProductList
