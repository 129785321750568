import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StartChatButton = ({ hirerId, vehicleId }) => {
  const clickCreateAndChat = () => {
    window.location.href =
      '/dashboard/?hirerId=' + hirerId + '&vehicleId=' + vehicleId
  }

  return (
    <div className="chat-with-only">
      <button className="btn btn-primary" onClick={() => clickCreateAndChat()}>
        <FontAwesomeIcon icon="comments" /> &nbsp; Chat with the vehicle owner!
      </button>
    </div>
  )
}

export default StartChatButton
