import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import './VehicleListPage.scss'
import { formatCurrency } from 'shared/utils.js'

class ListViewComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      list: this.props.list,
    }
  }

  componentDidMount() {
    document.body.classList.add('dashboard-vehicle-listview-page')
  }

  render() {
    return (
      <>
        <div className="main-body-container">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Vehicle Name</th>
                <th>Images</th>
                <th>Daily Rate</th>
                <th>Security Bond</th>
                <th>Booked</th>
                <th>Completed</th>
                <th>Vehicle Type</th>
                <th>Date Created</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {this.props.list.length > 0 &&
                this.props.list
                  .filter((x) => x.show === true)
                  .map((item, index) => {
                    let images = item.images ? JSON.parse(item.images) : []
                    let thumb = 'https://cdn.cnchire.com.au/c6.png'
                    if (item.thumbnail !== '') {
                      thumb = item.thumbnail
                    } else if (images[0]) {
                      thumb = images[0].image
                    }

                    return (
                      <tr
                        key={item.id}
                        className={index % 2 === 0 ? 'even' : 'odd'}
                      >
                        <td>{item.id}</td>
                        <td>
                          <a href={'/dashboard/vehicles/' + item.id}>
                            {' '}
                            {item.vehicleName}{' '}
                          </a>{' '}
                          <br />{' '}
                          {item.activeBooked > 0 && (
                            <div
                              className="status"
                              title={'Active Booking: ' + item.activeBooked}
                            >
                              Booking: {item.activeBooked}{' '}
                            </div>
                          )}
                        </td>
                        <td>
                          <img
                            alt="thumbnail"
                            className="thumbnail"
                            src={thumb}
                          />
                        </td>

                        <td>{formatCurrency(item.pricePerDay)}</td>
                        <td>{formatCurrency(item.securityBond)}</td>

                        <td>{item.booked ? item.booked : 0}</td>
                        <td>{item.completed ? item.completed : 0}</td>
                        <td>{item.vehicleType}</td>
                        <td>{moment(item.createdAt).format('LL')}</td>
                        <td>
                          {/*<button className="btn btn-info" onClick={ () => this.clickEnableDisable(item)}   >{ item.isActive  ?  'Disable' : 'Enable' }</button>   */}
                          {/* !item.booked  ?  (<>  
                                               <button className="btn btn-danger"  onClick={ () => this.clickDelete(item)}   >Delete</button>    
                                             </>) :  <> 
                                           </>
                                           */}

                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              (window.location.href =
                                '/dashboard/vehicles/' + item.id)
                            }
                          >
                            View
                          </button>
                          <a
                            href={'/dashboard/vehicle/update/' + item.id}
                            className="btn btn-primary"
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                    )
                  })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

export default withRouter(ListViewComponent)
