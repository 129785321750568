const InformationItems = [
  {
    name: 'FAQs',
    link: '/faqs',
  },
  {
    name: 'News',
    link: '/news',
  },
  {
    name: 'About Us',
    link: '/about-us',
  },
  {
    name: 'Contact',
    link: '/contact-us',
  },
  {
    name: 'How Does It Work For Owners',
    link: '/how-does-it-work-for-owners',
  },
]

export default InformationItems
