import React from 'react'

const LearnMorePage = () => {
  return (
    <>
      <div className="dashboard-container">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          pellentesque leo malesuada congue fringilla. Praesent vitae bibendum
          purus. Vestibulum tristique massa diam, id consectetur sapien lobortis
          quis. Ut gravida volutpat ante vitae blandit. Mauris vehicula mi at
          tempus pellentesque. Duis blandit nulla nisi, nec molestie nisl
          malesuada nec. Praesent pretium, dui vel lobortis imperdiet, mi felis
          elementum ipsum, eu efficitur velit nunc id nibh. Nullam faucibus
          porta feugiat. Maecenas condimentum lacinia massa non scelerisque.
          Nunc consectetur purus sed purus feugiat accumsan ut ut ante. Etiam
          sit amet erat leo. Quisque fringilla quis nibh id bibendum.
        </p>

        <p>
          Donec sem eros, dapibus ut ante a, sollicitudin tempor mi. Donec
          lacinia sapien dolor, vel faucibus nulla semper quis. Phasellus
          tristique dolor ac diam gravida, a feugiat dolor luctus. Sed pretium
          dapibus lorem at molestie. Quisque risus lectus, pulvinar ac neque ac,
          ultricies vehicula ante. Sed id dolor eget purus venenatis suscipit.
          Nulla at vulputate leo, a lobortis quam. Suspendisse vel tempor lorem.
          Nulla rutrum mi et euismod ornare. Nunc placerat porta velit ac
          consectetur. Maecenas non molestie dolor, vitae tincidunt urna. Ut
          rutrum urna eu gravida consectetur. Sed convallis consectetur urna,
          nec luctus neque sollicitudin ut. Nam efficitur ex eget sollicitudin
          posuere. Nulla auctor laoreet massa vel posuere.
        </p>
      </div>
    </>
  )
}

export default LearnMorePage
