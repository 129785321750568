import fp from 'lodash/fp'
import { invertDirection } from './helpers'
import * as faqService from '../../Services/faq'

const defaultState = {
  all: {},
  currentFAQ: {},
  sortBy: 'created_at',
  sortOrder: 'asc',
  page: 0,
  pageSize: 25,
  total: 0,
}

export default {
  state: defaultState,

  reducers: {
    getFAQs(state, payload) {
      return fp.merge(state, { all: payload })
    },
    getFAQItem(state, payload) {
      return fp.merge(state, { all: { [payload.id]: payload } })
    },
    getCurrentFAQItem(state, faqInfo) {
      return fp.assign(state, {
        currentFAQ: faqInfo,
      })
    },
  },

  effects: {
    async fetchFAQs(payload = {}, state) {
      const sorting = fp.merge(
        { sortBy: fp.getOr(state.faq.sortBy, 'sortBy')(payload) },
        {
          sortOrder:
            !fp.isEmpty(payload) && fp.has('sortBy', payload)
              ? invertDirection(state.faq.sortOrder)
              : state.faq.sortOrder,
        }
      )

      const pageSize = fp.getOr(state.faq.pageSize, 'pageSize')(payload)
      const page = fp.isEqual(pageSize, state.faq.pageSize)
        ? fp.get('page')(payload)
        : 0

      const pagination = { page, pageSize }
      const queryParams = fp.merge(sorting, pagination)

      const faqs = await faqService.fetchFAQs(queryParams)
      this.getFAQs(faqs)
    },
    async fetchFAQItem(id) {
      try {
        const faqInfo = await faqService.fetchFAQItem(id)
        this.getCurrentFAQItem(faqInfo[0])
      } catch (error) {
        throw error
      }
    },
  },

  selectors: (slice, createSelector, hasProps) => ({
    all() {
      return createSelector(slice, fp.get('all'))
    },
    allValues() {
      return createSelector(this.all, fp.values)
    },
    currentFAQ() {
      return createSelector(slice, fp.get('currentFAQ'))
    },
    getFaq: hasProps((models, id) => slice(fp.flow(fp.get('all'), fp.get(id)))),
  }),
}
