import React from 'react'
import { withRouter } from 'react-router'
import * as messageService from './MessageService'
import './MessageInboxPage.scss'
import { NotificationManager } from 'react-notifications'
import NewMessageComponent from './NewMessage'

class ReadMessage extends React.Component {
  constructor(props) {
    super(props)

    let { id } = this.props.match.params

    this.state = {
      id,
      inputMessage: '',
      inputSubject: '',
      message: false,
      messageState: 'inbox',
    }

    this.fetchLatest()
  }

  async fetchLatest() {
    const result = await messageService.get(this.state.id)

    if (result.success) {
      this.setState({ message: result.data[0] })
      console.log('000000000000000000', result.data[0])
    }
  }

  setReadMessage(item) {
    this.setState({ message: item })
  }

  setMessageState(state) {
    this.setState({ messageState: state })
  }

  setReadMessageHandler(item, type) {
    this.setReadMessage(item)
    this.setMessageState(type)
    this.setState({ inputSubject: 're:' + item.subject })
    this.setState({ inputMessage: '' })
  }

  //JAMES NOTE: this will fix syching issues of input fields
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  //createMessage
  sendMessageHandler() {
    const params = {
      subject: this.state.inputSubject,
      body: this.state.inputMessage,
      to: this.state.message.from,
      from: this.state.message.to,
    }

    messageService.create(params).then((result) => {
      if (result.success) {
        this.setState({ messageState: 'sent' })
      }
    })
  }

  async deleteMessageHandler(messageId) {
    const result = await messageService.remove({ id: messageId })
    if (result.data && result.data.isDeleted === 1) {
      //delete successful
      await this.fetchLatest()
      NotificationManager.success('Message has been deleted', 'Success')
    }
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    console.log('clicked pagination', page, this.state.meta)
    await this.setState({ meta: _meta })
    await this.fetchLatest()
  }

  render() {
    if (this.state.messageState === 'reply') {
      return (
        <>
          <NewMessageComponent
            message={this.state.message}
          ></NewMessageComponent>
        </>
      )
    } else {
      return (
        this.state.message !== false && (
          <>
            <div className="read-message-container">
              <div className="row">
                <div className="col-10">
                  <button
                    className="btn btn-secondary"
                    onClick={() =>
                      (window.location.href = '/dashboard/messages')
                    }
                  >
                    Back
                  </button>
                </div>
                <div className="col-2 text-right">
                  <button
                    className="pull-right btn btn-primary"
                    onClick={() => this.setMessageState('reply')}
                  >
                    Reply
                  </button>
                </div>
              </div>
              <div>
                <div className="row read-message-content">
                  <div className="col-12">
                    <div>
                      {' '}
                      <b>From:</b> {this.state.message.firstName}{' '}
                      {this.state.message.lastName}{' '}
                    </div>
                    <div>
                      {' '}
                      <b>Subject:</b> {this.state.message.subject}{' '}
                    </div>
                    <div>
                      {' '}
                      <b>Message:</b> <br />
                      <div>
                        {this.state.message.body
                          .split('\n')
                          .map((value, index) => {
                            return (
                              <span key={index}>
                                {value}
                                <br />
                              </span>
                            )
                          })}{' '}
                      </div>{' '}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2"></div>
                </div>
              </div>
            </div>
          </>
        )
      )
    }
  }
}

export default withRouter(ReadMessage)
