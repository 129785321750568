import { api_request } from 'Services/helpers'

export const upload = async (data = {}) =>
  await api_request('post', '/auth/booking/upload_contract', data, true)

export const sign = async (data = {}) =>
  await api_request('post', '/auth/contract/sign', data, true)

export const confirm = async (data = {}) =>
  await api_request('post', '/auth/contract/confirm', data, true)

export const get_booking = async (uuid = {}) =>
  await api_request('get', `/auth/booking/uuid/${uuid}`, {}, true)

export const pay_now = async (uuid = {}) =>
  await api_request('get', `/auth/booking/pay/${uuid}`, {}, true)
