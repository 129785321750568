import { api_request } from 'Services/helpers'

export const create = async (data = {}) => {
  return await api_request('post', '/auth/vehicle/create', data, true)
}

export const update = async (id, data = {}) => {
  return await api_request('post', `/auth/vehicle/update/${id}`, data, true)
}

export const upload = async (data = {}) => {
  return await api_request('post', '/auth/vehicle/upload', data, true)
}

export const getAmenities = async (data = {}) => {
  return await api_request('get', '/amenities', data, false)
}

export const fetch = async (id) => {
  return await api_request('get', '/auth/vehicle/detail/' + id, {}, true)
}
