import axios from 'axios'
import fp from 'lodash/fp'
import apiTwo from './api'

import { camelizeKeys } from './helpers'

const vehicleApi = apiTwo('/vehicles')

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/vehicles`,
})

export const fetchVehicles = async () => {
  const vehicleList = await api.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleList)
}

export const fetchVehicle = async (id) => {
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicles/${id}`,
  })
  const vehicleInfo = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleInfo)
}

export const fetchVehicleConditionsOfUse = async (id) => {
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicle-conditions-of-use?vehicle_id=${id}`,
  })
  const vehicleConditionsOfUse = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleConditionsOfUse)
}

export const fetchVehicleDetails = async (id) => {
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicle-details?vehicle_id=${id}`,
  })
  const vehicleDetails = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleDetails)
}

export const fetchVehicleTowingDetails = async (id) => {
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicle-towing-details?vehicle_id=${id}`,
  })
  const vehicleTowingDetails = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleTowingDetails)
}

export const fetchVehicleAmenities = async (id) => {
  console.log(
    'fecthing amenities',
    `${process.env.REACT_APP_API_URL}/vehicle-amenities?vehicle_id=${id}`
  )
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicle-amenities?vehicle_id=${id}`,
  })
  const vehicleAmenities = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleAmenities)
}

export const fetchVehicleAdditionalAmenities = async (id) => {
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicle-additional-amenities?vehicle_id=${id}`,
  })
  const vehicleAdditionalAmenities = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleAdditionalAmenities)
}

export const fetchVehicleByLocation = async (data = {}) => {
  const vehiclesByLocation = await vehicleApi.get('', { params: data })
  return fp.flow(fp.getOr({}, 'data'))(vehiclesByLocation)
}

export const fetchVehicleByType = async (vehicleType) => {
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicles?vehicle_type=${vehicleType}`,
  })
  const vehicleList = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleList)
}

export const fetchVehiclesByState = async (data = {}) => {
  const getVehiclesAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/vehicles?state=${data}`,
  })
  const vehicleList = await getVehiclesAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(vehicleList)
}

export const searchVehicle = async (data = {}) => {
  const vehicles = await vehicleApi.get('', { params: data })
  return fp.flow(fp.getOr({}, 'data'))(vehicles)
}
