import React from 'react'

import './VehiclesBanner.scss'
import VehicleTypes from '../Items/VehicleTypes'

const VehiclesBanner = () => {
  return (
    <React.Fragment>
      <div className="vehiclesBanner">
        <span className="vehiclesTitle">Vehicles You Can Hire</span>
        <div className="vehiclesContainer">
          {VehicleTypes.filter((item) => item.vehicleBanner).map(
            (item, index) => {
              return (
                <a href={item.link} className="vehicle" key={index}>
                  <div className="vehicleIconContainer">
                    <img className="vehicleIcon" alt="" src={item.icon} />
                  </div>
                  {item.name}
                </a>
              )
            }
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default VehiclesBanner
