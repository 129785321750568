import React, { useEffect, useState } from 'react'
import { fetch, send } from './chatService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import './chat.scss'

export const ChatBoxComponent = ({
  chat,
  user,
  clickClose,
  clickBack,
  seen,
}) => {
  const [messageList, setMessageList] = useState([])
  const [fetched, setFetched] = useState(false)
  const [message, setMessage] = useState('')

  // console.log('CHAT', chat)
  const { vehicleId, hirerId, hirerName, vehicleName } = chat || {}

  useEffect(() => {
    if (fetched) {
      const timer = setTimeout(() => {
        console.log('Marking chat as read', chat)
        const params = { hirer_id: chat.hirerId, vehicle_id: chat.vehicleId }
        seen(chat, params)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [fetched])

  const clickSend = async () => {
    // console.log('Sending message', message)
    if (message !== '') {
      const params = {
        hirer_id: hirerId,
        vehicle_id: vehicleId,
        message: message,
      }
      let result = await send(params)

      if (result.success) {
        let chats = result.data.chats
        chats.reverse()
        setMessageList(chats)
        setMessage('')
      }
    }
  }

  const fetchChat = async () => {
    if (hirerId && vehicleId) {
      const params = {
        hirer_id: hirerId,
        vehicle_id: vehicleId,
      }
      const result = await fetch(params)
      if (result.success) {
        let chats = result.data
        chats.reverse()
        setMessageList(chats)
        setFetched(true)
        console.log('MESSAGES', chats)
      }
    }
  }

  useEffect(() => {
    fetchChat()
  }, [chat])

  const myId = user ? user.id : -1
  const chatName = user.userRole === 'Hirer' ? chat.ownerName : chat.hirerName

  return (
    <div className="chat-list-box chat-box open">
      <div className="chat-header" style={{ display: 'flex' }}>
        <span className="back-btn" onClick={() => clickBack()}>
          <FontAwesomeIcon icon="chevron-circle-left" />
        </span>

        <div className="chat-title">
          {vehicleName} - <a href="/dashboard/">{chatName}</a>
        </div>

        <span className="close-btn" onClick={() => clickClose()}>
          <FontAwesomeIcon icon="times" />
        </span>
      </div>
      <div className="chat-body">
        {messageList
          .filter((m) => m.message != '-blank-')
          .map((x, index) => {
            return (
              <div
                className={
                  'chat-item chat-box-item ' +
                  (user?.userRole === 'Admin' ? 'Admin' : 'Owner')
                }
                key={index}
              >
                <div className="row">
                  {!(user.userRole === 'Admin' && x?.userType != 'Owner') &&
                  myId !== x?.userId ? (
                    <>
                      <div
                        className="col-2 avatar"
                        title={`${x.by} - ${x.userType}, ${moment(
                          x.time
                        ).format('LL')}`}
                      >
                        <FontAwesomeIcon icon="user-circle" />
                      </div>
                      <div
                        className="col-10"
                        title={`${x.by} - ${x.userType}, ${moment(
                          x.time
                        ).format('LL')}`}
                      >
                        <div className="message">{x.message}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="col-10"
                        title={
                          x.by +
                          ' ' +
                          ' - ' +
                          x.userType +
                          ' ' +
                          moment(x.time).format('LL')
                        }
                      >
                        <div className="message mymessage">{x.message}</div>
                      </div>
                      <div
                        className="col-2 avatar me"
                        title={
                          x.by +
                          ' ' +
                          ' - ' +
                          x.userType +
                          ' ,' +
                          moment(x.time).format('LL')
                        }
                      >
                        <FontAwesomeIcon icon="user-circle" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          })}
      </div>
      <div
        className="chat-footer"
        style={{
          position: 'fixed',
          bottom: 0,
          width: '300px',
          display: 'flex',
        }}
      >
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="enter text"
          style={{ flexGrow: 1 }}
        />
        <button onClick={() => clickSend()} className="send-btn">
          Send
        </button>
      </div>
    </div>
  )
}
