import React from 'react'
import decamelize from 'decamelize'
import 'react-datepicker/dist/react-datepicker.css'
import './CreateVehiclePage.scss'
import * as CreateVehicleService from './CreateVehicleService'
import { NotificationManager } from 'react-notifications'
import 'react-day-picker/lib/style.css'

import AmenitiesComponent from './components/AmenitiesComponent'
import AvailabilityComponent from './components/AvailabilityComponent'
import BasicInfoComponent from './components/BasicInfoComponent'
import ConditionOfUseComponent from './components/ConditionOfUseComponent'
import LocationComponent from './components/LocationComponent'
import TowingDetailsComponent from './components/TowingDetailsComponent'

export default class CreateVehiclePage extends React.Component {
  constructor(props) {
    super(props)

    let { id } = this.props.match.params
    console.log('id is ', id)

    this.state = {
      section: 'section-1',
      error_message: '',

      tabs: [
        {
          active: true,
          tabname: 'Basic Info',
          section: 'section-1',
          errorFlags: [
            'vehicle_name',
            'vehicle_type',
            'vehicle_year_manufactured',
            'vehicle_make',
            'vehicle_model',
            'vehicle_sleeps',
            'vehicle_length',
            'vehicle_width',
            'images',
            'description',
          ],
        },
        {
          active: false,
          tabname: 'Availability & Pricing',
          section: 'section-0',
          errorFlags: ['price_per_day'],
        },
        {
          active: false,
          tabname: 'Locations',
          section: 'section-2',
          errorFlags: ['lat', 'lng', 'location', 'state', 'state_code'],
        },
        {
          active: false,
          tabname: 'Amenities',
          section: 'section-3',
          errorFlags: ['amenities'],
        },
        {
          active: false,
          tabname: 'Condition Of Use',
          section: 'section-4',
          errorFlags: ['condition_min_driver_age'],
        },
        { active: false, tabname: 'Towing Details', section: 'section-5' },
      ],

      isUploading: false,
      rate_rule_button_active: false,
      isRuleDate: false,

      run_rate_date: '',

      number_of_travellers: 10,
      numberOfMonths: 2,
      from: undefined,
      to: undefined,

      runNumberOfMonhts: 2,
      run_from: undefined,
      run_to: undefined,

      computed_rates: [],
      computed_total: 0,

      security_bond: 250,
      insurance_bond_selections: [
        { order: 0, type: 'select', item: '$250', value: 250, active: true },
        { order: 0, type: 'select', item: '$500', value: 500, active: true },
        { order: 0, type: 'select', item: '$750', value: 750, active: true },
        { order: 0, type: 'select', item: '$1000', value: 1000, active: true },
        { order: 0, type: 'select', item: '$1500', value: 1500, active: true },
        { order: 0, type: 'select', item: '$2000', value: 2000, active: true },
        { order: 0, type: 'select', item: '$3000', value: 3000, active: true },
      ],

      rate_rules_selections: [
        {
          order: 0,
          type: 'select',
          item: '[Select Rule]',
          value: '-1',
          active: true,
        },
        {
          order: 1,
          type: 'Date Range',
          item: 'Date Range',
          value: 'Date Range',
          active: true,
        },
        {
          order: 2,
          type: 'Day',
          item: 'Sunday',
          value: 'Sunday',
          active: true,
        },
        {
          order: 3,
          type: 'Day',
          item: 'Monday',
          value: 'Monday',
          active: true,
        },
        {
          order: 4,
          type: 'Day',
          item: 'Tuesday',
          value: 'Tuesday',
          active: true,
        },
        {
          order: 5,
          type: 'Day',
          item: 'Wednesday',
          value: 'Wednesday',
          active: true,
        },
        {
          order: 6,
          type: 'Day',
          item: 'Thursday',
          value: 'Thursday',
          active: true,
        },
        {
          order: 7,
          type: 'Day',
          item: 'Friday',
          value: 'Friday',
          active: true,
        },
        {
          order: 8,
          type: 'Day',
          item: 'Saturday',
          value: 'Saturday',
          active: true,
        },

        {
          order: 9,
          type: 'Week',
          item: 'Week 1',
          value: 'Week 1',
          active: true,
        },
        {
          order: 10,
          type: 'Week',
          item: 'Week 2',
          value: 'Week 2',
          active: true,
        },
        {
          order: 11,
          type: 'Week',
          item: 'Week 3',
          value: 'Week 3',
          active: true,
        },
        {
          order: 12,
          type: 'Week',
          item: 'Week 4',
          value: 'Week 4',
          active: true,
        },

        {
          order: 13,
          type: 'Month',
          item: 'January',
          value: 'January',
          active: true,
        },
        {
          order: 14,
          type: 'Month',
          item: 'February',
          value: 'February',
          active: true,
        },
        {
          order: 15,
          type: 'Month',
          item: 'March',
          value: 'March',
          active: true,
        },
        {
          order: 16,
          type: 'Month',
          item: 'April',
          value: 'April',
          active: true,
        },
        { order: 17, type: 'Month', item: 'May', value: 'May', active: true },
        { order: 18, type: 'Month', item: 'June', value: 'June', active: true },
        { order: 19, type: 'Month', item: 'July', value: 'July', active: true },
        {
          order: 20,
          type: 'Month',
          item: 'August',
          value: 'August',
          active: true,
        },
        {
          order: 21,
          type: 'Month',
          item: 'September',
          value: 'September',
          active: true,
        },
        {
          order: 22,
          type: 'Month',
          item: 'October',
          value: 'October',
          active: true,
        },
        {
          order: 23,
          type: 'Month',
          item: 'November',
          value: 'November',
          active: true,
        },
        {
          order: 24,
          type: 'Month',
          item: 'December',
          value: 'December',
          active: true,
        },
      ],

      rate_rules: [],

      price_rate_rule: '',
      select_rate_rule: '-1',
      isErrored: true,
      is_new: id ? false : true,
      id,
      condition_min_driver_age: 18,
      condition_permitted_road_types: 'Any Road Types',
      vehicle_type: 'Campervan',
      hire_type: '',
      hire_type_1: false,
      hire_type_2: false,
      hire_type_3: false,
      me: this.props.user,
      images: [],
      screen: 'create',
      list: [],
      amenities: false,
      amenities_checkboxes: [],
    }

    this.fetch()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-createvehicle-page')
  }

  parentHandleAddOn = async (addons) => {
    console.log('parent handleaddon', addons)
    this.setState({ addons })
  }

  parentHandle = async (obj) => {
    this.setState({ ...obj }, () => {
      this.validateForm()
    })
  }

  longlatPosition = (position) => {
    const lat = position.coords.latitude
    const lng = position.coords.longitude
    this.setState({ lat, lng })
  }

  fetch = async () => {
    let obj = {}
    let amenities_array = []

    if (!this.state.is_new) {
      const result = await CreateVehicleService.fetch(this.state.id)
      obj = Object.fromEntries(
        Object.entries(result.data.vehicle).map(([key, value]) => [
          decamelize(key),
          value,
        ])
      )

      //console.log("hire type check-------------------->", obj);
      obj['hire_type_1'] = obj['hire_type1'] //camelized version is something like this? weird
      obj['hire_type_2'] = obj['hire_type2']
      obj['hire_type_3'] = obj['hire_type3']

      //include thumbnail if not in images here

      let _images = obj[`images`] ? JSON.parse(obj[`images`]) : []
      let images = []

      //add primary option here
      _images.forEach((item) => {
        images.push({ primary: false, ...item })
      })

      if (obj['thumbnail'] !== '') {
        images.push({ primary: true, image: obj['thumbnail'] })
      }

      obj[`images`] = images
      obj[`addon_amenities`] = obj[`addon_amenities`]
        ? JSON.parse(obj[`addon_amenities`])
        : {}

      for (let i = 1; i < 6; i++) {
        if (obj[`addon_amenities`][i]) {
          obj[`addon_amenities_price_${i}`] = obj[`addon_amenities`][i].price
          obj[`addon_amenities_${i}`] = obj[`addon_amenities`][i].amenity
        }
      }
      amenities_array = obj[`amenities`].split(',')

      if (obj[`rate_rules`]) {
        obj[`rate_rules`] = obj[`rate_rules`]
          ? JSON.parse(obj[`rate_rules`])
          : {}
      } else {
        obj[`rate_rules`] = {}
      }
    } else {
      //if(navigator.geolocation){ //if its new?
      //navigator.geolocation.getCurrentPosition(this.longlatPosition);
      //}

      try {
        navigator.geolocation.getCurrentPosition(this.longlatPosition)
      } catch (e) {}
    }

    const amenities = await CreateVehicleService.getAmenities()
    const list = amenities.data
    let array = []

    for (let item of list) {
      if (amenities_array.indexOf(item.id + '') > -1) {
        array.push({ checked: true, ...item })
      } else {
        array.push({ checked: false, ...item })
      }
    }

    try {
      obj[`unavailability`] = JSON.parse(obj[`unavailability`] || [])
    } catch (e) {
      obj[`unavailability`] = []
    }

    obj[`amenities_checkboxes`] = array
    obj['isReady'] = true
    await this.setState(obj)
    this.validateForm()
  }

  validateForm = () => {
    const params_check = [
      'vehicle_sleeps',
      'vehicle_year_manufactured',
      'vehicle_name',
      'description',
      'lat',
      'lng',
      'state',
      'state_code',
      'location',
      'number_of_travellers',
      'condition_min_driver_age',
      'vehicle_make',
      'vehicle_model',
      'price_per_day',
      'vehicle_length',
      'vehicle_width',
    ]
    let isErrored = false
    let obj = {}

    for (let item of params_check) {
      let str = this.state[item] + ''
      str = str.trim()

      if (!str || str === '' || str === undefined || str === 'undefined') {
        obj[`${item}_error`] = true
        isErrored = true
      } else {
        obj[`${item}_error`] = false
      }
      // console.log('Validating ', item, `:${str}:`, obj[`${item}_error`])
    }

    if (parseInt(this.state.price_per_day) === 0 || !this.state.price_per_day) {
      isErrored = true
      obj[`price_per_day_error`] = true
    } else {
      obj[`price_per_day_error`] = false
    }

    let amenities_count = 0
    for (let item of this.state.amenities_checkboxes) {
      if (item.checked) {
        amenities_count++
      }
    }

    if (amenities_count === 0) {
      isErrored = true
      obj[`amenities_error`] = true
    } else {
      obj[`amenities_error`] = false
    }

    if (!this.state.images || this.state.images.length === 0) {
      isErrored = true
      obj[`images_error`] = true
    } else {
      obj[`images_error`] = false
    }

    obj[`isErrored`] = isErrored
    this.setState(obj)
    console.log('Validated', isErrored, obj)
    return !isErrored
  }

  clickCancel = () => {
    window.location.href = '/dashboard/vehicles'
  }

  prepareParams = async () => {
    let amenity_ids = ''

    for (let item of this.state.amenities_checkboxes) {
      if (item.checked) {
        amenity_ids += amenity_ids === '' ? item.id : ',' + item.id
      }
    }

    //extract primary in the images
    let _images = [],
      _thumbnail = ''
    if (this.state.images.length === 1) {
      _thumbnail = this.state.images[0].image
    } else {
      for (var j = 0; j < this.state.images.length; j++) {
        let _i = this.state.images[j]

        if (_i.primary) {
          _thumbnail = _i.image
        } else {
          _images.push({ image: _i.image })
        }
      }
    }

    const params = {
      vehicle_name: this.state.vehicle_name,
      description: this.state.description,
      vehicle_type: this.state.vehicle_type,
      hire_type: '', //not used
      hire_type_1: this.state.hire_type_1 ? true : false,
      hire_type_2: this.state.hire_type_2 ? true : false,
      hire_type_3: this.state.hire_type_3 ? true : false,
      lat: this.state.lat + '',
      lng: this.state.lng + '',
      state: this.state.state,
      state_code: this.state.state_code,
      location: this.state.location,
      number_of_travellers: this.state.number_of_travellers,
      price_per_day: this.state.price_per_day,
      security_bond: this.state.security_bond,
      thumbnail: _thumbnail,
      images: JSON.stringify(_images),
      rate_rules:
        this.state.rate_rules && this.state.rate_rules.length > 0
          ? JSON.stringify(this.state.rate_rules.filter((x) => !x.deleted))
          : '{}',

      amenities: amenity_ids,
      addon_amenities: JSON.stringify(this.state.addons),

      condition_min_driver_age: this.state.condition_min_driver_age,
      condition_permitted_road_types: this.state.condition_permitted_road_types,
      condition_river_crossing: this.state.condition_river_crossing
        ? true
        : false,
      condition_beach_access: this.state.condition_beach_access ? true : false,
      condition_outback_roads: this.state.condition_outback_roads
        ? true
        : false,
      condition_specific_terms: this.state.condition_specific_terms
        ? this.state.condition_specific_terms
        : '',

      vehicle_make: this.state.vehicle_make,
      vehicle_model: this.state.vehicle_model,
      vehicle_year_manufactured: this.state.vehicle_year_manufactured,
      vehicle_sleeps: this.state.vehicle_sleeps,
      vehicle_length: this.state.vehicle_length,
      vehicle_width: this.state.vehicle_width,
      vehicle_family_friendly: this.state.vehicle_family_friendly
        ? true
        : false,
      vehicle_pet_friendly: this.state.vehicle_pet_friendly ? true : false,

      towing_tare: this.state.towing_tare || '',
      towing_atm: this.state.towing_atm || '',
      towing_towball_weight: this.state.towing_towball_weight || '',
      towing_plug: this.state.towing_plug || '',
      minimum_hire_duration: this.state.minimum_hire_duration || 3,
      maximum_hire_duration: this.state.maximum_hire_duration || 7,
      unavailability: JSON.stringify(this.state.unavailability || []),
      status: this.state.status,
    }

    return params
  }

  clickCreateVehicle = async () => {
    let isFormOkey = this.validateForm()

    if (!isFormOkey) {
      NotificationManager.error('Please correct the errors to continue.')
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      const params = await this.prepareParams()
      console.log('params ', params)

      let result
      if (this.state.is_new) {
        result = await CreateVehicleService.create(params)
      } else {
        result = await CreateVehicleService.update(this.state.id, params)
      }

      if (result.success) {
        await localStorage.setItem(
          'message_flash',
          'You have successfully ' +
            (this.state.is_new ? 'added' : 'updated') +
            ' the vehicle!'
        )
        window.location.href = '/dashboard/vehicles'
      } else {
        NotificationManager.error('Please correct the errors to continue.')
        this.setState({
          error_message:
            result.message ||
            'There was an error with your update. Please try again later.',
        })
      }
    }
  }

  handleChange = (e) => {
    var obj = {}
    obj[`${e.target.name}`] = e.target.value
    this.setState(obj, () => {
      this.validateForm()
    })
  }

  clickTab = (item) => {
    let tabs = this.state.tabs
    tabs = tabs.map((x) => {
      return {
        active: item.tabname === x.tabname ? true : false,
        tabname: x.tabname,
        section: x.section,
        errorFlags: x.errorFlags,
      }
    })
    this.setState({ tabs, section: item.section })
  }

  hasError = (item) => {
    const flags = item.errorFlags?.map((e) => {
      return this.state[`${e}_error`]
    })
    // console.log('FLAGS', flags, item)
    return flags?.some((a) => a)
  }

  render() {
    if (this.state.screen === 'create' && !this.state.isReady) {
      return <>loading..</>
    } else if (this.state.screen === 'create' && this.state.isReady) {
      return (
        <>
          <div className={'create-vehicle-container ' + this.state.section}>
            {this.state.isUploading && (
              <>
                {' '}
                <div className="loader">
                  <div className="lds-dual-ring"></div>{' '}
                </div>{' '}
                <div className="overlay"></div>
              </>
            )}

            <div className="tab-container">
              {this.state.tabs.length > 0 &&
                this.state.tabs.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`tab-item ${item.active ? 'active' : ''} ${
                        this.hasError(item) ? 'error' : ''
                      }`}
                      onClick={() => this.clickTab(item)}
                    >
                      {item.tabname}
                    </div>
                  )
                })}
            </div>

            <div className="clearfix"></div>

            {this.state.error_message !== '' ? (
              <div className="error-message"> {this.state.error_message} </div>
            ) : null}

            <div className="line-item">
              <div>Required (*)</div>
            </div>

            <AmenitiesComponent
              state={this.state}
              parentHandle={this.parentHandle}
            ></AmenitiesComponent>
            <BasicInfoComponent
              state={this.state}
              prepareParams={this.prepareParams}
              parentHandle={this.parentHandle}
            ></BasicInfoComponent>
            <AvailabilityComponent
              state={this.state}
              parentHandle={this.parentHandle}
            ></AvailabilityComponent>
            <ConditionOfUseComponent
              state={this.state}
              parentHandle={this.parentHandle}
            ></ConditionOfUseComponent>
            <LocationComponent
              state={this.state}
              parentHandle={this.parentHandle}
            ></LocationComponent>
            <TowingDetailsComponent
              state={this.state}
              parentHandle={this.parentHandle}
            ></TowingDetailsComponent>

            {this.state.isErrored ? (
              <div className="error-message">
                Some required information is missing. Please ensure you have
                completed the mandatory fields (*) on all tabs (Basic Info,
                Availability and Pricing, Locations etc.) before trying to
                proceed
              </div>
            ) : null}
            <button
              className="btn btn-secondary"
              onClick={() => this.clickCancel()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.isErrored}
              type="button"
              onClick={() => this.clickCreateVehicle()}
            >
              {this.state.is_new ? 'Create Vehicle' : 'Update Vehicle'}
            </button>
          </div>
        </>
      )
    }
  }
}
