import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import Footer from '../../Components/Footer/Footer'
import { withRouter } from 'react-router'
import './HireEnquiryPage.scss'
import * as vehicleService from '../../Services/vehicle'
import * as hireEnquiryService from './HireEnquiryService'
//import {NotificationManager} from 'react-notifications';

class HireEnquiryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicle: false,
      towing_brake_control: 0,
      travel_free_camp: 0,
      is_sent: false,
    }
  }

  async fetchVehicle(id) {
    const vehicleData = await vehicleService.fetchVehicle(id)
    console.log('testing data-->', vehicleData)
    await this.setState({ vehicle: vehicleData.data.vehicle })

    this.setState({
      travel_vehicle: this.state.vehicle.vehicleName,
      travel_suburb: this.state.vehicle.suburb,
      travel_postcode: this.state.vehicle.stateCode,
      vehicle_id: this.state.vehicle.id,

      first_name: this.props.user.firstName || '',
      last_name: this.props.user.lastName || '',
      contact_number: this.props.user.contactNumber || '',
      email: this.props.user.email || '',
      address_1: this.props.user.address || '',
      address_2: this.props.user.address2 || '',
    })
  }

  componentDidMount() {
    this.fetchVehicle(this.props.match.params.id)
  }

  async handleSubmit(event) {
    event.preventDefault()
    let obj = this.state
    delete obj.vehicle
    obj.travel_return = moment(obj.travel_return).format('YYYY-MM-DD')
    obj.travel_departure = moment(obj.travel_departure).format('YYYY-MM-DD')

    await hireEnquiryService.enquire(obj)

    //console.log("clicked submit", obj, enquiry);

    //this.setState({ is_sent :  true});
    //NotificationManager.success("Enquiry sent!");
  }

  sent() {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center' }}>
          <h1>
            Thank you for your interest! We will review your enquiry and get
            back to you shortly.
          </h1>
        </div>
      </React.Fragment>
    )
  }

  form() {
    return (
      <React.Fragment>
        <div className="hireEnquiryContainer">
          <form
            onSubmit={(event) => this.handleSubmit(event)}
            className="hireEnquiryForm"
          >
            <span className="sectionTitle">Personal Details</span>
            <div className="formRow">
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">First Name*</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.first_name}
                  onChange={(event) =>
                    this.setState({ first_name: event.target.value })
                  }
                  required
                  placeholder="First Name"
                />
              </div>
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">Last Name*</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.last_name}
                  onChange={(event) =>
                    this.setState({ last_name: event.target.value })
                  }
                  required
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="formRow">
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">Contact Number*</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.contact_number}
                  onChange={(event) =>
                    this.setState({ contact_number: event.target.value })
                  }
                  required
                  placeholder="Contact Number"
                />
              </div>
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">Email*</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  required
                  placeholder="Email"
                />
              </div>
            </div>

            <div className="formRow">
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">Address Line 1*</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.address_1}
                  onChange={(event) =>
                    this.setState({ address_1: event.target.value })
                  }
                  required
                  placeholder="Address Line 1"
                />
              </div>
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">Address Line 2</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.address_2}
                  onChange={(event) =>
                    this.setState({ address_2: event.target.value })
                  }
                  placeholder="Address Line 2"
                />
              </div>
            </div>

            <div className="formRow">
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">Suburb*</span>
                <input
                  className="hireEnquiryInput"
                  onChange={(event) =>
                    this.setState({ suburb: event.target.value })
                  }
                  required
                  placeholder="Suburb"
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">State*</span>
                <input
                  className="hireEnquiryInput"
                  onChange={(event) =>
                    this.setState({ state: event.target.value })
                  }
                  required
                  placeholder="State"
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">Postcode*</span>
                <input
                  className="hireEnquiryInput"
                  onChange={(event) =>
                    this.setState({ postcode: event.target.value })
                  }
                  required
                  placeholder="Postcode"
                />
              </div>
            </div>

            <span className="sectionTitle">Travel Details</span>
            <div className="formRow">
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">
                  Preferred Caravan*
                </span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.vehicle.vehicleName}
                  onChange={(event) =>
                    this.setState({ travel_vehicle: event.target.value })
                  }
                  required
                  placeholder="Preferred Caravan"
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">Vehicle Suburb*</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.vehicle.suburb}
                  onChange={(event) =>
                    this.setState({ travel_suburb: event.target.value })
                  }
                  required
                  placeholder="Vehicle Suburb"
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">Vehicle State*</span>
                <input
                  className="hireEnquiryInput"
                  defaultValue={this.state.vehicle.stateCode}
                  onChange={(event) =>
                    this.setState({ travel_state: event.target.value })
                  }
                  required
                  placeholder="Vehicle State"
                />
              </div>
            </div>

            <div className="formRow">
              <div className="hireEnquiryInputBox3">
                <span className="hireEnquiryInputBoxName">Departure Date*</span>

                <DatePicker
                  className="hireEnquiryInput"
                  required
                  placeholderText="Departure Date"
                  selected={this.state.travel_departure}
                  onChange={(date) => this.setState({ travel_departure: date })}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="hireEnquiryInputBox3">
                <span className="hireEnquiryInputBoxName">Return Date*</span>

                <DatePicker
                  className="hireEnquiryInput"
                  required
                  placeholderText="Return Date"
                  selected={this.state.travel_return}
                  onChange={(date) => this.setState({ travel_return: date })}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="hireEnquiryInputBox3">
                <span className="hireEnquiryInputBoxName">
                  Destination Suburb*
                </span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="Destination Suburb"
                  onChange={(event) =>
                    this.setState({
                      travel_destination_suburb: event.target.value,
                    })
                  }
                />
              </div>
              <div className="hireEnquiryInputBox3">
                <span className="hireEnquiryInputBoxName">
                  Destination State*
                </span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="Destination State"
                  onChange={(event) =>
                    this.setState({
                      travel_destination_state: event.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="formRow">
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">No. Adults*</span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="No. Adults"
                  type="number"
                  onChange={(event) =>
                    this.setState({ travel_adults: event.target.value })
                  }
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">No. Children*</span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="No. Children"
                  type="number"
                  onChange={(event) =>
                    this.setState({ travel_children: event.target.value })
                  }
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">
                  Are you intending to free camp?*
                </span>
                <div className="hireEnquiryRadioBox">
                  <div className="hireEnquiryRadio">
                    <input
                      type="radio"
                      value="Yes"
                      name="freeCamp"
                      onClick={() => this.setState({ travel_free_camp: 1 })}
                    />{' '}
                    Yes
                  </div>
                  <div className="hireEnquiryRadio">
                    <input
                      type="radio"
                      value="No"
                      name="freeCamp"
                      defaultChecked="yes"
                      onClick={() => this.setState({ travel_free_camp: 0 })}
                    />{' '}
                    No
                  </div>
                </div>
              </div>
            </div>

            <span className="sectionTitle">Towing Vehicle</span>
            <div className="formRow">
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">Vehicle Make*</span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="Vehicle Make"
                  onChange={(event) =>
                    this.setState({ towing_vehicle_make: event.target.value })
                  }
                />
              </div>
              <div className="hireEnquiryInputBox">
                <span className="hireEnquiryInputBoxName">Vehicle Model*</span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="Vehicle Model"
                  onChange={(event) =>
                    this.setState({ towing_vehicle_model: event.target.value })
                  }
                />
              </div>
            </div>

            <div className="formRow">
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">
                  Braked Towing Capacity*
                </span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="Braked Towing Capacity"
                  onChange={(event) =>
                    this.setState({
                      towing_towing_capacity: event.target.value,
                    })
                  }
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">Tow Bar Rating*</span>
                <input
                  className="hireEnquiryInput"
                  required
                  placeholder="Tow Bar Rating"
                  onChange={(event) =>
                    this.setState({ towing_tow_bar: event.target.value })
                  }
                />
              </div>
              <div className="hireEnquiryInputBox2">
                <span className="hireEnquiryInputBoxName">
                  Is your vehicle already fitted with brake control?*
                </span>
                <div className="hireEnquiryRadioBox">
                  <div className="hireEnquiryRadio">
                    <input
                      type="radio"
                      value="Yes"
                      name="brakeControl"
                      onClick={() => this.setState({ towing_brake_control: 1 })}
                    />{' '}
                    Yes
                  </div>
                  <div className="hireEnquiryRadio">
                    <input
                      type="radio"
                      value="No"
                      name="brakeControl"
                      defaultChecked="yes"
                      onClick={() => this.setState({ towing_brake_control: 0 })}
                    />{' '}
                    No
                  </div>
                </div>
              </div>
            </div>

            <span className="sectionTitle">Additional Information</span>
            <div className="formRow">
              <div className="hireEnquiryInputBox4">
                <span className="hireEnquiryInputBoxName">Optional Extras</span>
                <input
                  className="hireEnquiryInput"
                  onChange={(event) =>
                    this.setState({ info_extra: event.target.value })
                  }
                  placeholder="Optional Extras"
                />
              </div>
            </div>
            <div className="formRow">
              <div className="hireEnquiryInputBox4">
                <span className="hireEnquiryInputBoxName">
                  What experience have you had with towing?*
                </span>
                <input
                  required
                  className="hireEnquiryInput"
                  placeholder="What experience have you had with towing?"
                  onChange={(event) =>
                    this.setState({
                      info_experience_towing: event.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="formRow">
              <div className="hireEnquiryInputBox4">
                <span className="hireEnquiryInputBoxName">Booking Notes*</span>
                <textarea
                  className="orderNotesText"
                  id="orderNotesText"
                  name="orderNotesText"
                  rows="10"
                  onChange={(event) =>
                    this.setState({ info_notes: event.target.value })
                  }
                  cols="50"
                  required
                ></textarea>
              </div>
            </div>
            <button
              className="hireEnquirySubmitButton"
              onClick={this.clickSubmit}
            >
              Submit Enquiry
            </button>
          </form>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Hire Enquiry" link="/hire-enquiry" />
        {this.state.is_sent
          ? this.sent()
          : this.state.vehicle
          ? this.form()
          : null}
        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(HireEnquiryPage)
