import React from 'react'
import * as bookingService from '../BookingService'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import * as BookingService from 'Services/bookings'
import { formatCurrency } from 'shared/utils.js'

export default class EditBookingAction extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showForm: false,
      processing: false,
      defaultStatus: '',
      statuses: [
        '',
        'unpaid',
        'paid',
        'onhire',
        'cancelled',
        'paid',
        'refunded',
        'completed',
      ],

      //default
      bookingServiceFee: props.booking.bookingFee,
      amenitiesFee: props.booking.totalAddonsAmount,
      insuranceFee: props.booking.insuranceFee,
      total: props.booking.totalAmount,
      rateBreakdown: props.booking.rateBreakdown,

      _start_date: new Date(props.booking.startDate),
      _end_date: new Date(props.booking.endDate),
      ...props,
    }
  }

  recalculate = () => {
    let obj = BookingService.calculate_booking_numbers(
      this.state.booking.addonAmenities,
      this.state._start_date,
      this.state._end_date,
      this.state.booking.rateRules,
      this.state.booking.pricePerDay,
      this.state.booking.bondAmount
    )

    this.setState(obj)
  }

  async clickUpdateStatus() {
    let status = this.props.booking.status

    if (this.state.defaultStatus !== '') {
      status = this.state.defaultStatus
    }

    const result = await bookingService.updateManualBookingStatus({
      status,
      start_date: this.state._start_date,
      end_date: this.state._end_date,
      booking_id: this.props.booking.id,
      service_fee: this.state.bookingServiceFee,
      insurance_fee: this.state.insuranceFee,
      total_amount: this.state.total,

      total_earnings: this.state.total_earnings,
      commission: this.state.commission,
      total_commission: this.state.total_commission,
    })

    if (result.success) {
      await localStorage.setItem('message_flash', 'Status has been updated!')
      window.location.href = '/dashboard/bookings'
    }
  }

  handleInputChangeFrom = (date, e) => {
    this.setState({ _start_date: date })
    setTimeout(() => {
      this.recalculate()
    }, 100)
  }

  handleInputChangeEnd = (date, e) => {
    this.setState({ _end_date: date })
    setTimeout(() => {
      this.recalculate()
    }, 100)
  }

  renderEndDate() {
    return (
      <>
        End Date:{' '}
        <DatePicker
          required
          placeholderText="End Date"
          onChange={this.handleInputChangeEnd}
          dateFormat="dd/MM/yyyy"
        />{' '}
        <br />{' '}
      </>
    )
  }

  render() {
    return (
      <>
        <button
          className="edit-booking-btn"
          title="Click to edit booking"
          onClick={() => this.setState({ showForm: true })}
        >
          <FontAwesomeIcon icon="edit" />
        </button>

        <Modal
          show={this.state.showForm}
          onHide={() => this.setState({ showForm: false })}
          dialogClassName="bondclaim-modal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h2>Edit Booking</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Start Date:{' '}
              <DatePicker
                selected={this.state._start_date}
                onChange={this.handleInputChangeFrom}
                required
                placeholderText="Start Date"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <br />
            <div>
              End Date:{' '}
              <DatePicker
                selected={this.state._end_date}
                onChange={this.handleInputChangeEnd}
                required
                placeholderText="End Date"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <br />
            Service Fee: {formatCurrency(this.state.bookingServiceFee)} <br />
            Total Amenities: {formatCurrency(this.state.amenitiesFee)} <br />
            Insurance Fee: {formatCurrency(this.state.insuranceFee)} <br />
            Current Total : {formatCurrency(this.state.total)} <br />
            <br />
            <div>Current Satus: {this.props.booking.status}</div>
            <br />
            Change to: &nbsp;
            <select
              defaultValue={{ label: 'Select Status', value: '' }}
              onChange={(event) =>
                this.setState({ defaultStatus: event.target.value })
              }
            >
              {this.state.statuses &&
                this.state.statuses.map((item, index) => {
                  if (item === '') {
                    return <option key={index}>[select status]</option>
                  } else {
                    return <option key={index}>{item}</option>
                  }
                })}
            </select>{' '}
            <br />
            <br />
            Note: Not all statuses are covered in this manual update. Only the
            ones that can be manually change are available. <br />
            Changing status will auto send email notifications to the
            recipients. <br />
            Any money transfer/payout or reversal associated to the action
            should be manually process in stripe. <br /> <br />
            <button
              className="btn btn-secondary"
              onClick={() => this.setState({ showForm: false })}
            >
              Back
            </button>
            &nbsp;
            <button
              className="btn btn-primary"
              onClick={() => this.clickUpdateStatus()}
            >
              Save Booking
            </button>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}
