import React from 'react'
import { withRouter } from 'react-router'
import * as supportAdminService from './SupportAdminPageService'
import './SupportAdminPage.scss'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'

class SupportAdminPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      state: 'list',
      list: [],
      search: '',
      meta: null,
      item: false,
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-support-page')
  }

  async fetchLatest() {
    let params = {}
    if (this.state.meta !== null) {
      let meta = { filter: this.state.filter, ...this.state.meta }
      params = { filter: meta, ...params }
    }

    let result = await supportAdminService.fetch(params)

    console.log('check fetch latest ', result)
    if (result.success) {
      this.setState({ list: result.data, meta: result.meta })
    }
  }

  clickSendEmail = async (item) => {
    window.location.href = 'mailto:'
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    //*
    await this.setState({ meta: _meta })
    await this.fetchLatest()
    //*/
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    return (
      <>
        <div className="support-container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Date Submitted</th>
                <th>Author</th>
                <th>Email</th>
                <th>Phone</th>
                <th style={{ width: '400px' }}>Comment</th>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length > 0 &&
                this.state.list.map((item, index) => {
                  return (
                    <tr
                      key={item.id}
                      className={index % 2 === 0 ? 'even' : 'odd'}
                    >
                      <td>{item.id}</td>
                      <td>{moment(item.createdAt).format('LL')}</td>
                      <td>
                        {item.userId > 0 ? (
                          <a href={'/dashboard/user/' + item.userId}>
                            {item.firstName} {item.lastName}
                          </a>
                        ) : (
                          <>
                            {item.firstName} {item.lastName} (guest)
                          </>
                        )}
                      </td>
                      <td>
                        <a href={'mailto:' + item.email}>{item.email}</a>
                      </td>
                      <td>{item.phone}</td>

                      <td>{item.comment}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div style={{ float: 'right', marginTop: '20px' }}>
            {this.state.meta !== null && (
              <Pagination
                count={this.state.meta.count}
                variant="outlined"
                shape="rounded"
                onChange={this.clickPagination}
                size={'large'}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(SupportAdminPage)
