import React from 'react'
import { withRouter } from 'react-router'
import * as messageService from './MessageService'
import './MessageInboxPage.scss'
import { NotificationManager } from 'react-notifications'

class NewMessage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message: props.message,
      screen: 'form',
      inputMessage: '',
      inputSubject: '',
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  //createMessage
  sendMessageHandler = async () => {
    const params = {
      subject: this.state.inputSubject,
      body: this.state.inputMessage,
      to: this.state.message.from,
      from: this.state.message.to,
    }

    const result = await messageService.create(params)

    if (result.success) {
      await localStorage.setItem(
        'message_flash',
        'Your message has been sent successfully.'
      )
      window.location.href = '/dashboard/messages/'
    } else {
      NotificationManager.error('There was a problem sending the message')
    }
  }

  clickBack = () => {
    window.location.href = '/dashboard/messages/'
  }

  clickBackRead = () => {
    this.setState({ screen: 'read' })
  }

  render() {
    if (this.state.screen === 'sent') {
      return (
        <>
          <div className="reply-message-container">
            <div className="row">
              <div className="col-10">
                <button onClick={() => this.clickBackRead()}>Back</button>
              </div>
              <div className="col-2 text-right"></div>
            </div>

            <div className="message-sent">
              <h2>Your message has been sent!</h2>
            </div>
          </div>
        </>
      )
    } else if (this.state.screen === 'form') {
      return (
        <>
          <div className="reply-message-container">
            <div className="row">
              <div className="col-10">
                <button
                  className="btn btn-secondary"
                  onClick={() => this.clickBack()}
                >
                  Back
                </button>
              </div>
              <div className="col-2 text-right"></div>
            </div>
            <div>
              <div className="row read-message-content">
                <div className="col-12">
                  <div>
                    {' '}
                    <b>Send To:</b> {this.state.message.firstName}{' '}
                    {this.state.message.lastName}{' '}
                  </div>
                  <div>
                    {' '}
                    <b>Subject:</b>{' '}
                    <input
                      name="inputSubject"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.inputSubject}
                    />{' '}
                  </div>
                  <div>
                    {' '}
                    <b>Message:</b> <br />
                    <textarea
                      name="inputMessage"
                      onChange={this.handleChange}
                      defaultValue={this.state.inputMessage}
                    ></textarea>
                  </div>

                  <div>
                    {' '}
                    <button
                      className="btn btn-primary"
                      onClick={() => this.sendMessageHandler()}
                      disabled={
                        this.state.inputMessage === '' ||
                        this.state.inputSubject === ''
                      }
                    >
                      Send
                    </button>{' '}
                    &nbsp;
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.clickBackRead()}
                    >
                      Cancel
                    </button>{' '}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-10"></div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </>
      )
    }
  }
}

export default withRouter(NewMessage)
