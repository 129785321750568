import React from 'react'
import Carousel from 'react-elastic-carousel'

import './PartnersAndMedia.scss'
import PartnersAndMediaItems from '../Items/PartnersAndMediaItems'

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 6 },
  { width: 768, itemsToShow: 8 },
  { width: 1200, itemsToShow: 10 },
]

const PartnersAndMedia = ({ showPartnersAndMediaText }) => {
  const showPnMText = showPartnersAndMediaText ? true : false

  return (
    <React.Fragment>
      <div className="partnersAndMediaContainer">
        <div className="partnersAndMediaTitle">Partners And Media</div>
        {showPnMText ? (
          <div className="partnersAndMediaTextBox">
            <span className="partnersAndMediaText1">
              We have a large number of different Business Partners and
              affiliates across Australia that continually assist with the
              exposure and advertising of the CnC Hire AUS brand which
              significantly increases the reach and number of hire bookings for
              our registered Hire Partners/Caravan/Motorhome/Camper Trailer
              Owners.
            </span>

            <span className="partnersAndMediaText1">
              They also make local and global families and tourists aware that
              they can explore Australia in a beautiful Hire vehicle.
            </span>

            <span className="partnersAndMediaText1">
              Our Parters provide fantastic rewards and discounts for both
              Hirers and Owners who are part of the CnC Hire AUS community!
              <ul>
                <li>
                  If you would like to also like to Parter with us please send
                  us an email: enquiries@caravanandcampinghire.com
                </li>
              </ul>
            </span>
          </div>
        ) : null}
        <div className="sliderContainer">
          <Carousel
            pagination={false}
            breakPoints={breakPoints}
            enableAutoPlay={true}
            showArrows={false}
          >
            {PartnersAndMediaItems.map((item, index) => {
              return (
                <a
                  className="partnerAndMediaItem"
                  href={item.link}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  key={index}
                >
                  <img alt="" src={item.icon} className="partnerAndMediaIcon" />
                </a>
              )
            })}
          </Carousel>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PartnersAndMedia
