import React from 'react'
import { withRouter } from 'react-router'
import './VehicleDetailPage.scss'
import * as vehicleDetailService from './VehicleDetailService'
import { Link } from 'react-router-dom'
import { formatCurrency } from 'shared/utils.js'
import { NotificationManager } from 'react-notifications'
import * as vehicleListService from '../VehicleListPage/VehicleListService'

class VehicleDetailPage extends React.Component {
  constructor(props) {
    super(props)

    let { id } = this.props.match.params

    this.state = {
      userRole: this.props.user.userRole,
      addon_amenities: [],
      data: null,
      vehicle_images: [],
      isApproved: false,
    }

    window.location.href = '/vehicle/' + id
    //<td><a href={"/dashboard/vehicles/" + item.vehicleId}>{item.vehicleName}</a></td>

    this.fetchLatest(id)
  }

  componentDidMount() {
    document.body.classList.add('dashboard-vehicle-details-page')
  }

  async fetchLatest(id) {
    await vehicleDetailService.fetch(id).then((result) => {
      console.log('vehicle detail result is ', result)
      if (result.success) {
        let images = result.data.vehicle.images
          ? JSON.parse(result.data.vehicle.images)
          : []
        const addon_amenities = result.data.vehicle.addonAmenities
          ? JSON.parse(result.data.vehicle.addonAmenities)
          : []
        console.log('addon_amenities', addon_amenities)
        this.setState({ addon_amenities })
        this.setState({ vehicle_images: images })
        this.setState({ data: result.data })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  clickApprove = async (id) => {
    const result = await vehicleListService.approve(id)
    if (result.success) {
      let data = this.state.data
      data.vehicle.isApproved = true
      await this.setState({ data })
      NotificationManager.success('The vehicle has been approved.', 'Success')
    } else {
      NotificationManager.error(
        'There was a problem approving the vehicle at the moment.',
        'Error'
      )
    }
  }

  render() {
    if (!(this.state.data !== null)) {
      return <></>
    } else {
      const vehicle = this.state.data.vehicle
      return (
        <>
          {this.state.userRole === 'Owner' && (
            <div>
              <Link
                to="/dashboard/vehicles"
                className="btn btn-primary btn-back"
              >
                Go to Vehicles
              </Link>
              <Link
                to={'/vehicle/' + vehicle.id}
                className="btn btn-primary btn-back"
              >
                View Vehicle in Front Page
              </Link>
              <br />
              <br />{' '}
            </div>
          )}

          <div className="vehicles-container">
            {vehicle.isApproved === 0 && this.state.userRole === 'Admin' && (
              <div>
                <button
                  onClick={() => this.clickApprove(vehicle.id)}
                  className="btn btn-primary btn-approve"
                >
                  Approve
                </button>
                <br />
                <br />
              </div>
            )}

            <div className="main-body-container">
              <h1>{vehicle.vehicleName}</h1>

              <div className="line-item">
                <b>Vehicle Type:</b> {vehicle.vehicleType}
              </div>

              <div className="line-item">
                <b>Location:</b> {vehicle.location}
              </div>

              <div className="line-item">
                <b>Hire Type:</b> {vehicle.hireType}
              </div>

              <div className="line-item">
                <b>Vehicle Images:</b> <br />
                {this.state.vehicle_images.length > 0 &&
                  this.state.vehicle_images.map((item, index) => {
                    return <img key={index} alt="thumbnail" src={item.image} />
                  })}
              </div>

              <div className="line-item">
                <b>Daily Rate:</b> {formatCurrency(vehicle.pricePerDay)} / day
              </div>

              <div className="line-item">
                <b>Description:</b> <br />
                {vehicle.description}
              </div>

              <div className="line-item">
                <b>Included Amenities:</b> <br />
                <ul>
                  {this.state.data &&
                    this.state.data.amenities.length > 0 &&
                    this.state.data.amenities
                      .filter((x) => x.selected)
                      .map((item, index) => {
                        return <li key={item.id}>{item.amenity}</li>
                      })}
                </ul>
              </div>

              <div className="line-item">
                <b>Addon Amenities:</b> <br />
                <ul>
                  {this.state.addon_amenities &&
                    this.state.addon_amenities &&
                    this.state.addon_amenities.map((item, index) => {
                      return (
                        <li key={index}>
                          {' '}
                          {item.amenity} - plus {formatCurrency(item.price)}{' '}
                        </li>
                      )
                    })}
                </ul>
              </div>

              <h4>Vehicle Details:</h4>

              <div className="line-item">
                <b>Make:</b> {vehicle.vehicleMake}{' '}
              </div>
              <div className="line-item">
                <b>Model:</b> {vehicle.vehicleModel}{' '}
              </div>
              <div className="line-item">
                <b>Width:</b> {vehicle.vehicleWidth}{' '}
              </div>
              <div className="line-item">
                <b>Manufactured:</b> {vehicle.vehicleYearManufactured}{' '}
              </div>
              <div className="line-item">
                <b>Family Friendly:</b>{' '}
                {vehicle.vehicleFamilyFriendly ? 'Yes' : 'Not Permitted'}{' '}
              </div>
              <div className="line-item">
                <b>Pet Friendly:</b>{' '}
                {vehicle.vehiclePetFriendly ? 'Yes' : 'Not Permitted'}{' '}
              </div>
              <div className="line-item">
                <b>Sleep:</b> {vehicle.vehicleSleeps}{' '}
              </div>

              <h4>Towing Details:</h4>
              <div className="line-item">
                <b>Tare:</b> {vehicle.towingTare}{' '}
              </div>
              <div className="line-item">
                <b>Towing Weight:</b> {vehicle.towingTowballWeight}{' '}
              </div>
              <div className="line-item">
                <b>ATM:</b> {vehicle.towingAtm}{' '}
              </div>
              <div className="line-item">
                <b>Towing Plug:</b> {vehicle.towingPlug}{' '}
              </div>

              <h4>Condition Of Use:</h4>
              <div className="line-item">
                <b>Minimum Driver Age:</b> {vehicle.conditionMinDriverAge}{' '}
              </div>
              <div className="line-item">
                <b>Permitted Road Types:</b>{' '}
                {vehicle.conditionPermittedRoadTypes}{' '}
              </div>
              <div className="line-item">
                <b>Beach Access:</b>{' '}
                {vehicle.conditionBeachAccess ? 'Yes' : 'No'}{' '}
              </div>
              <div className="line-item">
                <b>River Crossings:</b>{' '}
                {vehicle.conditionRiverCrossing ? 'Yes' : 'No'}{' '}
              </div>
              <div className="line-item">
                <b>Outback Roads:</b>{' '}
                {vehicle.conditionOutbackRoads ? 'Yes' : 'No'}{' '}
              </div>
              <div className="line-item">
                <b>Vehicle Specific Terms:</b> {vehicle.conditionSpecificTerms}{' '}
              </div>
            </div>
          </div>
        </>
      )
    }
  }
}

export default withRouter(VehicleDetailPage)
