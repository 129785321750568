const facebookIcon = '//cdn.cnchire.com.au/facebook.png'
const instagramIcon = '//cdn.cnchire.com.au/Instagram.png'
const twitterIcon = '//cdn.cnchire.com.au/Twitter.png'
const youtubeIcon = '//cdn.cnchire.com.au/YouTube.png'

const SocialMediaItems = [
  {
    icon: facebookIcon,
    link: 'https://www.facebook.com/caravanandcampinghire/',
  },
  {
    icon: instagramIcon,
    link: 'https://www.instagram.com/caravanandcampinghireaus/',
  },
  {
    icon: twitterIcon,
    link: 'https://twitter.com/caravancamphire/',
  },
  {
    icon: youtubeIcon,
    link: 'https://www.youtube.com/user/caravanandcamphire/',
  },
]

export default SocialMediaItems
