import axios from 'axios'

export const searchLocation = async (location) => {
  const _api_url = `https://maps.googleapis.com/maps/api/geocode/json?region=au&address=${encodeURI(
    location
  )}&key=AIzaSyB1ht0n8AFV5GiGi6xctMu4lU5xiQD4OU4`
  const result = await axios.get(_api_url, {}) // .then( function).catch(_error);

  // Australia
  // filter australia
  const matched =
    result && result.data && result.data.results.length > 0
      ? result.data.results[0].formatted_address
      : ''

  if (matched.search('Australia') > -1) {
    return result
  }
  return false
}
