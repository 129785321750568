import React from 'react'
import { Icon, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import Footer from '../../Components/Footer/Footer'
import * as ForgotPageService from './ForgotPageService'

import './ForgotPage.scss'

class ForgotPage extends React.Component {
  message = ''

  constructor(props) {
    super(props)

    const id = props.match.params.id

    this.state = {
      isSuccess: false,
      id,
      isProcessing: false,
      password: '',
      confirm_password: '',
      error_message: '',
    }
  }

  clickResetPassword = async () => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    var email = this.state.email

    if (!email || !email.match(mailformat)) {
      this.setState({ error_message: 'Invalid email address' })
    } else {
      this.setState({ isProcessing: true })
      const result = await ForgotPageService.reset({ email: this.state.email })
      if (result.success) {
        this.setState({ isSuccess: true })
      } else {
        this.setState({ error_message: 'Cant find the email address.' })
        this.setState({ isProcessing: false })
      }
    }
  }

  render() {
    return (
      <>
        <React.Fragment>
          <NavBar linkTextColor={{ color: '#000000' }} />
          <PageBanner title="Forgot Password" link="/forgot-password" />

          <div className="loginContainer">
            <div className="imageFormContainer">
              <img
                className="loginImageIcon"
                alt=""
                src="//cdn.cnchire.com.au/loginImage.png"
              />

              <div className="loginFormContainer">
                {!this.state.isSuccess ? (
                  <>
                    {this.state.error_message !== '' ? (
                      <div className="loginErrorMessage">
                        {this.state.error_message}
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="loginAccountTitle">Forgot Password</div>

                    <i>
                      Please enter your email address to request password reset.
                    </i>
                    {this.state.invalidLogin && (
                      <div className="loginErrorMessage">
                        Invalid Email/Password.
                      </div>
                    )}

                    <Input
                      className="loginInputBox"
                      iconPosition="left"
                      placeholder="email"
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    >
                      <Icon name="user" />
                      <input />
                    </Input>

                    {this.state.isProcessing ? (
                      <button className="loginSubmitButtonWait" type="button">
                        <span className="loginSubmitButtonLabel">
                          Please Wait...
                        </span>
                      </button>
                    ) : (
                      <button
                        className="loginSubmitButton reset-btn"
                        onClick={() => this.clickResetPassword()}
                      >
                        <span className="loginSubmitButtonLabel">
                          Reset Password
                        </span>
                      </button>
                    )}

                    <div className="signUpDetails">
                      <span className="singUpDetailsText">
                        Don't have an account?
                      </span>
                      <span className="singUpDetailsText">
                        Sign Up as{' '}
                        <Link
                          to="/register?type=owner"
                          className="signUpDetailsLink"
                        >
                          Owner
                        </Link>
                        {' or '}
                        <Link
                          to="/register?type=hirer"
                          className="signUpDetailsLink"
                        >
                          Hirer
                        </Link>
                      </span>
                    </div>
                  </>
                ) : (
                  <div
                    className="loginAccountTitle"
                    style={{ textAlign: 'center' }}
                  >
                    Successfully sent a password reset request.
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
        <Footer />
      </>
    )
  }
}

export default withRouter(ForgotPage)
