import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import store from '../../Redux/store'
import fp from 'lodash/fp'

import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import SingleFAQ from 'Components/SingleFAQ/SingleFAQ'
import Footer from 'Components/Footer/Footer'
import './SingleFAQPage.scss'

class SingleFAQPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('single-faq-page')
    this.props.fetchFAQItem(this.props.match.params.id)
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="FAQs" link="/faqs" />
        <SingleFAQ faq={this.props.faqInfo} />
        <Footer />
      </React.Fragment>
    )
  }
}

SingleFAQPage.propTypes = {
  faqInfo: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  fetchFAQItem: dispatch.faq.fetchFAQItem,
})

const mapStateToProps = (state) => ({
  faqInfo: store.select.faq.currentFAQ(state),
})

export default fp.flow(connect(mapStateToProps, mapDispatchToProps))(
  SingleFAQPage
)
