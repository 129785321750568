import React from 'react'
import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import FAQ from 'Components/FAQ/FAQ'
import Footer from 'Components/Footer/Footer'

import './FAQPage.scss'
import * as faqPageService from './FAQPageService'
import { withRouter } from 'react-router'

class FAQPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('faq-page')
  }

  async fetchLatest() {
    await faqPageService.fetch().then((result) => {
      this.setState({ list: result.data })
    })
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="FAQs" link="/faqs" />
        <FAQ faqs={this.state.list} />
        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(FAQPage)
