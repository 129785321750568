import React, { useState } from 'react'

const Topbar = ({
  dashboardStats,
  user,
  setShowSidebar,
  showSidebar,
  page_title,
}) => {
  const [showAlert, setShowAlert] = useState(false)

  const clickLogout = (event) => {
    localStorage.removeItem('persist:root')
    window.location.href = `/logout`
  }

  // const clickNotification = async (item) =>{
  //   await dashboardService.markNotificationRead(item.id);
  //   window.location.href = item.pageLink;
  // }

  return (
    <>
      <div className="top-container ">
        <div className="row">
          <div className="col-8">
            <span
              className="more"
              onClick={() => {
                setShowSidebar(!showSidebar)
              }}
            ></span>
            <input
              type="input"
              className="search-input"
              placeholder="Search"
              style={{ display: 'none' }}
            />
          </div>
          <div className="col-4">
            <div className="row topnav-item-container ">
              <div
                className="col-2"
                title="Open Notifications"
                onClick={() => {
                  setShowAlert(
                    dashboardStats.notificationCount > 0 && !showAlert
                  )
                }}
              >
                <span className="alert-icon">
                  <i>0</i>
                </span>

                {/*<span className="alert-icon"> 
                                                   <i>{dashboardStats.notificationCount}</i> 

                                                   {showAlert && ( <>
                                                   <div className="notification-box" >
                                                        <span className="arrow-tabon">
                                                          <FontAwesomeIcon icon="angle-up"  />
                                                        </span>
                                                      <ul>
                                                                    {dashboardStats.notifications.map((item, index) => {
                                                                        return (<li key={item.id} >
                                                                            <div onClick={() => clickNotification(item) }  >{item.message}</div>
                                                                          </li>);
                                                                      })}
                                                      </ul>
                                                    </div> 
                                                    <div onClick={() => { setShowAlert(!showAlert); }}  className="notification-box-overlay"></div>
                                                    </>
                                                    ) }

                                                                    </span> */}
              </div>
              <div className="col-2  " title="Open Messages">
                <a href="?chat=1">
                  <span className="message-icon">
                    <i>{dashboardStats.pmCount}</i>
                  </span>
                </a>
              </div>
              <div className="col-2  ">
                <a href={'/dashboard/user/' + user.id}>
                  <span className="thumb"></span>
                </a>
              </div>
              <div className="col-6 name">
                <h4>{user.firstName}</h4>
                <div className="user-type">{user.userRole}</div>
                <div className="account-box">
                  <div className="toogle-button">
                    <span className="toogle-button-icon"></span>

                    <div className="inner-nav">
                      <ul>
                        <li>
                          <a href={'/dashboard/user/' + user.id + '?update=1'}>
                            Settings
                          </a>
                        </li>
                        <li>
                          <a
                            href="/logout"
                            onClick={() => {
                              clickLogout()
                            }}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {page_title !== 'Dashboard' && (
        <div className="body-top-area">
          <div className="row">
            <div className="col-6">
              <h1>{page_title}</h1>
            </div>
            <div className="col-6 text-right">
              <a href="/dashboard/">Dashboard</a> |
              <span className="active" style={{ paddingLeft: '2px' }}>
                {page_title}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Topbar
