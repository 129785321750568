import React from 'react'
import { withRouter } from 'react-router'
import './SalesStats.scss'
import moment from 'moment'
import { formatCurrency } from 'shared/utils.js'

const ReactHighcharts = require('react-highcharts')
const revenueColor = '#536CFB'
const commissionColor = '#419788'

class SalesStatsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      from: this.props.from,
      to: this.props.to,
      totalearnings: 0,
      totalCommissions: 0,
      reportData: null,
      stats: this.props.data,
    }
  }

  componentDidMount() {
    this.fetchLatest()
  }

  async fetchLatest() {
    let data = this.state.stats
    let commissions = data.commissions
    let earnings = data.earnings
    let _categories = []
    let _commissions = []
    let _earnings = []
    let _totalearnings = 0
    let _totalCommissions = 0

    for (let i = 0; i < earnings.length; i++) {
      var _cat = ''
      if (earnings[i]) {
        _earnings.push(earnings[i].total)
        _totalearnings += earnings[i].total
        _cat = earnings[i].month
      } else {
        _earnings.push(0)
      }

      if (commissions[i]) {
        _commissions.push(commissions[i].total)
        _totalCommissions += commissions[i].total
        _cat = commissions[i].month
      } else {
        _commissions.push(0)
      }

      _categories.push(_cat)
    }

    let reportData = {
      chart: { backgroundColor: '#F7F7F7', zoomType: 'xy' },
      title: { text: '' },
      credits: { enabled: false },
      xAxis: { categories: _categories, crosshair: true },
      yAxis: { min: 0, title: { text: 'AUD' } },
      // { headerFormat: '',  }, //<b>{series.name}</b><br>

      tooltip: {
        formatter: function () {
          return this.point.series.name + ': <b> AUD' + this.point.y + '</b>'
        },
      },

      plotOptions: {
        column: {},
        series: { pointPadding: 0, groupPadding: 0.1 },
      },

      series: [
        {
          showInLegend: false,
          name: 'Revenue',
          type: 'column',
          color: revenueColor,
          data: _earnings,
        },
        {
          showInLegend: false,
          name: 'Commission',
          type: 'column',
          color: commissionColor,
          data: _commissions,
        },
      ],
    }

    this.setState({
      reportData,
      totalCommissions: _totalCommissions,
      totalearnings: _totalearnings,
    })
  }

  render() {
    if (!this.state.stats) {
      return <>Sales..</>
    } else {
      return (
        <>
          <div className="sales-stats">
            {this.state.from !== '' ? (
              <h4>
                Sales Stats from {moment(this.state.from).format('LL')} to{' '}
                {moment(this.state.to).format('LL')}
              </h4>
            ) : (
              <h4>Sales Stats from last 12months</h4>
            )}

            {this.state.totalearnings === 0 ? (
              <>No data matched in this search. </>
            ) : (
              <>
                <br />
                <br />

                <div className="chart-legend">
                  <div className="revenue-legend">
                    {formatCurrency(this.state.totalCommissions)} AUD
                  </div>
                  <div className="commision-legend">
                    {formatCurrency(this.state.totalearnings)} AUD
                  </div>
                </div>

                <br />
                <br />
                <br />
                <br />

                {this.state.reportData && (
                  <>
                    {this.totalCommissions === 0 ? (
                      <>No data matched to this search.</>
                    ) : (
                      <ReactHighcharts config={this.state.reportData} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )
    }
  }
}

export default withRouter(SalesStatsComponent)
