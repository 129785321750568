import { api_request } from '../../../Services/helpers'

export const fetch = async (params = {}) =>
  await api_request('post', '/auth/messages/list', params, true)

export const remove = async (params = {}) =>
  await api_request('delete', `/auth/messages/${params.id}`, {}, true)

export const create = async (params = {}) =>
  await api_request('post', '/auth/messages/create', params, true)

export const get = async (id) =>
  await api_request('get', `/auth/messages/${id}`, {}, true)

export const markMessageRead = async (id) =>
  await api_request('get', `/auth/dashboard/read_message/${id}`, {}, true)
