import React from 'react'
import { Link } from 'react-router-dom'
import './PageBanner.scss'

const PageBanner = ({ title }) => {
  return (
    <React.Fragment>
      <div className="pageBannerContainer">
        <div className="pg-banner">
          <div className="pg-banner-title">{title}</div>

          <div className="pg-banner-description">
            <Link to="/" className="pg-banner-linksBanner">
              Home
            </Link>
            <span className="pg-banner-linksBanner">|</span>
            <span className="pg-banner-linksBanner-title">{title}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PageBanner
