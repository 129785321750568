import React from 'react'
import { withRouter } from 'react-router'
import './LocationSearches.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class LocationSearchesComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stats: this.props.data,
    }
  }

  render() {
    if (!this.state.stats) {
      return <>Location Searches..</>
    } else {
      return (
        <>
          <div className="location-searches">
            <h4>Top Location Searches</h4>

            <div className="location-list">
              {this.state.stats.length === 0 && (
                <>No data matched in this search. </>
              )}

              {this.state.stats.length > 0 &&
                this.state.stats.map((item, index) => {
                  return (
                    <div key={index} className="location-item">
                      <div className="circle"></div>
                      <div className="name">
                        {item.location}
                        <div className="count"> {item.count}</div>
                      </div>
                    </div>
                  )
                })}

              <div className="clearfix"></div>
            </div>
            <div
              className="more-searches"
              onClick={() =>
                (window.location.href = '/dashboard/location-search')
              }
              title="More Location Search"
            >
              <FontAwesomeIcon icon="angle-double-right" />
            </div>
          </div>
        </>
      )
    }
  }
}

export default withRouter(LocationSearchesComponent)
