import React from 'react'
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { format } from 'date-fns'
import Dropdown from 'react-bootstrap/Dropdown'
import { Icon, Input } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import './AdvanceSearchBox.scss'
import '../AdvanceSearchModal/AdvanceSearchModal.scss'
import moment from 'moment'
import VehicleTypes from '../Items/VehicleTypes'
import * as css from 'react-day-picker/lib/style.css'
import 'Pages/VehicleDetailsPage/components/VehicleDetails/VehicleDetails.scss'
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment'
import {
  travellersItems,
  priceItems,
  hireTypeItems,
  minYearItems,
  maxYearItems,
} from '../Items/AdvanceSearchModalItems'

import * as geoLocation from '../../Services/geolocation'
import useAnalyticsEventTracker from 'Pages/App/useAnalyticsTracker'

const AdvanceSearchBox = (props) => {
  const tracker = useAnalyticsEventTracker('Search')
  return <AdvanceSearchBoxOld {...props} tracker={tracker} />
}
class AdvanceSearchBoxOld extends React.Component {
  constructor(props) {
    super(props)

    const parsed = queryString.parse(this.props.location.search)

    this.state = {
      showDates: false,
      vehicleTypeValue: this.props.vehicleType ? this.props.vehicleType : '',
      numberOfTravellers: parsed.numberOfTravellers || '',
      minPriceValue: parsed.minPrice || '',
      maxPriceValue: parsed.maxPrice || '',
      hireTypeValue: parsed.hireType || '',
      minYearValue: parsed.minYear || '',
      maxYearValue: parsed.maxYear || '',
      keywordValue: parsed.keyword || '',
      showAdvanceSearch: parsed.advanceSearch === 'true',
      keywords: '',
      location: this.props.address || '',
      lng: this.props.lng,
      lat: this.props.lat,
      locationCompleterNotFound: '',
      locationCompleter: '',
      pleaseWait: false,
      start_date: this.props.dateRange
        ? new Date(this.props.dateRange.from)
        : undefined,
      end_date: this.props.dateRange
        ? new Date(this.props.dateRange.to)
        : undefined,
    }
    this.GPRef = React.createRef()
    this.keywordsRef = React.createRef()
    this.timeout = 0

    this.startDatePickerOpened = true
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const locationInputVal = this.state.location

    if (locationInputVal === '' || locationInputVal === undefined) {
    } else if (this.state.lat === '' || this.state.lat === undefined) {
      // lat not yet defined?
      this.setState({ pleaseWait: true })
      await this.processGeoLocation(locationInputVal)
      this.setState({ pleaseWait: false })
      if (this.state.lat === '' || this.state.lat === undefined) {
        // hasError
      }
    }

    this.processSearch()
  }

  changeEndDate = async (date) => {
    console.log('END', date)
    await this.setState({ end_date: date })
    // this.dateProcessAfter()
  }

  changeStartDate = async (date) => {
    console.log('DATE', date)
    await this.setState({ start_date: date })
  }

  processSearch = () => {
    const { lat } = this.state
    const { lng } = this.state
    const locationInputVal = this.state.location

    let advanceSearch = this.state.showAdvanceSearch
    if (
      this.state.numberOfTravellers === '' &&
      this.state.minPriceValue === '' &&
      this.state.maxPriceValue === '' &&
      this.state.hireTypeValue === '' &&
      this.state.minYearValue === '' &&
      this.state.maxYearValue === ''
    ) {
      advanceSearch = false
    } else {
      advanceSearch = true
    }

    const location_search = this.state.lat
      ? `location=${locationInputVal}&lat=${lat}&lng=${lng}&`
      : ''

    const dateQuery =
      this.state.showDates === true
        ? `${
            this.state.start_date && this.state.end_date
              ? `&end_date=${moment(this.state.end_date).format(
                  'YYYY-MM-DD'
                )}&start_date=${moment(this.state.start_date).format(
                  'YYYY-MM-DD'
                )}`
              : ''
          }`
        : ''
    const link =
      `/search-results?${location_search}vehicleType=${this.state.vehicleTypeValue}&` +
      `advanceSearch=${advanceSearch}&numberOfTravellers=${this.state.numberOfTravellers}&` +
      `hireType=${this.state.hireTypeValue}&` +
      `minPrice=${this.state.minPriceValue}&` +
      `maxPrice=${this.state.maxPriceValue}&` +
      `minYear=${this.state.minYearValue}&` +
      `maxYear=${this.state.maxYearValue}&` +
      `keyword=${this.state.keywordValue}` +
      dateQuery

    this.props.tracker('redirect', link)
    window.location.href = link

    // this.props.history.push(link);
  }

  processGeoLocation = async (location) => {
    const result = await geoLocation.searchLocation(location)
    if (result && result.status === 200) {
      console.log('result is ', result)
      this.setState({
        locationCompleter: result.data.results[0].formatted_address,
        lat: result.data.results[0].geometry.location.lat,
        lng: result.data.results[0].geometry.location.lng,
      })
      this.props.tracker('geoLocation', result.data)
    }
  }

  handleAdvanceSearch(event) {
    event.preventDefault()
    this.setState({ showAdvanceSearch: !this.state.showAdvanceSearch })
  }

  handleReset(event) {
    event.preventDefault()

    this.setState({
      lat: '',
      lng: '',
      location: '',
      locationValue: '',
      vehicleTypeValue: '',
      numberOfTravellers: '',
      minPriceValue: '',
      maxPriceValue: '',
      hireTypeValue: '',
      minYearValue: '',
      maxYearValue: '',
      start_date: undefined,
      end_date: undefined,
    })
    this.props.tracker('resetSearchForm')
  }

  clickCompleterMatched = () => {
    this.timeout = -1

    this.setState({
      location: this.state.locationCompleter,
      locationCompleter: '',
    })
    this.props.tracker('completer', this.state.locationCompleter)
  }

  handleLocationChange = async (event) => {
    const location = event.target.value

    if (location === '') {
      if (this.timeout) clearTimeout(this.timeout)
      this.setState({ locationCompleter: '' })
      this.setState({ lat: '' })
      this.setState({ lng: '' })
    } else if (this.timeout === -1) {
      this.timeout = 0
    } else {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        await this.processGeoLocation(location)
      }, 100)
    }

    this.setState({ location })
    this.props.tracker('locationChange', location)
  }

  render() {
    const { vehicleTypeValue } = this.state

    const handleSelectVehicleType = (vehicleTypeValue) => {
      this.setState({ vehicleTypeValue })
    }

    const advanceSearchBox = this.props.advanceSearchBoxClassName
      ? this.props.advanceSearchBoxClassName
      : 'advanceSearchBox'

    const handleTravellersDropdown = (numberOfTravellers) => {
      this.setState({ numberOfTravellers })
    }

    const handleMinPriceDropdown = (minPriceValue) => {
      this.setState({ minPriceValue })
    }

    const handleMaxPriceDropdown = (maxPriceValue) => {
      this.setState({ maxPriceValue })
    }

    const handleHireTypeDropdown = (hireTypeValue) => {
      this.setState({ hireTypeValue })
    }

    const handleMinYearDropdown = (minYearValue) => {
      this.setState({ minYearValue })
    }

    const handleMaxYearDropdown = (maxYearValue) => {
      this.setState({ maxYearValue })
    }

    // let footer = <p>Please pick your date range.</p>
    // console.log('ST', this.state.start_date, this.state.end_date)
    // if (this.state.start_date) {
    //   if (!this.state.end_date) {
    //     footer = <p>{format(this.state.start_date, 'PPP')}</p>
    //   } else if (this.state.end_date) {
    //     footer = (
    //       <p>
    //         {format(this.state.start_date || moment.today(), 'PPP')}–
    //         {format(this.state.end_date || moment.today(), 'PPP')}
    //       </p>
    //     )
    //   }
    // }

    return (
      <div
        className={advanceSearchBox}
        style={this.state.showAdvanceSearch ? { top: '70%' } : null}
      >
        <div className="topRectangle" />
        <div className="advanceSearchBoxForm">
          <div className="locationSearchContainer">
            Location
            {this.state.pleaseWait && <span>Please wait..</span>}
            <br />
            <input
              type="text"
              autoComplete="off"
              name="location"
              className="searchInput"
              value={this.state.location}
              onChange={this.handleLocationChange}
            />
            {this.state.locationCompleter !== '' && (
              <div
                className="locationCompleter"
                onClick={() => this.clickCompleterMatched()}
              >
                {this.state.locationCompleter}
              </div>
            )}
          </div>
          <div className="advanceSearchContainer">
            Vehicle Type
            <Dropdown onSelect={handleSelectVehicleType}>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="vehicleTypeDropdown"
              >
                {vehicleTypeValue === '' ? (
                  <span className="typeTextValue">All Vehicle Types</span>
                ) : (
                  <span className="typeTextValue">{vehicleTypeValue}</span>
                )}
                {vehicleTypeValue === '' && !this.props.vehicleType ? (
                  <Icon
                    style={{ marginLeft: '55%' }}
                    size="small"
                    name="caret down"
                  />
                ) : null}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="All Vehicle Types"
                  className="navDropdownItem"
                >
                  All Vehicle Types
                </Dropdown.Item>
                {VehicleTypes.map((item, index) => (
                  <Dropdown.Item
                    eventKey={item.name}
                    key={index}
                    className="navDropdownItem"
                  >
                    <span className="navDropdownItem">{item.name}</span>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <button
            className="searchButton"
            type="submit"
            onClick={(event) => this.handleSubmit(event)}
          >
            <img
              className="searchIcon"
              alt=""
              src="https://cdn.cnchire.com.au/Search icon.png"
            />
          </button>
          <button
            className="advanceSearchButton"
            onClick={(event) => this.handleAdvanceSearch(event)}
            style={{ padding: '0 3rem' }}
          >
            Advanced
          </button>
          <button
            className="advanceSearchButton"
            onClick={(event) => this.handleReset(event)}
          >
            Reset
          </button>
        </div>
        {this.state.showDates && (
          <div
            className="advanceSearchBoxForm"
            style={{
              marginLeft: '2rem',
              marginBottom: '1rem',
              // display: 'flex',
              // flexDirection: 'row',
              justifyContent: 'flex-start',
              // width: '100%',
              // margin: '1rem',
            }}
          >
            <div
              className="datepicker-container startdate advanceSearchContainer"
              style={{ width: '35%', marginTop: '0.5rem' }}
            >
              Start Date
              <DayPickerInput
                component={(props) => (
                  <input
                    {...props}
                    className="searchInput"
                    style={{ width: '80%' }}
                  />
                )}
                classNames={{ containerx: 'searchInput' }}
                selected={this.state.start_date}
                minDate={new Date(moment().add(1, 'days'))}
                onDayChange={(date) => this.changeStartDate(date)}
                dateFormat="yyyy-mm-dd"
                formatDate={formatDate}
                format="LL"
                parseDate={parseDate}
                placeholder={`${formatDate(new Date(), 'LL', 'en-AU')}`}
                dayPickerProps={{
                  locale: 'en-AU',
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </div>

            <div
              className="datepicker-container enddate advanceSearchContainer"
              style={{ width: '35%', marginTop: '0.5rem' }}
            >
              End Date
              <DayPickerInput
                component={(props) => (
                  <input
                    {...props}
                    className="searchInput"
                    style={{ width: '80%' }}
                  />
                )}
                classNames={{ containerx: 'searchInput' }}
                minDate={new Date(moment().add(1, 'days'))}
                selected={this.state.end_date}
                onDayChange={(date) => this.changeEndDate(date)}
                dateFormat="yyyy-mm-dd"
                formatDate={formatDate}
                format="LL"
                parseDate={parseDate}
                placeholder={`${formatDate(new Date(), 'LL', 'en-AU')}`}
                dayPickerProps={{
                  locale: 'en-AU',
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </div>
          </div>
        )}
        {this.state.showAdvanceSearch && (
          <div className="advanceSearchBoxContainer">
            <form className="advanceSearchBoxContainerForm">
              <div className="firstRowModal">
                <div className="travellersBox">
                  Travellers
                  <Dropdown onSelect={handleTravellersDropdown}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="travellersDropdown"
                    >
                      {this.state.numberOfTravellers === '' ? (
                        <span className="typeText">Select</span>
                      ) : (
                        <span className="typeTextValue">
                          {this.state.numberOfTravellers}
                        </span>
                      )}
                      {this.state.numberOfTravellers === '' ? (
                        <Icon
                          style={{ marginLeft: '60%' }}
                          size="small"
                          name="caret down"
                        />
                      ) : null}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {travellersItems.map((item, index) => (
                        <Dropdown.Item eventKey={item.number} key={index}>
                          {item.number}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="minPriceBox">
                  Minimum Price
                  <Dropdown onSelect={handleMinPriceDropdown}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="minPriceDropdown"
                    >
                      {this.state.minPriceValue === '' ? (
                        <span className="typeText">Select</span>
                      ) : (
                        <span className="typeTextValue">
                          {this.state.minPriceValue}
                        </span>
                      )}
                      {this.state.minPriceValue === '' ? (
                        <Icon
                          style={{ marginLeft: '60%' }}
                          size="small"
                          name="caret down"
                        />
                      ) : null}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {priceItems.map((item, index) => (
                        <Dropdown.Item eventKey={item.price} key={index}>
                          {item.price}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="maxPriceBox">
                  Maximum Price
                  <Dropdown onSelect={handleMaxPriceDropdown}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="maxPriceDropdown"
                    >
                      {this.state.maxPriceValue === '' ? (
                        <span className="typeText">Select</span>
                      ) : (
                        <span className="typeTextValue">
                          {this.state.maxPriceValue}
                        </span>
                      )}
                      {this.state.maxPriceValue === '' ? (
                        <Icon
                          style={{ marginLeft: '60%' }}
                          size="small"
                          name="caret down"
                        />
                      ) : null}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {priceItems.map((item, index) => (
                        <Dropdown.Item eventKey={item.price} key={index}>
                          {item.price}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="hireTypeBox">
                  Hire Type
                  <Dropdown onSelect={handleHireTypeDropdown}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="hireTypeDropdown"
                    >
                      {this.state.hireTypeValue === '' ? (
                        <span className="typeText">Select</span>
                      ) : (
                        <span className="typeTextValue">
                          {this.state.hireTypeValue}
                        </span>
                      )}
                      {this.state.hireTypeValue === '' ? (
                        <Icon
                          style={{ marginLeft: '60%' }}
                          size="small"
                          name="caret down"
                        />
                      ) : null}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {hireTypeItems.map((item, index) => (
                        <Dropdown.Item eventKey={item.type} key={index}>
                          {item.type}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="secondRowModal">
                <div className="minYearBox">
                  Year Minimum
                  <Dropdown onSelect={handleMinYearDropdown}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="minYearDropdown"
                    >
                      {this.state.minYearValue === '' ? (
                        <span className="typeText">Select</span>
                      ) : (
                        <span className="typeTextValue">
                          {this.state.minYearValue}
                        </span>
                      )}
                      {this.state.minYearValue === '' ? (
                        <Icon
                          style={{ marginLeft: '60%' }}
                          size="small"
                          name="caret down"
                        />
                      ) : null}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {minYearItems.map((item, index) => (
                        <Dropdown.Item eventKey={item.year} key={index}>
                          {item.year}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="maxYearBox">
                  Year Maximum
                  <Dropdown onSelect={handleMaxYearDropdown}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="maxYearDropdown"
                    >
                      {this.state.maxYearValue === '' ? (
                        <span className="typeText">Select</span>
                      ) : (
                        <span className="typeTextValue">
                          {this.state.maxYearValue}
                        </span>
                      )}
                      {this.state.maxYearValue === '' ? (
                        <Icon
                          style={{ marginLeft: '60%' }}
                          size="small"
                          name="caret down"
                        />
                      ) : null}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {maxYearItems.map((item, index) => (
                        <Dropdown.Item eventKey={item.year} key={index}>
                          {item.year}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="keywordsBox">
                  Keyword(s)
                  <Input className="keywordsInputBox" placeholder="Keywords">
                    <input
                      className="keywordsInput"
                      name="keyword"
                      onChange={(event) =>
                        this.setState({ keywordValue: event.target.value })
                      }
                      value={this.state.keywordValue}
                      ref={this.keywordsRef}
                    />
                  </Input>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(AdvanceSearchBox)
