import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

import './ProductCard.scss'

const ProductCard = ({
  productName,
  productPrice,
  productImage,
  productId,
}) => {
  const useStyles = makeStyles({
    root: {
      width: 300,
    },
    media: {
      height: 140,
    },
  })

  const classes = useStyles()

  return (
    <React.Fragment>
      <div className="productCardContainer">
        <Card
          className={classes.root}
          onClick={() => (window.location.href = `/item/${productId}`)}
        >
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={productImage}
              title={productName}
            />

            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                <span className="productName">{productName}</span>
              </Typography>
              <Typography gutterBottom variant="h5" component="h2">
                <span className="productPrice">{`$${productPrice}.00`}</span>
              </Typography>
            </CardContent>
          </CardActionArea>

          <Link
            className="productCardButtonLink"
            style={{ textDecoration: 'none' }}
            to={`/item/${productId}`}
          >
            <button className="productCardButton">More Information</button>
          </Link>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default ProductCard
