import fp from 'lodash/fp'
import { api_request } from './helpers'

export const fetchMessages = async (params = {}) => {
  const messages = await api_request('get', '/messages', {}, true)
  return fp.flow(fp.getOr({}, 'data'))(messages)
}

export const deleteMessage = async (params = {}) => {
  const messages = await api_request(
    'delete',
    `/messages/${params.id}`,
    {},
    true
  )
  return fp.flow(fp.getOr({}, 'data'))(messages)
}

export const createMessage = async (params = {}) =>
  await api_request('post', '/messages/create', params, true)
