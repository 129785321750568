const vehicle0 = 'https://cdn.cnchire.com.au/c0.png'
const vehicle1 = 'https://cdn.cnchire.com.au/c3.png'
const vehicle2 = 'https://cdn.cnchire.com.au/c4.png'
const vehicle3 = 'https://cdn.cnchire.com.au/c5.png'
const vehicle4 = 'https://cdn.cnchire.com.au/c6.png'
const vehicle5 = 'https://cdn.cnchire.com.au/c7.png'
const vehicle6 = 'https://cdn.cnchire.com.au/c8.png'
const vehicle7 = 'https://cdn.cnchire.com.au/c9.png'

const VehicleTypes = [
  {
    name: 'Camper Trailer',
    link: '/search-results?vehicleType=Camper Trailer',
    listType: true,
    vehicleBanner: true,
    icon: vehicle1,
  },
  {
    name: 'Campervan',
    link: '/search-results?vehicleType=Campervan',
    listType: true,
    vehicleBanner: true,
    icon: vehicle0,
  },
  {
    name: 'Caravan',
    link: '/search-results?vehicleType=Caravan',
    listType: true,
    vehicleBanner: true,
    icon: vehicle2,
  },
  {
    name: 'Expander',
    link: '/search-results?vehicleType=Expander',
    listType: true,
    vehicleBanner: true,
    icon: vehicle3,
  },
  {
    name: 'Motorhome',
    link: '/search-results?vehicleType=Motorhome',
    listType: false,
    vehicleBanner: false,
  },
  {
    name: 'Motorhome Class A',
    link: '/search-results?vehicleType=Motorhome Class A',
    listType: true,
    vehicleBanner: true,
    icon: vehicle4,
  },
  {
    name: 'Motorhome Class C',
    link: '/search-results?vehicleType=Motorhome Class C',
    listType: true,
    vehicleBanner: true,
    icon: vehicle5,
  },
  {
    name: 'Pop Top',
    link: '/search-results?vehicleType=Pop Top',
    listType: true,
    vehicleBanner: true,
    icon: vehicle6,
  },
  {
    name: 'Toy Hauler',
    link: '/search-results?vehicleType=Toy Hauler',
    listType: true,
    vehicleBanner: true,
    icon: vehicle7,
  },
]

export default VehicleTypes
