import React, { useEffect, useState } from 'react'
import { list, send, seen } from './chatService'
import { ChatBoxComponent } from './ChatBoxComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import queryString from 'query-string'
import './chat.scss'

export const ChatList = ({ user }) => {
  const [chatList, setChatList] = useState()
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isChatBoxOpened, setChatBoxOpened] = useState(false)
  const [chat, setChat] = useState()

  // BUGBUG useLocation failing for some reason so fallback to parsing the query string
  // const location = useLocation()
  const markSeen = (chat, params) => {
    const foundChat = chatList.filter((c) => c.id === chat.id)[0]
    switch (user.userRole) {
      case 'Owner':
        foundChat.ownerSeen = 1
        break
      case 'Hirer':
        foundChat.hirerSeen = 1
        break
      default:
        foundChat.adminSeen = 1
        break
    }
    seen(params)
    const newchats = chatList.map((c) => {
      if (c.id === chat.id) {
        return foundChat
      }
      return c
    })
    // console.log(newchats)
    setChatList(newchats)
  }

  const parsed = queryString.parse(window.location.search)

  const [chatId, setChatId] = useState(parsed.chat)
  const hirerId = parsed.hirerId
  const vehicleId = parsed.vehicleId

  useEffect(() => {
    const createChatIfRequired = async () => {
      let is_target_found = false
      if (loaded && chatList && chatList.length > 0) {
        for (let item of chatList) {
          if (item.hirerId == hirerId && item.vehicleId == vehicleId) {
            setChat(item)
            setChatBoxOpened(true)
            is_target_found = true
            break
          }
        }
      }

      if (!is_target_found && chatList && loaded && hirerId && vehicleId) {
        //no chat created for this target yet?  create one
        const params = {
          hirer_id: hirerId,
          vehicle_id: vehicleId,
          message: '-blank-',
        }

        const newchat = await send(params)
        console.log(params, newchat)
        await fetchList()
        if (newchat.success && newchat.data) {
          setChatId(newchat.data.uuid)
          setChatBoxOpened(true)
        }
      }
    }
    createChatIfRequired()
  }, [hirerId, vehicleId, loaded])

  useEffect(() => {
    setChat(chatList?.filter((c) => (c.uuid = chatId))[0])
  }, [chatId])

  const clickInitChatBox = (chat, index) => {
    setChat(chat)
    setChatBoxOpened(true)
  }

  const fetchList = async () => {
    const result = await list()
    if (result.success) {
      setChatList(result.data)
      setLoaded(true)
    }
  }

  const clickClose = () => {
    setOpen(false)
  }

  const clickOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    fetchList()
  }, [])

  const chatBoxList = () => {
    const chats = chatList?.map((chat, index) => {
      let isSeen = false

      switch (user.userRole) {
        case 'Owner':
          isSeen = chat.ownerSeen === 1 ? true : false
          break
        case 'Hirer':
          isSeen = chat.hirerSeen === 1 ? true : false
          break
        default:
          isSeen = chat.adminSeen === 1 ? true : false
          break
      }
      const chatName =
        user.userRole === 'Hirer' ? chat.ownerName : chat.hirerName
      return (
        <div
          className={isSeen ? 'chat-item' : 'chat-item not-seen'}
          key={index}
        >
          <div className="row">
            <div
              className="col-12"
              onClick={() => clickInitChatBox(chat, index)}
            >
              <>
                <FontAwesomeIcon icon="comment-alt" />
                {chat.bookingId ? '#' + chat.bookingId : ''}{' '}
                {chat.vehicleName.substring(0, 10) + '...'} -{' '}
                <a href="/dashboard/">{chatName}</a>{' '}
                {moment(chat.time).fromNow()}
              </>
            </div>
          </div>
        </div>
      )
    })

    return (
      <div className={open ? 'chat-list-box open' : 'chat-list-box close'}>
        <div className="closed">
          <button onClick={() => clickOpen()} className="open-btn-message">
            Messages
          </button>
        </div>

        <div className="opened">
          <div className="chat-header">
            <div className="chat-title">Messages</div>

            <span className="close-btn" onClick={() => clickClose()}>
              <FontAwesomeIcon icon="times" />
            </span>
          </div>
          <div className="chat-body">
            {loaded
              ? chats.length === 0
                ? 'No messages yet!'
                : chats
              : 'Fetching...'}
          </div>
        </div>
      </div>
    )
  }
  console.log(user)
  return (
    <>
      {isChatBoxOpened ? (
        <ChatBoxComponent
          chat={chat}
          user={user}
          seen={markSeen}
          clickClose={() => {
            setOpen(false)
            setChatBoxOpened(false)
          }}
          clickBack={() => setChatBoxOpened(false)}
        />
      ) : (
        chatBoxList()
      )}
    </>
  )
}
