import React from 'react'
import { withRouter } from 'react-router'
import SignaturePad from 'react-signature-canvas'
import './SignSignatureComponent.scss'

class SignSignatureComponent extends React.Component {
  state = { trimmedDataURL: null }
  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }
  trim = () => {
    if (!this.sigPad.isEmpty()) {
      const imageData = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
      console.log('imageData ===> ', imageData)
      this.props.sendData(imageData)

      this.setState({ trimmedDataURL: imageData })
    }
  }

  render() {
    let { trimmedDataURL } = this.state

    return (
      <React.Fragment>
        <div className="digital-signature">
          <div>
            {!trimmedDataURL ? (
              <>
                Draw your digital signature in the box: <br />
                <div>
                  <SignaturePad
                    canvasProps={{ className: 'sigPad' }}
                    ref={(ref) => {
                      this.sigPad = ref
                    }}
                  />
                </div>
                <div>
                  <div className="signature-buttons">
                    <button className="btn btn-secondary" onClick={this.clear}>
                      Clear
                    </button>
                    <button className="btn btn-secondary" onClick={this.trim}>
                      Save Signature
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {trimmedDataURL ? (
              <>
                Your digital signature:{' '}
                <img alt="signature" src={trimmedDataURL} />{' '}
              </>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(SignSignatureComponent)
