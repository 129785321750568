import axios from 'axios'
import fp from 'lodash/fp'

import { camelizeKeys } from './helpers'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/news`,
})

export const fetchNews = async () => {
  const newsList = await api.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(newsList)
}

export const fetchNewsItem = async (id) => {
  const getNewsAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/news/${id}`,
  })
  const newsInfo = await getNewsAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(newsInfo)
}
