import React from 'react'
import { Link } from 'react-router-dom'

import './ProductBanner.scss'

const ProductBanner = ({ productName, productType, path }) => {
  const className = `productBanner${productType}`

  return (
    <React.Fragment>
      <div className={className}>
        <span className="productName">{productName}</span>
        <Link
          className="viewProductButtonLink"
          style={{ textDecoration: 'none' }}
          to={path}
        >
          <button className="viewProductButton">View Products</button>
        </Link>
      </div>
    </React.Fragment>
  )
}

export default ProductBanner
