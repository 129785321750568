import { api_request } from 'Services/helpers'

export const getStats = async (data = {}) => {
  return await api_request('get', '/auth/dashboard/stats/tab', data, true)
}

export const getLatestBookings = async (data = {}) => {
  return await api_request('post', '/auth/bookings/latest', data, true)
}

export const getSearchLocationsStats = async (data = {}) => {
  return await api_request(
    'post',
    '/auth/dashboard/stats/locations',
    data,
    true
  )
}

export const getSales = async (data = {}) => {
  return await api_request('post', '/auth/dashboard/stats/sales', data, true)
}
