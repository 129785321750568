import { api_request } from '../../../Services/helpers'

export const detail = async (id) =>
  await api_request('get', `/auth/booking/detail/${id}`, {}, true)

export const updateBookingStatus = async (data = {}) =>
  await api_request('post', '/auth/booking/status', data, true)

export const updateManualBookingStatus = async (data = {}) =>
  await api_request('post', '/auth/booking/manual-status', data, true)

export const fetch = async (data = {}) =>
  await api_request('post', '/auth/bookings', data, true)

export const payout = async (id) =>
  await api_request('get', `/auth/booking/payout/${id}`, {}, true)

export const payoutBondClaim = async (id) =>
  await api_request('get', `/auth/booking/payout-bond-claim/${id}`, {}, true)

export const refund = async (id) =>
  await api_request('get', `/auth/booking/refund/${id}`, {}, true)

export const fetchBookings = async (data = {}) =>
  await api_request('get', '/api/public/bookings', data, false)

export const fetchBooking = async (id) =>
  await api_request('get', `/api/public/bookings/${id}`, {}, false)

export const createBooking = async (data) =>
  await api_request('post', '/auth/bookings/create', data, true)

export const message = async (data) =>
  await api_request('post', '/auth/booking/message', data, true)
