import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-router-dom'
import fp from 'lodash/fp'
import './NavBar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProductItems from '../Items/ProductItems'
import LocationItems from '../Items/LocationItems'
import InformationItems from '../Items/InformationItems'
import VehicleTypes from '../Items/VehicleTypes'

const logoImage = '//cdn.cnchire.com.au/logo.png'

const NavBar = ({ linkTextColor }) => {
  const persistRoot = localStorage.getItem('persist:root')
  const user = !fp.isNil(persistRoot)
    ? JSON.parse(fp.get('user', JSON.parse(persistRoot)))
    : {}
  const currentUser = fp.get('currentUser', user)
  const authed = !fp.isEmpty(currentUser)

  const color = linkTextColor ? linkTextColor : { color: '#ffffff' }
  const navAddClass =
    linkTextColor.color === '#ffffff' ? 'nav-bar homepage' : 'nav-bar '

  return (
    <React.Fragment>
      <Navbar className={navAddClass} collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>

        <a href="/">
          <img
            alt=""
            style={{ display: 'none' }}
            src={logoImage}
            className="logoImageMobile"
          />
        </a>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto navWrapper">
            <img alt="" src={logoImage} className="logoImage" />

            <Nav.Link className="home-link navItems nav-link-item" href="/">
              <span style={color} className="linkText">
                Home
              </span>
            </Nav.Link>

            <Nav className="nav-link-item">
              <div className="custom-dropdown">
                <span style={color} className="linkText">
                  Vehicles <FontAwesomeIcon icon="angle-down" />
                </span>
                <div className="sub-menus">
                  <div className="invisible-overlay-bg">
                    <ul>
                      <li
                        className="navDropdownItem"
                        style={{ textDecoration: 'none' }}
                      >
                        <a href="/vehicles">All Vehicles</a>
                      </li>
                      <li
                        className="navDropdownItem with-submenu"
                        style={{ textDecoration: 'none' }}
                      >
                        <span className="vehicle-type">
                          All Vehicles By State{' '}
                          <FontAwesomeIcon icon="angle-right" />
                        </span>
                        <ul className="inner-submenu">
                          {LocationItems.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="navDropdownItem"
                                style={{ textDecoration: 'none' }}
                              >
                                <a href={item.link}>{item.name}</a>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                      <li
                        className="navDropdownItem with-submenu"
                        style={{ textDecoration: 'none' }}
                      >
                        <span className="vehicle-type">
                          All Vehicles By Type{' '}
                          <FontAwesomeIcon icon="angle-right" />
                        </span>
                        <ul className="inner-submenu">
                          {VehicleTypes.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="navDropdownItem"
                                style={{ textDecoration: 'none' }}
                              >
                                <a href={item.link}>{item.name}</a>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Nav>

            <Nav className="nav-link-item">
              <div className="custom-dropdown">
                <span style={color} className="linkText">
                  Information <FontAwesomeIcon icon="angle-down" />
                </span>
                <div className="sub-menus">
                  <ul>
                    {InformationItems.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="navDropdownItem"
                          style={{ textDecoration: 'none' }}
                        >
                          <a href={item.link}>{item.name}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </Nav>

            <Nav.Link
              className="navItems nav-link-item car-hire-nav"
              href="https://www.driveaway.com.au/affiliates/1011318"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <span style={color} className="linkText">
                Car Hire
              </span>
            </Nav.Link>

            <Nav className="nav-link-item">
              <div className="custom-dropdown">
                <span style={color} className="linkText">
                  Products <FontAwesomeIcon icon="angle-down" />
                </span>
                <div className="sub-menus">
                  <ul>
                    {ProductItems.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="navDropdownItem"
                          style={{ textDecoration: 'none' }}
                        >
                          <a href={item.link}>{item.name}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </Nav>

            <Nav className="nav-link-item inquiries-nav">
              {/*
            <span style={color} className="linkText">
                <FontAwesomeIcon icon="phone" /> 0409 964 049<br/>
                <FontAwesomeIcon icon="envelope"  /> <span onClick={() => window.location.href ='mailto:enquiries@cnchire.com.au'}>enquiries@cnchire.com.au </span>
             </span>   
            */}
            </Nav>

            {authed ? (
              <>
                <a href="/dashboard" className="loginButtonLink loginButton">
                  Dashboard
                </a>
                <Link to="/logout" className="signUpButton">
                  Logout
                </Link>
                <div style={color} className="welcome-link linkText">
                  Welcome {currentUser.firstName}!
                </div>
              </>
            ) : (
              <>
                <a href="/login" className="loginButtonLink loginButton">
                  Login
                </a>
                <Link to="/register" className="signUpButton">
                  Sign Up
                </Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  )
}

export default NavBar
