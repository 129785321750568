import React from 'react'
import { withRouter } from 'react-router'
import * as supportUserService from './SupportUserPageService'
import './SupportUserPage.scss'
import { NotificationManager } from 'react-notifications'

class SupportUserPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      state: 'list',
      list: [],
      search: '',
      message: '',
      success_message: false,
      error_message: '',
      item: false,
      is_processing: false,
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-support-page')
  }

  async fetchLatest() {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  clickSubmit = async () => {
    this.setState({ is_processing: true })
    if (this.state.message !== '') {
      const result = await supportUserService.create({
        comment: this.state.message,
      })
      if (result.success) {
        NotificationManager.success('Successfully sent your message.')

        this.setState({ success_message: true })
      } else {
        NotificationManager.error('There was an error sending your message.')
      }
    } else {
      NotificationManager.error('Invalid message')
    }
    this.setState({ is_processing: false })
  }

  render() {
    return (
      <>
        <div className="support-container">
          {this.state.success_message ? (
            <>
              <div className="success-message">
                <h1>
                  Thank you for your message. You will hear from us shortly.{' '}
                </h1>
              </div>
            </>
          ) : (
            <>
              {this.state.error_message !== '' && (
                <div className="error-message">{this.state.error_message}</div>
              )}

              <table>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      Please write your concern or problem here.
                    </td>
                  </tr>
                  <tr>
                    <td>Name:</td>
                    <td>
                      {this.props.user.firstName} {this.props.user.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{this.props.user.email}</td>
                  </tr>
                  <tr>
                    <td>Message</td>
                    <td>
                      <textarea
                        className="message"
                        onChange={(e) =>
                          this.setState({ message: e.target.value })
                        }
                        name="message"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      {this.state.is_processing ? (
                        <button className="btn btn-primary">
                          Please wait..
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => this.clickSubmit()}
                        >
                          Submit
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(SupportUserPage)
