import React from 'react'

import './Experience.scss'
import LocationItems from '../Items/LocationItems'

const Experience = () => {
  const title = 'Experience An Amazing Caravan & Camping Holiday'

  return (
    <React.Fragment>
      <div className="experienceContainer">
        <div className="experienceTitle">{title}</div>
        <div className="experienceWrapper">
          {LocationItems.map((item, index) => {
            return (
              <a key={index} href={item.link} className="experience">
                <img className="imageIcon" src={item.image} alt="" />
                {item.name}
              </a>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Experience
