import React from 'react'

import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import Footer from 'Components/Footer/Footer'
import Checkout from 'Components/Checkout/Checkout'
import { withRouter } from 'react-router'

class CheckoutPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('checkout-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Checkout" link="/checkout" />
        <Checkout />
        <Footer />
      </React.Fragment>
    )
  }
}
export default withRouter(CheckoutPage)
