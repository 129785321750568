import React from 'react'
import './PaymentsComponent.scss'
import * as paymentService from '../../Payments/PaymentsService'

export class PaymentList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      months: [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      cardList: [],
    }
    this.fetchLatest()
  }

  async fetchLatest() {
    const params = {}
    await paymentService.fetchCards(params).then((result) => {
      if (result.success && result.data.length > 0) {
        const cardList = result.data.map((x) => {
          return { isDefault: 0, ...x }
        })
        cardList[0].isDefault = 1
        this.setState({ cardList, cardSelected: cardList[0] })
        this.props.cardInfo(cardList[0])
      } else {
        //if no card yet, force open the card popup
      }
    })
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      function () {
        this.validateFields()
      }
    )
  }

  clickItemSelect = (index, cardSelected) => {
    if (this.state.targetBookingPayment) {
      let cardList = this.state.cardList
      for (var i = 0; i < cardList.length; i++) {
        cardList[i].isDefault = 0
        if (i === index) {
          cardList[i].isDefault = 1
        }
      }

      this.props.cardInfo(cardSelected)
      this.setState({ cardList, cardSelected })
    }
  }

  componentWillReceiveProps(props) {
    const { newcard } = this.props
    if (props.newcard && newcard && props.newcard.id !== newcard.id) {
      this.fetchLatest()
    }
  }

  openCardPopup = () => {
    this.props.openCardPopup()
  }

  render() {
    return (
      <div className="payment-container">
        {/*
                <div className="note-info">
                  <b>Security Notice</b> Card details are for hire payments/commissions. 
                  This is transmitted and stored securely on our PCI compliant payment processor's systems.
                  No banking or credit card details are transmitted via or stored on the Caravan & Camping Hire Website. 
                     </div>*/}

        <div className="payment-box-body">
          {this.state.cardList &&
            this.state.cardList.length > 0 &&
            this.state.cardList.map((item, index) => {
              return (
                <label
                  key={item.id}
                  onClick={() => this.clickItemSelect(index, item)}
                  className={
                    this.state.itemSelectedIndex === index &&
                    this.state.targetBookingPayment
                      ? 'selected cardItem'
                      : 'cardItem'
                  }
                >
                  <input
                    type="radio"
                    defaultChecked={item.isDefault ? 'checked' : ''}
                    name="paybookingid"
                    defaultValue={item.id}
                  />

                  <div>******* </div>
                  <div className="cardType">{item.brand}</div>
                  <div>{item.name}</div>
                  <div>
                    {this.state.months[item.expMonth] + ' ' + item.expYear}
                  </div>
                </label>
              )
            })}

          <br />
          <button
            className="add-payment-button btn btn-primary"
            onClick={() => this.openCardPopup()}
          >
            Add Payment Details
          </button>
        </div>
      </div>
    )
  }
}
