import React from 'react'
import { withRouter } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'

class AddonAmenities extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      amenities_checkboxes: this.props.amenities_checkboxes,
      addon_amenities: this.props.addon_amenities,
      options: [],
    }
  }

  _setState = async (obj) => {
    this.setState(obj)
  }

  initData = async () => {
    await this.renderOptions()
    await this.clickProcessAmenities()
  }

  componentDidMount() {
    this.initData()
  }

  clickProcessAmenities = async () => {
    let options = this.state.options
    let addons = []

    options.forEach((item) => {
      if (item.checked) {
        let amenity = item.id
        let price = item.price

        price = parseFloat(price)
        addons.push({ price, amenity })
      }
    })

    await this.setState({ addons })
    this.props.parentHandleAddOn(addons)
  }

  renderOptions() {
    let checkboxes = this.state.amenities_checkboxes //original from main addon list
    let options = [],
      checked,
      amenity = ''
    let obj = []
    for (let i = 0; i < checkboxes.length; i++) {
      amenity = checkboxes[i].amenity
      const id = parseInt(checkboxes[i].id)

      let matched = false
      //if(!checkboxes[i].checked){ //do not include the already included
      if (this.state.addons && this.state.addons.length > 0) {
        matched = this.state.addons.find((x) => parseInt(x.amenity) === id)
      } else if (this.state.addon_amenities) {
        matched = this.state.addon_amenities.find(
          (x) => parseInt(x.amenity) === id
        )
      }

      let price = 0.0
      obj['addon_id_' + id] = id

      if (matched) {
        obj['addon_price_' + id] = matched.price
        price = matched.price
        checked = true
      } else {
        obj['addon_id_' + id] = ''
        obj['addon_price_' + id] = ''
        checked = false
      }
      options.push({ checked, id, amenity, price })
    }

    this._setState({ options })
  }

  handleChangeCheckbox = async (e) => {
    let options = this.state.options

    options.forEach((item) => {
      if (parseInt(item.id) === parseInt(e.target.value)) {
        item.checked = e.target.checked
      }
    })

    console.log('options is', options)
    await this._setState({ options })
    this.clickProcessAmenities()
  }

  handleChangeCost = async (e, id) => {
    let options = this.state.options

    options.forEach((item) => {
      if (parseInt(item.id) === id) {
        item.price = e.target.value
        //item.checked =  e.target.checked
      }
    })

    console.log('options is', options)
    await this._setState({ options })
    this.clickProcessAmenities()
  }

  render() {
    return (
      <>
        <table className="addon-amenities">
          <thead>
            <tr>
              <td style={{ width: '140px' }}>Active </td>
              <td>Amenity</td>
              <td style={{ width: '140px' }}>&nbsp;&nbsp;&nbsp;Cost</td>
            </tr>
          </thead>
          <tbody>
            {this.state.options &&
              this.state.options.length > 0 &&
              this.state.options.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        value={item.id}
                        checked={item.checked || ''}
                        key={item.id}
                        onChange={(event) => this.handleChangeCheckbox(event)}
                      />{' '}
                    </td>
                    <td>{item.amenity}</td>
                    <td>
                      $
                      <input
                        type="text"
                        value={item.price}
                        onChange={(event) =>
                          this.handleChangeCost(event, item.id)
                        }
                        placeholder="0.00"
                        className="cost"
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </>
    )
  }
}

export default withRouter(AddonAmenities)
