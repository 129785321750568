import React from 'react'
import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import Footer from 'Components/Footer/Footer'
import './confirm-booking-success.scss'
import { withRouter } from 'react-router'

class ConfirmBookingSuccessPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('confirm-booking-success-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />

        <PageBanner title="Book a caravan" link="/vehicles" />

        <React.Fragment>
          <div className="faqContainer">
            <div className="faqTitle">Thank you!</div>
            <div className="faqWrapper booking-body">
              Your booking request has been sent to owner for approval. <br />
              Please go to your dashboard/bookings to manage the status.
            </div>
          </div>
        </React.Fragment>

        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(ConfirmBookingSuccessPage)
