import { api_request } from '../../../Services/helpers'

export const fetch = async (params = {}) =>
  await api_request('post', '/auth/reviews', params, true)

export const approve = async (id) =>
  await api_request('PUT', `/auth/reviews/approve/${id}`, {}, true)

export const unapprove = async (id) =>
  await api_request('PUT', `/auth/reviews/unapprove/${id}`, {}, true)
