import React from 'react'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import DashboardBoxesComponent from './components/DashboardBoxes/DashboardBoxes'
import DateFilterComponent from './components/DateFilter/DateFilter'
import LocationSearchesComponent from './components/LocationSearches/LocationSearches'
import SalesStatsComponent from './components/SalesStats/SalesStats'
import LatestBookingComponent from './components/LatestBooking/LatestBooking'
import moment from 'moment'
import * as apiService from './components/DashboardComponentService'
import './DashboardPage.scss'

class DashboardPage extends React.Component {
  constructor(props) {
    super(props)

    let from = ''
    let to = ''
    let parsed = queryString.parse(this.props.location.search)
    if (parsed.from) {
      from = moment(parsed.from).format('MM/DD/YYYY')
      to = moment(parsed.to).format('MM/DD/YYYY')
    }

    this.state = {
      user: this.props.user,
      from,
      to,
      salesStats: false,
      locationStats: false,
      bookingStats: false,
      commonStats: false,
    }
  }

  componentDidMount() {
    document.body.classList.add('dashboard-main-page')
    this.fetchLatest()
  }

  receiveChildValue = (value) => {
    var _from = moment(value.from).format('MM-DD-YYYY')
    var _to = moment(value.to).format('MM-DD-YYYY')
    window.location.href = '/dashboard/?from=' + _from + '&to=' + _to
  }

  fetchLatest = async () => {
    setTimeout(() => {
      this.fetchCommonStats()
      switch (this.state.user.userRole) {
        case 'Admin':
          this.fetchSalesStats()
          this.fetchLocationStats()
          this.fetchBookingStats()
          break
        case 'Owner':
          this.fetchSalesStats()
          this.fetchBookingStats()
          break
        default: //hirer
          this.fetchBookingStats()
      }
    }, 100) //delay this. lets
  }

  getDateFilterParams = () => {
    return this.state.from !== undefined &&
      this.state.to !== undefined &&
      this.state.from !== '' &&
      this.state.to !== ''
      ? {
          from: moment(this.state.from).format('YYYY-MM-DD'),
          to: moment(this.state.to).format('YYYY-MM-DD'),
        }
      : { from: '', to: '' }
  }

  fetchCommonStats = async () => {
    let params = { filter: { ...this.getDateFilterParams() } }
    const result = await apiService.getStats(params)
    if (result.success) {
      this.setState({ commonStats: result.data })
    }
  }

  fetchSalesStats = async () => {
    let params = { filter: { ...this.getDateFilterParams() } }
    const result = await apiService.getSales(params)
    if (result.success) {
      this.setState({ salesStats: result.data })
    }
  }

  fetchBookingStats = async () => {
    let params = {
      filter: { page_size: 5, dateFiltered: 1, ...this.getDateFilterParams() },
    }
    const result = await apiService.getLatestBookings(params)
    if (result.success) {
      this.setState({ bookingStats: result.data })
    }
  }

  fetchLocationStats = async () => {
    let params = { filter: { ...this.getDateFilterParams() } }
    const result = await apiService.getSearchLocationsStats(params)

    if (result.success) {
      this.setState({ locationStats: result.data })
    }
  }

  render() {
    return (
      <>
        <div className="dashboard-container">
          <DateFilterComponent
            from={this.state.from}
            to={this.state.to}
            fromChildToParentCallback={this.receiveChildValue}
          ></DateFilterComponent>

          {this.state.commonStats && (
            <DashboardBoxesComponent
              data={this.state.commonStats}
            ></DashboardBoxesComponent>
          )}

          {this.state.locationStats && (
            <LocationSearchesComponent
              data={this.state.locationStats}
            ></LocationSearchesComponent>
          )}

          {this.state.salesStats && (
            <SalesStatsComponent
              from={this.state.from}
              to={this.state.to}
              data={this.state.salesStats}
            ></SalesStatsComponent>
          )}

          {this.state.bookingStats && (
            <LatestBookingComponent
              data={this.state.bookingStats}
              userRole={this.state.user.userRole}
            ></LatestBookingComponent>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(DashboardPage)
