import React from 'react'
import { withRouter } from 'react-router'
import { NotificationManager } from 'react-notifications'
import * as BankAccountService from './BankAccountService'
import './BankAccountPage.scss'

class BankAccountPage extends React.Component {
  constructor(props) {
    super(props)

    /*const _test = {   
    bank_name: 'bdo',
    routing_number : '110000', //test val 
    account_number: '000123456', //test val
    name : 'James martinez',
    success : '', 
    error_message : ''
  };*/

    const _default = {
      bank_name: '',
      routing_number: '',
      account_number: '',
      name: '',
      success: '',
      error_message: '',
    }

    this.state = _default

    this.fetch()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-bankaccount-page')
  }

  async fetch() {
    const result = await BankAccountService.fetch()
    if (result.success && result.data && result.data.bankName) {
      this.setState({
        bank_name: result.data.bankName,
        routing_number: result.data.routingNumber,
        account_number: result.data.accountNumber,
        name: result.data.name,
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  clickAddBank = async () => {
    const params = {
      bank_name: this.state.bank_name,
      routing_number: this.state.routing_number,
      account_number: this.state.account_number,
      name: this.state.name,
    }

    const result = await BankAccountService.createAccount(params)
    if (result.success) {
      NotificationManager.success(
        'Successfully added your bank info',
        'Success'
      )

      this.setState({
        success: 'Your account has been added.',
      })
    } else {
      NotificationManager.error(
        'There seem to be a problem adding your bank info',
        'Error'
      )
      this.setState({ error_message: result.message })
    }
  }

  render() {
    return (
      <>
        <div className="bank-container">
          <div className="note-info">
            <b>Security Notice</b> Bank details are for hire
            payments/commissions. This is transmitted and stored securely on our
            PCI compliant payment processor's systems. No banking or credit card
            details are transmitted via or stored on the Caravan & Camping Hire
            Website.
          </div>

          {this.state.success !== '' && (
            <div style={{ color: 'green' }}>{this.state.success}</div>
          )}
          {this.state.error_message !== '' && (
            <div style={{ color: 'red' }}>{this.state.error_message}</div>
          )}

          <div className="form-line">
            <div className="caption-box"> Bank Name : </div>
            <div className="input-box">
              <input
                type="text"
                name="bank_name"
                value={this.state.bank_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-line">
            <div className="caption-box">Routing Number: </div>
            <div className="input-box">
              <input
                type="text"
                name="routing_number"
                value={this.state.routing_number}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-line">
            <div className="caption-box">Account Number :</div>
            <div className="input-box">
              <input
                type="text"
                name="account_number"
                value={this.state.account_number}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-line">
            <div className="caption-box">Account Holder Name :</div>
            <div className="input-box">
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-line">
            <button
              className="btn btn-primary"
              onClick={() => this.clickAddBank()}
            >
              Update Bank Account
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(BankAccountPage)
