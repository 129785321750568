import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import { NotificationManager } from 'react-notifications'
import './VehicleListPage.scss'
import * as vehicleListService from './VehicleListService'

class PendingListViewComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show_pending: false,
      list: this.props.list,
    }
  }

  componentDidMount() {
    document.body.classList.add('dashboard-vehicle-pendinglistview-page')
  }

  clickApprove = async (id) => {
    const result = await vehicleListService.approve(id)
    if (result.success) {
      let list = this.state.list.filter((x) => x.id !== id)
      this.setState({ list })
      await localStorage.setItem(
        'message_flash',
        'The vehicle has been approved.'
      )
      window.location.href = '/dashboard/vehicles'
    } else {
      NotificationManager.error(
        'There was a problem approving the vehicle at the moment.',
        'Error'
      )
    }
  }

  render() {
    if (this.state.show_pending === false) {
      return (
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ show_pending: true })}
        >
          {' '}
          Show Pending Items{' '}
        </button>
      )
    } else {
      return (
        <>
          {this.state.list && this.state.list.length > 0 && (
            <div className="vehicle-list-container pending-container">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ show_pending: false })}
              >
                {' '}
                Hide Pending Items{' '}
              </button>

              <h1>Pending Vehicle Approval</h1>
              <div className="main-body-container">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Vehicle Name</th>
                      <th>Images</th>
                      <th>Created By</th>
                      <th>Vehicle Type</th>
                      <th>Date Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.list.length > 0 &&
                      this.state.list
                        .filter((x) => x.show === true)
                        .map((item, index) => {
                          let images = item.images
                            ? JSON.parse(item.images)
                            : []
                          let thumb = 'https://cdn.cnchire.com.au/c6.png'
                          if (item.thumbnail !== '') {
                            thumb = item.thumbnail
                          } else if (images[0]) {
                            thumb = images[0].image
                          }

                          return (
                            <tr
                              key={item.id}
                              className={index % 2 === 0 ? 'even' : 'odd'}
                            >
                              <td>{item.id}</td>
                              <td>
                                <a href={'/dashboard/vehicles/' + item.id}>
                                  {' '}
                                  {item.vehicleName}{' '}
                                </a>{' '}
                                <br />{' '}
                                {item.activeBooked > 0 && (
                                  <div
                                    className="status"
                                    title={
                                      'Active Booking: ' + item.activeBooked
                                    }
                                  >
                                    Booking: {item.activeBooked}{' '}
                                  </div>
                                )}
                              </td>
                              <td>
                                <img
                                  alt="thumbnail"
                                  width="50"
                                  className="thumbnail"
                                  src={thumb}
                                />
                              </td>
                              <td>
                                <a href={'/dashboard/user/' + item.ownerId}>
                                  {item.ownerFirstName} {item.ownerLastName}
                                </a>
                              </td>
                              <td>{item.vehicleType}</td>
                              <td>{moment(item.createdAt).format('LL')}</td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    (window.location.href =
                                      '/dashboard/vehicle/update/' + item.id)
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    (window.location.href =
                                      '/dashboard/vehicles/' + item.id)
                                  }
                                >
                                  View
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => this.clickApprove(item.id)}
                                >
                                  Approve
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )
    }
  }
}

export default withRouter(PendingListViewComponent)
