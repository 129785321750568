import { api_request } from 'Services/helpers'

export const fetch = async (data = {}) => {
  return await api_request('post', '/auth/vehicle/list', data, true)
}

export const remove = async (id) => {
  return await api_request('delete', `/auth/vehicle/delete/${id}`, id, true)
}

//undraft
export const undraft = async (id) => {
  return await api_request('put', `/auth/vehicle/enable/${id}`, id, true)
}

//draft
export const draft = async (id) => {
  return await api_request('put', `/auth/vehicle/disable/${id}`, id, true)
}

export const fetchPending = async (id) => {
  return await api_request('get', `/auth/vehicle/pending`, id, true)
}

export const approve = async (id) => {
  return await api_request('put', `/auth/vehicle/approve/${id}`, id, true)
}
