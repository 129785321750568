import { api_request } from 'Services/helpers'
import moment from 'moment'

export const getBooking = async (uuid) =>
  await api_request('get', `/auth/booking/uuid/${uuid}`, {}, true)

export const updateBooking = async (payload) =>
  await api_request('post', '/auth/bookings/update', payload, true)

export const getDateRate = (date, rate_rules, rate) => {
  const _date = new Date(date)
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const today = days[_date.getDay()]
  const monthStart = new Date(_date)
  monthStart.setDate(0)
  const offset = ((monthStart.getDay() + 1) % 7) - 1 // -1 is for a week starting on Monday
  const weekNumber = Math.ceil((_date.getDate() + offset) / 7)

  const monthName = moment(_date).format('MMMM')

  if (rate_rules && rate_rules.length > 0) {
    for (const item of rate_rules) {
      if (item.deleted !== true) {
        if (item.type === 'Date Range') {
          const array = item.rule.split('-')
          const _from = new Date(array[0])
          const _to = new Date(array[1])

          if (_date >= _from && _date <= _to) {
            // in between our daterange
            rate = item.rate
            break
          }
        }

        if (item.type === 'Day' && today === item.rule) {
          rate = item.rate
          break
        }

        if (item.type === 'Week' && `Week ${weekNumber}` === item.rule) {
          rate = item.rate
          break
        }

        if (item.type === 'Month' && monthName === item.rule) {
          rate = item.rate
          break
        }
      }
    }
  }

  return rate
}

export const processRates = (from, to, rate_rules, price_per_day) => {
  const run_from = moment(from).format('YYYY-MM-DD')
  const run_to = moment(to).format('YYYY-MM-DD')
  const start = new Date(run_from)
  const end = new Date(run_to)
  let loop = new Date(start)
  let total = 0
  const computed_rates = []

  while (loop <= end) {
    const this_date = moment(loop).format('YYYY-MM-DD')
    const this_rate = getDateRate(this_date, rate_rules, price_per_day)

    total += Number(this_rate)
    computed_rates.push({
      date: moment(this_date).format('MMMM DD'),
      rate: this_rate,
    })

    const newDate = loop.setDate(loop.getDate() + 1)
    loop = new Date(newDate)
  }

  return { computed_rates, computed_total: total }
}
