import React, { useState, useEffect } from 'react'
import lodash from 'lodash'

import FAQCategoryButton from '../FAQCategoryButton/FAQCategoryButton'
import './FAQ.scss'

const FAQ = ({ faqs }) => {
  const [faqsData, setFaqsData] = useState({})
  let firstHalf = []
  let secondHalf = []

  useEffect(() => {
    setFaqsData(lodash.groupBy(faqs, 'category'))
  }, [faqs])

  if (faqsData) {
    for (let i = 0; i < Object.keys(faqsData).length; i++) {
      if (i % 2 === 0) {
        firstHalf.push(Object.keys(faqsData)[i])
      } else {
        secondHalf.push(Object.keys(faqsData)[i])
      }
    }
  }

  return (
    <React.Fragment>
      <div className="faqContainer">
        <div className="faqTitle">Frequently Asked Questions</div>
        <div className="faqWrapper">
          <div className="faqColumn1">
            {faqsData &&
              firstHalf.map((categoryItem, index) => {
                return (
                  <FAQCategoryButton
                    categoryItem={categoryItem}
                    faqs={faqsData[categoryItem]}
                    key={index}
                  />
                )
              })}
          </div>
          <div className="faqColumn2">
            {faqsData &&
              secondHalf.map((categoryItem, index) => {
                return (
                  <FAQCategoryButton
                    categoryItem={categoryItem}
                    faqs={faqsData[categoryItem]}
                    key={index}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FAQ
