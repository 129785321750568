import React from 'react'
import { connect } from 'react-redux'
import store from '../../Redux/store'
import fp from 'lodash/fp'
import NavBar from 'Components/NavBar/NavBar'
import VehicleDetails from './components/VehicleDetails/VehicleDetails'
import Footer from 'Components/Footer/Footer'
import './VehicleDetailsPage.scss'
import * as vehicleService from '../../Services/vehicle'
import { Helmet } from 'react-helmet'

class VehicleDetailsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      vehicle: false,
      vehicleAmenities: [],
      vehicleAdditionalAmenities: [],
      reviews: [],
    }
  }

  async fetchVehicle(id) {
    const vehicleData = await vehicleService.fetchVehicle(id)

    console.log(
      'vehicleData reviews ',
      vehicleData.data.reviews,
      vehicleData.data
    )
    let images = []

    if (vehicleData.data.vehicle.thumbnail) {
      images = [{ image: vehicleData.data.vehicle.thumbnail }]
    }

    if (images.length === 0) {
      images = vehicleData.data.vehicle.images
        ? JSON.parse(vehicleData.data.vehicle.images).map((x) => ({
            image: x.image,
          }))
        : []
    }
    console.log(images)

    await this.setState({ reviews: vehicleData.data.reviews })
    await this.setState({ vehicleAmenities: vehicleData.data.amenities })
    await this.setState({ vehicle: vehicleData.data.vehicle })
    await this.setState({ vehicleImages: images })
  }

  componentDidMount() {
    document.body.classList.add('vehicle-detail-page')
    this.fetchVehicle(
      this.props.match.params.id || this.props.match.params.slug
    )
  }

  render() {
    if (!this.state.vehicle) {
      return <></>
    } else {
      console.log('VEH', this.state.vehicle)
      return (
        <React.Fragment>
          <Helmet>
            <meta
              property="og:url"
              content={`https://www.cnchire.com.au/vehicle/${this.state.vehicle.id}`}
            />
            <meta
              property="og:title"
              content={`${this.state.vehicle.vehicleName}: cnchire.com.au`}
            />
            <meta
              property="og:description"
              content="Caravan and Camping Hire AUS | Peer to Peer private Caravan Hire"
            />
            <meta
              property="og:image"
              content={
                this.state.vehicleImages &&
                this.state.vehicleImages.length > 0 &&
                this.state.vehicleImages[0].image
              }
            />
          </Helmet>

          <NavBar linkTextColor={{ color: '#000000' }} />
          <VehicleDetails
            amenities={this.props.amenities}
            user={this.props.user}
            isHirer={this.props.isHirer}
            isLoggedIn={this.props.isLoggedIn}
            vehicle={this.state.vehicle}
            reviews={this.state.reviews}
            vehicleAmenities={this.state.vehicleAmenities}
            vehicleAdditionalAmenities={this.state.vehicleAdditionalAmenities}
            props={this.props}
          />
          <Footer />
        </React.Fragment>
      )
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchVehicle: dispatch.vehicle.fetchVehicle,
})

const mapStateToProps = (state) => ({
  isHirer: store.select.user.isLoggedIn(state),
  isLoggedIn: store.select.user.isLoggedIn(state),
})

export default fp.flow(connect(mapStateToProps, mapDispatchToProps))(
  VehicleDetailsPage
)
