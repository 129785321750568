import React from 'react'
import './PaymentsComponent.scss'
import { PaymentList } from './PaymentList'
import { NewCardPopup } from './NewCardPopup'
import useAnalyticsEventTracker from 'Pages/App/useAnalyticsTracker'

export const PaymentsComponent = (props) => {
  const tracker = useAnalyticsEventTracker('Payments')
  return <PaymentsComponentOld {...props} tracker={tracker} />
}
class PaymentsComponentOld extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newcard: [],
      cardInfo: [],
      isNewCard: false,
    }
  }

  cardInfo = (data) => {
    this.setState({ cardInfo: data })
    this.props.cardInfo(data)
  }

  openCardPopup = () => {
    this.setState({ isNewCard: true })
  }

  closeCardPopup = () => {
    this.setState({ isNewCard: false })
    this.setState({ refresh: true })
  }

  closeCardPopupAfterComplete = (data) => {
    this.props.tracker('newCard', data)
    console.log('new card is ', data)
    this.setState({ newcard: data })
    this.closeCardPopup()
  }

  //<!--<NewCardPopup  ></NewCardPopup>-->

  render() {
    return (
      <>
        {this.state.isNewCard === true ? (
          <NewCardPopup
            closeCardPopupAfterComplete={this.closeCardPopupAfterComplete}
            closeCardPopup={this.closeCardPopup}
          ></NewCardPopup>
        ) : null}
        <PaymentList
          openCardPopup={this.openCardPopup}
          newcard={this.state.newcard}
          cardInfo={this.cardInfo}
        ></PaymentList>
      </>
    )
  }
}
