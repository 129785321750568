import React from 'react'
import { withRouter } from 'react-router'
import * as revenueService from './RevenuesPageService'
import { formatCurrency } from 'shared/utils.js'
import './RevenuesPage.scss'

class RevenuesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: false,
      totalRevenue: 0,
      stats: false,
    }

    this.fetchLatest()
  }

  async fetchLatest() {
    const params = {}
    const result = await revenueService.fetch(params)

    let stats = await revenueService.fetchStats()
    if (stats.success) {
      this.setState({ stats: stats.data })
    }

    if (result.success) {
      this.setState({ list: result.data })
    }
  }

  render() {
    return (
      <>
        <div className="revenues-container">
          <div className="current-earnings">{this.state.stats && <></>}</div>

          <table className="revenues-list">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Booking ID</th>
                <th>CNC Commission (10%)</th> <th>Earnings</th>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length > 0 &&
                this.state.list.map((item, index) => {
                  return (
                    <tr
                      key={item.id}
                      className={index % 2 === 0 ? 'even' : 'odd'}
                    >
                      <td>{item.id}</td>
                      <td>{item.createdAt}</td>
                      <td>
                        <a href={'/dashboard/bookings/' + item.bookingId}>
                          {item.bookingId}
                        </a>
                      </td>
                      <td>{formatCurrency(item.deductedCommission)}</td>
                      <td>{formatCurrency(item.amount)}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

export default withRouter(RevenuesPage)
