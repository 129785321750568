import React from 'react'
import {
  MDBRow,
  MDBContainer,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBCardBody,
  MDBBtn,
} from 'mdbreact'

const SearchResultVehicles = () => (
  <MDBContainer style={{ marginTop: '4em' }}>
    <MDBRow>
      <MDBCol md="4">
        <MDBCard style={{ width: '22rem' }}>
          <MDBCardImage
            className="img-fluid"
            src="https://cnchire.com.au/wp-content/uploads/2019/10/92-DB0D286D-6F38-454E-BB88-C240FD0AA162-500x374.jpeg"
            waves
          />
          <MDBCardBody>
            <MDBCardTitle>2000 Jayco Starcraft Bunk</MDBCardTitle>
            <MDBCardText>
              Some quick example text to build on the card title and make up the
              bulk of the card&apos;s content.
            </MDBCardText>
            <MDBBtn href="#">MDBBtn</MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4">
        <MDBCard style={{ width: '22rem' }}>
          <MDBCardImage
            className="img-fluid"
            src="https://cnchire.com.au/wp-content/uploads/2018/11/4709-7FF1EA54-7D16-438D-9E32-5C5B63189E42-500x374.jpeg"
            waves
          />
          <MDBCardBody>
            <MDBCardTitle>2018 Lumberjack Forward</MDBCardTitle>
            <MDBCardText>
              Some quick example text to build on the card title and make up the
              bulk of the card&apos;s content.
            </MDBCardText>
            <MDBBtn href="#">MDBBtn</MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4">
        <MDBCard style={{ width: '22rem' }}>
          <MDBCardImage
            className="img-fluid"
            src="https://cnchire.com.au/wp-content/uploads/2018/12/4709-E512D0CD-4105-41AB-8EF1-5B321A4FFB3E-500x374.jpeg"
            waves
          />
          <MDBCardBody>
            <MDBCardTitle>Avan 3-Berth Caravan</MDBCardTitle>
            <MDBCardText>
              Some quick example text to build on the card title and make up the
              bulk of the card&apos;s content.
            </MDBCardText>
            <MDBBtn href="#">MDBBtn</MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
)

export default SearchResultVehicles
