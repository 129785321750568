import React from 'react'

import './ProductDetails.scss'

const ProductDetails = ({ product }) => {
  console.log('product---------->', product)
  let images = []

  if (product && product.id) {
    images =
      product.imagePath.split(',').length === 0
        ? product.imagePath
        : product.imagePath.split(',')
  }

  return (
    <React.Fragment>
      <div className="productDetailsContainer">
        <div className="productDetailsBox">
          <div className="productImageContainer">
            <table>
              <tbody>
                {images.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img className="product-images" alt="" src={item} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="productInfoBox">
            <span className="productNameBox">{product.productName}</span>
            <span className="productPriceBox">{`$${product.price}.00`}</span>
            <div className="productDescriptionBox">
              <div
                dangerouslySetInnerHTML={{ __html: product.description }}
              ></div>
            </div>

            {product.sku !== '' && (
              <>
                <span className="productCodeBox">Product Code</span>
                <span className="productCode">{product.sku}</span>
                <span className="productCodeSecondaryTextBox">{}</span>
                <span className="specificationTexBox">Specifications</span>
                <table className="specsTable">
                  <tbody>
                    <tr className="specsTableRow">
                      <td className="specsTableDataLabel">SKU</td>
                      <td className="specsTableData">{product.sku}</td>
                    </tr>
                    <tr className="specsTableRow">
                      <td className="specsTableDataLabel">Brand</td>
                      <td className="specsTableData">{product.brand}</td>
                    </tr>
                    <tr className="specsTableRow">
                      <td className="specsTableDataLabel">Shipping Weight</td>
                      <td className="specsTableData">
                        {product.shippingWeight}
                      </td>
                    </tr>
                    <tr className="specsTableRow">
                      <td className="specsTableDataLabel">Shipping Width</td>
                      <td className="specsTableData">
                        {product.shippingWidth}
                      </td>
                    </tr>
                    <tr className="specsTableRow">
                      <td className="specsTableDataLabel">Shipping Height</td>
                      <td className="specsTableData">
                        {product.shippingHeight}
                      </td>
                    </tr>
                    <tr className="specsTableRow">
                      <td className="specsTableDataLabel">Shipping Length</td>
                      <td className="specsTableData">
                        {product.shippingLength}
                      </td>
                    </tr>
                    <tr className="specsTableRow">
                      <td className="specsTableDataLabel">Shipping Cubic</td>
                      <td className="specsTableData">
                        {product.shippingCubic}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            <a
              className="purchaseButtonLink"
              style={{ textDecoration: 'none' }}
              href={product.buyButtonLink}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <button className="purchaseButton">Purchase From RV 4x4</button>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductDetails
