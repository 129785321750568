import React from 'react'
import { withRouter } from 'react-router'
import * as messageService from './MessageService'
import './MessageInboxPage.scss'
import Pagination from '@material-ui/lab/Pagination'
import { NotificationManager } from 'react-notifications'

class MessageInboxPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputMessage: '',
      inputSubject: '',
      messageList: [],
      message: {},
      messageState: 'inbox',
      meta: null,
    }

    this.fetchLatest()
  }

  async fetchLatest() {
    let params = {}
    if (this.state.meta !== null) {
      params = { filter: this.state.meta, ...params }
    }
    const result = await messageService.fetch(params)

    if (result.success) {
      this.setState({ messageList: result.data })
      this.setState({ meta: result.meta })
    }
  }

  setReadMessage(item) {
    this.setState({ message: item })
  }

  setMessageState(state) {
    this.setState({ messageState: state })
  }

  setReadMessageHandler(item, type) {
    this.setReadMessage(item)
    this.setMessageState(type)
    this.setState({ inputSubject: 're:' + item.subject })
    this.setState({ inputMessage: '' })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  async deleteMessageHandler(messageId) {
    const result = await messageService.remove({ id: messageId })
    if (result.data && result.data.isDeleted === 1) {
      //delete successful
      await this.fetchLatest()
      NotificationManager.success('Message has been deleted', 'Success')
    }
  }

  goToMessage = async (item) => {
    if (!item.isRead) {
      await messageService.markMessageRead(item.id)
    }

    window.location.href = '/dashboard/messages/' + item.id
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    console.log('clicked pagination', page, this.state.meta)
    await this.setState({ meta: _meta })
    await this.fetchLatest()
  }

  render() {
    return (
      <>
        <div className="inbox-container">
          <table width="100%">
            <thead>
              <tr>
                <th>From </th>
                <th>Subject</th>
                <th>Date Sent</th>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.messageList.length > 0 &&
                this.state.messageList.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <a href={'/dashboard/user/' + item.from}>
                          {item.firstName} {item.lastName}
                        </a>
                      </td>
                      <td>
                        <button
                          className="linkbutton"
                          onClick={() => this.goToMessage(item)}
                        >
                          <div
                            className={item.isRead ? 'is_read' : 'is_not_read'}
                          >
                            {item.subject}
                          </div>
                        </button>
                      </td>
                      <td>{item.createdAt}</td>
                      <td>
                        <button className="btn btn-primary">Reply</button>{' '}
                        &nbsp;
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.deleteMessageHandler(item.id)
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>

          <div style={{ float: 'right', marginTop: '20px' }}>
            {this.state.meta !== null && (
              <Pagination
                count={this.state.meta.count}
                variant="outlined"
                shape="rounded"
                onChange={this.clickPagination}
                size={'large'}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(MessageInboxPage)
