import React from 'react'
import { withRouter } from 'react-router'
import './ViewProfile.scss'

import LatestBookingComponent from '../../../Dashboard/components/LatestBooking/LatestBooking'
import LatestVehiclesComponent from '../LatestVehicles/LatestVehicles'
import * as dashboardApiService from '../../../Dashboard/components/DashboardComponentService'

class ViewProfileComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      me: this.props.me,
      user: this.props.user,
      bookingLatest: false,
      canSeePersonalInfo: false,
      isAdmin: this.props.me.userRole === 'Admin' ? true : false,
      isOwnerOfProfile: this.props.me.id === this.props.user.id ? true : false,
    }

    if (this.props.user.isHirer) {
      this.fetchbookingLatest()
    }
  }

  fetchbookingLatest = async () => {
    let params = {
      filter: {
        page_size: 5,
        dateFiltered: 1,
        filterHirer: this.state.user.id,
      },
    }
    const result = await dashboardApiService.getLatestBookings(params)
    if (result.success) {
      let bookingLatest = result.data

      for (let item of bookingLatest) {
        if (
          item.hirerId === this.state.me.id ||
          item.ownerId === this.state.me.id
        ) {
          //if i will be viewing this and part of the booking, i can see the personal details
          console.log('I am here view profile', item.step)

          if (item.step > 4) {
            this.setState({ canSeePersonalInfo: true }) //this is paid so user can see this info
          }
        }
      }

      this.setState({ bookingLatest })
    }
  }

  render() {
    return (
      <>
        <div className="item">
          <img
            alt="Avatar"
            src="//cdn.cnchire.com.au/dashboard/avatar.svg"
            width="100"
          />
        </div>
        <h2>Personal Details</h2>

        <div className="item">
          <b>Account Type:</b> {this.state.user.userRole}
        </div>

        <div className="item">
          <b>First Name: </b> {this.state.user.firstName}
        </div>

        <div className="item">
          <b>Last Name: </b> {this.state.user.lastName}
        </div>

        {this.state.isAdmin || this.state.canSeePersonalInfo ? (
          <>
            <div className="item">
              <b>Address: </b> {this.state.user.address}
            </div>

            <div className="item">
              <b>Email:</b> {this.state.user.email}
            </div>

            <div className="item">
              <b>Phone:</b> {this.state.user.contactNumber}
            </div>
          </>
        ) : null}

        <br />

        {this.props.user.isOwner &&
        (this.state.isOwnerOfProfile || this.state.isAdmin) ? (
          <div>
            <h2 className="subtitle">Owner Account/Business Details </h2>
            <div className="info-box">
              <div className="field-line row">
                <div className="col-4">
                  <b>Account Contact First Name</b> <br />
                  {this.state.user.ownerFirstname || ''}
                </div>

                <div className="col-8">
                  <b>Account Contact Surname</b>
                  <br />
                  {this.state.user.ownerLastname || ''}
                </div>
              </div>

              <div className="field-line row">
                <div className="col-4">
                  <b>Date Of Birth</b> <br />
                  {this.state.user.ownerDob || ''}
                </div>

                <div className="col-8">
                  <b>Account Type</b> <br />
                  {this.state.user.ownerType || ''}
                </div>
              </div>

              {this.state.user.ownerType === 'individual' ? (
                <>
                  <div className="field-line row individual">
                    <div className="col-4">
                      <b>Unique Public Name</b> <br />
                      {this.state.user.ownerUniquePublicName || ''}
                    </div>
                    <div className="col-8">
                      <b>Contact Phone Number</b> <br />
                      {this.state.user.ownerContactNumber || ''}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="field-line row business">
                    <div className="col-4">
                      <b>Business Legal Entity</b> <br />
                      {this.state.user.ownerBusinessLegalEntity || ''}
                    </div>
                    <div className="col-8">
                      <b>Australian Business Number</b> <br />
                      {this.state.user.ownerAustralianBusinessNumber || ''}
                    </div>
                  </div>

                  <div className="field-line row business">
                    <div className="col-4">
                      <b>Unique Public Name</b> <br />
                      {this.state.user.ownerUniquePublicName || ''}
                    </div>

                    <div className="col-8">
                      <b>Contact Phone Number</b> <br />
                      {this.state.user.ownerContactNumber || ''}
                    </div>
                  </div>

                  <div className="field-line row business">
                    <div className="col-4">
                      {this.state.user.ownerIsGstRegistered || '' ? (
                        <span>GST Registered</span>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </div>

            <h2 className="subtitle">Account Address</h2>

            <div className="info-box">
              <div className="field-line row">
                <div className="col-4">
                  <b>Owner/Business Address Line 1</b> <br />
                  {this.state.user.ownerBusinessAddress1 || ''}
                </div>
                <div className="col-8">
                  <b>Owner/Business Address Line 2</b> <br />
                  {this.state.user.ownerBusinessAddress2 || ''}
                </div>
              </div>

              <div className="field-line row">
                <div className="col-4">
                  <b>Owner/Business Suburb</b> <br />
                  {this.state.user.ownerBusinessSuburb || ''}
                </div>
                <div className="col-4">
                  <b>Owner/Business State</b> <br />
                  {this.state.user.ownerBusinessState || ''}
                </div>
                <div className="col-4">
                  <b>Owner/Business Postcode</b> <br />
                  {this.state.user.ownerBusinessZipcode || ''}
                </div>
              </div>
            </div>

            <h2 className="subtitle">Financial Details</h2>
            <div className="info-box">
              <div className="field-line row">
                <div className="col-4">
                  <b>BSB</b> <br />
                  {this.state.user.ownerFinancialBsb || ''}
                </div>
                <div className="col-8">
                  <b>Account Number</b> <br />
                  {this.state.user.ownerFinancialAccountNumber || ''}
                </div>
              </div>

              <div className="field-line row">
                <div className="col-4">
                  <b>Account Name</b> <br />
                  {this.state.user.ownerFinancialAccountName || ''}
                </div>
                <div className="col-8">
                  <b>Account Type</b> <br />
                  {this.state.user.ownerFinancialAccountType || ''}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <br />

        {this.state.user.userRole === 'Hirer' && this.state.bookingLatest && (
          <>
            <LatestBookingComponent
              isProfile="true"
              data={this.state.bookingLatest}
              userRole={this.state.user.userRole}
            ></LatestBookingComponent>
          </>
        )}

        {this.state.user.userRole === 'Owner' && (
          <>
            <LatestVehiclesComponent
              user={this.state.user}
            ></LatestVehiclesComponent>
          </>
        )}
      </>
    )
  }
}

export default withRouter(ViewProfileComponent)
