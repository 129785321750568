import React from 'react'
import './VehicleBanner.scss'
import { withRouter } from 'react-router'
import { FaCircle } from 'react-icons/fa'

class VehicleBanner extends React.Component {
  constructor(props) {
    super(props)

    let images = this.props.vehicle.images
      ? JSON.parse(this.props.vehicle.images).map((x) => ({ image: x.image }))
      : []

    if (images.length === 0) {
      if (this.props.vehicle.thumbnail) {
        images = [{ image: this.props.vehicle.thumbnail }]
      }
    }

    if (images.length > 4) {
      images.splice(4, 1)
    }

    images = this.shuffleArray(images)

    this.state = {
      vehicle: this.props.vehicle,
      slideDirection: 'forward',
      removeAnimationCss: '',
      slide: 0,
      images: images,
    }
  }

  shuffleArray = (d) => {
    for (var c = d.length - 1; c > 0; c--) {
      var b = Math.floor(Math.random() * (c + 1))
      var a = d[c]
      d[c] = d[b]
      d[b] = a
    }
    return d
  }

  clickButtonSlider = (index) => {
    this.setState({ slide: index })
  }

  clickNextSlide = () => {
    let slide =
      this.state.slide < this.state.images.length - 1
        ? this.state.slide + 1
        : this.state.slide
    this.setState({ removeAnimationCss: 'remove-animation' })

    setTimeout(() => {
      this.setState({ removeAnimationCss: '' })
      this.setState({ slideDirection: 'forward' })
      this.setState({ slide })
    }, 50)
  }

  clickPrevSlide = () => {
    let slide = this.state.slide > 0 ? this.state.slide - 1 : this.state.slide

    this.setState({ removeAnimationCss: 'remove-animation' })

    setTimeout(() => {
      this.setState({ removeAnimationCss: '' })
      this.setState({ slideDirection: 'reverse' })
      this.setState({ slide })
    }, 50)
  }

  render() {
    return (
      <React.Fragment>
        <div className="vehicleBannerContainer">
          <div className="arrownav-container">
            <div className="row">
              <div className="col-6">
                <div
                  className={
                    this.state.slide === 0
                      ? 'button-left disabled'
                      : 'button-left'
                  }
                  onClick={() => this.clickPrevSlide()}
                ></div>
              </div>
              <div className="col-6">
                <div
                  className={
                    this.state.slide === 3
                      ? 'button-right disabled'
                      : 'button-right'
                  }
                  onClick={() => this.clickNextSlide()}
                ></div>
              </div>
            </div>
          </div>

          <div className="slide-banner-container">
            <div
              className={
                'animate animate-' +
                this.state.slide +
                ' ' +
                this.state.slideDirection +
                ' ' +
                this.state.removeAnimationCss
              }
            >
              {this.state.images.length > 0 && (
                <>
                  <div
                    className="slide-banner"
                    style={{
                      backgroundImage:
                        'url(' +
                        this.state.images[
                          this.state.images.length - 1
                        ].image.replace('"', '') +
                        ')',
                    }}
                  ></div>

                  {this.state.images.length > 0 &&
                    this.state.images.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="slide-banner"
                          style={{
                            backgroundImage:
                              'url(' + item.image.replace('"', '') + ')',
                          }}
                        ></div>
                      )
                    })}
                </>
              )}
            </div>

            <div className="slide-bottons">
              {this.state.images.length > 0 &&
                this.state.images.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => this.clickButtonSlider(index)}
                      className={
                        this.state.slide === index
                          ? 'slide-button-item active'
                          : 'slide-button-item'
                      }
                    >
                      <FaCircle />
                    </div>
                  )
                })}
            </div>
          </div>

          <div className="thumbnails-container">
            <div className="row">
              {this.state.images.length > 0 &&
                this.state.images.map((item, index) => {
                  return (
                    <div key={index} className="col-3 thumb-item-container">
                      <div
                        className={
                          this.state.slide === index
                            ? 'thumb-item active'
                            : 'thumb-item'
                        }
                        onClick={() => this.clickButtonSlider(index)}
                        style={{
                          backgroundImage:
                            'url(' + item.image.replace('"', '') + ')',
                        }}
                      ></div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>{' '}
        {/*vehicleBannerContainer*/}
      </React.Fragment>
    )
  }
}

export default withRouter(VehicleBanner)
