import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import './VehicleCard.scss'

const VehicleCard = ({
  vehicle,
  vehicleID,
  vehicleImage,
  vehicleName,
  pricePerDay,
  vehicleType,
  location,
  vehicleSleeps,
  lat,
  lng,
  distance,
  searched_location,
}) => {
  const useStyles = makeStyles({
    root: {
      width: 267,
      height: 410,
    },
    searched: {
      width: 267,
      height: 490,
    },
    media: {
      height: 140,
    },
    marginTop: 10,
  })

  const classes = useStyles()

  //const isBooked = vehicle.isBooked && vehicle.isBooked === 1 ? true : false;
  //{ isBooked &&  <div className="ribbon"><span className="booked">Booked</span></div> }

  return (
    <React.Fragment>
      <div className="vehicleCardContainer">
        <Card className={distance !== '' ? classes.searched : classes.root}>
          <CardActionArea>
            <Link to={`/vehicle/${vehicleID}`}>
              <CardMedia
                className={classes.media}
                image={vehicleImage}
                title={vehicleName}
              />
            </Link>
          </CardActionArea>
          <div className="vehicleCardNameBox">
            <span className="vehicleName">{vehicleName}</span>
          </div>
          <div className="vehicleCardPriceBox">
            <span className="pricePerDay">{`$${pricePerDay}`}</span>
            <span className="perDay">{`/day`}</span>
          </div>
          <div className="vehicleCardItemBox">
            <Icon style={{ marginRight: '8%' }} size="small" name="bus" />
            <span className="vehicleCardItem">{vehicleType}</span>
          </div>
          <div className="vehicleCardItemBox">
            <Icon
              style={{ marginRight: '8%' }}
              size="small"
              name="map marker alternate"
            />
            <span className="vehicleCardItem">
              {location.includes('Australia')
                ? location.split(', Australia')[0]
                : location.split(', New Zealand')[0]}
            </span>
          </div>
          <div className="vehicleCardItemBox">
            <Icon style={{ marginRight: '8%' }} size="small" name="bed" />
            <span className="vehicleCardItem">Sleeps {vehicleSleeps}</span>
          </div>

          {distance !== '' ? (
            <>
              <div className="vehicleCardItemBox">
                <table>
                  <tbody>
                    <tr>
                      <td valign="top">
                        <Icon
                          style={{ marginRight: '8%' }}
                          size="small"
                          name="search"
                        />
                      </td>
                      <td>
                        <div className="vehicleCardItem searched">
                          {parseInt(distance) + 'KM from ' + searched_location}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : null}

          <CardActions>
            <Link
              className="vehicleCardButtonLink"
              style={{ textDecoration: 'none' }}
              to={`/vehicle/${vehicleID}`}
            >
              <button className="vehicleCardButton">More Information</button>
            </Link>
          </CardActions>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default VehicleCard
