import React from 'react'
import { connect } from 'react-redux'
import store from '../../Redux/store'
import fp from 'lodash/fp'

import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import ProductDetails from '../../Components/ProductDetails/ProductDetails'
import Footer from '../../Components/Footer/Footer'
import './SingleProductPage.scss'

class SingleProductPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('single-product-page')
    this.props.fetchProduct(this.props.match.params.id)
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Product Details" link="/item" />
        <ProductDetails product={this.props.productItem} />
        <Footer />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProduct: dispatch.product.fetchProduct,
})

const mapStateToProps = (state) => ({
  productItem: store.select.product.currentProduct(state),
})

export default fp.flow(connect(mapStateToProps, mapDispatchToProps))(
  SingleProductPage
)
