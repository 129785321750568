import React from 'react'
import { withRouter } from 'react-router'

class PreviewContractComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = { pdfFile: this.props.pdfFile }
  }
  render() {
    return (
      <React.Fragment>
        <div className="contract-box">
          <iframe title="pdf-title" src={this.state.pdfFile}></iframe>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(PreviewContractComponent)
