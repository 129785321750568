import React from 'react'
import { withRouter } from 'react-router'
import './UpdateProfile.scss'
import { NotificationManager } from 'react-notifications'
import * as _api from './UpdateProfileService'
import BirthDayComponent from './BirthDayComponent'
import decamelize from 'decamelize'
import fp from 'lodash/fp'

class UpdateProfileComponent extends React.Component {
  constructor(props) {
    super(props)

    let user = this.props.user

    if (!user.ownerType) {
      user.ownerType = 'individual'
    }

    //user.ownerDob = '1984-10-03';
    //user.dob = '1984-10-04';
    let require_financial_data = false

    //not sure where stripebanktoken is used. this is possibly when transacting

    if (!user.stripeAccountId && user.userRole === 'Owner') {
      require_financial_data = true
    }

    if (!user.ownerFinancialAccountType) {
      user.ownerFinancialAccountType = ''
    }

    this.state = {
      isAutoCheck: false,
      isSaving: false,
      me: this.props.me,
      isAdmin: this.props.me.userRole === 'Admin' ? true : false,
      isOwnerOfProfile: this.props.me.id === this.props.user.id ? true : false,
      require_financial_data,
      user,
      error: [],
      current_password: '',
      new_password: '',
      confirm_password: '',
      save_error: '',
    }
  }

  async cleanUser(user) {
    // these variables are not needed for validation or saving

    if (user.isHirer) {
      //remove owner fields because this is not included in this form if it is hirer
      delete user['ownerBusinessAddress1']
      delete user['ownerBusinessState']
      delete user['ownerBusinessSuburb']
      delete user['ownerBusinessZipcode']
      delete user['ownerContactNumber']
      delete user['ownerDob']
      delete user['ownerFinancialAccountName']
      delete user['ownerFinancialAccountNumber']
      delete user['ownerFinancialBsb']
      delete user['ownerFirstname']
      delete user['ownerLastname']
      delete user['ownerUniquePublicName']
      delete user['ownerBusinessLegalEntity']
    }

    delete user['stripeAccountId']
    delete user['stripeBankToken']
    delete user['isDeleted']
    delete user['isHirer']
    delete user['isOwner']
    delete user['isActive']
    delete user['email']
    delete user['middleName']
    delete user['ownerIsDetailComplete']
    delete user['stripeCustomerId']
    delete user['username']
    return user
  }

  async validateCheck() {
    let user = { ...this.state.user }
    let error = {}
    let has_error = false
    user = await this.cleanUser(user) //do not include in the check

    if (user.ownerType === 'individual') {
      delete user['ownerBusinessLegalEntity'] //do not include if it is individual
    }

    delete user['ownerIsGstRegistered'] //optional
    delete user['ownerAustralianBusinessNumber'] //optional
    delete user['ownerBusinessAddress2'] //optional
    delete user['address2'] //optional

    if (!this.state.require_financial_data) {
      delete user['ownerFinancialAccountName']
      delete user['ownerFinancialAccountNumber']
      delete user['ownerFinancialBsb']
      delete user['ownerFinancialAccountType']
    }

    Object.keys(user).forEach(function (key, index) {
      let value = user[key]
      if (!value) {
        error[key] = 1
        has_error = true
      }
    })

    console.log('error--------------------------------->', error)

    if (this.state.confirm_password !== this.state.new_password) {
      has_error = true
      error['confirm_password'] = 1
      error['new_password'] = 1

      if (this.state.current_password === '') {
        error['current_password'] = 1
      }
    } else if (
      this.state.current_password !== '' &&
      this.state.confirm_password === ''
    ) {
      has_error = true
      error['confirm_password'] = 1
      error['new_password'] = 1
    }

    this.setState({ error })

    return has_error
  }

  async clickSave() {
    const has_error = await this.validateCheck()

    if (has_error) {
      this.setState({ isAutoCheck: true })
      NotificationManager.error(
        'There is an error found in the form. Please correct it.'
      )
    } else {
      this.setState({ save_error: '' })
      let user = { ...this.state.user }
      user = await this.cleanUser(user) //do not include in the check

      if (this.state.current_password !== '') {
        user['current_password'] = this.state.current_password
        user['new_password'] = this.state.new_password
      }

      let newUser = Object.fromEntries(
        Object.entries(user).map(([key, value]) => [decamelize(key), value])
      )

      this.setState({ isSaving: true })
      const result = await _api.update(newUser)
      if (result.success) {
        if (this.state.isOwnerOfProfile) {
          //update me
          let me = { ...this.state.me }
          me.firstName = newUser.first_name
          me.lastName = newUser.last_name
          me.ownerIsDetailComplete = 1
          await this.updateLocalStorage(me)
        }
        await localStorage.setItem('message_flash', 'Successfully updated!')
        window.location.href = '' //refresh
      } else {
        this.setState({ save_error: result.message })
        NotificationManager.error('There was a problem saving your profile!')
      }

      this.setState({ isSaving: false })
    }
  }

  async updateLocalStorage(_user) {
    const defaultState = { all: {}, currentUser: {} }
    const user = await fp.merge(defaultState, { currentUser: _user })

    await localStorage.setItem(
      'persist:root',
      JSON.stringify({ user: JSON.stringify(user) })
    )
  }

  handleChange = (e) => {
    let user = this.state.user
    user[e.target.name] = e.target.value
    this.setChangeField({ user })
  }

  handleBirthday1 = async (date) => {
    let user = this.state.user
    user.ownerDob = date
    this.setState({ user })

    if (this.state.isAutoCheck) {
      this.validateCheck()
    }
  }

  handleBirthday2 = async (date) => {
    let user = this.state.user
    user.dob = date
    this.setState({ user })

    if (this.state.isAutoCheck) {
      this.validateCheck()
    }
  }

  setChangeField = async (obj) => {
    await this.setState(obj)

    if (this.state.isAutoCheck) {
      this.validateCheck()
    }
  }

  clickCancel() {
    this.props.clickCancel()
  }

  render() {
    if (this.state.user && this.state.user.id > 0) {
      return (
        <>
          {' '}
          <h1>Update Profile</h1>
          {this.state.user.userRole === 'Owner' ? (
            <div className="owner-details-container">
              {this.state.save_error ? (
                <div className="error-box">{this.state.save_error}</div>
              ) : null}

              {!this.state.user.ownerIsDetailComplete && (
                <h5>Please complete your profile to continue using cnc. </h5>
              )}

              <h2 className="subtitle">Owner Account/Business Details </h2>

              <div className="field-line row">
                <div className="col-4">
                  Account Contact Firstname<span className="required">*</span>{' '}
                  <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    name="ownerFirstname"
                    className={this.state.error.ownerFirstname ? 'error' : ''}
                    value={this.state.user.ownerFirstname || ''}
                  />
                </div>

                <div className="col-8">
                  Account Contact Surname<span className="required">*</span>
                  <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    name="ownerLastname"
                    className={this.state.error.ownerLastname ? 'error' : ''}
                    value={this.state.user.ownerLastname || ''}
                  />
                </div>
              </div>

              <div className="field-line row">
                <div className="col-4">
                  Date Of Birth (mm/dd/yyyy)<span className="required">*</span>{' '}
                  <br />
                  <BirthDayComponent
                    handleChange={this.handleBirthday1}
                    name="ownerDob"
                    value={this.state.user.ownerDob || ''}
                    className={this.state.error.ownerDob ? 'error' : ''}
                  ></BirthDayComponent>
                </div>

                <div className="col-8">
                  Account Type<span className="required">*</span> <br />
                  <select
                    onChange={(e) => this.handleChange(e)}
                    name="ownerType"
                    className={this.state.error.ownerType ? 'error' : ''}
                    value={this.state.user.ownerType || ''}
                  >
                    <option value="business">
                      Registered Business/Company
                    </option>
                    <option value="individual">Individual</option>
                  </select>
                </div>
              </div>

              {this.state.user.ownerType === 'individual' ? (
                <>
                  <div className="field-line row individual">
                    <div className="col-4">
                      Unique Public Name<span className="required">*</span>{' '}
                      <br />
                      <input
                        onChange={(e) => this.handleChange(e)}
                        name="ownerUniquePublicName"
                        className={
                          this.state.error.ownerUniquePublicName ? 'error' : ''
                        }
                        value={this.state.user.ownerUniquePublicName || ''}
                      />
                    </div>
                    <div className="col-8">
                      Contact Phone Number<span className="required">*</span>{' '}
                      <br />
                      <input
                        onChange={(e) => this.handleChange(e)}
                        name="ownerContactNumber"
                        className={
                          this.state.error.ownerContactNumber ? 'error' : ''
                        }
                        value={this.state.user.ownerContactNumber || ''}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="field-line row business">
                    <div className="col-4">
                      Business Legal Entity<span className="required">*</span>{' '}
                      <br />
                      <input
                        onChange={(e) => this.handleChange(e)}
                        name="ownerBusinessLegalEntity"
                        className={
                          this.state.error.ownerBusinessLegalEntity
                            ? 'error'
                            : ''
                        }
                        value={this.state.user.ownerBusinessLegalEntity || ''}
                      />
                    </div>
                    <div className="col-8">
                      Australian Business Number <br />
                      <input
                        onChange={(e) => this.handleChange(e)}
                        name="ownerAustralianBusinessNumber"
                        type="text"
                        value={
                          this.state.user.ownerAustralianBusinessNumber || ''
                        }
                      />
                    </div>
                  </div>

                  <div className="field-line row business">
                    <div className="col-4">
                      Unique Public Name<span className="required">*</span>{' '}
                      <br />
                      <input
                        onChange={(e) => this.handleChange(e)}
                        name="ownerUniquePublicName"
                        className={
                          this.state.error.ownerUniquePublicName ? 'error' : ''
                        }
                        value={this.state.user.ownerUniquePublicName || ''}
                      />
                    </div>

                    <div className="col-8">
                      Contact Phone Number<span className="required">*</span>{' '}
                      <br />
                      <input
                        onChange={(e) => this.handleChange(e)}
                        name="ownerContactNumber"
                        className={
                          this.state.error.ownerContactNumber ? 'error' : ''
                        }
                        value={this.state.user.ownerContactNumber || ''}
                      />
                    </div>
                  </div>

                  <div className="field-line row business">
                    <div className="col-4">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => this.handleChange(e)}
                          name="ownerIsGstRegistered"
                          value={this.state.user.ownerIsGstRegistered || ''}
                        />{' '}
                        GST Registered
                      </label>
                    </div>
                  </div>
                </>
              )}

              <h2 className="subtitle">Account Address</h2>

              <div className="field-line row">
                <div className="col-4">
                  Owner/Business Address Line 1
                  <span className="required">*</span> <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    name="ownerBusinessAddress1"
                    className={
                      this.state.error.ownerBusinessAddress1 ? 'error' : ''
                    }
                    value={this.state.user.ownerBusinessAddress1 || ''}
                  />
                </div>
                <div className="col-8">
                  Owner/Business Address Line 2 <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    name="ownerBusinessAddress2"
                    className={
                      this.state.error.ownerBusinessAddress2 ? 'error' : ''
                    }
                    value={this.state.user.ownerBusinessAddress2 || ''}
                  />
                </div>
              </div>

              <div className="field-line row">
                <div className="col-4">
                  Owner/Business Suburb<span className="required">*</span>{' '}
                  <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    name="ownerBusinessSuburb"
                    className={
                      this.state.error.ownerBusinessSuburb ? 'error' : ''
                    }
                    value={this.state.user.ownerBusinessSuburb || ''}
                  />
                </div>
                <div className="col-4">
                  Owner/Business State<span className="required">*</span> <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    name="ownerBusinessState"
                    className={
                      this.state.error.ownerBusinessState ? 'error' : ''
                    }
                    value={this.state.user.ownerBusinessState || ''}
                  />
                </div>
                <div className="col-4">
                  Owner/Business Postcode<span className="required">*</span>{' '}
                  <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    name="ownerBusinessZipcode"
                    className={
                      this.state.error.ownerBusinessZipcode ? 'error' : ''
                    }
                    value={this.state.user.ownerBusinessZipcode || ''}
                  />
                </div>
              </div>

              <h2 className="subtitle">Financial Details</h2>
              <div className="bank-note">
                <b>Security Notice:</b> Banking details are for hire
                payments/commissions. Banking details are transmitted via and
                stored securely on our PCI compliant payment processor's
                systems. No banking or credit card details are transmitted via
                or stored on the Caravan & Camping Hire Website.
              </div>

              {this.state.user.ownerIsDetailComplete ? (
                <div className="bank-note-update">
                  Only enter your banking details below if you wish to update
                  the details previously entered. Banking details on file cannot
                  be retrieved through the CnC Hire website.
                </div>
              ) : null}

              <div className="field-line row">
                <div className="col-4">
                  BSB<span className="required">*</span> <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    placeholder="110000"
                    name="ownerFinancialBsb"
                    className={
                      this.state.error.ownerFinancialBsb ? 'error' : ''
                    }
                    value={this.state.user.ownerFinancialBsb || ''}
                  />
                </div>
                <div className="col-8">
                  Account Number<span className="required">*</span> <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    placeholder="000123456"
                    name="ownerFinancialAccountNumber"
                    className={
                      this.state.error.ownerFinancialAccountNumber
                        ? 'error'
                        : ''
                    }
                    value={this.state.user.ownerFinancialAccountNumber || ''}
                  />
                </div>
              </div>

              <div className="field-line row">
                <div className="col-4">
                  Account Name<span className="required">*</span> <br />
                  <input
                    onChange={(e) => this.handleChange(e)}
                    placeholder="John Doe"
                    name="ownerFinancialAccountName"
                    className={
                      this.state.error.ownerFinancialAccountName ? 'error' : ''
                    }
                    value={this.state.user.ownerFinancialAccountName || ''}
                  />
                </div>
                <div className="col-8">
                  Account Type<span className="required">*</span> <br />
                  <select
                    onChange={(e) => this.handleChange(e)}
                    name="ownerFinancialAccountType"
                    className={
                      this.state.error.ownerFinancialAccountType ? 'error' : ''
                    }
                    value={this.state.user.ownerFinancialAccountType || ''}
                  >
                    <option value="">Account Type</option>
                    <option value="business">
                      Registered Business/Company
                    </option>
                    <option value="individual">Individual</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          <div className="personal-details-container">
            <h2 className="subtitle">Personal Info</h2>

            <div className="field-line row">
              <div className="col-4">
                First name<span className="required">*</span> <br />
                <input
                  onChange={(e) => this.handleChange(e)}
                  name="firstName"
                  className={this.state.error.firstName ? 'error' : ''}
                  value={this.state.user.firstName || ''}
                />
              </div>
              <div className="col-8">
                Last name<span className="required">*</span> <br />
                <input
                  onChange={(e) => this.handleChange(e)}
                  name="lastName"
                  className={this.state.error.lastName ? 'error' : ''}
                  value={this.state.user.lastName || ''}
                />
              </div>
            </div>

            <div className="field-line row">
              <div className="col-4">
                Address 1<span className="required">*</span> <br />
                <input
                  onChange={(e) => this.handleChange(e)}
                  name="address"
                  className={this.state.error.address ? 'error' : ''}
                  value={this.state.user.address || ''}
                />
              </div>

              <div className="col-4">
                Address 2 <br />
                <input
                  onChange={(e) => this.handleChange(e)}
                  name="address2"
                  className={this.state.error.address2 ? 'error' : ''}
                  value={this.state.user.address2 || ''}
                />
              </div>

              <div className="col-8">
                Phone NUmber<span className="required">*</span> <br />{' '}
                <input
                  onChange={(e) => this.handleChange(e)}
                  name="contactNumber"
                  className={this.state.error.contactNumber ? 'error' : ''}
                  value={this.state.user.contactNumber || ''}
                />
              </div>
            </div>

            <div className="field-line row">
              <div className="col-4">
                Date Of Birth (yyyy/mm/dd)<span className="required">*</span>{' '}
                <br />
                <BirthDayComponent
                  handleChange={this.handleBirthday2}
                  name="dob"
                  value={this.state.user.dob || ''}
                  className={this.state.error.dob ? 'error' : ''}
                ></BirthDayComponent>
              </div>
              <div className="col-8">
                Email <br />{' '}
                <input
                  name="email"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.user.email || ''}
                  disabled="disabled"
                />
              </div>
            </div>

            <h2 className="subtitle">Change Password</h2>

            <div className="field-line row">
              <div className="col-2">
                Current password <br />
                <input
                  type="password"
                  name="current_password"
                  onChange={(e) =>
                    this.setChangeField({ current_password: e.target.value })
                  }
                  className={this.state.error.current_password ? 'error' : ''}
                  autoComplete="new-password"
                  value={this.state.current_password}
                />
              </div>
            </div>

            <div className="field-line row">
              <div className="col-2">
                New password <br />
                <input
                  type="password"
                  name="new_password"
                  onChange={(e) =>
                    this.setChangeField({ new_password: e.target.value })
                  }
                  className={this.state.error.new_password ? 'error' : ''}
                  value={this.state.new_password}
                />
              </div>
            </div>

            <div className="field-line row">
              <div className="col-2">
                Confirm password <br />
                <input
                  type="password"
                  name="confirm_password"
                  onChange={(e) =>
                    this.setChangeField({ confirm_password: e.target.value })
                  }
                  className={this.state.error.confirm_password ? 'error' : ''}
                  value={this.state.confirm_password}
                />
              </div>
            </div>
          </div>
          {this.state.isSaving ? (
            <button className="btn btn-primary">Please wait...</button>
          ) : (
            <button
              onClick={() => this.clickSave()}
              className="btn btn-primary"
            >
              {' '}
              Save{' '}
            </button>
          )}
          <button
            className="btn btn-secondary"
            onClick={() => this.clickCancel()}
          >
            {' '}
            Cancel{' '}
          </button>
        </>
      )
    } else {
      return <> ...</>
    }
  }
}

export default withRouter(UpdateProfileComponent)
