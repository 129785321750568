import React from 'react'
import { Icon, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import Footer from '../../Components/Footer/Footer'
import * as RegistrationPageService from './RegistrationPageService'
import './RegistrationPage.scss'
import queryString from 'query-string'

const loginImage = '//cdn.cnchire.com.au/loginImage.png'

class Registration extends React.Component {
  constructor(props) {
    super(props)

    let parsed = queryString.parse(this.props.location.search)

    this.state = {
      processing: false,
      first_name: '',
      last_name: '',
      address: '',
      address2: '',
      contact_number: '',
      is_owner: parsed && parsed.type === 'owner' ? true : false,
      is_hirer: parsed && parsed.type === 'hirer' ? true : false,
      email: '',
      password: '',
      compare_password: '',
      user_role: '',
      showErrorAlert: false,
      error_message: '',
    }
  }

  componentDidMount() {
    document.body.classList.add('registration-page')
  }

  async handleSubmit(event) {
    event.preventDefault()
    this.setState({ processing: true })
    var has_error = false

    if (this.state.password !== this.state.compare_password) {
      has_error = true
      this.setState({ error_message: "Confirm password didn't match!" })
    }

    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    var email = this.state.email

    if (!email || !email.match(mailformat)) {
      has_error = true
      this.setState({ error_message: 'Invalid email address' })
    }

    if (!has_error) {
      const payload = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,

        address: this.state.address,
        address2: this.state.address2,
        contact_number: this.state.contact_number,
        is_owner: this.state.is_owner,
        is_hirer: this.state.is_hirer,
        email: this.state.email,
        password: this.state.password,
        user_role: this.state.is_owner ? 'Owner' : 'Hirer',
      }

      const result = await RegistrationPageService.createUser(payload)
      console.log(`Registration:  `, result)

      let lastVehicle = localStorage.getItem('last_vehicle_settings')

      if (lastVehicle) {
        let lastVehicleSettings = JSON.parse(lastVehicle) || {}
        if (lastVehicleSettings.lastLink) {
          lastVehicleSettings = { isRegistered: true, ...lastVehicleSettings } //add registered flag so that we can redirect to vehicle after logging in.
          localStorage.setItem('last_vehicle_settings', lastVehicleSettings)
        }
      }

      if (result.success) {
        const message = encodeURI('You have successfully registered.')
        window.location = '/login?message=' + message
      } else {
        this.setState({ error_message: result.message.replace('Error:', '') })
      }
    }

    this.setState({ processing: false })
  }

  resetToDefault() {
    this.setState({
      first_name: '',
      last_name: '',
      address: '',
      address2: '',
      contact_number: '',
      is_owner: false,
      is_hirer: false,
      email: '',
      password: '',
      compare_password: '',
      user_role: '',
    })
  }

  toggleHirerOwner = (isHirer) => {
    if (isHirer) {
      this.setState({ is_owner: false, is_hirer: true })
    } else {
      this.setState({ is_owner: true, is_hirer: false })
    }
  }

  checkHirer = (event) => {
    if (event.target.checked) {
      this.toggleHirerOwner(true)
    } else {
      this.toggleHirerOwner(false)
    }
  }

  checkOwner = (event) => {
    if (event.target.checked) {
      this.toggleHirerOwner(false)
    } else {
      this.toggleHirerOwner(true)
    }
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Registration" link="/register" />

        <div className="registrationContainer">
          <div className="regImageFormContainer">
            <div className="createUserFormBox">
              <img className="regLoginImageIcon" alt="" src={loginImage} />
              <form
                className="regFormContainer"
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <div className="regTitle">Registration</div>

                {this.state.error_message !== '' ? (
                  <div className="errorMessage">{this.state.error_message}</div>
                ) : (
                  ''
                )}

                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="First Name"
                  onChange={(event) =>
                    this.setState({ first_name: event.target.value })
                  }
                  required
                >
                  <Icon name="user" />
                  <input />
                </Input>
                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="Last Name"
                  onChange={(event) =>
                    this.setState({ last_name: event.target.value })
                  }
                  required
                >
                  <Icon name="user" />
                  <input />
                </Input>

                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="Address"
                  onChange={(event) =>
                    this.setState({ address: event.target.value })
                  }
                  required
                >
                  <Icon name="location arrow" />
                  <input />
                </Input>

                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="Address line 2"
                  onChange={(event) =>
                    this.setState({ address2: event.target.value })
                  }
                  required
                >
                  <Icon name="location arrow" />
                  <input />
                </Input>

                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="Contact"
                  onChange={(event) =>
                    this.setState({ contact_number: event.target.value })
                  }
                  required
                >
                  <Icon name="phone" />
                  <input />
                </Input>
                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="Email Address"
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  required
                >
                  <Icon name="mail" />
                  <input />
                </Input>
                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="Password"
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                  required
                >
                  <Icon name="lock" />
                  <input type="password" />
                </Input>
                <Input
                  className="regInputBox"
                  iconPosition="left"
                  placeholder="Confirm Password"
                  onChange={(event) =>
                    this.setState({ compare_password: event.target.value })
                  }
                  required
                >
                  <Icon name="lock" />
                  <input type="password" />
                </Input>
                <div className="regFunctionContainer">
                  <div className="ui   checkboxContainer">
                    <label
                      className={
                        this.state.is_owner
                          ? 'buttonHirerOwner active'
                          : 'buttonHirerOwner'
                      }
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td valign="top">
                              <input
                                type="radio"
                                name="is_owner"
                                value="1"
                                checked={this.state.is_owner}
                                onChange={(event) => this.checkOwner(event)}
                              />
                            </td>
                            <td>
                              <h4>Owner</h4>I want to list my vehicle
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </label>

                    <label
                      className={
                        this.state.is_hirer
                          ? 'buttonHirerOwner active'
                          : 'buttonHirerOwner'
                      }
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td valign="top">
                              <input
                                type="radio"
                                name="is_hirer"
                                value="1"
                                checked={this.state.is_hirer}
                                onChange={(event) => this.checkHirer(event)}
                              />
                            </td>
                            <td>
                              <h4>Hirer</h4>I want to hire a vehicle
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </label>
                  </div>
                </div>
                <button
                  className="regSubmitButton"
                  type="submit"
                  disabled={this.state.processing}
                >
                  <span className="regSubmitButtonLabel">
                    {this.state.processing ? 'Please wait..' : 'Register'}
                  </span>
                </button>
                <div className="loginDetails">
                  <span className="loginDetailsText">
                    Already have an account?{' '}
                    <Link to="/login" className="loginDetailsLink">
                      Sign in
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(Registration)
