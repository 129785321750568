const image1 = '//cdn.cnchire.com.au/Image 1.png'
const image2 = '//cdn.cnchire.com.au/Image 2.png'
const image3 = '//cdn.cnchire.com.au/Image 3.png'
const image4 = '//cdn.cnchire.com.au/Image 4.png'
const image5 = '//cdn.cnchire.com.au/Image 5.png'
const image6 = '//cdn.cnchire.com.au/Image 6.png'
const image7 = '//cdn.cnchire.com.au/Image 7.png'
const image8 = '//cdn.cnchire.com.au/Image 8.png'

const LocationItems = [
  {
    name: 'Australian Capital Territory',
    link: '/search-results?state=act',
    image: image1,
  },
  {
    name: 'New South Wales',
    link: '/search-results?state=nsw',
    image: image2,
  },
  {
    name: 'Northern Territory',
    link: '/search-results?state=nt',
    image: image3,
  },
  {
    name: 'Queensland',
    link: '/search-results?state=qld',
    image: image4,
  },
  {
    name: 'South Australia',
    link: '/search-results?state=sa',
    image: image5,
  },
  {
    name: 'Tasmania',
    link: '/search-results?state=tas',
    image: image6,
  },
  {
    name: 'Victoria',
    link: '/search-results?state=vic',
    image: image7,
  },
  {
    name: 'Western Australia',
    link: '/search-results?state=wa',
    image: image8,
  },
]

export default LocationItems
