import React from 'react'

import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import BookingConfirmation from 'Components/Booking/Confirmation'
import PartnersAndMedia from 'Components/PartnersAndMedia/PartnersAndMedia'
import Footer from 'Components/Footer/Footer'
import { withRouter } from 'react-router'

class BookingConfirmationPage extends React.Component {
  footerBackground = { background: '#F7F7F7' }

  componentDidMount() {
    document.body.classList.add('booking-confirmation-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Booking Confirmation" link="/booking-confirmation" />
        <BookingConfirmation />
        <PartnersAndMedia showPartnersAndMediaText={true} />
        <Footer footerContainerBackground={this.footerBackground} />
      </React.Fragment>
    )
  }
}
export default withRouter(BookingConfirmationPage)
