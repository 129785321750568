import React from 'react'
import { withRouter } from 'react-router'
import './VehicleListPage.scss'
import * as vehicleListService from './VehicleListService'
import { NotificationManager } from 'react-notifications'
import Pagination from '@material-ui/lab/Pagination'

import ThumbnailViewComponent from './ThumbnailView'
import ListViewComponent from './ListView'
import PendingListViewComponent from './PendingListView'
import queryString from 'query-string'

class VehicleListPage extends React.Component {
  constructor(props) {
    super(props)

    let parsed = queryString.parse(this.props.location.search)
    let filterById = false

    if (parsed && parsed.id) {
      let { id } = parsed
      filterById = id
    }

    ///filteredVehicle

    this.state = {
      filterById,
      filter: 1,
      pending_show: false,
      search: '',
      userRole: this.props.user.userRole,
      screen: 'list',
      type: 'thumb',
      list: [],
      showConfirmForm: false,
      targetItem: null,
      targetType: null,
      meta: filterById ? { filteredVehicle: filterById } : null,
      fetched: false,

      pendingList: [],
    }

    this.fetchLatest()

    if (this.state.userRole === 'Admin') {
      this.fetchPending()
    }
  }

  clickFilter = async (t) => {
    let _meta = this.state.meta
    _meta.count = 0
    await this.setState({ meta: _meta })

    await this.setState({ filter: t })
    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-vehicle-list-page')
  }

  fetchPending = async () => {
    let params = {}
    if (this.state.meta !== null) {
      params = { filter: this.state.meta, ...params }
    }

    const result = await vehicleListService.fetchPending(params)

    if (result.success) {
      this.setState({
        pendingList: result.data.map((x) => {
          return { show: true, ...x }
        }),
      })
    }
  }

  fetchLatest = async () => {
    let params = this.state.filterId ? { id: this.state.filterId } : {}

    let meta = { filter: this.state.filter }
    if (this.state.meta !== null) {
      meta = { ...meta, ...this.state.meta }
    }

    if (this.state.search !== '') {
      meta = { ...meta, search: this.state.search }
    }

    params = { filter: meta, ...params }

    const result = await vehicleListService.fetch(params)

    if (result.success) {
      this.setState({
        list: result.data.map((x) => {
          return { show: true, ...x }
        }),
      })
      this.setState({ meta: result.meta })
    }

    this.setState({ fetched: true })
  }

  clickEnableDisable = async (item) => {
    this.setState({ targetItem: item })
    this.setState({ targetType: item.isActive ? 'disable' : 'enable' })
    this.setState({ showConfirmForm: true })
  }

  clickDelete = async (item) => {
    this.setState({ targetItem: item })
    this.setState({ targetType: 'delete' })
    this.setState({ showConfirmForm: true })
  }

  clickConfirmAction = async () => {
    if (this.state.targetType === 'delete') {
      const result = await vehicleListService.remove(this.state.targetItem.id)

      if (result.success) {
        await this.fetchLatest()
        NotificationManager.success('The vehicle has been deleted.', 'Success')
      } else {
        NotificationManager.error(
          'There was a problem deleting the vehicle at the moment.',
          'Error'
        )
      }
    } else if (this.state.targetType === 'disable') {
      const result = await vehicleListService.draft(this.state.targetItem.id)

      if (result.success) {
        await this.fetchLatest()
        NotificationManager.success('The vehicle has been disabled.', 'Success')
      } else {
        NotificationManager.error(
          'There was a problem disabling the vehicle at the moment.',
          'Error'
        )
      }
    } else if (this.state.targetType === 'enable') {
      const result = await vehicleListService.undraft(this.state.targetItem.id)

      if (result.success) {
        await this.fetchLatest()
        NotificationManager.success('The vehicle has been enabled.', 'Success')
      } else {
        NotificationManager.error(
          'There was a problem enabling the vehicle at the moment.',
          'Error'
        )
      }
    }

    this.clickCloseCancelForm()
    this.setState({ targetItem: null })
    this.setState({ targetType: null })
  }

  clickCloseCancelForm = () => {
    this.setState({ showConfirmForm: false })
  }

  clickCreateNew = () => {
    window.location.href = '/dashboard/vehicles/new'
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    console.log('clicked pagination', page, this.state.meta)
    await this.setState({ meta: _meta })
    await this.fetchLatest()
  }

  toogleOrderList = () => {
    this.setState({ type: this.state.type === 'thumb' ? 'list' : 'thumb' })
  }

  draftVehicle = async (item) => {
    await vehicleListService.draft(item.id)
    await this.fetchLatest()
    NotificationManager.success('The vehicle is drafted', 'Success')
  }

  onSearch = (event) => {
    let search = event.target.value
    this.setState({ search })
    this.fetchLatest()
  }

  render() {
    if (this.state.userRole === 'Hirer') {
      return <> You cant access this page. </>
    } else if (this.state.screen === 'list' && this.state.fetched === true) {
      //
      return (
        <>
          {this.state.userRole === 'Admin' && (
            <>
              <div
                style={{
                  float: 'right',
                  marginRight: '20px',
                  marginBottom: '0px',
                }}
              >
                <a
                  className="btn btn-secondary"
                  href="/dashboard/vehicles/draft"
                >
                  Draft Vehicles
                </a>
              </div>
              <div style={{ clear: 'both' }}></div>
            </>
          )}

          {this.state.pendingList && this.state.pendingList.length > 0 && (
            <PendingListViewComponent
              list={this.state.pendingList}
            ></PendingListViewComponent>
          )}

          {this.state.showConfirmForm && (
            <div className="confirm-popup">
              <h1>
                Are you sure you want to {this.state.targetType} this{' '}
                <a href={`/dashboard/vehicle/${this.state.targetItem.id}`}>
                  {this.state.targetItem.vehicleName}
                </a>
                ?
              </h1>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.clickConfirmAction()}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.clickCloseCancelForm()}
              >
                Cancel
              </button>
            </div>
          )}

          <div className="vehicle-list-container">
            <input
              type="text"
              className="search-box"
              value={this.state.search}
              onChange={(event) => this.onSearch(event)}
              placeholder="Search Vehicle"
            />
            <div className="order-list" onClick={() => this.toogleOrderList()}>
              {this.state.type === 'list' && (
                <div className="selected-list"></div>
              )}
              {this.state.type === 'thumb' && (
                <div className="selected-thumbnail"></div>
              )}
            </div>

            <div className="main-container">
              {this.state.filterById ? (
                <div className="results">
                  1 result /{' '}
                  <button
                    className="btn btn-secondary"
                    onClick={() => (window.location.href = '?')}
                  >
                    Go to list
                  </button>
                </div>
              ) : (
                <div className="results">
                  {this.state.meta ? this.state.meta.rows : 0} Results
                </div>
              )}

              <div className="top-button-container">
                <button
                  onClick={() => {
                    this.clickCreateNew()
                  }}
                  className="btn btn-primary add-vehicle-btn"
                  disabled={this.state.userRole !== 'Owner'}
                >
                  Add Vehicle
                  {this.state.userRole === 'Admin'
                    ? '- add vehicle by admin is not supported yet.'
                    : ''}
                </button>
                <div className="clearfix"></div>
              </div>

              <div className="filter-selection">
                Filter:
                <span
                  className={this.state.filter === 1 ? 'active' : ''}
                  onClick={() => this.clickFilter(1)}
                >
                  All{' '}
                </span>{' '}
                |
                <span
                  className={this.state.filter === 2 ? 'active' : ''}
                  onClick={() => this.clickFilter(2)}
                >
                  Name
                </span>{' '}
                |
                <span
                  className={this.state.filter === 3 ? 'active' : ''}
                  onClick={() => this.clickFilter(3)}
                >
                  Date
                </span>{' '}
                |
                <span
                  className={this.state.filter === 4 ? 'active' : ''}
                  onClick={() => this.clickFilter(4)}
                >
                  Price
                </span>{' '}
                |
                <span
                  className={this.state.filter === 5 ? 'active' : ''}
                  onClick={() => this.clickFilter(5)}
                >
                  Pending
                </span>{' '}
                |
                <span
                  className={this.state.filter === 6 ? 'active' : ''}
                  onClick={() => this.clickFilter(6)}
                >
                  Draft
                </span>
              </div>
              <br />
              <br />

              {this.state.type === 'thumb' && (
                <ThumbnailViewComponent
                  list={this.state.list}
                  role={this.state.userRole}
                  draftVehicle={this.draftVehicle}
                ></ThumbnailViewComponent>
              )}
              {this.state.type === 'list' && (
                <ListViewComponent list={this.state.list}></ListViewComponent>
              )}

              <div style={{ float: 'right', marginTop: '20px' }}>
                {this.state.meta !== null && (
                  <Pagination
                    count={this.state.meta.count}
                    variant="outlined"
                    shape="rounded"
                    onChange={this.clickPagination}
                    size={'large'}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return <>...</>
    }
  }
}

export default withRouter(VehicleListPage)
