import React from 'react'
import Alert from 'react-bootstrap/Alert'
import VehicleCard from '../VehicleCard/VehicleCard'
import { withRouter } from 'react-router'
import AdvanceSearchBox from 'Components/AdvanceSearchBox/AdvanceSearchBox'
import Map from 'Components/Map'
import './AllVehicles.scss'

class AllVehicles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searched_location: this.props.searched_location,
      vehicleList: this.props.vehicleList,
      title: this.props.title,
      vehicleType: this.props.vehicleType,
      search: this.props.search ? this.props.search : '',
      dateRange: this.props.dateRange,
      totalPages: 10,
      displayCount: 9,

      lat: this.props.lat,
      lng: this.props.lng,
      searchType: this.props.searchType,
      notFound: this.props.vehicleList.length === 0 ? true : false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ vehicleList: nextProps.vehicleList })
  }

  handleListChange = () => {}

  render() {
    return (
      <React.Fragment>
        <div className="allVehiclesContainer">
          <span className="allVehiclesTitle">
            {this.props.searched_location
              ? 'Searched vehicle in ' + this.props.searched_location
              : this.state.title
              ? this.state.title
              : 'Search Our Great Range of RV’s For Hire'}
          </span>
          <div className="searchContainer">
            <AdvanceSearchBox
              advanceSearchBoxClassName={'advanceSearchBoxInVehiclePage'}
              address={this.state.searched_location}
              lat={this.state.lat}
              lng={this.state.lng}
              vehicleType={this.state.vehicleType}
              dateRange={this.state.dateRange}
            />
          </div>

          <div className="allVehiclesBody">
            <div className="vehicleListPaginationBox">
              <div className="vehicleListingsContainer">
                {this.state.notFound && (
                  <div className="alertMsgContainer">
                    <Alert variant="danger">
                      {this.state.searchType === 'state' ? (
                        <>
                          <Alert.Heading>State Search Result</Alert.Heading>
                          <p>No vehicles found in this state.</p>
                        </>
                      ) : (
                        <>
                          <Alert.Heading>No results found!</Alert.Heading>
                          <p>
                            We cant find any caravan in{' '}
                            <b>{this.state.search}.</b>
                          </p>
                        </>
                      )}
                    </Alert>
                  </div>
                )}

                {this.state.vehicleList.length > 0 &&
                  this.state.vehicleList.map((item, index) => {
                    let images = item.images ? JSON.parse(item.images) : []
                    let thumb = 'https://cdn.cnchire.com.au/c6.png'

                    if (item.thumbnail !== '') {
                      thumb = item.thumbnail
                    } else if (images[0]) {
                      thumb = images[0].image
                    }

                    const distance = item.distance ? item.distance : ''

                    return (
                      <VehicleCard
                        vehicle={item}
                        vehicleID={item.id}
                        vehicleImage={thumb}
                        vehicleName={item.vehicleName}
                        pricePerDay={item.pricePerDay}
                        vehicleType={item.vehicleType}
                        location={item.location}
                        vehicleSleeps={item.vehicleSleeps}
                        searched_location={this.state.searched_location}
                        lat={item.lat}
                        lng={item.lng}
                        distance={distance}
                        key={index}
                      />
                    )
                  })}
              </div>
            </div>

            {!this.state.notFound && (
              <Map
                vehicleList={this.state.vehicleList}
                isSearched={
                  this.state._location === '' ||
                  this.state._location === undefined
                    ? false
                    : true
                }
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(AllVehicles)
