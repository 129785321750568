import React from 'react'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

import './Checkout.scss'

const card1 = '//cdn.cnchire.com.au/Discover card 1.png'
const card2 = '//cdn.cnchire.com.au/American Express 1.png'
const card3 = '//cdn.cnchire.com.au/Mastercard 1.png'
const card4 = '//cdn.cnchire.com.au/Visa 1.png'

const Checkout = () => {
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs()

  return (
    <React.Fragment>
      <div className="checkoutContainer">
        <div className="billingcolumncontainer">
          <div className="billingcolumn">
            <span className="billingTitle">Billing Details</span>
            <div className="billingrow">
              <div className="billingrowItem">
                <span className="billingfield">First Name*</span>
                <input className="billingInput" placeholder="First Name" />
              </div>
              <div className="billingrowItem">
                <span className="billingfield">Last Name*</span>
                <input className="billingInput" placeholder="Last Name" />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Company Name (optional)</span>
                <input className="billingInput" placeholder="Company Name" />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Country / Region*</span>
                <input
                  className="billingInput"
                  placeholder="Country / Region"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Street Address*</span>
                <input
                  className="billingInput"
                  placeholder="House number and street name"
                />
                <input
                  className="billingInput"
                  placeholder="Apartment, suite, unit, etc (optional)"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Town / City*</span>
                <input className="billingInput" placeholder="Town / City" />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Postcode / ZIP*</span>
                <input className="billingInput" placeholder="Postcode / Zip" />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Phone*</span>
                <input className="billingInput" placeholder="Phone" />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Email Address*</span>
                <input className="billingInput" placeholder="Email Address" />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Account Username*</span>
                <input
                  className="billingInput"
                  placeholder="Account Username"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Create Account Password*</span>
                <input
                  className="billingInput"
                  placeholder="Password"
                  type="password"
                />
              </div>
            </div>

            <span className="billingTitle" style={{ marginTop: '5%' }}>
              Additional Information
            </span>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">No. of travellers (Total)*</span>
                <input
                  className="billingInput"
                  placeholder="No. of travellers"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">
                  No. of children travellers (Total)*
                </span>
                <input
                  className="billingInput"
                  placeholder="No. of children travellers"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Planned Destinations*</span>
                <input
                  className="billingInput"
                  placeholder="Planned Destinations"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Towing Vehicle Make*</span>
                <input
                  className="billingInput"
                  placeholder="Towing Vehicle Make"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Towing Vehicle Model*</span>
                <input
                  className="billingInput"
                  placeholder="Towing Vehicle Model"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">
                  Towing Vehicle Year Of Manufacture*
                </span>
                <input
                  className="billingInput"
                  placeholder="Towing Vehicle Year Of Manufacture"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">
                  Does Towing Vehicle Have Electronic Brake Controller*
                </span>
                <input
                  className="billingInput"
                  placeholder="Towing Vehicle Year Of Manufacture"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">
                  <input type="checkbox" name="freeCamping" /> Are you free
                  camping? (Optional)
                </span>
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">Towing Experience*</span>
                <input
                  className="billingInput"
                  placeholder="Towing Experience"
                />
              </div>
            </div>

            <div className="billingrow">
              <div className="billingrowItem2">
                <span className="billingfield">
                  Additional Requests/Extras (Charges may apply)(Optional)
                </span>
                <textarea
                  className="billingTextArea"
                  placeholder="Additional Requests/Extras..."
                  rows="10"
                  cols="50"
                />
              </div>
            </div>

            <div className="billingrow" style={{ marginBottom: '10%' }}>
              <div className="billingrowItem2">
                <span className="billingfield">
                  Additional Notes for Owner(Optional)
                </span>
                <textarea
                  className="billingTextArea"
                  placeholder="Additional Notes for Owner..."
                  rows="10"
                  cols="50"
                />
              </div>
            </div>
          </div>
          <div className="billingcolumn2">
            <span className="yourOrderTitle">Your Order</span>
            <div className="checkoutLine" />
            <table className="checkoutTable">
              <tbody>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdTitle">Products</span>
                  </td>
                  <td className="tableColumn2">
                    <span className="tdTitle">Total</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdFieldData">Vehicle Name</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdField">Booking Date:</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdFieldData">October 15, 2020</span>
                  </td>
                  <td className="tableColumn2">
                    <span className="tdPrice">$30.00</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdField">Duration:</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdFieldData">3 days</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdField">Linked To:</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdFieldData">Vehicle Name</span>
                  </td>
                  <td className="tableColumn2">
                    <span className="tdPrice">$30.00</span>
                  </td>
                </tr>
                <tr style={{ height: '75px' }}>
                  <td className="tableColumn1">
                    <span className="tdField2">Subtotal</span>
                  </td>
                  <td className="tableColumn2">
                    <span className="tdPrice">$30.00</span>
                  </td>
                </tr>
                <tr>
                  <td className="tableColumn1">
                    <span className="tdField2">Booking + Service Fee</span>
                  </td>
                  <td className="tableColumn2">
                    <span className="tdPrice">$30.00</span>
                  </td>
                </tr>
                <tr style={{ height: '75px' }}>
                  <td className="tableColumn1">
                    <span className="tdField2">Total</span>
                  </td>
                  <td className="tableColumn2">
                    <span className="tdPrice">$30.00</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="checkoutLine"></div>
            <div className="ccTitleBox">
              <div className="creditCardTitle">Credit Card</div>
              <div>
                <img src={card1} alt="" />
                <img src={card2} alt="" />
                <img src={card3} alt="" />
                <img src={card4} alt="" />
              </div>
            </div>
            <span className="paymentDetails">{`Payments will appear on your statement as "Caravan & Camping Hire"`}</span>
            <span className="ccDebitCard">Credit or Debit Card</span>
            <PaymentInputsWrapper style={{ width: '100%' }} {...wrapperProps}>
              <svg {...getCardImageProps({ images })} />
              <input {...getCardNumberProps()} />
              <input {...getExpiryDateProps()} />
              <input {...getCVCProps()} />
            </PaymentInputsWrapper>
            <span className="term1">
              <input
                type="checkbox"
                name="savePaymentInfo"
                style={{ marginRight: '1%' }}
              />
              Save payment information to my account for future purchases.
            </span>
            <span
              className="term1"
              style={{ marginTop: '8%', marginBottom: '8%' }}
            >
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our privacy policy.{' '}
            </span>
            <span className="term1">
              <input
                type="checkbox"
                name="savePaymentInfo"
                style={{ marginRight: '1%' }}
              />
              I have read and agree to the website terms and conditions*
            </span>
            <div className="proceedToCheckoutBtnContainer">
              <button className="proceedToCheckoutBtn">
                <span className="proceedToCheckoutBtnLabel">
                  Proceed To Checkout
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Checkout
