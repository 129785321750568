import React from 'react'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './LegendComponent.scss'
import * as icons from './StatusIcon'

class LegendComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <>
        {' '}
        <div className="legend-container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status failed" title="Failed">
                <FontAwesomeIcon icon={icons.statusUnpaid} />
              </span>{' '}
              Request Approval
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status failed" title="Failed">
                <FontAwesomeIcon icon={icons.statusFailed} />
              </span>{' '}
              Failed Payment
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status unpaid" title="Unpaid">
                <FontAwesomeIcon icon={icons.statusUnpaid} />
              </span>{' '}
              Unpaid
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status partial-payment" title="Partially Paid">
                <FontAwesomeIcon icon={icons.statusPartiallyPaid} />
              </span>{' '}
              Partially Paid
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status on-hold" title="Order on Hold">
                <FontAwesomeIcon icon={icons.statusOnHold} />
              </span>{' '}
              On Hold
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status processing" title="Paid">
                <FontAwesomeIcon icon={icons.statusPaid} />
              </span>{' '}
              Paid
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status onhire" title="On Hire">
                <FontAwesomeIcon icon={icons.statusOnHire} />
              </span>{' '}
              On Hire
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span
                className="status bond-investigate"
                title="Pending Inspection"
              >
                <FontAwesomeIcon icon={icons.statusPendingInspection} />
              </span>{' '}
              Pending Inspection
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status refund-bond" title="Refund the Bond">
                <FontAwesomeIcon icon={icons.statusRefundBond} />
              </span>{' '}
              Refund Bond
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status withhold-bond" title="Withhold the Bond">
                <FontAwesomeIcon icon={icons.statusWithholdBond} />
              </span>{' '}
              Withhold Bond
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status bond-refunded" title="Bond Refunded">
                <FontAwesomeIcon icon={icons.statusBondRefunded} />
              </span>{' '}
              Bond Refunded
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span
                className="status bond-partially-refunded"
                title="Bond Partially Refunded"
              >
                <FontAwesomeIcon icon={icons.statusBondPartiallyRefunded} />
              </span>{' '}
              Bond Partially Refunded
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status bond-withheld" title="Bond Withheld">
                <FontAwesomeIcon icon={icons.statusBondWithheld} />
              </span>{' '}
              Bond Withheld
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status refunded" title="Fully Refunded">
                <FontAwesomeIcon icon={icons.statusBondFullyRefunded} />
              </span>{' '}
              Fully Refunded
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="status cancelled" title="Cancelled">
                <FontAwesomeIcon icon={icons.statusCancelled} />
              </span>{' '}
              Cancelled
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(LegendComponent)
