import React from 'react'
import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import Footer from 'Components/Footer/Footer'
import './confirm-booking-success.scss'
import { withRouter } from 'react-router'

class PaymentBookingSuccessPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('confirm-booking-success-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />

        <PageBanner title="Book a caravan" link="/vehicles" />

        <React.Fragment>
          <div className="faqContainer">
            <div className="faqTitle">Thank you!</div>
            <div className="faqWrapper booking-body">
              Your booking payment has been received. Please check the status of
              your booking in your dashboard.
            </div>
          </div>
        </React.Fragment>

        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(PaymentBookingSuccessPage)
