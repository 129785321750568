import React from 'react'
import * as userService from '../UserService'
import { NotificationManager } from 'react-notifications'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class PasswordChangeAction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      new_password: '',
      showForm: false,
      processing: false,
      ...props,
    }
  }

  async clickChangePassword() {
    if (this.state.new_password !== '') {
      const result = await userService.changeNewPassword({
        new_password: this.state.new_password,
        user: this.state.user,
      })
      if (result.success) {
        NotificationManager.success('The password has been changed!')
      }
    } else {
      NotificationManager.error(
        'There was an error changing the user password!'
      )
    }
    this.setState({ showForm: false })
  }

  render() {
    return (
      <>
        <button
          title="Click to change user password"
          className="btn btn-secondary manual-update-btn"
          onClick={() => this.setState({ showForm: true })}
        >
          <FontAwesomeIcon icon="key" />
        </button>

        <Modal
          show={this.state.showForm}
          onHide={() => this.setState({ showForm: false })}
          dialogClassName="change-password-modal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h2>Emergency Password Change</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Enter New Password:{' '}
            <input
              type="text"
              onChange={(event) =>
                this.setState({ new_password: event.target.value })
              }
            />{' '}
            <br />
            <br />
            <button
              className="btn btn-secondary"
              onClick={() => this.setState({ showForm: false })}
            >
              Back
            </button>
            &nbsp;
            <button
              className="btn btn-primary"
              onClick={() => this.clickChangePassword()}
            >
              Update Status
            </button>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}
