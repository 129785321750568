import React from 'react'
import { withRouter } from 'react-router'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'

class TowingDetailsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...this.props.state }
  }

  _setState = async (obj) => {
    this.setState(obj)
    this.props.parentHandle(obj)
  }

  handleChange = async (e) => {
    var obj = {}
    obj[`${e.target.name}`] = e.target.value
    await this._setState(obj)
  }

  render() {
    return (
      <>
        <div className="subcontent section-5">
          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Tare:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.state.towing_tare || ''}
                  name="towing_tare"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>ATM:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.state.towing_atm || ''}
                  name="towing_atm"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Towball weight:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.state.towing_towball_weight || ''}
                  name="towing_towball_weight"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Towing plug:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.state.towing_plug || ''}
                  name="towing_plug"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>{' '}
        {/*subcontent*/}
      </>
    )
  }
}

export default withRouter(TowingDetailsComponent)
