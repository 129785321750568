import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete'

const LocationComponent = (props) => {
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  // console.log('VAL', value, autocompleteValue)

  useEffect(() => {
    if (autocompleteValue) {
      ;(async () => {
        // console.log('Geocoding', autocompleteValue)
        try {
          const results = await geocodeByAddress(autocompleteValue?.label)
          // console.log(results)
          const latLng = await getLatLng(results[0])
          // console.log(latLng)
          _setState({
            ...latLng,
            location: autocompleteValue.label,
            state: results[0].address_components[4].short_name,
            state_code: results[0].address_components[6].short_name,
          })
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [autocompleteValue])

  const _setState = (obj) => {
    props.parentHandle(obj)
  }

  const handleChange = (e) => {
    _setState({ [`${e.target.name}`]: e.target.value })
  }
  const className =
    props.state.lat_error ||
    props.state.lng_error ||
    props.state.state_error ||
    props.state.location_error ||
    props.state.state_code_error
      ? 'select error'
      : 'select'

  return (
    <>
      <div className="subcontent section-2">
        <div className="line-item">
          <div className="row">
            <div className="col-2">
              <b>Address search:</b>
            </div>
            <div className="col-10">
              <GooglePlacesAutocomplete
                apiKey="AIzaSyAdfTQAZrOFadHRfHQJrtZEq_MOaYmKLIo"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['au', 'nz'],
                  },
                }}
                minLengthAutocomplete="5"
                selectProps={{
                  className,
                  styles: {
                    container: (provided) => ({
                      ...provided,
                      maxWidth: '500px',
                      borderRadius: '5px',
                      border: '1px solid #8a8a8a',
                    }),
                  },
                  value: autocompleteValue,
                  onChange: setAutocompleteValue,
                }}
              />
            </div>
          </div>
        </div>
        <div className="line-item">
          <div className="row">
            <div className="col-2">
              <b>Geo Locations*:</b>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={props.state.lat || ''}
                className={props.state.lat_error ? 'error latlong' : 'latlong'}
                disabled={autocompleteValue?.label}
                name="lat"
                placeholder="Latitude"
                onChange={handleChange}
              />
              <input
                type="text"
                value={props.state.lng || ''}
                className={props.state.lng_error ? 'error latlong' : 'latlong'}
                disabled={autocompleteValue?.label}
                name="lng"
                placeholder="Longitude"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="line-item">
          <div className="row">
            <div className="col-2">
              <b>Address*:</b>
            </div>
            <div className="col-10">
              <input
                type="text"
                disabled={autocompleteValue?.label}
                value={props.state.location || ''}
                className={props.state.location_error ? 'error' : ''}
                name="location"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="line-item">
          <div className="row">
            <div className="col-2">
              <b>State*:</b>
            </div>
            <div className="col-10">
              <input
                type="text"
                disabled={autocompleteValue?.label}
                value={props.state.state || ''}
                className={props.state.state_error ? 'error' : ''}
                name="state"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="line-item">
          <div className="row">
            <div className="col-2">
              <b>Zip Code*:</b>
            </div>
            <div className="col-10">
              <input
                type="text"
                disabled={autocompleteValue?.label}
                value={props.state.state_code || ''}
                className={props.state.state_code_error ? 'error' : ''}
                name="state_code"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>{' '}
      {/*subcontent*/}
    </>
  )
}

export default withRouter(LocationComponent)
