import React from 'react'
import { withRouter } from 'react-router'
import './DateFilter.scss'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { format as formatDate } from 'date-fns'

import 'react-day-picker/lib/style.css'

class DateFilterComponent extends React.Component {
  constructor(props) {
    super(props)
    //this.props.fromChildToParentCallback(this.clickFilterDate);
    //let {id}  = this.props.match.params ;

    this.state = {
      invalidDate: false,
      from: this.props.from,
      to: this.props.to,
    }
  }

  clickFilterDate = () => {
    if (this.state.from !== '' && this.state.to !== '') {
      this.props.fromChildToParentCallback({
        from: this.state.from,
        to: this.state.to,
      })
    }
  }

  enterDate = async (type, day) => {
    let obj = {}
    obj[type] = day
    await this.setState(obj)

    if (this.state.from !== '' && this.state.to !== '') {
      this.setState({
        invalidDate:
          Date.parse(this.state.from) > Date.parse(this.state.to)
            ? true
            : false,
      })
    }
  }

  render() {
    const format = 'MM/dd/yyyy'

    return (
      <>
        <div className="date-filter">
          <div className="row">
            <div className="col-6">
              Date Start <br />
              <DayPickerInput
                format={format}
                value={this.state.from}
                formatDate={formatDate}
                placeholder="Choose Date"
                onDayChange={(day) => this.enterDate('from', day)}
              />
              {this.state.invalidDate && (
                <span className="invalid-date">Invalid date range</span>
              )}
            </div>

            <div className="col-6">
              Date End <br />
              <DayPickerInput
                format={format}
                value={this.state.to}
                formatDate={formatDate}
                placeholder="Choose Date"
                onDayChange={(day) => this.enterDate('to', day)}
              />
              {this.state.invalidDate && (
                <span className="invalid-date">Invalid date range</span>
              )}
            </div>
          </div>

          {this.state.from !== '' && (
            <button
              className="load-btn btn clear"
              onClick={() => (window.location.href = '/dashboard')}
            >
              Clear
            </button>
          )}

          <button
            className="load-btn btn"
            onClick={() => this.clickFilterDate()}
            disabled={this.state.invalidDate}
          >
            Filter
          </button>
        </div>
      </>
    )
  }
}

export default withRouter(DateFilterComponent)
