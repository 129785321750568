import axios from 'axios'
import fp from 'lodash/fp'

import { camelizeKeys } from './helpers'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/products`,
})

export const fetchProducts = async () => {
  const productList = await api.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(productList)
}

export const fetchProduct = async (id) => {
  const getProductAPI = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/products/${id}`,
  })
  const productInfo = await getProductAPI.get()
  return fp.flow(fp.getOr({}, 'data'), camelizeKeys)(productInfo)
}
