export const travellersItems = [
  {
    number: '1',
  },
  {
    number: '2',
  },
  {
    number: '3',
  },
  {
    number: '4',
  },
  {
    number: '5',
  },
  {
    number: '6',
  },
  {
    number: '7',
  },
  {
    number: '8',
  },
  {
    number: '9',
  },
  {
    number: '+10',
  },
]

export const priceItems = [
  {
    price: '$25',
  },
  {
    price: '$50',
  },
  {
    price: '$100',
  },
  {
    price: '$150',
  },
  {
    price: '$200',
  },
  {
    price: '$250',
  },
  {
    price: '$300',
  },
  {
    price: '$350',
  },
  {
    price: '$400+',
  },
]

export const hireTypeItems = [
  {
    type: 'Hirer Drive',
  },
  {
    type: 'Hirer Tow',
  },
  {
    type: 'Owner Deliver',
  },
  {
    type: 'Onsite Ready To Go',
  },
]

export const minYearItems = [
  {
    year: 'Min',
  },
  {
    year: '1990',
  },
  {
    year: '1991',
  },
  {
    year: '1992',
  },
  {
    year: '1993',
  },
  {
    year: '1994',
  },
  {
    year: '1995',
  },
  {
    year: '1996',
  },
  {
    year: '1997',
  },
  {
    year: '1998',
  },
  {
    year: '1999',
  },
  {
    year: '2000',
  },
  {
    year: '2001',
  },
  {
    year: '2002',
  },
  {
    year: '2003',
  },
  {
    year: '2004',
  },
  {
    year: '2005',
  },
  {
    year: '2006',
  },
  {
    year: '2007',
  },
  {
    year: '2008',
  },
  {
    year: '2009',
  },
  {
    year: '2010',
  },
  {
    year: '2011',
  },
  {
    year: '2012',
  },
  {
    year: '2013',
  },
  {
    year: '2014',
  },
  {
    year: '2015',
  },
  {
    year: '2017',
  },
  {
    year: '2018',
  },
  {
    year: '2019',
  },
  {
    year: '2020',
  },
  {
    year: '2021',
  },
  {
    year: '2022',
  },
  {
    year: '2023',
  },
]

export const maxYearItems = [
  {
    year: 'Max',
  },
  {
    year: '2000',
  },
  {
    year: '2001',
  },
  {
    year: '2002',
  },
  {
    year: '2003',
  },
  {
    year: '2004',
  },
  {
    year: '2005',
  },
  {
    year: '2006',
  },
  {
    year: '2007',
  },
  {
    year: '2008',
  },
  {
    year: '2009',
  },
  {
    year: '2010',
  },
  {
    year: '2011',
  },
  {
    year: '2012',
  },
  {
    year: '2013',
  },
  {
    year: '2014',
  },
  {
    year: '2015',
  },
  {
    year: '2017',
  },
  {
    year: '2018',
  },
  {
    year: '2019',
  },
  {
    year: '2020',
  },
  {
    year: '2021',
  },
  {
    year: '2022',
  },
  {
    year: '2023',
  },
]
