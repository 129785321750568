import fp from 'lodash/fp'

import * as productService from '../../Services/product'

const defaultState = {
  all: {},
  currentProduct: {},
}

export default {
  state: defaultState,

  reducers: {
    getProducts(state, payload) {
      return fp.merge(state, { all: payload })
    },
    getProduct(state, productInfo) {
      return fp.merge(state, {
        currentProduct: productInfo,
      })
    },
  },

  effects: {
    async fetchProducts() {
      const productList = await productService.fetchProducts()
      this.getProducts(productList)
    },
    async fetchProduct(id) {
      const productInfo = await productService.fetchProduct(id)
      this.getProduct(productInfo.data[0])
    },
  },

  selectors: (slice, createSelector) => ({
    all() {
      return createSelector(slice, fp.get('all'))
    },
    allValues() {
      return createSelector(this.all, fp.values)
    },
    currentProduct() {
      return createSelector(slice, fp.get('currentProduct'))
    },
  }),
}
