import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Sidebar = ({ user, showSidebar }) => {
  if (user.userRole === 'Hirer') {
    return (
      <>
        <div className={showSidebar ? 'sidebar col-2' : 'sidebar col-2 less'}>
          <a href="/">
            <span className="logo"></span>
          </a>
          <div className="inquire-box">
            <FontAwesomeIcon icon="phone" /> 0467 745 986 <br />
            <FontAwesomeIcon icon="envelope" />{' '}
            <span
              onClick={() =>
                (window.location.href = 'mailto:enquiries@cnchire.com.au')
              }
            >
              enquiries@cnchire.com.au
            </span>
          </div>

          <ul>
            <li>
              {' '}
              <a href="/dashboard">
                <FontAwesomeIcon icon="home" /> <span>Dashboard</span>
              </a>
            </li>

            <li>
              {' '}
              <a href="/dashboard/support">
                <FontAwesomeIcon icon="info-circle" /> <span>Support</span>
              </a>
            </li>

            <li>
              <a href="/dashboard/bookings">
                <FontAwesomeIcon icon="list-alt" /> <span>Bookings</span>
              </a>
            </li>

            {/*
                                      <li>
                                      <a href="/dashboard/transactions">
                                      <FontAwesomeIcon icon="money-check-alt" /><span>Transactions</span> 
                                        </a>
                                      </li>

                                       <li>
                                         <a href="/dashboard/payment-details">
                                          <FontAwesomeIcon icon="money-check-alt" /><span>Payment Details</span> 
                                        </a>
                                      </li>
                                      */}
          </ul>
        </div>
      </>
    )
  } else if (user.userRole === 'Admin') {
    return (
      <>
        <div className={showSidebar ? 'sidebar col-2' : 'sidebar col-2 less'}>
          <a href="/">
            <span className="logo"></span>
          </a>
          <div className="inquire-box">
            <FontAwesomeIcon icon="phone" /> 0467 745 986 <br />
            <FontAwesomeIcon icon="envelope" />{' '}
            <span
              onClick={() =>
                (window.location.href = 'mailto:enquiries@cnchire.com.au')
              }
            >
              enquiries@cnchire.com.au
            </span>
          </div>

          <ul>
            <li>
              {' '}
              <a href="/dashboard">
                <FontAwesomeIcon icon="home" />
                <span>Dashboard</span>
              </a>
            </li>

            <li>
              {' '}
              <a href="/dashboard/support">
                <FontAwesomeIcon icon="info-circle" /> <span>Support</span>
              </a>
            </li>

            <li>
              <a href="/dashboard/vehicles">
                <FontAwesomeIcon icon="caravan" /> <span>Vehicles</span>
              </a>
            </li>
            <li>
              <a href="/dashboard/bookings">
                <FontAwesomeIcon icon="list-alt" /> <span>Bookings</span>
              </a>
            </li>

            <li>
              <a href="/dashboard/hirers">
                <FontAwesomeIcon icon="user" />
                <span>Hirers</span>
              </a>
            </li>
            <li>
              <a href="/dashboard/owners">
                <FontAwesomeIcon icon="user-circle" />
                <span>Owners</span>
              </a>
            </li>

            <li>
              <a href="/dashboard/reviews">
                <FontAwesomeIcon icon="star" />
                <span>Reviews</span>
              </a>
            </li>

            <li>
              <a href="/dashboard/faqs">
                <FontAwesomeIcon icon="question-circle" />
                <span>Faqs</span>
              </a>
            </li>

            {/* <li>
                                <span className="icon-report">  Products </span>
                                  <ul>
                                    <li>TODO 1</li> 
                                  </ul>
                                </li>*/}
          </ul>
        </div>
      </>
    )
  } else {
    ///owner
    return (
      <>
        <div className={showSidebar ? 'sidebar col-2' : 'sidebar col-2 less'}>
          <a href="/">
            <span className="logo"></span>
          </a>
          <div className="inquire-box">
            <FontAwesomeIcon icon="phone" /> 0467 745 986 <br />
            <FontAwesomeIcon icon="envelope" />{' '}
            <span
              onClick={() =>
                (window.location.href = 'mailto:enquiries@cnchire.com.au')
              }
            >
              enquiries@cnchire.com.au
            </span>
          </div>

          <ul>
            <li>
              {' '}
              <a href="/dashboard">
                <FontAwesomeIcon icon="home" /> <span>Dashboard</span>
              </a>
            </li>

            <li>
              {' '}
              <a href="/dashboard/support">
                <FontAwesomeIcon icon="info-circle" /> <span>Support</span>
              </a>
            </li>

            <li>
              <a href="/dashboard/bookings">
                <FontAwesomeIcon icon="list-alt" /> <span> Bookings</span>
              </a>
            </li>
            <li>
              <a href="/dashboard/vehicles">
                <FontAwesomeIcon icon="caravan" /> <span> Vehicles</span>
              </a>
            </li>

            {/*
                                <li> 
                                   <a href="/dashboard/revenues">
                                       <FontAwesomeIcon icon="dollar-sign" />  
                                       <span> Earnings</span>
                                    </a> 
                                </li> 

                                <li>
                                         <a href="/dashboard/payment-details">
                                          <FontAwesomeIcon icon="money-check-alt" /><span>Payment Details</span> 
                                        </a>
                                      </li>
*/}
          </ul>
        </div>
      </>
    )
  }
}

export default Sidebar
