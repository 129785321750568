import React from 'react'
import { withRouter } from 'react-router'
import { NotificationManager } from 'react-notifications'
import * as bookingService from './BookingService'
import AdminBookingsPage from './AdminBookingsPage'
import HirerBookingsPage from './HirerBookingsPage'
import OwnerBookingsPage from './OwnerBookingsPage'
import LegendComponent from './shared/LegendComponent'
import './BookingsPage.scss'
import Modal from 'react-bootstrap/Modal'
/* 
status 
      pending-approval
      pending-initial-payment
      pending-full-payment 
      pending-final-payment  
      pending-owner-remark 
      
      STEP 1: request
      STEP 2: approved
      STEP 3: fully paid
      STEP 4: completed
*/
class BookingsPage extends React.Component {
  constructor(props) {
    super(props)

    let is_pending_only = false
    if (this.props.page_title === 'Pending Bookings') {
      is_pending_only = true
    }

    const { id } = this.props.match.params
    this.state = {
      showModalForm: false,
      modalFormMessage: '',
      is_pending_only,
      filterId: id,
      booking: [],
      default: this.props.user.userRole,
      screen: this.props.user.userRole,
      bookingList: [],
      showConfirmForm: false,
      showCompleteFormSuccess: false,
      confirmType: '',
      meta: null,
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-bookings-page')
  }

  modalError(message) {
    this.setState({ showModalForm: true })
    this.setState({ modalFormMessage: message })
  }

  initModalPopup() {
    return (
      <>
        <Modal
          show={this.state.showModalForm}
          onHide={() => this.setState({ showModalForm: false })}
          dialogClassName="bondclaim-modal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h2>Error</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalFormMessage}</Modal.Body>
        </Modal>{' '}
      </>
    )
  }

  async fetchLatest() {
    let is_pending_only = this.state.is_pending_only
    let params = this.state.filterId
      ? { id: this.state.filterId }
      : { is_pending_only }

    if (this.state.meta !== null) {
      params = { filter: this.state.meta, ...params }
    }

    const result = await bookingService.fetch(params)
    if (result.success) {
      this.setState({ bookingList: result.data })
      this.setState({ meta: result.meta })
    }
  }

  clickPayNow = (item) => {
    this.setState({ booking: item })

    localStorage.setItem('booked', JSON.stringify(item))

    //TODO: add the item id to the local storage.
    window.location.href = '/dashboard/payment-details/pay'
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  async refreshAndAllert() {
    await this.fetchLatest()
    NotificationManager.success('Booking status has been updated!', 'Success')
  }

  async refreshAndAllertCompleteJob() {
    await this.fetchLatest()
    this.setState({ showCompleteFormSuccess: true })
  }

  clickConfirmStatusUpdate = () => {
    bookingService
      .updateBookingStatus({
        booking_id: this.state.booking.id,
        status: this.state.confirmType,
      })
      .then((result) => {
        if (this.state.confirmType) {
          this.refreshAndAllertCompleteJob()
        } else {
          this.refreshAndAllert()
        }
      })

    this.setState({ showConfirmForm: false })
  }

  clickConfirmForm = (item, type) => {
    this.setState({ booking: item })
    this.setState({ showConfirmForm: true })
    this.setState({ confirmType: type })
  }

  clickCloseCancelForm = () => {
    this.setState({ showConfirmForm: false, showCompleteFormSuccess: false })
  }

  clickComplete = (item) => {}

  sendMessageHandler() {}

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    await this.setState({ meta: _meta })
    await this.fetchLatest()
  }

  render() {
    let page

    switch (this.state.screen) {
      case 'Admin':
        page = (
          <>
            <AdminBookingsPage
              modalError={this.modalError.bind(this)}
              filterId={this.state.filterId}
              screen={this.state.screen}
              user={this.props.user}
            ></AdminBookingsPage>
          </>
        )
        break

      case 'Owner':
        page = (
          <>
            <OwnerBookingsPage
              filterId={this.state.filterId}
              screen={this.state.screen}
              user={this.props.user}
            ></OwnerBookingsPage>
          </>
        )
        break
      default:
        page = (
          <>
            <HirerBookingsPage
              filterId={this.state.filterId}
              screen={this.state.screen}
              user={this.props.user}
            ></HirerBookingsPage>
          </>
        )
    }

    return (
      <>
        <div className="bookingsPageContainer">
          {this.initModalPopup()}
          <LegendComponent></LegendComponent>
          <br />
          <br />
          {page}
        </div>
      </>
    )
    //
  }
}

export default withRouter(BookingsPage)
