import React from 'react'

import './Banner.scss'
import ProductBanner from '../ProductBanner/ProductBanner'
import Testimonial from '../Testimonial/Testimonial'
import VehiclesBanner from '../VehiclesBanner/VehiclesBanner'
import EarningsBanner from '../EarningsBanner/EarningsBanner'
import PartnersAndMedia from '../PartnersAndMedia/PartnersAndMedia'

const Banner = () => {
  return (
    <React.Fragment>
      <div className="bannerContainer">
        <div className="productBox">
          <ProductBanner
            className="productBanner"
            productName="RV4X4 is the ONE!"
            productType="1"
            path="/rv-products"
          />
          <ProductBanner
            className="productBanner"
            productName="Caravan/RV Accessories"
            productType="2"
            path="/caravan-rv-accessories"
          />
        </div>

        <div className="testimonialBox">
          <span className="testimonialTitle">Plenty of Great Experiences</span>
          <div className="testimonialContainer">
            <Testimonial
              className="testimonialCard"
              id="1"
              name="Michael and Kirsten"
              location="McCrae VIC"
              message="Our family had a fantastic stay in this beautiful caravan."
              rating={5}
            />
            <Testimonial
              className="testimonialCard"
              id="2"
              name="Adam Laws"
              location="Eltham North"
              message="This one is so good we are going to take her to Uluru later in the year!"
              rating={5}
            />
          </div>
        </div>
        <VehiclesBanner />
        <EarningsBanner />
        <PartnersAndMedia />
      </div>
    </React.Fragment>
  )
}

export default Banner
