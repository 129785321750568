import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import './NewsCard.scss'

const NewsCard = ({ newsTitle, newsSecondaryText, imageURL, date, newsId }) => {
  const useStyles = makeStyles({
    root: {
      width: 300,
      height: 370,
    },
    media: {
      height: 140,
    },
    marginTop: 10,
  })

  const classes = useStyles()
  return (
    <React.Fragment>
      <div className="newsCardContainer">
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={imageURL}
              title={newsTitle}
            />
            <div className="dateNewsContainer">{date}</div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {/* {newsTitle} */}
                {`${newsTitle.substring(0, 20)}...`}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {`${newsSecondaryText.substring(0, 100)}...`}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a
              href="https://www.facebook.com/caravanandcampinghire/"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon className="readMoreArrow" size="large" name="facebook f" />{' '}
            </a>
            <a
              href="https://twitter.com/caravancamphire/"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon className="readMoreArrow" size="large" name="twitter" />
            </a>

            <a
              href="/news"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon
                className="readMoreArrow"
                size="large"
                name="google plus g"
              />
            </a>

            <a
              href="/news"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon className="readMoreArrow" size="large" name="pinterest p" />
            </a>
          </CardActions>
          <Divider variant="middle" />
          <CardActions>
            <Link
              to={`/news/${newsId}`}
              className="newsReadMore"
              style={{ textDecoration: 'none' }}
            >
              <span className="readMoreText">Read More</span>
              <div className="doubleRightCircle">
                <Icon
                  size="small"
                  style={{ color: '#000000', margin: 'auto' }}
                  name="angle double right"
                />
              </div>
            </Link>
          </CardActions>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default NewsCard
