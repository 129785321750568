import React from 'react'
import { withRouter } from 'react-router'
import * as apiService from './SearchPageService'
import './SearchesPage.scss'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'

class LocationSearchPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      meta: null,
    }

    this.fetchLatest()
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    //*
    await this.setState({ meta: _meta })
    await this.fetchLatest()
    //*/
  }

  async fetchLatest() {
    let params = {}
    if (this.state.meta !== null) {
      let meta = { filter: this.state.filter, ...this.state.meta }
      params = { filter: meta, ...params }
    }

    let result = await apiService.fetchSearchLocation(params)
    if (result.success) {
      this.setState({ list: result.data, meta: result.meta })
    }
  }

  render() {
    return (
      <>
        <div className="searchpage-container">
          <table>
            <thead>
              <tr>
                <th>Location</th>
                <th>Total Search Count</th>
                <th>Last Searched</th>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length > 0 &&
                this.state.list.map((item, index) => {
                  return (
                    <tr
                      key={item.id}
                      className={index % 2 === 0 ? 'even' : 'odd'}
                    >
                      <td>{item.location}</td>
                      <td>{item.searches}</td>
                      <td>{moment(item.lastSearched).format('LL')}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div style={{ float: 'right', marginTop: '20px' }}>
            {this.state.meta !== null && (
              <Pagination
                count={this.state.meta.count}
                variant="outlined"
                shape="rounded"
                onChange={this.clickPagination}
                size={'large'}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(LocationSearchPage)
