import { api_request } from '../../Services/helpers'

export const reset = async (data = {}) =>
  await api_request('post', '/users/reset_password', data, false)

export const validate = async (data = {}) =>
  await api_request('post', '/users/validate_code', data, false)

export const changepassword = async (data = {}) =>
  await api_request('post', '/users/new_password', data, false)
