import React from 'react'
import Map from 'Components/Map'
import Vehicles from 'Components/Map/Vehicles'

const SearchResultPage = () => (
  <React.Fragment>
    <Map />
    <Vehicles />
  </React.Fragment>
)

export default SearchResultPage
