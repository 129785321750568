import React from 'react'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
class UserLogoutComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicles: [],
    }
    localStorage.removeItem('persist:root')
  }

  componentDidMount() {
    document.body.classList.add('user-logout-page')
  }
  render() {
    return (
      <div>
        <Redirect to="/login" />
      </div>
    )
  }
}

export default withRouter(UserLogoutComponent)
