import fp from 'lodash/fp'
import moment from 'moment'

export const structureById = fp.keyBy('id')

export const transformState = (vehicleState) => {
  const obj = {
    Tasmania: 'TAS',
    Queensland: 'QLD',
  }

  return obj[vehicleState]
}

export const invertDirection = (data) => {
  const invertion = {
    asc: 'desc',
    desc: 'asc',
  }
  return invertion[fp.lowerCase(data)]
}

export const transformUser = (data = {}) => {
  const updatedAt = fp.get('updatedAt', data)
  const createdAt = fp.get('createdAt', data)

  return {
    ...data,
    updatedAt: moment(updatedAt).format('LLL'),
    createdAt: moment(createdAt).format('LL'),
  }
}
