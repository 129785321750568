import React from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import * as paymentService from './PaymentsService'
import './PaymentsPage.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { formatCurrency } from 'shared/utils.js'

class PaymentsPage extends React.Component {
  constructor(props) {
    super(props)
    const { id } = this.props.match.params

    if (id === 'pay') {
      this.retrieveBookedItem()
    }

    var d = new Date()
    var n = d.getFullYear()
    var i = 0

    const expiry_year_from = n
    const expiry_year_to = n + 10

    let expiry_year_options = []
    let expiry_month_options = []

    for (i = expiry_year_from; i <= expiry_year_to; i++) {
      expiry_year_options.push(i)
    }

    for (i = 1; i <= 12; i++) {
      expiry_month_options.push(i)
    }

    this.state = {
      months: [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      expiryMonth: 1,
      expiryYear: expiry_year_from,
      expiry_month_options,
      expiry_year_options,

      cardSelected: {},
      booked: {},
      expiry_year_from,
      expiry_year_to,

      step: 'browse',
      targetBookingPayment: id ? true : false,
      inputCardNumberError: false,
      inputCardNameError: false,
      inputBankNameError: false,
      inputCardExpiryError: false,
      inputSecurityCodeError: false,
      disabledSubmitButton: true,
      itemSelectedIndex: 0,
      inputCardExpiry: '',
      inputSecurityCode: '',

      cardProcessing: '',
      cardProcessingError: '',

      showAddPaymentDetailsForm: false,
      default: this.props.user.userRole,
      screen: this.props.user.userRole,
      inputCardType: 'Visa',
      cardList: false,
    }

    console.log('state', this.state)
    this.fetchLatest()
  }

  validateFields() {
    let params = {
      inputCardNumberError: false,
      inputCardNameError: false,
      inputBankNameError: false,
      inputCardExpiryError: false,
      inputSecurityCodeError: false,
      disabledSubmitButton: false,
    }

    if (
      !(this.state.inputCardNumber && this.state.inputCardNumber.length > 2)
    ) {
      params.inputCardNumberError = true
      params.disabledSubmitButton = true
    }

    if (!(this.state.inputCardName && this.state.inputCardName.length > 0)) {
      params.inputCardNameError = true
      params.disabledSubmitButton = true
    }

    /*
  if(!this.state.inputCardExpiry) {
     params.inputCardExpiryError = true; 
     params.disabledSubmitButton = true;
  }*/

    let code = this.state.inputSecurityCode + ''

    if (!(code.length === 3 || code.length === 4)) {
      params.inputSecurityCodeError = true
      params.disabledSubmitButton = true
    }

    console.log('is disabled??? ', params.disabledSubmitButton)

    this.setState(params)
  }

  async retrieveBookedItem() {
    let booked = await localStorage.getItem('booked')
    try {
      booked = booked ? JSON.parse(booked) : false
    } catch (e) {
      booked = false
    }

    if (booked) {
      paymentService.getHirerCurrentDue(booked.id).then((result) => {
        if (result.success) {
          const params = { step: 'select', booked, ...result.data }
          this.setState(params)
        }
      })
    }
  }

  async fetchLatest() {
    const params = {} // this.state.filterId ? { id: this.state.filterId }  : {};
    await paymentService.fetchCards(params).then((result) => {
      if (result.success) {
        this.setState({ cardList: result.data, cardSelected: result.data[0] })
      }
    })
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      function () {
        this.validateFields()
      }
    )
  }

  dateChanged = (date) => {
    this.setState({ inputCardExpiry: date }, this.validateFields)
  }

  clickItemSelect = (index, cardSelected) => {
    console.log('click item Select', index)
    if (this.state.targetBookingPayment) {
      this.setState({ itemSelectedIndex: index, cardSelected: cardSelected })
    }
  }

  processPayment() {
    console.log('process payment success 111?????')
    paymentService
      .pay({
        booking_id: this.state.booked.id,
        card_id: this.state.cardSelected.id,
        amount: this.state.amountDue,
        description:
          this.state.paymentType +
          ' / ' +
          this.state.booked.vehicleName +
          ' / booking id: #' +
          this.state.booked.id,
      })
      .then((result) => {
        if (result.success) {
          this.setState({ step: 'success' })
        } else {
          NotificationManager.error(
            'There was an error processing your payment.',
            'Error'
          )
          this.setState({ step: 'pay' })
        }
      })
  }

  processAddCard() {}

  clickPayNow = () => {
    this.setState({ step: 'processing' })
    //add a litte delay effect
    setTimeout(() => this.processPayment(), 1000)
  }

  addCardHandler = async () => {
    this.setState({
      cardProcessing: 'Please wait....',
      cardProcessingError: '',
    })
    const params = {
      card_number: this.state.inputCardNumber,
      name: this.state.inputCardName,
      card_type: this.state.inputCardType,
      expiry_year: this.state.expiryYear,
      expiry_month: this.state.expiryMonth,
      security: this.state.inputSecurityCode,
    }
    const result = await paymentService.addCard(params)
    this.setState({ cardProcessing: '' })
    if (result.success) {
      NotificationManager.success('Successfully added your card', 'Success')
      await this.fetchLatest()
      this.setState({
        showAddPaymentDetailsForm: false,
        inputCardNumber: '',
        inputCardName: '',
        inputSecurityCode: '',
      })
    } else {
      this.setState({ cardProcessingError: result.message })
    }
  }

  clickToBookings = () => {
    window.location.href = '/dashboard/bookings'
  }

  render() {
    if (this.state.screen === 'Hirer') {
      if (this.state.step === 'processing') {
        return (
          <>
            <div className="payment-details-container">
              <div className="inner-container">
                <h2>Processing </h2>
                <div className="thankyou-body">
                  <h3>Please wait while we process your request...</h3>
                </div>
              </div>
            </div>
          </>
        )
      } else if (this.state.step === 'success') {
        return (
          <>
            <div className="payment-details-container">
              <div className="inner-container">
                <h2>Thank you! </h2>
                <div className="thankyou-body">
                  <h3>Your payment has been received.</h3>
                  <button
                    className="btn btn-success"
                    onClick={() => this.clickToBookings()}
                  >
                    Take me to Bookings
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      } else if (this.state.step === 'pay') {
        return (
          <>
            <div className="payment-details-container">
              <div className="inner-container">
                <h2>Confirm Payment</h2>

                <div className="payment-box-body">
                  <div className="row">
                    <div className="col-6">Credit Card:</div>
                    <div className="col-6">
                      <div>******* </div>
                      <div className="cardType">
                        {this.state.cardSelected.brand}
                      </div>
                      <div>{this.state.cardSelected.name}</div>
                      <div>
                        {this.state.months[this.state.cardSelected.expMonth] +
                          ' ' +
                          this.state.cardSelected.expYear}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">Description:</div>
                    <div className="col-6">
                      book# {this.state.booked.id} <br />
                      booked for{' '}
                      {moment(this.state.booked.startDate).format('LL')} to{' '}
                      {moment(this.state.booked.endDate).format('LL')} <br />
                      {this.state.booked.vehicleName} <br />
                      <b>{this.state.paymentType}</b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">Amount Due:</div>
                    <div className="col-6 amount">
                      {formatCurrency(this.state.amountDue)}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 pull-right">
                      <button
                        className="bottom-buttons btn btn-primary"
                        onClick={() => {
                          this.clickPayNow()
                        }}
                      >
                        Pay Now
                      </button>
                      <button
                        className="bottom-buttons btn btn-secondary"
                        onClick={() => {
                          this.setState({ step: 'select' })
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      } else {
        return (
          <>
            {' '}
            <div className="payment-details-container">
              <div className="inner-container">
                {this.state.showAddPaymentDetailsForm && (
                  <div className="payment-details-container-popup">
                    {this.state.cardProcessing !== '' && (
                      <div style={{ color: 'red' }}>
                        {this.state.cardProcessing}
                      </div>
                    )}
                    {this.state.cardProcessingError !== '' && (
                      <div style={{ color: 'red' }}>
                        {this.state.cardProcessingError}
                      </div>
                    )}

                    <h1>New Payment Details</h1>

                    <div className="row field-line">
                      <div className="col-4">Card Number</div>
                      <div className="col-8">
                        <input
                          className={
                            this.state.inputCardNumberError
                              ? 'input-field error'
                              : 'input-field'
                          }
                          type="number"
                          name="inputCardNumber"
                          defaultValue={this.state.inputCardNumber}
                          onChange={this.handleChange}
                        />{' '}
                      </div>
                    </div>

                    <div className="row  field-line">
                      <div className="col-4">Name on the card</div>
                      <div className="col-8">
                        <input
                          className={
                            this.state.inputCardNameError
                              ? 'input-field error'
                              : 'input-field'
                          }
                          name="inputCardName"
                          defaultValue={this.state.inputCardName}
                          onChange={this.handleChange}
                          type="text"
                        />{' '}
                      </div>
                    </div>

                    <div className="row  field-line">
                      <div className="col-4">Card Type</div>
                      <div className="col-8">
                        <select
                          className={
                            this.state.inputCardTypeError
                              ? 'input-field error'
                              : 'input-field'
                          }
                          defaultValue={this.state.inputCardType}
                          onChange={this.handleChange}
                        >
                          <option value="Visa">Visa</option>
                          <option value="Master Card">Master Card</option>
                          <option value="Discover Network">
                            Discover Network
                          </option>
                          <option value="American Express">
                            American Express
                          </option>
                        </select>{' '}
                      </div>
                    </div>

                    <div className="row field-line">
                      <div className="col-4">Card Expiry</div>
                      <div className="col-8">
                        <select
                          name="expiryMonth"
                          className="expMonth"
                          defaultValue={this.state.expiryMonth}
                          onChange={this.handleChange}
                        >
                          {this.state.expiry_month_options.length > 0 &&
                            this.state.expiry_month_options.map(
                              (item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {this.state.months[item]}
                                  </option>
                                )
                              }
                            )}
                        </select>

                        <select
                          name="expiryYear"
                          className="expYear"
                          defaultValue={this.state.expiryYear}
                          onChange={this.handleChange}
                        >
                          {this.state.expiry_year_options.length > 0 &&
                            this.state.expiry_year_options.map(
                              (item, index) => {
                                return <option key={index}>{item}</option>
                              }
                            )}
                        </select>
                      </div>
                    </div>

                    <div className="row field-line">
                      <div className="col-4">3-4 Digit Security Code </div>
                      <div className="col-8">
                        <input
                          className={
                            this.state.inputSecurityCodeError
                              ? 'input-field error'
                              : 'input-field'
                          }
                          type="number"
                          name="inputSecurityCode"
                          defaultValue={this.state.inputSecurityCode}
                          onChange={this.handleChange}
                        />{' '}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <button
                          disabled={this.state.disabledSubmitButton}
                          className="btn btn-primary"
                          onClick={() => this.addCardHandler()}
                        >
                          Add Card
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            this.setState({ showAddPaymentDetailsForm: false })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row payment-box">
                  <div className="note-info">
                    <b>Security Notice</b> Card details are for hire
                    payments/commissions. This is transmitted and stored
                    securely on our PCI compliant payment processor's systems.
                    No banking or credit card details are transmitted via or
                    stored on the Caravan & Camping Hire Website.
                  </div>

                  <h2>
                    {this.state.targetBookingPayment
                      ? 'Select Card'
                      : 'My Cards'}
                  </h2>

                  <div className="payment-box-body">
                    {this.state.cardList &&
                      this.state.cardList.length > 0 &&
                      this.state.cardList.map((item, index) => {
                        return (
                          <label
                            key={item.id}
                            onClick={() => this.clickItemSelect(index, item)}
                            className={
                              this.state.itemSelectedIndex === index &&
                              this.state.targetBookingPayment
                                ? 'selected cardItem'
                                : 'cardItem'
                            }
                          >
                            {this.state.step === 'select' && (
                              <input
                                type="radio"
                                defaultChecked={item.isDefault ? 'checked' : ''}
                                name="paybookingid"
                                defaultValue={item.id}
                              />
                            )}

                            <div>******* </div>
                            <div className="cardType">{item.brand}</div>
                            <div>{item.name}</div>
                            <div>
                              {this.state.months[item.expMonth] +
                                ' ' +
                                item.expYear}
                            </div>
                          </label>
                        )
                      })}

                    <button
                      className="add-payment-button"
                      onClick={() =>
                        this.setState({ showAddPaymentDetailsForm: true })
                      }
                    >
                      Add Payment Details
                    </button>

                    <div className="clearfix"></div>

                    {this.state.step === 'select' && this.state.cardList && (
                      <button
                        className="bottom-buttons btn btn-primary paynow-btn pull-right"
                        onClick={() => this.setState({ step: 'pay' })}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    } else if (this.state.screen === 'Owner') {
      return <>payments owner</>
    } else if (this.state.screen === 'Admin') {
      return <>payments admin</>
    }
  }
}

export default withRouter(PaymentsPage)
