import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'
import { formatCurrency } from 'shared/utils.js'
import './styles.css'

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      activeColor: 'white',
      textColor: 'gray',
      selectedMarker: -1,
    }
  }

  _onChildClick = (key, childProps) => {
    console.log('key', key)
    console.log('childProps', childProps)
    this.setState({
      show: !this.state.show,
      selectedMarker: key,
    })
  }

  render() {
    if (this.props.vehicleList.length === 0) {
      return <></>
    } else {
      let vehicle = this.props.vehicleList[0]
      let center = {
        lat: Number(vehicle.lat),
        lng: Number(vehicle.lng),
      }

      //if searched for specific area, let u zoom it a little closer.
      //default will be in zoomedout mode.
      let zoom = this.props.isSearched ? 18 : 8

      return (
        <div className="App">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyB1ht0n8AFV5GiGi6xctMu4lU5xiQD4OU4',
              language: 'en',
              region: 'AU',
            }}
            defaultCenter={center}
            defaultZoom={zoom}
            onChildClick={this._onChildClick}
          >
            {this.props.vehicleList &&
              this.props.vehicleList.length > 0 &&
              this.props.vehicleList.map((item, index) => {
                let images = item.images ? JSON.parse(item.images) : []

                let thumb = 'https://cdn.cnchire.com.au/c6.png'

                if (item.thumbnail !== '') {
                  thumb = item.thumbnail
                } else if (images[0]) {
                  thumb = images[0].image
                }

                return (
                  <Marker
                    lat={Number(item.lat)}
                    lng={Number(item.lng)}
                    key={index}
                    keyNum={index}
                    place={item.location}
                    text={
                      item.vehicleName + ' ' + formatCurrency(item.pricePerDay)
                    }
                    vehicleName={item.vehicleName}
                    pricePerDay={item.pricePerDay}
                    imagePath={thumb}
                    show={this.state.show}
                    color={this.state.activeColor}
                    textColor={this.state.textColor}
                    selectedMarker={this.state.selectedMarker}
                    vehicleId={item.id}
                  />
                )
              })}
          </GoogleMapReact>
        </div>
      )
    }
  }
}

export default Map
