import React from 'react'
import { withRouter } from 'react-router'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import AllVehicles from '../../Components/AllVehicles/AllVehicles'
import Footer from '../../Components/Footer/Footer'
import Pagination from '@material-ui/lab/Pagination'
import './AllVehiclesForHirePage.scss'
import * as allVehiclesForHirePageService from './AllVehiclesForHirePageService'

class AllVehiclesForHirePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicles: [],
      meta: {},
      fetched: false,
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('all-vehicles-page')
  }

  async fetchLatest() {
    let params = {}
    if (this.state.meta !== null) {
      params = { filter: this.state.meta, ...params }
    }

    const result = await allVehiclesForHirePageService.fetch(params)
    console.log('all vehicles is ', result)
    this.setState({ vehicles: result.data, meta: result.meta, fetched: true })
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    console.log('clicked pagination', page, this.state.meta)
    this.setState({ vehicles: [], meta: _meta })
    await this.fetchLatest()
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="All Vehicles For Hire" link="/vehicles" />
        {this.state.fetched && (
          <>
            {' '}
            <AllVehicles
              vehicleList={this.state.vehicles}
              vehicleType="All Vehicle Types"
            />
            <div className="vehiclePaginationContainer">
              <Pagination
                count={this.state.meta.pageSize / this.state.meta.total}
                variant="outlined"
                shape="rounded"
                onChange={this.clickPagination}
                size={'large'}
              />
            </div>
          </>
        )}

        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(AllVehiclesForHirePage)
