import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import Pagination from '@material-ui/lab/Pagination'
import './VehicleListPage.scss'
import * as vehicleListService from './VehicleListService'
import { NotificationManager } from 'react-notifications'

class VehicleDraftListComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      list: false,
      meta: null,
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-vehicle-draft-page')
  }
  fetchLatest = async () => {
    let params = {}
    if (this.state.meta !== null) {
      params = { filter: this.state.meta, ...params }
    }

    const result = await vehicleListService.fetch(params)
    console.log('drafted result', result)

    if (result.success) {
      await this.setState({
        list: result.data.map((x) => {
          return { show: true, ...x }
        }),
      })
      await this.setState({ meta: result.meta })
    }
  }

  clickUnDraft = async (id) => {
    await vehicleListService.undraft(id)
    await this.fetchLatest()
    NotificationManager.success('The vehicle is publish', 'Success')
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    console.log('clicked pagination', page, this.state.meta)
    await this.setState({ meta: _meta })
    await this.fetchLatest()
  }

  render() {
    return (
      <>
        <div
          style={{ float: 'left', marginRight: '20px', marginBottom: '5px' }}
        >
          <a className="btn btn-secondary" href="/dashboard/vehicles">
            Go Back Vehicles
          </a>
        </div>
        <div style={{ clear: 'both' }}></div>

        {this.state.list && this.state.list.length > 0 && (
          <div className="vehicle-list-container draft-container">
            <div className="results">
              {this.state.meta ? this.state.meta.rows : 0} Results
            </div>

            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Vehicle Name</th>
                  <th>Images</th>
                  <th>Created By</th>
                  <th>Vehicle Type</th>
                  <th>Date Created</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {this.state.list.length > 0 &&
                  this.state.list
                    .filter((x) => x.show === true)
                    .map((item, index) => {
                      let images = item.images ? JSON.parse(item.images) : []
                      let thumb = 'https://cdn.cnchire.com.au/c6.png'
                      if (item.thumbnail !== '') {
                        thumb = item.thumbnail
                      } else if (images[0]) {
                        thumb = images[0].image
                      }

                      return (
                        <tr
                          key={item.id}
                          className={index % 2 === 0 ? 'even' : 'odd'}
                        >
                          <td>{item.id}</td>
                          <td>
                            <a href={'/dashboard/vehicles/' + item.id}>
                              {' '}
                              {item.vehicleName}{' '}
                            </a>
                          </td>
                          <td>
                            <img
                              alt="thumbnail"
                              width="50"
                              className="thumbnail"
                              src={thumb}
                            />
                          </td>
                          <td>
                            <a href={'/dashboard/user/' + item.ownerId}>
                              {item.ownerFirstName} {item.ownerLastName}
                            </a>
                          </td>
                          <td>{item.vehicleType}</td>
                          <td>{moment(item.createdAt).format('LL')}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                (window.location.href =
                                  '/dashboard/vehicles/' + item.id)
                              }
                            >
                              View
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => this.clickUnDraft(item.id)}
                            >
                              UnDraft
                            </button>
                          </td>
                        </tr>
                      )
                    })}
              </tbody>
            </table>

            <div style={{ float: 'right', marginTop: '50px' }}>
              {this.state.meta !== null && (
                <Pagination
                  count={this.state.meta.count}
                  variant="outlined"
                  shape="rounded"
                  onChange={this.clickPagination}
                  size={'large'}
                />
              )}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default withRouter(VehicleDraftListComponent)
