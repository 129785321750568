import { api_request } from 'Services/helpers'

export const fetch = async (data) =>
  await api_request('post', '/auth/chats/fetch', data, true, true)

export const send = async (data = {}) =>
  await api_request('post', '/auth/chats/send', data, true)

export const list = async (data = {}) =>
  await api_request('get', '/auth/chats/list', data, true)

export const seen = async (data = {}) =>
  await api_request('post', '/auth/chats/seen', data, true)
