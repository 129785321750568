import Modal from 'react-bootstrap/Modal'
import React, { Fragment, useState } from 'react'
import * as bookingService from '../BookingService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatusIconText from './StatusIconText'

function BookingDetail({ id }) {
  const [show, setShow] = useState(false)
  const [data, setBooking] = useState(false)

  const getDetail = async function () {
    let result = await bookingService.detail(id)

    console.log('booking detail is ', result)
    if (result.success) {
      setBooking(result.data)
    }
    setShow(true)
  }
  const addons = data ? JSON.parse(data.booking?.addons) : []
  return (
    <>
      <button
        variant="primary"
        className="btn btn-secondary"
        onClick={() => getDetail()}
      >
        View Details
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Booking Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data !== false ? (
            <>
              <h3>Hirer Details</h3>
              Name: {data.hirer.firstName} {data.hirer.lastName} <br />
              Email:{' '}
              <a href={'mailto:' + data.hirer.email}>{data.hirer.email}</a>{' '}
              <br />
              Phone: {data.hirer.contactNumber} <br />
              Suburb:{data.hirer.address} <br /> {data.hirer.address2} <br />
              <h3>Booking Details</h3>
              Booking Status: <StatusIconText
                status={data.booking.status}
              />{' '}
              <br />
              Start Date: {data.booking.startDate} <br />
              End Date: {data.booking.endDate} <br />
              Total No. Travellers: {data.booking.totalGuest} <br />
              No. Children: {data.booking.childrenTravelers} <br />
              Planned Destination: {data.booking.plannedDestinations} <br />
              Intending to Free Camp:{' '}
              {data.booking.is_free_camping === 1 ? 'YES' : 'NO'} <br />
              Tow Vehicle Make: {data.booking.towingVehicleMake} <br />
              Tow Vehicle Model: {data.booking.vehicleModel} <br />
              Tow Vehicle Year: {data.booking.vehicleYear} <br />
              Towing Capacity: {data.booking.towingCapacity} <br />
              Electronic Brakes Fitted: {data.booking.electronicBrakes} <br />
              Towing Experience: {data.booking.towingExperience} <br />
              Additional Requests: <br />
              <div>{data.booking.additionalExtra}</div>
              Addons: <br />
              <ul>
                {addons.length > 0 ? (
                  addons.map((a) => (
                    <li key={a.amenity}>
                      {a.amenity}: ${a.price}
                    </li>
                  ))
                ) : (
                  <li>None</li>
                )}
              </ul>
              Additional Notes : <br />
              <div>{data.booking.additionalNotes}</div>
              <h3>Contract</h3>
              Hire Agreement:{' '}
              <a
                href={data.booking.contract}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon="file-pdf" />
              </a>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default class BookingDetailPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      booking: {},
    }
  }

  render() {
    return <BookingDetail id={this.props.id}></BookingDetail>
  }
}
