import fp from 'lodash/fp'
import { invertDirection } from './helpers'
import * as bookingsService from '../../Services/bookings'

const defaultState = {
  all: {},
  currentBooking: {},
  sortBy: 'created_at',
  sortOrder: 'asc',
  page: 0,
  pageSize: 25,
  total: 0,
}

export default {
  state: defaultState,

  reducers: {
    getBookings(state, payload) {
      return fp.merge(state, { all: payload })
    },
    getBooking(state, payload) {
      return fp.merge(state, { all: { [payload.id]: payload } })
    },
    getCurrentBooking(state, bookingInfo) {
      return fp.assign(state, {
        currentBooking: bookingInfo,
      })
    },
  },

  effects: {
    async fetchBookings(payload = {}, state) {
      const sorting = fp.merge(
        { sortBy: fp.getOr(state.bookings.sortBy, 'sortBy')(payload) },
        {
          sortOrder:
            !fp.isEmpty(payload) && fp.has('sortBy', payload)
              ? invertDirection(state.bookings.sortOrder)
              : state.bookings.sortOrder,
        }
      )

      const pageSize = fp.getOr(state.bookings.pageSize, 'pageSize')(payload)
      const page = fp.isEqual(pageSize, state.bookings.pageSize)
        ? fp.get('page')(payload)
        : 0

      const pagination = { page, pageSize }
      const queryParams = fp.merge(sorting, pagination)

      const bookings = await bookingsService.fetchBookings(queryParams)
      this.getBookings(bookings)
    },
    async fetchBooking(id) {
      try {
        const bookingInfo = await bookingsService.fetchBooking(id)
        this.getCurrentBooking(bookingInfo[0])
      } catch (error) {
        throw error
      }
    },
    async createBooking(payload) {
      try {
        const bookingInfo = await bookingsService.createBooking(payload)
        return bookingInfo
        // this.getCurrentBooking(bookingInfo[0]);
      } catch (error) {
        throw error
      }
    },
  },

  selectors: (slice, createSelector, hasProps) => ({
    all() {
      return createSelector(slice, fp.get('all'))
    },
    allValues() {
      return createSelector(this.all, fp.values)
    },
    currentBooking() {
      return createSelector(slice, fp.get('currentBooking'))
    },
    getFaq: hasProps((models, id) => slice(fp.flow(fp.get('all'), fp.get(id)))),
  }),
}
