import React from 'react'
import { connect } from 'react-redux'
import store from '../../Redux/store'
import fp from 'lodash/fp'

import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import AllVehicles from 'Components/AllVehicles/AllVehicles'
import Footer from 'Components/Footer/Footer'

const vehicleTypesList = {
  act: 'Australian Capital Territory',
  nsw: 'New South Wales',
  nt: 'Northern Territory',
  qld: 'Queensland',
  sa: 'South Australia',
  tas: 'Tasmania',
  vic: 'Victoria',
  wa: 'Western Australia',
}

class VehiclesByState extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicleState: vehicleTypesList[this.props.match.params.state],
      fetched: false,
    }
  }

  componentDidMount() {
    document.body.classList.add('vehicle-by-state-page')
    this.props.fetchVehiclesByState(this.state.vehicleState)
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title={this.state.vehicleState} link="/vehicles" />

        <AllVehicles
          vehicleList={this.props.vehicleList}
          title={`All Vehicles Available For Hire In ${this.state.vehicleState}`}
          vehicleType="All Vehicle Types"
        />

        <Footer />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchVehiclesByState: dispatch.vehicle.fetchVehiclesByState,
})

const mapStateToProps = (state) => ({
  vehicleList: store.select.vehicle.vehiclesByState(state),
})

export default fp.flow(connect(mapStateToProps, mapDispatchToProps))(
  VehiclesByState
)
