import React from 'react'
import { withRouter } from 'react-router'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'
import AddonAmenities from './AddonAmenities'

class AmenitiesComponent extends React.Component {
  constructor(props) {
    super(props)
  }
  parentHandleAddOn = (addons) => {
    this.props.parentHandle({ addons })
  }

  update(state) {
    this.props.parentHandle(state)
  }

  handleCheckChieldElement = (event) => {
    let amenities = this.props.state.amenities_checkboxes

    amenities.forEach((item) => {
      if (parseInt(item.id) === parseInt(event.target.value)) {
        item.checked = event.target.checked
      }
    })
    this.props.parentHandle({ amenities_checkboxes: amenities })
  }

  render() {
    return (
      <>
        <div className="subcontent section-3">
          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>GENERAL FEATURES:</b>
              </div>
              <div className="col-10 included-amenities">
                <div
                  className={
                    this.props.state.amenities_error
                      ? 'rounded-error row'
                      : 'row'
                  }
                >
                  {this.props.state.amenities_checkboxes &&
                    this.props.state.amenities_checkboxes.length > 0 &&
                    this.props.state.amenities_checkboxes.map((item, index) => {
                      return (
                        <div className="col-4" key={item.id}>
                          {' '}
                          <label>
                            <input
                              className={
                                this.props.state.amenities_error ? ' error' : ''
                              }
                              type="checkbox"
                              value={item.id}
                              checked={item.checked || ''}
                              key={item.id}
                              name="amenity_checkbox"
                              onChange={(event) =>
                                this.handleCheckChieldElement(event)
                              }
                            />{' '}
                            {item.amenity}
                          </label>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>HIRED FEATURES :</b>
              </div>
              <div className="col-10">
                <AddonAmenities
                  amenities_checkboxes={this.props.state.amenities_checkboxes}
                  parentHandleAddOn={this.parentHandleAddOn}
                  addon_amenities={this.props.state.addon_amenities}
                ></AddonAmenities>
              </div>
            </div>
          </div>
        </div>{' '}
        {/*subcontent*/}
      </>
    )
  }
}

export default withRouter(AmenitiesComponent)
