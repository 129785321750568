import React from 'react'
import { withRouter } from 'react-router'
import './LatestVehicles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as apiService from './VehicleListService'

class LatestVehiclesComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: this.props.user,
    }
  }

  componentDidMount() {
    this.fetchLatest()
  }

  async fetchLatest() {
    let params = {
      filter: { page_size: 100, filteredOwner: this.state.user.id },
    }
    let result = await apiService.fetch(params)
    let list = []

    if (result.success) {
      list = result.data
    }

    this.setState({ list })
  }

  toggleItem = (item) => {
    var list = this.state.list

    for (var _item of list) {
      if (_item.id === item.id) {
        _item.more = !_item.more
      }
    }

    this.setState({ list })
  }

  render() {
    if (!this.state.list) {
      return <>Latest booking...</>
    } else {
      return (
        <>
          <div className="latest-vehicles">
            <h4>Latest Vehicles</h4>

            {this.state.list.length > 0 ? (
              <>
                <div className="list-outer-box">
                  <div className="list-body">
                    {this.state.list.length > 0 &&
                      this.state.list.map((item, index) => {
                        let images = item.images ? JSON.parse(item.images) : []
                        let thumb = 'https://cdn.cnchire.com.au/c6.png'

                        if (item.thumbnail !== '') {
                          thumb = item.thumbnail
                        } else if (images[0]) {
                          thumb = images[0].image
                        }

                        return (
                          <div className="list-item" key={index}>
                            <label
                              className="label-for-mobile"
                              style={{ display: 'none' }}
                            >
                              Vehicle
                            </label>

                            <a href={'/vehicle/' + item.id}>
                              <div className="thumb-box">
                                <img
                                  alt="small thumb"
                                  src={thumb}
                                  className="small-thumb"
                                />{' '}
                              </div>
                              <div className="vehicle-name">
                                {item.vehicleName}
                              </div>
                            </a>
                          </div>
                        )
                      })}

                    <div className="clearfix"></div>

                    <br />
                    <br />
                  </div>

                  <div
                    className="more-searches"
                    title="More Bookings"
                    onClick={() => {
                      window.location.href = '/dashboard/bookings'
                    }}
                  >
                    <FontAwesomeIcon icon="angle-double-right" />
                  </div>
                </div>
              </>
            ) : (
              <div className="description">
                <>
                  Owner has no <b>active vehicles</b>{' '}
                </>
              </div>
            )}
          </div>
        </>
      )
    }
  }
}

export default withRouter(LatestVehiclesComponent)
