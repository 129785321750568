import React from 'react'
import { withRouter } from 'react-router'

class BlankPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>HELLO</h1>
      </React.Fragment>
    )
  }
}

export default withRouter(BlankPage)
