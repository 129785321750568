import React from 'react'

import AdvanceSearchBox from '../AdvanceSearchBox/AdvanceSearchBox'

import './HeaderBanner.scss'

const HeaderBanner = () => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="cncHireDetailsContainer">
          <div className="cncHire">Caravan and Camping Hire Australia</div>
          <div className="cncHire2">
            Find your Ideal Caravan/RV to Hire and go Explore Australia!{' '}
          </div>

          <AdvanceSearchBox />

          {/* <div className="cncHire3">
            <img alt="badge" src="//cdn.cnchire.com.au/badge-1.png" />
          </div> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default HeaderBanner
