import React from 'react'
import * as ContractPageService from './ContractPageService'
import { withRouter } from 'react-router-dom'
import PreviewContractComponent from './components/PreviewContractComponent'
import SignSignatureComponent from './components/SignSignatureComponent'
import { NotificationManager } from 'react-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import Footer from 'Components/Footer/Footer'
import './ContractPage.scss'
import { formatCurrency } from 'shared/utils.js'
import useAnalyticsEventTracker from 'Pages/App/useAnalyticsTracker'

const ContractPage = (props) => {
  const tracker = useAnalyticsEventTracker('contract')
  return <ContractPageOld {...props} tracker={tracker} />
}
class ContractPageOld extends React.Component {
  imageSignatureData = ''
  constructor(props) {
    super(props)
    this.state = {
      error_license_file_1: false,
      error_license_file_2: false,
      error_driver_name: false,
      error_driver_dob: false,
      error_signature: false,
      is_processing: false,
      has_error: true,
      pdfFile: '',
      step: 2,
      isUploading: false,
      license_file_1: '',
      license_file_2: '',
      signatureImageData: '',
      driver_name: '',
      driver_dob: '',
      uuid: this.props.match.params.id,
    }

    this.fetchBooking()
  }

  componentDidMount() {
    document.body.classList.add('contract-page')
  }

  fetchBooking = async () => {
    let obj = {}
    const result = await ContractPageService.get_booking(this.state.uuid)

    obj.booking = result.data.booking
    obj.vehicle = result.data.vehicle

    const booking = obj.booking

    if (booking.step >= 5) {
      window.location.href = '/dashboard/bookings'
      return false
    } else if (booking.step === 1) {
      window.location.href = '/confirm-booking/' + this.state.uuid
      return false
    }

    if (booking.contract) {
      obj.pdfFile = booking.contract
    }

    if (booking.step < 5) {
      obj.step = booking.step

      if (booking.step === 4) {
        const is_initial_deposit = !booking.initialPaymentPaid ? true : false
        const booking_info =
          obj.vehicle.vehicleName +
          ' ( ' +
          booking.startDate +
          ' to ' +
          booking.endDate +
          ')'
        obj['paymentDescription'] = booking_info

        if (booking.paymentType === 'full') {
          obj['paymentType'] = 'Full Payment'
        } else {
          if (is_initial_deposit) {
            //first time
            obj['paymentType'] = 'Initial Deposit'
          } else {
            obj['paymentType'] = 'Final Payment'
          }
        }
      }
    }
    this.setState(obj)

    return true
  }

  validateForm = () => {
    let error_license_file_1 = false
    let error_license_file_2 = false
    let error_driver_name = false
    let error_driver_dob = false
    let error_signature = false

    if (this.state.license_file_1 === '') error_license_file_1 = true
    if (this.state.license_file_2 === '') error_license_file_2 = true
    if (this.state.signatureImageData === '') error_signature = true
    if (this.state.driver_dob === '') error_driver_dob = true
    if (this.state.driver_name === '') error_driver_name = true

    const has_error =
      error_driver_dob ||
      error_driver_name ||
      error_license_file_1 ||
      error_license_file_2 ||
      error_signature
        ? true
        : false
    const errors = {
      error_driver_dob,
      error_driver_name,
      error_license_file_1,
      error_license_file_2,
      error_signature,
      has_error,
    }
    this.setState(errors)
    this.props.tracker('validateForm', errors)
  }

  getParams = () => {
    const params = {
      uuid: this.state.uuid,
      license_file_1: this.state.license_file_1,
      license_file_2: this.state.license_file_2,
      signatureImageData: this.state.signatureImageData,
      driver_dob: this.state.driver_dob,
      driver_name: this.state.driver_name,
    }

    return params
  }

  async processContractInfo() {
    this.setState({ is_processing: true })
    const params = this.getParams()
    const result = await ContractPageService.sign(params)
    if (result.success) {
      this.setState({ pdfFile: result.data.data, step: this.state.step + 1 })
    }

    this.setState({ is_processing: false })
  }

  async clickPayNow() {
    this.setState({ is_processing: true })
    const result = await ContractPageService.pay_now(this.state.uuid)
    this.props.tracker('payNow', this.state.uuid)
    if (result.success) {
      this.props.tracker('payNow - Success', this.state.uuid)
      window.location.href = '/confirm-payment-success'
    } else {
      this.props.tracker('payNow - Failure', this.state.uuid)
      this.setState({ is_processing: false })
      NotificationManager.error('There was an error processing your payment.')
    }
  }

  async clickConfirm() {
    this.setState({ is_processing: true })
    const params = this.getParams()
    const result = await ContractPageService.confirm(params)
    if (result.success) {
      window.location.href = '' //refresh this page
    } else {
      this.setState({ is_processing: false })
    }
  }

  async clickCancel() {
    window.location.href = '/' //TODO add cancel message page.
  }

  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    })
    await this.validateForm()
  }

  onFileChange = async (e, target) => {
    this.setState({ isUploading: true })
    const files = Array.from(e.target.files)

    const formData = new FormData()

    files.forEach((file, i) => {
      formData.append(i, file)
    })

    console.log('onFileChange triggered')

    const result = await ContractPageService.upload(formData)

    if (result.data && result.data.error) {
      NotificationManager.error(result.data.error)
    } else if (result.data && result.data.success) {
      let obj = {}
      obj[target] = result.data.imageUrl
      await this.setState(obj)
    } else {
      NotificationManager.error(
        'There was an error uploading your file. Please try again.'
      )
    }

    await this.setState({ isUploading: false })
    this.validateForm()
    return {}
  }

  getData = async (val) => {
    await this.setState({ signatureImageData: val })
    this.validateForm()
  }

  render() {
    if (!this.state.step) {
      return <>..</>
    }

    switch (this.state.step) {
      case 2: //fill form and Sign
        //read contract
        return (
          <React.Fragment>
            <NavBar linkTextColor={{ color: '#000000' }} />
            <PageBanner title="Driver's ID" link="/contract" />

            <div className="contractContainer">
              {this.state.isUploading && (
                <>
                  {' '}
                  <div className="loader">
                    <div className="lds-dual-ring"></div>{' '}
                  </div>{' '}
                  <div className="overlay"></div>
                </>
              )}

              <div className="tabs">
                <div className="tab-item active">
                  Step 1: Drivers Detail & Signature
                </div>
                <div className="tab-item">Step 2 : Confirm Contract </div>
                <div className="tab-item">Step 3 : Pay Booking </div>
              </div>

              <div className="clearFix"></div>

              <div>
                <i>Complete your driver's detail & signature.</i> <br />
                <br />
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="20%">Driver's license (Front Photo):</td>
                      <td>
                        <label
                          htmlFor="myFile1"
                          className="upload-button"
                          title="Upload new image"
                        >
                          {' '}
                          <FontAwesomeIcon icon="upload" /> &nbsp; Upload
                          Picture{' '}
                        </label>
                        <input
                          type="file"
                          name="myFile1"
                          id="myFile1"
                          accept="image/*"
                          onChange={(event) =>
                            this.onFileChange(event, 'license_file_1')
                          }
                          style={{ display: 'none' }}
                        />

                        {this.state.license_file_1 !== '' ? (
                          <img
                            alt="license 1"
                            src={this.state.license_file_1}
                            className="licenseThumb"
                          />
                        ) : null}

                        {this.state.error_license_file_1 ? (
                          <span className="error ">
                            Invalid driver's license photo 1
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">Driver's license (Back Photo):</td>
                      <td>
                        <label
                          htmlFor="myFile2"
                          className="upload-button"
                          title="Upload new image"
                        >
                          {' '}
                          <FontAwesomeIcon icon="upload" /> &nbsp; Upload
                          Picture{' '}
                        </label>
                        <input
                          type="file"
                          name="myFile2"
                          id="myFile2"
                          accept="image/*"
                          onChange={(event) =>
                            this.onFileChange(event, 'license_file_2')
                          }
                          style={{ display: 'none' }}
                        />

                        {this.state.license_file_2 !== '' ? (
                          <img
                            alt="license 2"
                            src={this.state.license_file_2}
                            className="licenseThumb"
                          />
                        ) : null}

                        {this.state.error_license_file_2 ? (
                          <span className="error ">
                            Invalid driver's license photo 2
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Driver's Name: </td>
                      <td>
                        <input
                          type="text"
                          onChange={this.handleChange}
                          name="driver_name"
                          placeholder="John Martin"
                        />

                        {this.state.error_driver_name ? (
                          <span className="error">Invalid driver's name</span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Driver's Birth Date: </td>
                      <td>
                        {' '}
                        <input
                          type="text"
                          onChange={this.handleChange}
                          name="driver_dob"
                          placeholder="January 1, 1983"
                        />
                        {this.state.error_driver_dob ? (
                          <span className="error">
                            Invalid driver's birth date
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {this.state.error_signature ? (
                  <>
                    <br />
                    <br />{' '}
                    <span className="signature-error error">
                      Invalid signature
                    </span>
                  </>
                ) : null}
                <SignSignatureComponent
                  sendData={this.getData}
                ></SignSignatureComponent>
              </div>

              <div className="buttons-bottom">
                {this.state.has_error || this.state.is_processing ? (
                  <button
                    className="btn-primary btn  next-btn"
                    disabled="disabled"
                    type="button"
                  >
                    {this.state.has_error ? 'Next' : 'Please wait..'}
                  </button>
                ) : (
                  <button
                    className="btn-primary btn  next-btn"
                    type="button"
                    onClick={() => this.processContractInfo()}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>

            <Footer />
          </React.Fragment>
        )

      case 3:
        //read contract
        return (
          <React.Fragment>
            <NavBar linkTextColor={{ color: '#000000' }} />
            <PageBanner title="Hire Contract" link="/contract" />

            <div className="contractContainer">
              <div className="tabs">
                <div className="tab-item">
                  Step 1: Drivers Detail & Signature
                </div>
                <div className="tab-item active">
                  Step 2 : Confirm Contract{' '}
                </div>
                <div className="tab-item">Step 3 : Pay Booking </div>
              </div>

              <div className="clearFix"></div>

              <div className="agree-container">
                <PreviewContractComponent
                  pdfFile={this.state.pdfFile}
                ></PreviewContractComponent>
                <h4>Accept Terms:</h4>
                <label>
                  {' '}
                  I fully agree to the terms and conditions of this contract.{' '}
                </label>{' '}
                <br />
                <button
                  className="btn-secondary btn  next-btn"
                  type="button"
                  onClick={() => this.clickCancel()}
                >
                  Cancel
                </button>
                {this.state.is_processing ? (
                  <button
                    className="btn-primary btn  next-btn"
                    disabled="true"
                    type="button"
                  >
                    Please wait...
                  </button>
                ) : (
                  <button
                    className="btn-primary btn  next-btn"
                    type="button"
                    onClick={() => this.clickConfirm()}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </div>
            <Footer />
          </React.Fragment>
        )

      case 4:
        let output = null

        if (this.state.booking.paymentType === 'partial') {
          if (this.state.paymentType === 'Initial Deposit') {
            output = (
              <>
                <div className="payment-box">
                  <div>
                    <h5>Initial Deposit</h5>
                  </div>
                  <div style={{ padding: '10px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>50% security bond</td>
                          <td>
                            {formatCurrency(this.state.booking.bondAmount / 2)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Booking/Service Fee</td>
                          <td>
                            {formatCurrency(this.state.booking.bookingFee)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Insurance Fee</td>
                          <td>
                            {formatCurrency(this.state.booking.insuranceFee)}{' '}
                          </td>
                        </tr>
                        <tr className="tr-line">
                          <td> Total</td>
                          <td>
                            {formatCurrency(
                              this.state.booking.insuranceFee +
                                this.state.booking.bookingFee +
                                this.state.booking.bondAmount / 2
                            )}{' '}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.clickPayNow()}
                    disabled={this.state.is_processing ? 'disabled' : ''}
                  >
                    {!this.state.is_processing ? 'Pay Now' : 'Please wait..'}
                  </button>
                </div>
                <div className="payment-box disabled">
                  <div>
                    <h5>Final Payment</h5>
                  </div>
                  <div style={{ padding: '10px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>50% security bond</td>
                          <td>
                            {formatCurrency(this.state.booking.bondAmount / 2)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Booking Cost</td>
                          <td>
                            {formatCurrency(this.state.booking.bookingCost)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Addons</td>
                          <td>
                            {formatCurrency(
                              this.state.booking.totalAddonsAmount
                            )}{' '}
                          </td>
                        </tr>
                        <tr className="tr-line">
                          <td> Total</td>
                          <td>
                            {formatCurrency(
                              this.state.booking.totalAddonsAmount +
                                this.state.booking.bookingCost +
                                this.state.booking.bondAmount / 2
                            )}{' '}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )
          } else {
            //final payment

            output = (
              <>
                <div className="payment-box disabled">
                  <div>
                    <h5>
                      Initial Deposit <div className="paid-status">Paid</div>
                    </h5>{' '}
                  </div>
                  <div style={{ padding: '10px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>50% security bond</td>
                          <td>
                            {formatCurrency(this.state.booking.bondAmount / 2)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Booking/Service Fee</td>
                          <td>
                            {formatCurrency(this.state.booking.bookingFee)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Insurance Fee</td>
                          <td>
                            {formatCurrency(this.state.booking.insuranceFee)}{' '}
                          </td>
                        </tr>
                        <tr className="tr-line">
                          <td> Total</td>
                          <td>
                            {formatCurrency(
                              this.state.booking.insuranceFee +
                                this.state.booking.bookingFee +
                                this.state.booking.bondAmount / 2
                            )}{' '}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="payment-box">
                  <div>
                    <h5>Final Payment</h5>
                  </div>
                  <div style={{ padding: '10px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>50% security bond</td>
                          <td>
                            {formatCurrency(this.state.booking.bondAmount / 2)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Booking Cost</td>
                          <td>
                            {formatCurrency(this.state.booking.bookingCost)}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Addons</td>
                          <td>
                            {formatCurrency(
                              this.state.booking.totalAddonsAmount
                            )}{' '}
                          </td>
                        </tr>
                        <tr className="tr-line">
                          <td> Total</td>
                          <td>
                            {formatCurrency(
                              this.state.booking.totalAddonsAmount +
                                this.state.booking.bookingCost +
                                this.state.booking.bondAmount / 2
                            )}{' '}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.clickPayNow()}
                    disabled={this.state.is_processing ? 'disabled' : ''}
                  >
                    {!this.state.is_processing ? 'Pay Now' : 'Please wait..'}
                  </button>
                </div>
              </>
            )
          }
        } else {
          output = (
            <>
              <div className="payment-box">
                <div>
                  <h5>Full Payment</h5>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>Bond Amount</td>
                      <td>{formatCurrency(this.state.booking.bondAmount)} </td>
                    </tr>
                    <tr>
                      <td>Booking Cost</td>
                      <td>{formatCurrency(this.state.booking.bookingCost)} </td>
                    </tr>
                    <tr>
                      <td>Booking/Service Fee</td>
                      <td>{formatCurrency(this.state.booking.bookingFee)} </td>
                    </tr>
                    <tr>
                      <td>Insurance Fee</td>
                      <td>
                        {formatCurrency(this.state.booking.insuranceFee)}{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>Addons</td>
                      <td>
                        {formatCurrency(this.state.booking.totalAddonsAmount)}{' '}
                      </td>
                    </tr>
                    <tr className="tr-line">
                      <td> Total</td>
                      <td>{this.state.booking.totalAmount} </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  className="btn btn-primary"
                  onClick={() => this.clickPayNow()}
                  disabled={this.state.is_processing ? 'disabled' : ''}
                >
                  {' '}
                  {!this.state.is_processing ? 'Pay Now' : 'Please wait..'}
                </button>
              </div>
            </>
          )
        }

        //pay now
        return (
          <React.Fragment>
            <NavBar linkTextColor={{ color: '#000000' }} />
            <PageBanner title="Pay Booking" link="/contract" />

            <div className="contractContainer">
              <div className="tabs">
                <div className="tab-item">
                  Step 1: Drivers Detail & Signature
                </div>
                <div className="tab-item">Step 2 : Confirm Contract </div>
                <div className="tab-item active">Step 3 : Pay Booking </div>
              </div>

              <div className="clearFix"></div>

              <h1>{this.state.paymentDescription}</h1>
              {output}
            </div>
            <Footer />
          </React.Fragment>
        )

      default:
    }
  }
}

export default withRouter(ContractPage)
