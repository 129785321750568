import React from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import { Icon } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReviewCard from 'Components/ReviewCard/ReviewCard'
import DatePicker from 'react-datepicker'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import 'react-day-picker/lib/style.css'
import './VehicleDetails.scss'

import {
  FaShuttleVan,
  FaMapMarkerAlt,
  FaCheckCircle,
  FaCircle,
} from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import { formatCurrency } from 'shared/utils'
import VehicleBanner from 'Components/VehicleBanner/VehicleBanner'
import ChatComponent from 'Layouts/Dashboard/components/chat/chat'
import StartChatButton from 'Layouts/Dashboard/components/chat/StartChatButton'
import * as bookingService from '../../../../Services/bookings'
import * as bookingService2 from '../../VehicleService'

class VehicleDetails extends React.Component {
  constructor(props) {
    super(props)
    let vehicleAmenitiesToRender = []

    const newLocal = this
    if (newLocal.props.vehicleAmenities.length > 0) {
      vehicleAmenitiesToRender = newLocal.props.vehicleAmenities
        .filter(
          (x) =>
            !(
              x.amenity.toLowerCase().search('cost') > -1 ||
              x.amenity.toLowerCase().search('fee') > -1
            )
        )
        .map((item, index) =>
          item.selected === true ? (
            <span key={index} className="vehicleDetailsSectionAmenitiesBoxItem">
              <FaCheckCircle style={{ marginRight: '8%' }} color="ff8c3f" />
              {item.amenity}
            </span>
          ) : null
        )
    }

    this.state = {
      to: '',
      from: '',
      buttonDisabled: false,
      invalidMinRange: false,
      invalidDateRange: false,
      isDateRangeBlocked: false,
      dateBlockedFrom: '',
      dateBlockedTo: '',
      exclude_dates: null,
      exclude_dates_set: false,
      vehicleAmenitiesToRender,
      current_tab: 'all',
      // paymentType: 'payDeposit',
      totalPriceApplied: 0,
      // fromMinDate: new Date(),
      startDatePickerOpened: false,
      endDatePickerOpened: false,
      loginPopup: false,
      vehicleAdditionalAmenities: [],
      total: -1,
      // canPartial: false,
      ...this.props,
    }

    this.fetch_exclude_dates()
  }

  dateProcessAfter = async () => {
    await this.setState({
      endDatePickerOpened: false,
      startDatePickerOpened: false,
    })
    await this.processPriceApplied()
    await this.isButtonDisabled()
    const total_number_of_stay = this.countNumOfDaysStay()
    const start = Date.now()
    const total_days_from_now = Math.floor(
      (this.state.from - start) / (1000 * 3600 * 24) + 1
    )
    const canPartial = total_days_from_now >= 30
    await this.setState({ canPartial, total_number_of_stay })

    if (this.isValidDateRange()) {
      this.setTotal()
    } else {
      this.setState({ total: -1 })
    }

    setLastVehicle()
  }

  setLastVehicle = () => {
    const lastVehicle = {
      lastLink: `/vehicle/${this.state.vehicle.id}`,
      vehicleAdditionalAmenities: this.state.vehicleAdditionalAmenities,
      vehicleID: this.state.vehicle.id,
      startDate: this.state.from,
      endDate: this.state.to,
    }
    console.log('Storing last vehicle', lastVehicle)
    localStorage.setItem('last_vehicle_settings', JSON.stringify(lastVehicle))
  }

  changeEndDate = async (date) => {
    await this.setState({ to: date })
    this.dateProcessAfter()
  }

  changeStartDate = async (date) => {
    await this.setState({ from: date })
    this.dateProcessAfter()
  }

  setTotal = () => {
    const obj = bookingService.calculate_booking_numbers(
      this.state.vehicleAdditionalAmenities,
      this.state.from,
      this.state.to,
      this.state.rateRules,
      this.state.price,
      this.state.securityBond
    )
    this.setState(obj)
  }

  handleCheckChieldElement = (event) => {
    const amenities = this.state.vehicleAdditionalAmenities
    let index = 0
    for (const item of amenities) {
      if (parseInt(item.amenity, 10) === parseInt(event.target.value, 10)) {
        amenities[index].checked = event.target.checked
      }
      index++
    }

    this.setState({ vehicleAdditionalAmenities: amenities })
    this.setTotal()
  }

  async componentDidMount() {
    const allamenities = this.state.vehicleAmenities
    const addons_array = []
    const { vehicle } = this.state
    const addons = vehicle.addonAmenities
      ? JSON.parse(vehicle.addonAmenities)
      : []
    for (const item of addons) {
      const _item = {
        checked: false,
        amenity: item.amenity,
        price: item.price,
        caption: allamenities.filter(
          (x) => Number(x.id) === Number(item.amenity)
        )[0].amenity,
      }
      addons_array.push(_item)
    }

    await this.setState({
      rateRules: vehicle.rateRules ? JSON.parse(vehicle.rateRules) || [] : [],
      price: vehicle.pricePerDay,
      vehicleAdditionalAmenities: addons_array,
      securityBond: vehicle.securityBond,
    })

    this.isButtonDisabled()

    const lastVehicle = localStorage.getItem('last_vehicle_settings')

    if (lastVehicle) {
      const lastVehicleSettings = JSON.parse(lastVehicle) || {}

      if (lastVehicleSettings.vehicleID === this.state.vehicle.id) {
        // make sure this is the same vehicle page?
        console.log('check lastVehicle ', lastVehicleSettings)
        this.setState({
          to: new Date(lastVehicleSettings.endDate),
          from: new Date(lastVehicleSettings.startDate),
        })

        // debugger;
        if (lastVehicleSettings.vehicleAdditionalAmenities) {
          this.setState({
            vehicleAdditionalAmenities:
              lastVehicleSettings.vehicleAdditionalAmenities,
          })
        }
      }
      this.dateProcessAfter()
    }
  }

  clickShowPopup() {
    this.setState({ loginPopup: true })
  }

  loginPopup() {
    return (
      <Modal
        show={this.state.loginPopup}
        onHide={() => this.setState({ loginPopup: false })}
        dialogClassName="common-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <h2>Login to continue</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need to login as
          <b>HIRER</b>
          to perform this action.
          <br />
          <br />
          <button
            className="btn btn-primary"
            onClick={() => (window.location.href = '/login?redirectUrl=' + window.location)}
            style={{ marginRight: '10px' }}
          >
            Login
          </button>
          <button
            className="btn btn-primary"
            onClick={() => (window.location.href = '/register?type=hirer&redirectUrl=' + window.location)}
            style={{ marginRight: '10px' }}
          >
            Register As Hirer
          </button>
          &nbsp;
          <button
            className="btn btn-secondary"
            onClick={() => this.setState({ loginPopup: false })}
          >
            Cancel
          </button>
          <br />
          <br />
          <br />
          <br />
        </Modal.Body>
      </Modal>
    )
  }

  async fetch_exclude_dates() {
    const result = await bookingService.get_exclude_dates(this.state.vehicle.id)
    const exclude_dates = []

    console.log('exclude --- ', result)
    if (result.success) {
      for (const item of result.data) {
        exclude_dates.push(new Date(item.date))
      }
    }

    console.log('exclude_dates ==> ', exclude_dates)
    this.setState({ exclude_dates })
    this.setState({ exclude_dates_set: true })
  }

  requestBookingSubmit = async (event) => {
    const params = {
      start_date: moment(this.state.from).format('YYYY-MM-DD'),
      end_date: moment(this.state.to).format('YYYY-MM-DD'),
      vehicle_id: this.state.vehicle.id,
      hirer_id: this.state.user.id,
      owner_id: this.state.vehicle.userId,

      total_number_of_stay: Number(this.state.total_number_of_stay),

      addons: JSON.stringify(
        this.state.vehicleAdditionalAmenities
          .filter((item) => item.checked === true)
          .map((x) => ({ amenity: x.caption, price: x.price }))
      ),

      rate_breakdown: JSON.stringify(this.state.rateBreakdown.computed_rates),

      booking_cost: this.state.totalPriceApplied,
      total_applied_rate: this.state.totalPriceApplied,
      bond_amount: this.state.securityBond,
      total_addons_amount: this.state.amenitiesFee,
      booking_fee: this.state.bookingFee,
      service_fee: 0, // this is a duplicate from booking_fee
      insurance_fee: this.state.insuranceFee,
      total_amount: this.state.total,

      total_earnings: this.state.total_earnings,
      commission: this.state.commission,
      total_commission: this.state.total_commission,
    }

    console.log('clicked requestBookingSubmit', params)
    const result = await bookingService2.request(params)
    if (result.success) {
      const { uuid } = result.data
      localStorage.removeItem('last_vehicle_settings')
      window.location.href = `/confirm-booking/${uuid}`
    }
  }

  processPriceApplied = () => {
    let rateRules = []
    try {
      rateRules = JSON.parse(this.state.vehicle.rateRules)
    } catch (e) {
      rateRules = []
    }

    const rateBreakdown = bookingService.processRates(
      this.state.from,
      this.state.to,
      rateRules,
      this.state.vehicle.pricePerDay
    )
    const obj = {}
    obj.totalPriceApplied = rateBreakdown.computed_total
    obj.rateBreakdown = rateBreakdown
    this.setState(obj)
  }

  isValidDateRange() {
    return this.state.total_number_of_stay > 0
  }

  async isButtonDisabled() {
    const total_number_of_stay = this.countNumOfDaysStay()
    const stay = total_number_of_stay

    console.log('isButtonDisabled check executed...')
    let invalidDateRange = false
    let buttonDisabled = true
    let isDateRangeBlocked = false
    let dateBlockedFrom = ''
    let dateBlockedTo = ''
    let invalidMinRange = false
    const invalidMaxRange = false

    if (this.state.to && this.state.from) {
      if (total_number_of_stay < 1) {
        invalidDateRange = true
      } else {
        buttonDisabled = false

        if (
          this.state.vehicle.minimumHireDuration &&
          stay < this.state.vehicle.minimumHireDuration
        ) {
          console.log('button disabled here 2')
          invalidMinRange = true
          buttonDisabled = true
        } else {
          invalidMinRange = false
        }

        /*
                  if( this.state.vehicle.maximumHireDuration &&  stay > this.state.vehicle.maximumHireDuration){
                    console.log("button disabled here 3");
                    invalidMaxRange = true ;
                    buttonDisabled = true;
                  }else{
                    invalidMaxRange = false ;
                  }

                  // */

        try {
          let unavailability = JSON.parse(
            this.state.vehicle.unavailability || []
          )
          unavailability = this.props.unavailability.filter((x) => x) // filter clean data;

          // check if dates is blocked
          const _to = new Date(this.state.to)
          const _from = new Date(this.state.from)

          for (let i = 0; i < unavailability.length; i++) {
            const u = unavailability[i]
            if (u.error === '') {
              const _range_from = new Date(u.from)
              const _range_to = new Date(u.to)

              if (
                (_from >= _range_from && _from <= _range_to) ||
                (_to >= _range_from && _to <= _range_to)
              ) {
                // if from-to is under the range, this is a problem
                isDateRangeBlocked = true
                console.log('button disabled here 4')
                dateBlockedFrom = u.from
                dateBlockedTo = u.to
              }
            }
          }
        } catch (e) {
          console.log('error ---> ', e)
        }
      }
    }

    await this.setState({
      buttonDisabled,
      invalidMaxRange,
      invalidDateRange,
      isDateRangeBlocked,
      dateBlockedFrom,
      invalidMinRange,
      dateBlockedTo,
    })
  }

  countNumOfDaysStay = () =>
    (this.state.to - this.state.from) / (1000 * 3600 * 24) + 1

  clickChooseStart = () => {
    this.setState({ startDatePickerOpened: !this.state.startDatePickerOpened })
    this.setState({ endDatePickerOpened: false })
  }

  clickChooseEnd = () => {
    this.setState({ startDatePickerOpened: false })
    this.setState({ endDatePickerOpened: !this.state.endDatePickerOpened })
  }

  handleChange = async (e) => {
    const obj = {}
    obj[`${e.target.name}`] = e.target.value
    await this.setState(obj)
  }

  setShowAdditionalTerms = (terms) => {
    this.setState({ showAdditionalTerms: terms })
  }

  clickChangeTab = (tab) => {
    this.setState({ current_tab: tab })
  }

  render() {
    return (
      <div className="vehicleDetailsContainer">
        <div className="vehicleDetailsBox">
          <div className="vehicleDetailsColumnsBox">
            {this.loginPopup()}

            <div className="vehicleDetailsColumn1">
              <VehicleBanner vehicle={this.state.vehicle} />

              <ul className="vehicleTabs">
                <li
                  className={this.state.current_tab === 'all' ? 'active' : ''}
                  onClick={() => this.clickChangeTab('all')}
                >
                  OVERVIEW
                </li>
                <li
                  className={
                    this.state.current_tab === 'vehicle-details' ? 'active' : ''
                  }
                  onClick={() => this.clickChangeTab('vehicle-details')}
                >
                  VEHICLE DETAILS
                </li>
                <li
                  className={
                    this.state.current_tab === 'towing-details' ? 'active' : ''
                  }
                  onClick={() => this.clickChangeTab('towing-details')}
                >
                  TOWING
                </li>
                <li
                  className={
                    this.state.current_tab === 'amenities' ? 'active' : ''
                  }
                  onClick={() => this.clickChangeTab('amenities')}
                >
                  AMENITIES
                </li>
                <li
                  className={
                    this.state.current_tab === 'condition-of-use'
                      ? 'active'
                      : ''
                  }
                  onClick={() => this.clickChangeTab('condition-of-use')}
                >
                  CONDITION
                </li>
                <li
                  className={
                    this.state.current_tab === 'reviews'
                      ? 'active rating-tab'
                      : 'rating-tab'
                  }
                  onClick={() => this.clickChangeTab('reviews')}
                >
                  REVIEWS
                  <div className="rating-tab-container">
                    {/* <Rating name="read-only" value={3} size="small" style={{ color: "#ffc107" }} readOnly /> */}
                  </div>
                </li>
              </ul>

              {(this.state.current_tab === 'vehicle-details' ||
                this.state.current_tab === 'all') && (
                <div className="vehicleDetailsSectionBox">
                  <span className="vehicleDetailsSectionTitle">
                    Vehicle Details
                  </span>

                  <br />
                  <span className="vehicleDetailsTableDataLabel">
                    Description:
                  </span>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.vehicle.description.replaceAll(
                        '\n',
                        '<br/>'
                      ),
                    }}
                  />

                  <table className="vehicleDetailsTable">
                    <tbody>
                      <tr className="vehicleDetailsTableRow">
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Make:
                          </span>
                          {this.state.vehicle.vehicleMake}
                        </td>
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Length:
                          </span>
                          {this.state.vehicle.vehicleLength}
                        </td>
                      </tr>

                      <tr className="vehicleDetailsTableRow">
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Model:
                          </span>
                          {this.state.vehicle.vehicleModel}
                        </td>
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Width:
                          </span>
                          {this.state.vehicle.vehicleWidth}
                        </td>
                      </tr>

                      <tr className="vehicleDetailsTableRow">
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Manufactured:
                          </span>
                          {this.state.vehicle.vehicleYearManufactured}
                        </td>
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Family Friendly:
                          </span>
                          {this.state.vehicle.vehicleFamilyFriendly
                            ? 'Yes'
                            : 'No'}
                        </td>
                      </tr>

                      <tr className="vehicleDetailsTableRow">
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Sleeps:
                          </span>
                          {this.state.vehicle.vehicleSleeps}
                        </td>
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Pet Friendly:
                          </span>
                          {this.state.vehicle.vehiclePetFriendly
                            ? 'Yes'
                            : 'Not Permitted'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {(this.state.current_tab === 'towing-details' ||
                this.state.current_tab === 'all') && (
                <div className="vehicleDetailsSectionBox">
                  <span className="vehicleDetailsSectionTitle">
                    Towing Details
                  </span>
                  <table className="vehicleDetailsTable">
                    <tbody>
                      <tr className="vehicleDetailsTableRow">
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Tare:
                          </span>
                          {this.state.vehicle.towingTare}
                        </td>
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Towball Weight:
                          </span>
                          {this.state.vehicle.towingTowballWeight}
                        </td>
                      </tr>

                      <tr className="vehicleDetailsTableRow">
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            ATM:
                          </span>
                          {this.state.vehicle.towingAtm}
                        </td>
                        <td className="vehicleDetailsTableData">
                          <span className="vehicleDetailsTableDataLabel">
                            Towing Plug:
                          </span>
                          {this.state.vehicle.towingPlug}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {(this.state.current_tab === 'amenities' ||
                this.state.current_tab === 'all') && (
                <div className="vehicleDetailsSectionBox">
                  <span className="vehicleDetailsSectionTitle">
                    Amenities Included
                  </span>
                  <div className="vehicleDetailsSectionAmenitiesBox">
                    {this.state.vehicleAmenitiesToRender}
                  </div>
                </div>
              )}

              {(this.state.current_tab === 'condition-of-use' ||
                this.state.current_tab === 'all') && (
                <div className="vehicleDetailsSectionBox">
                  <span className="vehicleDetailsSectionTitle">
                    Conditions of Use
                  </span>
                  <div className="vehicleDetailsSectionRow">
                    <span className="vehicleDetailsSpecificTermsLabel">
                      Minimum Driver Age:
                    </span>
                    <span className="vehicleDetailsSectionRowContent">
                      {this.state.vehicle.conditionMinDriverAge}
                    </span>
                  </div>
                  <div className="vehicleDetailsSectionRow">
                    <span className="vehicleDetailsSpecificTermsLabel">
                      Permitted Road Types:
                    </span>
                    <span className="vehicleDetailsSectionRowContent">
                      {this.state.vehicle.conditionPermittedRoadTypes}
                    </span>
                  </div>
                  <div className="vehicleDetailsSectionRow">
                    <span className="vehicleDetailsSpecificTermsLabel">
                      Beach Access:
                    </span>
                    <span className="vehicleDetailsSectionRowContent">
                      {this.state.vehicle.conditionBeachAccess ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div className="vehicleDetailsSectionRow">
                    <span className="vehicleDetailsSpecificTermsLabel">
                      River Crossings:
                    </span>
                    <span className="vehicleDetailsSectionRowContent">
                      {this.state.vehicle.conditionRiverCrossing ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div className="vehicleDetailsSectionRow">
                    <span className="vehicleDetailsSpecificTermsLabel">
                      Outback Roads:
                    </span>
                    <span className="vehicleDetailsSectionRowContent">
                      {this.state.vehicle.conditionOutbackRoads ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div className="vehicleDetailsSpecificTermsRow">
                    <span className="vehicleDetailsSpecificTermsLabel">
                      Vehicle Specific Terms:
                    </span>
                    <span className="vehicleDetailsSectionRowContent">
                      {this.state.vehicle.conditionSpecificTerms}
                    </span>
                  </div>
                  <div className="vehicleDetailsSectionRow">
                    <span className="vehicleDetailsSection*RowNote">
                      *Any intention to travel on Graded or Ungraded Gravel/Dirt
                      Roads OR 4WD/Off-Road Tracks/Trails must have their routes
                      planned and preapproved by the owner prior to travel.
                    </span>
                  </div>
                </div>
              )}

              {(this.state.current_tab === 'reviews' ||
                this.state.current_tab === 'all') && (
                <div className="vehicleDetailsSectionBox">
                  <span className="vehicleDetailsSectionTitle">Reviews</span>

                  {this.state.reviews &&
                    this.state.reviews.map((item, index) => (
                      <span
                        key={index}
                        className="vehicleDetailsSectionAmenitiesBoxItem"
                      >
                        <ReviewCard
                          name={item.name}
                          date={moment(item.createdAt).format('LL')}
                          rating={item.rate}
                          comment={item.comment}
                        />
                      </span>
                    ))}

                  <span className="vehicleDetailsSectionContent">
                    You must be logged in to post a review.
                  </span>
                </div>
              )}
            </div>

            <div className="vehicleDetailsColumn2">
              <div className="vehicle-basic-info">
                <div className="vehicle-title">
                  {this.state.vehicle.vehicleName}
                </div>

                <div className="vehicle-location">
                  <FaMapMarkerAlt aria-hidden="true" />{' '}
                  {this.state.vehicle.location}
                </div>

                <div className="vehicle-type">
                  <FaShuttleVan />
                  {this.state.vehicle.vehicleType}
                </div>

                {/*
                  <div className="vehicle-rating">
                        <Rating name="read-only" value={this.state.vehicle.score} size="small" style={{ color: "#ffc107" }} readOnly />
                        </div> */}

                <div className="vehicle-description" />

                <div className="vehicle-price-box">
                  <div className="vehicle-inner">
                    <span className="from">from/</span>
                    <span className="dollar">$</span>
                    <span className="amount">
                      {this.state.vehicle.pricePerDay.toFixed(2)}/
                    </span>
                    <span className="day">day</span>
                  </div>
                </div>
              </div>

              <div className="bookingBox">
                {this.state.exclude_dates_set ? (
                  <div className="bookingBoxHeader">
                    <div className="vehicleDetailsSectionTitle">
                      Availability
                      {this.state.startDatePickerOpened && (
                        <div className="datepicker-container startdate">
                          {/* <div className="arrow-datepicker"><FaCaretRight></FaCaretRight></div> */}

                          <DatePicker
                            className="form__form-group-datepicker"
                            excludeDates={this.state.exclude_dates}
                            selected={this.state.from}
                            minDate={new Date(moment().add(1, 'days'))}
                            onChange={(date) => this.changeStartDate(date)}
                            dateFormat="yyyy-mm-dd"
                            inline
                          />
                        </div>
                      )}
                      {this.state.endDatePickerOpened && (
                        <div className="datepicker-container enddate">
                          {/* <div className="arrow-datepicker"><FaCaretRight></FaCaretRight></div> */}
                          <DatePicker
                            className="form__form-group-datepicker"
                            minDate={new Date(moment().add(1, 'days'))}
                            selected={this.state.to}
                            onChange={(date) => this.changeEndDate(date)}
                            excludeDates={this.state.exclude_dates}
                            dateFormat="yyyy-mm-dd"
                            inline
                          />
                        </div>
                      )}
                    </div>
                    <div className="vehicleDetailsSectionContent">
                      Select your travel dates using the calendar below{' '}
                    </div>
                  </div>
                ) : null}

                <div className="vehicleDetailsCalendarBox">
                  <div
                    className="dateFromToBox"
                    onClick={() => this.clickChooseStart()}
                  >
                    <span className="dateFromToLabel">
                      Choose your start date
                    </span>
                    <button className="dateFromToButton">
                      <span className="dateFromToButtonLabel">
                        {this.state.from && this.state.from !== ''
                          ? moment(this.state.from).format('DD/MM/YYYY')
                          : 'Choose A Date'}
                      </span>
                      <Icon
                        className="dateFromToButtonIcon"
                        size="large"
                        name="calendar alternate"
                      />
                    </button>
                  </div>

                  <div
                    className="dateFromToBox"
                    onClick={() => this.clickChooseEnd()}
                  >
                    <span className="dateFromToLabel">
                      Choose your end date
                    </span>
                    <button className="dateFromToButton">
                      <span className="dateFromToButtonLabel">
                        {this.state.to && this.state.to !== ''
                          ? moment(this.state.to).format('DD/MM/YYYY')
                          : 'Choose A Date'}
                      </span>
                      <Icon
                        className="dateFromToButtonIcon"
                        size="large"
                        name="calendar alternate"
                      />
                    </button>
                  </div>

                  <div className="addon-box-adjustable">
                    <br />
                    <b>Addon Amenities:</b>
                    <FormGroup column="false">
                      {this.state.vehicleAdditionalAmenities &&
                      this.state.vehicleAdditionalAmenities.length > 0 ? (
                        this.state.vehicleAdditionalAmenities.map(
                          (item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  name="addAmenity"
                                  checked={item.checked}
                                />
                              }
                              label={`${item.caption} (${formatCurrency(
                                item.price
                              )})`}
                              value={item.amenity}
                              onChange={(event) =>
                                this.handleCheckChieldElement(event)
                              }
                            />
                          )
                        )
                      ) : (
                        <div>No additional aminities</div>
                      )}
                    </FormGroup>
                  </div>

                  {this.state.buttonDisabled ? null : (
                    <div className="dateFromToBox">
                      <span className="bookingCostLabel">
                        <div className="line-medium">
                          Hire Fees:{' '}
                          {formatCurrency(this.state.totalPriceApplied)}
                        </div>

                        <div className="line-small">
                          Security Bond:{' '}
                          {formatCurrency(this.state.securityBond)}
                        </div>

                        <div className="line-small">
                          Addon Amenities:{' '}
                          {formatCurrency(this.state.amenitiesFee)}
                        </div>

                        <div className="line-small top-line">
                          Subtotal: {formatCurrency(this.state.subtotal)}
                        </div>

                        <div className="line-small">
                          Booking + Service Fee :{' '}
                          {formatCurrency(this.state.bookingFee)}
                        </div>

                        <div className="line-small">
                          Insurance Fee :{' '}
                          {formatCurrency(this.state.insuranceFee)}
                        </div>

                        <div className="line-big">
                          TOTAL: {formatCurrency(this.state.total)}
                        </div>
                      </span>
                    </div>
                  )}
                </div>

                <form className="vehicleDetailsSectionBox">
                  <div className="error-container">
                    {/* {this.state.invalidMaxRange === true ?  <div className="invalid-range">Must be maximum of  {this.state.vehicle.maximumHireDuration} days!</div> : null } */}
                    {this.state.invalidDateRange === true ? (
                      <div className="invalid-range"> Invalid Date Range!</div>
                    ) : null}
                    {this.state.invalidMinRange === true ? (
                      <div className="invalid-range">
                        {' '}
                        Must be a minimum of{' '}
                        {this.state.vehicle.minimumHireDuration} days!
                      </div>
                    ) : null}

                    {this.state.isDateRangeBlocked === true ? (
                      <div className="invalid-range">
                        {' '}
                        The dates {this.state.dateBlockedFrom} to{' '}
                        {this.state.dateBlockedTo} are unavailable!
                      </div>
                    ) : null}
                  </div>

                  {this.state.user && this.state.user.isHirer ? (
                    <button
                      className={
                        this.state.buttonDisabled
                          ? 'vehicleDetailsButtonFullSized disabled'
                          : 'vehicleDetailsButtonFullSized'
                      }
                      type="button"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.requestBookingSubmit()}
                    >
                      Request To Book
                    </button>
                  ) : (
                    <button
                      className={
                        this.state.buttonDisabled
                          ? 'vehicleDetailsButtonFullSized disabled'
                          : 'vehicleDetailsButtonFullSized'
                      }
                      type="button"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.clickShowPopup()}
                    >
                      {' '}
                      Request To Book
                    </button>
                  )}
                </form>
              </div>

              <div className="vehicleDetailsSectionBox">
                <span className="vehicleDetailsSectionTitle">
                  Make an Enquiry
                </span>
                <span className="vehicleDetailsSectionContent">
                  Make An Enquiry To Learn More About This Vehicle
                </span>
                {this.state.user && this.state.user.isHirer ? (
                  // <ChatComponent
                  //   user={this.state.user}
                  //   is_vehicle_page="1"
                  //   vehicle_id={this.state.vehicle.id}
                  //   hirer_id={this.state.user.id}
                  // />
                  <StartChatButton
                    hirerId={this.state.user.id}
                    vehicleId={this.state.vehicle.id}
                  />
                ) : (
                  <div className="chat-with-only">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.clickShowPopup()}
                    >
                      <FontAwesomeIcon icon="comments" /> &nbsp; Chat with the
                      vehicle owner!
                    </button>
                  </div>
                )}
              </div>

              <div className="additionalfeesbox  ">
                <span className="vehicleDetailsSectionTitle">
                  Additional Fees & Charge
                </span>
                <span className="vehicleDetailsSectionContent">
                  In addition to the standard booking fee the following
                  additional charges will apply:
                </span>
                <ul className="additionalFeesAndChargeBox">
                  <li>
                    <FaCircle />${this.state.vehicle.securityBond}
                    .00 Refundable security bond
                  </li>
                  <li>
                    <FaCircle />
                    $35 Booking Fee plus standard Credit Card charges{' '}
                  </li>
                  <li>
                    <FaCircle />
                    $5.00 daily insurance fee
                  </li>
                  <li>
                    <FaCircle />
                    Any selected additional amenities
                  </li>
                  <li>
                    <FaCircle />
                    Delivery/Pickup Costs by negotiation with Owners via 'Chat'
                  </li>
                  <li>
                    <FaCircle />
                    Use the Chat Channel to discuss and confirm all details
                  </li>
                </ul>
              </div>

              <br />

              <div className="vehicleDetailsSectionBox">
                <button
                  className="vehicleDetailsCollapseButton"
                  onClick={() => {
                    this.setShowAdditionalTerms(!this.state.showAdditionalTerms)
                  }}
                >
                  <span className="vehicleDetailsCollapseButtonLabel">
                    Important Additional Terms & Conditions
                  </span>
                  <Icon
                    className="faqButtonArrow"
                    size="large"
                    name={
                      this.state.showAdditionalTerms
                        ? 'chevron circle up'
                        : 'chevron circle down'
                    }
                  />
                </button>
                {this.state.showAdditionalTerms && (
                  <div className="vehicleDetailsSectionBox additionalfeesbox">
                    <span className="vehicleDetailsSectionTitle">
                      Booking Terms
                    </span>{' '}
                    <br />
                    <ul className="additionalTermsList">
                      <li>
                        All Drivers/Hirers must be 25 years or above and have
                        third party or comprehensive insurance on their towing
                        vehicle
                      </li>
                      <li>
                        Balance of Bond amount and all Hire Fees due 30 days
                        prior to Pick Up/Departure Date.
                      </li>
                      <li>
                        For ALL Dec/Jan bookings – Bonds and Hire Fees are
                        required to be paid in full by Nov 1st or ASAP if booked
                        after Nov 1st
                      </li>
                      <li>
                        Bond refunded in full when Hire Vehicle is returned in
                        its’ original and fully cleaned condition
                      </li>
                      <li>
                        Any Awning/Annex repair or damage costs will be deducted
                        from the bond
                      </li>
                      <li>
                        Strict ‘Cleaning inside and outside’ before returning
                        Hire Vehicle policies in place. Bond Penalties apply
                      </li>
                    </ul>
                    <div className="vehicleDetailsSectionBox">
                      <br />
                      <br />
                      <span className="vehicleDetailsSectionTitle">
                        Hiring Terms And Conditions That Must Be Followed
                      </span>{' '}
                      <br />
                      <br />
                      <ul className="additionalTermsList">
                        <li>
                          No bikes or heavy items to be stored in the caravan
                          while in transit
                        </li>
                        <li>No Smoking in or around the Vehicle at anytime</li>
                        <li>
                          No heavy corrugation or red dust roads without owners
                          approval
                        </li>
                        <li>
                          Return van in the same condition as when it was hired
                        </li>
                        <li>
                          Report all damages no matter how minor you think it is
                        </li>
                        <li>
                          Bond Penalties apply for any damages and non cleaning
                          of Caravan including toilet/shower if applicable
                        </li>
                        <li>Do not exceed 100kms per hour when towing</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="vehicleDetailsSectionBox">
            <span className="vehicleDetailsSectionTitle">Location</span>
            <span className="vehicleDetailsSectionContent">
              {this.state.vehicle.location}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(VehicleDetails)
