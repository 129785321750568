import fp from 'lodash/fp'
import { structureById, transformUser, invertDirection } from './helpers'
import * as messagesService from '../../Services/messages'

const defaultState = {
  all: {},
  error: {},
  sortBy: 'created_at',
  sortOrder: 'asc',
  page: 0,
  pageSize: 25,
  total: 0,
  actionButtons: {},
}

export default {
  state: defaultState,

  reducers: {
    getMessages(state, response) {
      const transformMessagesValues = response.data.map(transformUser)
      const messagesValues = { all: structureById(transformMessagesValues) }
      const messages = fp.merge(messagesValues, response.meta)

      return fp.assign(state, messages)
    },
    storeError(state, error = {}) {
      return fp.merge(state, {
        error: {
          message: fp.get('message', error),
          statusCode: fp.get('statusCode', error),
          error: fp.get('error', error),
        },
      })
    },
  },

  effects: {
    async fetchMessages(payload = {}, state) {
      // TODO: centralize data manipulation in one function for sorting and paging
      const sorting = fp.merge(
        { sortBy: fp.getOr(state.messages.sortBy, 'sortBy')(payload) },
        {
          sortOrder:
            !fp.isEmpty(payload) && fp.has('sortBy', payload)
              ? invertDirection(state.messages.sortOrder)
              : state.messages.sortOrder,
        }
      )

      // pagination
      const pageSize = fp.getOr(state.messages.pageSize, 'pageSize')(payload)
      const page = fp.isEqual(pageSize, state.messages.pageSize)
        ? fp.get('page')(payload)
        : 0

      const pagination = { page, pageSize }

      const queryParams = fp.merge(sorting, pagination)
      const messages = await messagesService.fetchMessages(queryParams)

      this.getMessages(messages)
    },
  },

  selectors: (slice, createSelector, hasProps) => ({
    all() {
      return createSelector(slice, fp.get('all'))
    },
    error() {
      return createSelector(slice, fp.get('error'))
    },
    allValues: hasProps((models, { sortBy, sortOrder }) =>
      slice(fp.flow(fp.get('all'), fp.values, fp.orderBy(sortBy, sortOrder)))
    ),
  }),
}
