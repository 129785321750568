import { api_request } from '../../../Services/helpers'

export const fetchCards = async (data = {}) => {
  return await api_request('get', '/auth/payment/details', data, true)
}

export const addCard = async (data = {}) => {
  return await api_request('post', '/auth/payment/create', data, true)
}

export const getHirerCurrentDue = async (id) => {
  return await api_request('get', '/auth/payment/due/' + id, {}, true)
}

export const pay = async (data = {}) => {
  return await api_request('post', '/auth/payment/pay', data, true)
}
