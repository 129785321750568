/*

import { formatCurrency } from 'shared/utils.js';
import { formatDate } from 'shared/utils.js';

*/

import moment from 'moment'
import { fp } from 'lodash'

export const formatDate = (date) =>
  fp.isDate && moment(date).format('YYYY-MM-DD')
// export const formatDate = date => fp.isDate && moment(date).format('YYYY-MM-DD');
// export const formatDate = date => fp.isDate && moment(date).format('YYYY-MM-DD');

export const formatDateWithFromNow = (date) =>
  fp.isDate && `${formatDate(date)} (${moment(date).fromNow()})`
export const formatCurrency = (number) =>
  `$${
    (number &&
      Number(number)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) ||
    0
  }`

export const countDays = (_from, _end) => {
  const start = moment(_from)
  const end = moment(_end)
  return end.diff(start, 'days') + 1
}
