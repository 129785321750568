import React from 'react'
import { withRouter } from 'react-router'
import * as faqsService from './FaqsPageService'
import './FaqsPage.scss'
import { FaPlusCircle, FaTrash, FaPen } from 'react-icons/fa'
import { NotificationManager } from 'react-notifications'

class FaqsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      state: 'list',
      list: [],
      search: '',
      item: false,
    }

    this.fetchLatest()
  }

  async fetchLatest() {
    let params = {}
    if (this.state.meta !== null) {
      params = { filter: this.state.meta, ...params }
    }
    const result = await faqsService.fetch(params)

    let list = []
    for (let item of result.data) {
      list.push({ isShow: true, isOpened: false, ...item })
    }

    if (result.success) {
      this.setState({ list })
      this.setState({ meta: result.meta })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  clickItem = (item) => {
    let list = this.state.list
    for (let _item of list) {
      _item.isOpened = item.id === _item.id ? !_item.isOpened : false
    }
    this.setState({ list })
  }

  onSearch = (event) => {
    let search = event.target.value
    let list = this.state.list

    for (let item of list) {
      if (
        item.faqAnswer.toLowerCase().search(search) > -1 ||
        item.faqQuestion.toLowerCase().search(search) > -1
      ) {
        item.isShow = true
      } else {
        item.isShow = false
      }
    }

    this.setState({ list, search })
  }

  clickConfirmDelete = async () => {
    const id = this.state.item.id
    const result = await faqsService.remove(id)
    console.log('result delete', result)
    let list = this.state.list.filter((x) => x.id !== id)
    this.setState({ list, item: false })
    NotificationManager.success('Successfully deleted the item.')
  }

  clickCancelDelete = () => {
    this.setState({ item: false })
  }

  clickDelete = (item) => {
    this.setState({ item })
  }

  render() {
    return (
      <>
        {this.state.item && (
          <div className="confirm-popup">
            <h1>
              Are you sure you to delete "{this.state.item.faqQuestion}"?{' '}
            </h1>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.clickConfirmDelete()}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.clickCancelDelete()}
            >
              Cancel
            </button>
          </div>
        )}

        <div className="faqListContainer">
          <div className="searchbox">
            <input
              type="text"
              placeholder="Search in FAQ's"
              value={this.state.search}
              onChange={(event) => this.onSearch(event)}
            />
          </div>

          <div
            className="addBtn"
            onClick={() => (window.location.href = '/dashboard/faqs/add')}
          >
            <FaPlusCircle></FaPlusCircle>
          </div>

          {this.state.list.length > 0 &&
            this.state.list
              .filter((x) => x.isShow)
              .map((item, index) => {
                return (
                  <div
                    onClick={() => this.clickItem(item)}
                    key={item.id}
                    className={index % 2 === 0 ? 'item even' : 'item odd'}
                  >
                    <b>
                      {item.faqQuestion}

                      <span
                        className="deleteBtn"
                        onClick={() => this.clickDelete(item)}
                        title="Delete Item"
                      >
                        <FaTrash></FaTrash>
                      </span>

                      <span
                        className="editBtn"
                        onClick={() => {
                          window.location.href =
                            '/dashboard/faqs/update/' + item.id
                        }}
                        title="Edit Item"
                      >
                        <FaPen></FaPen>
                      </span>
                    </b>

                    {item.isOpened && (
                      <div className="opened">
                        <section
                          className="not-found-controller"
                          dangerouslySetInnerHTML={{ __html: item.faqAnswer }}
                        />
                      </div>
                    )}
                  </div>
                )
              })}
        </div>
      </>
    )
  }
}

export default withRouter(FaqsPage)
