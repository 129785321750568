import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import fp from 'lodash/fp'
import { withRouter } from 'react-router'
import './confirm-booking.scss'
import { NotificationManager } from 'react-notifications'
import * as bookingService from '../../BookingService'

import { PaymentsComponent } from '../../../Dashboard/shared/payments/PaymentsComponent'
import { formatCurrency, countDays } from 'shared/utils.js'
import useAnalyticsEventTracker from 'Pages/App/useAnalyticsTracker'

const ConfirmBooking = (props) => {
  const tracker = useAnalyticsEventTracker()
  return <ConfirmBookingOld {...props} tracker={tracker} />
}
class ConfirmBookingOld extends React.Component {
  constructor(props) {
    super(props)

    const persistRoot = localStorage.getItem('persist:root')
    const user = !fp.isNil(persistRoot)
      ? JSON.parse(fp.get('user', JSON.parse(persistRoot)))
      : {}
    const currentUser = fp.get('currentUser', user)
    const authed = !fp.isEmpty(currentUser)

    const isHirer = currentUser.isHirer

    if (!isHirer) {
      const message = encodeURI('Requires a hirer account to make a booking.')
      const link =
        '/login/?message=' + message + '&redirectUrl=' + window.location
      window.location.href = link
    }

    //console.log("isHirer", isHirer, currentUser);

    this.state = {
      authed,
      isHirer,
      isSubmitting: false,
      totalPriceApplied: 0,
      currentUser,
      vehicle: null,
      rateRules: [],
      vehicleAdditionalAmenities: [],
      rateBreakdown: [],
      thumb: '',
      startDate: '',
      endDate: '',
      total_number_of_stay: 0,
      paymentType: 'payFull',
      price: 0,
      total: 0,
      subtotal: 0,
      bookingFee: 0,
      insuranceFee: 0,
      securityBond: 0,
      totalPricePerDay: 0,
      amenitiesFee: 0,

      total_guest: 0,
      no_adult_travellers: 0,
      children_travelers: '',
      planned_destinations: '',
      planned_destination_state: '',
      towing_vehicle_make: '',
      towing_vehicle_tow_bar: '',
      vehicle_model: '',
      vehicle_year: '',
      towing_capacity: '',
      electronic_brakes: 'NO',
      is_free_camping: 1,
      towing_experience: '',
      additional_extra: '',
      additional_notes: '',
      cardInfo: '',
      isWithError: false,
      isDisabled: true,
      uuid: this.props.match.params.id,
      canPartial: false,
    }

    this.fetchBooking(this.state.uuid)
  }

  async fetchBooking(uuid) {
    const result = await bookingService.getBooking(uuid)

    if (result) {
      const { vehicle, amenities, booking } = result.data
      const total_number_of_stay = countDays(booking.startDate, booking.endDate)
      this.setState({
        booking,
        startDate: booking.startDate,
        endDate: booking.endDate,
        total_number_of_stay,
      })
      const start = Date.now()
      const total_days_from_now = Math.floor(
        (new Date(booking.startDate) - start) / (1000 * 3600 * 24) + 1
      )
      const canPartial = total_days_from_now >= 30 ? true : false

      this.setState({ canPartial })
      await this.fetchVehicle(vehicle, amenities)
    }
  }

  async fetchVehicle(vehicle, amenities) {
    this.setState({ securityBond: vehicle.securityBond })
    this.setState({ vehicle })
    this.setState({ vehicleAmenities: vehicle.amenities })
    this.setState({
      rateRules: vehicle.rateRules ? JSON.parse(vehicle.rateRules) || [] : [],
    })
    this.setState({ price: vehicle.pricePerDay })
    this.setState({ totalPricePerDay: vehicle.pricePerDay })

    let images_json = vehicle.images ? JSON.parse(vehicle.images) : []
    let allamenities = amenities
    let addons_array = []

    const addons = vehicle.addonAmenities
      ? JSON.parse(vehicle.addonAmenities)
      : []
    for (let item of addons) {
      let _item = {
        checked: false,
        amenity: item.amenity,
        price: item.price,
        caption: allamenities.filter(
          (x) => Number(x.id) === Number(item.amenity)
        )[0].amenity,
      }
      addons_array.push(_item)
    }

    this.setState({ vehicleAdditionalAmenities: addons_array })

    if (images_json.length > 0) {
      const thumb = images_json[0].image
      this.setState({ thumb })
    } else {
      this.setState({ thumb: vehicle.thumbnail })
    }

    //this.setTotal();
  }

  /*
 setTotal = () => { 
  let amenities_total  = 0;
  let addAmenities = this.state.vehicleAdditionalAmenities;
  for(let item of addAmenities){
    if(item.checked){
      amenities_total  += parseFloat(item.price); 
    } 
  }
   
 
  const rateBreakdown = bookingService.processRates(this.state.startDate, this.state.endDate, this.state.rateRules, this.state.price);  
  let total =  rateBreakdown.computed_total + amenities_total  +  this.state.securityBond;     
  this.setState({subtotal: total}); 
  const insuranceFee =  5 * this.state.total_number_of_stay; //5per day 
   
  const aupercentage	= 0.0175;
  const fee	= 35;     ///flat rate booking fee is $35.00 TODO: configurable in admin    
  const orderAmount    = total; 
  let surcharge	= ((orderAmount) * aupercentage );
	surcharge	= surcharge - (surcharge/11);
  const bookingServiceFee     =  fee + surcharge;  //+ 1.75% of the total amount           
   
  this.setState( { bookingFee : bookingServiceFee});  
  total = total + bookingServiceFee + insuranceFee;    

  this.setState( { insuranceFee });
  this.setState({total}); 
  this.setState({ amenitiesFee : amenities_total });    
  this.setState({ rateBreakdown }) ; 
  this.setState({ totalPriceApplied : rateBreakdown.computed_total });  //final computed vehicle rate (excluded the amenities + fee and bond )  
 }

          


handleCheckChieldElement = (event) => { 
  let amenities = this.state.vehicleAdditionalAmenities;
  let index = 0;  
  for(let item of amenities ){     
      if (parseInt(item.amenity) === parseInt(event.target.value)){ 
        amenities[index].checked =  event.target.checked;
      }
      index ++; 
  } 

  this.setState({ vehicleAdditionalAmenities : amenities });
  this.setTotal(); 
}


handleAmenitiesTotal = (event) => { 
        console.log("amenities", this.state.vehicleAdditionalAmenities); 
        this.setTotal(); 
};

       */

  validateForm = () => {
    let error = false
    let obj = []

    if (parseInt(this.state.total_guest) < 1) {
      error = true
      obj['total_guest_error'] = 1
    } else {
      obj['total_guest_error'] = 0
    }

    if (parseInt(this.state.no_adult_travellers) < 1) {
      error = true
      obj['no_adult_travellers_error'] = 1
    } else {
      obj['no_adult_travellers_error'] = 0
    }

    if (this.state.children_travelers === '') {
      error = true
      obj['children_travelers_error'] = 1
    } else {
      obj['children_travelers_error'] = 0
    }

    if (this.state.planned_destinations === '') {
      error = true
      obj['planned_destinations_error'] = 1
    } else {
      obj['planned_destinations_error'] = 0
    }

    if (this.state.planned_destination_state === '') {
      error = true
      obj['planned_destination_state_error'] = 1
    } else {
      obj['planned_destination_state_error'] = 0
    }

    if (this.state.towing_vehicle_make === '') {
      error = true
      obj['towing_vehicle_make_error'] = 1
    } else {
      obj['towing_vehicle_make_error'] = 0
    }

    if (this.state.vehicle_model === '') {
      error = true
      obj['vehicle_model_error'] = 1
    } else {
      obj['vehicle_model_error'] = 0
    }

    if (this.state.vehicle_year === '') {
      error = true
      obj['vehicle_year_error'] = 1
    } else {
      obj['vehicle_year_error'] = 0
    }

    if (this.state.towing_capacity === '') {
      error = true
      obj['towing_capacity_error'] = 1
    } else {
      obj['towing_capacity_error'] = 0
    }

    if (this.state.electronic_brakes === '') {
      error = true
      obj['electronic_brakes_error'] = 1
    } else {
      obj['electronic_brakes_error'] = 0
    }

    if (this.state.towing_vehicle_tow_bar === '') {
      error = true
      obj['towing_vehicle_tow_bar_error'] = 1
    } else {
      obj['towing_vehicle_tow_bar_error'] = 0
    }

    if (this.state.additional_notes === '') {
      error = true
      obj['additional_notes_error'] = 1
    } else {
      obj['additional_notes_error'] = 0
    }

    if (this.state.towing_experience === '') {
      error = true
      obj['towing_experience_error'] = 1
    } else {
      obj['towing_experience_error'] = 0
    }

    if (this.state.cardInfo === '') {
      error = true
      obj['credit_card_error'] = 1
    } else {
      obj['credit_card_error'] = 0
    }

    obj['isDisabled'] = error

    this.setState(obj)
  }

  setStateField = (params) => {
    this.setState(params, this.validateForm)
  }

  cardInfo = (data) => {
    console.log('cardInfo', data)
    this.setState({ cardInfo: data })
    this.validateForm()
  }

  handleSubmitForm = async () => {
    await this.setState({ isSubmitting: true })
    if (!this.state.isHirer || !this.state.authed) {
      const message = encodeURI('Requires a hirer account to make a booking.')
      const link = '/login/?message=' + message
      this.props.tracker('redirect', link)
      this.props.history.push(link)
    } else {
      const booking_payload = {
        uuid: this.state.uuid,
        hirer_id: this.state.currentUser.id,
        owner_id: this.state.vehicle.userId,
        vehicle_id: this.state.vehicle.id,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        payment_type:
          this.state.paymentType === 'payDeposit' ? 'partial' : 'full',
        total_guest: Number(this.state.total_guest),

        no_adult_travellers: Number(this.state.no_adult_travellers),
        children_travelers: Number(this.state.children_travelers),
        planned_destinations: this.state.planned_destinations,
        planned_destination_state: this.state.planned_destination_state,

        towing_vehicle_make: this.state.towing_vehicle_make,
        vehicle_model: this.state.vehicle_model,
        vehicle_year: this.state.vehicle_year,
        towing_capacity: this.state.towing_capacity,
        towing_vehicle_tow_bar: this.state.towing_vehicle_tow_bar,
        electronic_brakes: this.state.electronic_brakes,
        is_free_camping: this.state.is_free_camping,
        towing_experience: this.state.towing_experience,
        additional_extra: this.state.additional_extra,
        additional_notes: this.state.additional_notes,
        card_id: this.state.cardInfo.id,
      }
      this.props.tracker('bookingrequest', booking_payload)
      const result = await bookingService.updateBooking(booking_payload)
      if (result.success) {
        //window.location.href = `/contract/${this.state.uuid}`;
        window.location.href = `/confirm-booking-success`
      } else {
        this.props.tracker('error', result)
        NotificationManager.error(
          'There was a problem booking at the moment.',
          'Error',
          5000,
          () => {}
        )
        await this.setState({ isSubmitting: false })
      }
    }
  }

  render() {
    if (!this.state.vehicle) {
      return <></>
    } else {
      const total = this.state.booking
        ? parseFloat(this.state.booking.totalAmount) -
          parseFloat(this.state.securityBond)
        : 0
      console.log(
        total,
        this.state.securityBond,
        this.state.booking.totalAmount
      )
      return (
        <React.Fragment>
          <div className="confirmBookingContainer">
            <Container fixed style={{ justifyContent: 'center' }}>
              <Grid
                container
                spacing={3}
                style={{ marginTop: '10px', justifyContent: 'center' }}
              >
                <Grid item xs={11} md={6} style={{ order: 2 }}>
                  <Paper style={{ padding: '3em' }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <h3>Payment Type</h3>

                        <div className="payment-notes">
                          <ul>
                            <li>
                              If Booking Dates selected are within the next 30 x
                              days you must pay in full
                            </li>
                            <li>
                              If Booking Dates selected are over 30 x Days away
                              you can select Pay in full or Pay Deposit only{' '}
                            </li>
                            <li>
                              All Balance of Bond and Hire Fee payments are due
                              14 x days before Booking starts
                            </li>
                          </ul>
                        </div>

                        {this.state.canPartial ? (
                          <>
                            <label>
                              <input
                                type="radio"
                                name="paymentType"
                                defaultChecked="true"
                                onClick={() => {
                                  this.props.tracker('setPaymentType', 'full')
                                  this.setState({ paymentType: 'payFull' })
                                }}
                                value="payFull"
                              />
                              &nbsp;Pay in full
                            </label>{' '}
                            &nbsp;&nbsp;
                            <label>
                              <input
                                type="radio"
                                name="paymentType"
                                onClick={() => {
                                  this.props.tracker(
                                    'setPaymentType',
                                    'deposit'
                                  )
                                  this.setState({ paymentType: 'payDeposit' })
                                }}
                                value="payDeposit"
                              />
                              &nbsp;Pay Deposit
                            </label>
                          </>
                        ) : (
                          <>
                            <label>
                              <input
                                type="radio"
                                defaultChecked="true"
                                name="paymentType"
                                onClick={() => {
                                  this.props.tracker('setPaymentType', 'full')
                                  this.setState({ paymentType: 'payFull' })
                                }}
                                value="payFull"
                              />
                              &nbsp;Pay in full
                            </label>{' '}
                            &nbsp;&nbsp;
                            <label>
                              <input
                                type="radio"
                                disabled
                                name="paymentType"
                                value="payDeposit"
                              />
                              &nbsp;Pay Deposit
                            </label>
                          </>
                        )}

                        <br />
                        <br />
                        <br />

                        <h3>Credit Card</h3>

                        <div
                          className={
                            this.state.credit_card_error ? 'div-error' : ''
                          }
                        >
                          Payments will appear on your statement as "Caravan &
                          Camping Hire" <br />
                          Credit or debit card
                        </div>

                        <PaymentsComponent cardInfo={this.cardInfo} />
                        <br />
                        <br />
                      </Grid>
                      <Grid item xs={12}>
                        <h3>Additional Hirer Information Needed</h3>

                        <div className="input-heading">
                          No. of travellers (Total)
                          <span className="required">*</span>
                        </div>
                        <select
                          className={
                            this.state.total_guest_error ? 'error' : ''
                          }
                          defaultValue=""
                          onChange={(e) =>
                            this.setStateField({ total_guest: e.target.value })
                          }
                        >
                          <option value="" disabled>
                            Number of travellers
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                        </select>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="input-heading">
                          No. of Adult Travellers (Total)
                          <span className="required">*</span>
                        </div>
                        <select
                          className={
                            this.state.no_adult_travellers_error ? 'error' : ''
                          }
                          defaultValue=""
                          onChange={(e) =>
                            this.setStateField({
                              no_adult_travellers: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled>
                            Number of adult travellers
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                        </select>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="input-heading">
                          No. of Children Travellers
                          <span className="required">*</span>
                        </div>
                        <select
                          className={
                            this.state.children_travelers_error ? 'error' : ''
                          }
                          defaultValue=""
                          onChange={(e) =>
                            this.setStateField({
                              children_travelers: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled style={{ color: '#eee' }}>
                            No. of Children Travellers
                          </option>
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                        </select>
                      </Grid>

                      <Grid item xs={12}>
                        {' '}
                        <div className="input-heading">
                          Planned Destinations
                          <span className="required">*</span>
                        </div>
                        <input
                          type="text"
                          className={
                            this.state.planned_destinations_error ? 'error' : ''
                          }
                          placeholder="Planned Destinations"
                          name="planned_destinations"
                          onChange={(e) =>
                            this.setStateField({
                              planned_destinations: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {' '}
                        <div className="input-heading">
                          Planned Destination State
                          <span className="required">*</span>
                        </div>
                        <input
                          type="text"
                          className={
                            this.state.planned_destination_state_error
                              ? 'error'
                              : ''
                          }
                          placeholder="Planned Destination State"
                          name="planned_destination_state"
                          onChange={(e) =>
                            this.setStateField({
                              planned_destination_state: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {' '}
                        <div className="input-heading">
                          Towing Vehicle Make<span className="required">*</span>
                        </div>
                        <select
                          className={
                            this.state.towing_vehicle_make_error ? 'error' : ''
                          }
                          onChange={(e) =>
                            this.setStateField({
                              towing_vehicle_make: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled>
                            Select some options
                          </option>
                          <option value="N/A">N/A</option>
                          <option value="Unknown">Unknown</option>
                          <option value="Toyota">Toyota</option>
                          <option value="Holden">Holden</option>
                          <option value="Ford">Ford</option>
                          <option value="Nissan">Nissan</option>
                          <option value="Bmw">Bmw</option>
                          <option value="Mazda">Mazda</option>
                          <option value="Mercedes-Benz">Mercedes-Benz</option>
                          <option value="Volkswagen">Volkswagen</option>
                          <option value="Audi">Audi</option>
                          <option value="Kia">Kia</option>
                          <option value="Peugeot">Peugeot</option>
                          <option value="Hyundai">Hyundai</option>
                          <option value="Abarth">Abarth</option>
                          <option value="Alfa Romeo">Alfa Romeo</option>
                          <option value="Aston Martin">Aston Martin</option>
                          <option value="Austin">Austin</option>
                          <option value="Bedford">Bedford</option>
                          <option value="Bentley">Bentley</option>
                          <option value="Bolwell">Bolwell</option>
                          <option value="Bufori">Bufori</option>
                          <option value="Cadillac">Cadillac</option>
                          <option value="Caterham">Caterham</option>
                          <option value="Chery">Chery</option>
                          <option value="Chevrolet">Chevrolet</option>
                          <option value="Chrysler">Chrysler</option>
                          <option value="Citroen">Citroen</option>
                          <option value="Daewoo">Daewoo</option>
                          <option value="Daihatsu">Daihatsu</option>
                          <option value="Dodge">Dodge</option>
                          <option value="Fiat">Fiat</option>
                          <option value="Geely">Geely</option>
                          <option value="Great Wall">Great Wall</option>
                          <option value="Hino">Hino</option>
                          <option value="Hummer">Hummer</option>
                          <option value="Infiniti">Infiniti</option>
                          <option value="Isuzu">Isuzu</option>
                          <option value="Jaguar">Jaguar</option>
                          <option value="Jeep">Jeep</option>
                          <option value="Land Rover">Land Rover</option>
                          <option value="Lexus">Lexus</option>
                          <option value="Lotus">Lotus</option>
                          <option value="Mini">Mini</option>
                          <option value="Mitsubishi">Mitsubishi</option>
                          <option value="Opel">Opel</option>
                          <option value="Porsche">Porsche</option>
                          <option value="Proton">Proton</option>
                          <option value="Range Rover">Range Rover</option>
                          <option value="Renault">Renault</option>
                          <option value="Saab">Saab</option>
                          <option value="Skoda">Skoda</option>
                          <option value="Ssangyong">Ssangyong</option>
                          <option value="Subaru">Subaru</option>
                          <option value="Suzuki">Suzuki</option>
                          <option value="Tata">Tata</option>
                          <option value="Volvo">Volvo</option>
                        </select>
                      </Grid>
                      <div className="input-heading">
                        Towing Vehicle Model<span className="required">*</span>
                      </div>
                      <Grid item xs={12}>
                        <input
                          type="text"
                          className={
                            this.state.vehicle_model_error ? 'error' : ''
                          }
                          placeholder="Towing Vehicle Model"
                          name="vehicle_model"
                          onChange={(e) =>
                            this.setStateField({
                              vehicle_model: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <div className="input-heading">
                        Towing Vehicle Year of Manufacture
                        <span className="required">*</span>
                      </div>
                      <Grid item xs={12}>
                        <select
                          className={
                            this.state.vehicle_year_error ? 'error' : ''
                          }
                          onChange={(e) =>
                            this.setStateField({ vehicle_year: e.target.value })
                          }
                        >
                          <option value="" disabled>
                            Select some options
                          </option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>
                          <option value="1999">1999</option>
                          <option value="1998">1998</option>
                          <option value="1997">1997</option>
                          <option value="1996">1996</option>
                          <option value="1995">1995</option>
                          <option value="1994">1994</option>
                          <option value="1993">1993</option>
                          <option value="1992">1992</option>
                          <option value="1991">1991</option>
                          <option value="1990">1990</option>
                        </select>
                      </Grid>
                      <div className="input-heading">
                        Towing Vehicle Towing Capacity (kg)
                        <span className="required">*</span>
                      </div>
                      <Grid item xs={12}>
                        <input
                          type="text"
                          className={
                            this.state.towing_capacity_error ? 'error' : ''
                          }
                          placeholder="Towing Capacity"
                          name="towing_capacity"
                          onChange={(e) =>
                            this.setStateField({
                              towing_capacity: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <div className="input-heading">
                        Towing Vehicle Tow Bar Rating (kg)
                        <span className="required">*</span>
                      </div>

                      <Grid item xs={12}>
                        <input
                          type="text"
                          className={
                            this.state.towing_vehicle_tow_bar_error
                              ? 'error'
                              : ''
                          }
                          placeholder="Towing Capacity"
                          name="towing_vehicle_tow_bar"
                          onChange={(e) =>
                            this.setStateField({
                              towing_vehicle_tow_bar: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <div className="input-heading">
                        Does Towing Vehicle Have Electronic Brake Controller
                        <span className="required">*</span>{' '}
                      </div>
                      <Grid item xs={12}>
                        <select
                          onChange={(e) =>
                            this.setStateField({
                              electronic_brakes: e.target.value,
                            })
                          }
                        >
                          <option value="NO">NO</option>
                          <option value="YES">YES</option>
                        </select>
                      </Grid>
                      <div className="input-heading">
                        <input
                          type="checkbox"
                          name="is_free_camping"
                          defaultChecked={this.state.is_free_camping}
                          value="1"
                          onChange={(e) =>
                            this.setStateField({
                              is_free_camping: e.target.value,
                            })
                          }
                        />{' '}
                        Are you Free Camping? (optional)
                      </div>

                      <div className="input-heading">
                        Towing Experience<span className="required">*</span>
                      </div>
                      <Grid item xs={12}>
                        <input
                          type="text"
                          placeholder="Towing Experience"
                          className={
                            this.state.towing_experience_error ? 'error' : ''
                          }
                          name="towing_experience"
                          onChange={(e) =>
                            this.setStateField({
                              towing_experience: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <div className="input-heading">
                        Additional Requests/Extras (Charges may apply)
                        (optional)
                      </div>
                      <Grid item xs={12}>
                        <textarea
                          placeholder="Additional request"
                          onChange={(e) =>
                            this.setStateField({
                              additional_extra: e.target.value,
                            })
                          }
                        ></textarea>
                      </Grid>

                      <div className="input-heading">
                        *Itinerary and Travel Notes for Owner
                      </div>
                      <Grid item xs={12}>
                        <textarea
                          placeholder="Additional notes"
                          className={
                            this.state.additional_notes_error ? 'error' : ''
                          }
                          onChange={(e) =>
                            this.setStateField({
                              additional_notes: e.target.value,
                            })
                          }
                        ></textarea>
                      </Grid>

                      {this.state.isSubmitting ? (
                        <button className="vehicleDetailsButtonFullSized disabled">
                          Please wait....
                        </button>
                      ) : (
                        <button
                          className={
                            this.state.isDisabled
                              ? 'vehicleDetailsButtonFullSized disabled'
                              : 'vehicleDetailsButtonFullSized'
                          }
                          onClick={() => this.handleSubmitForm()}
                          disabled={this.state.isDisabled}
                        >
                          Submit Booking Detail
                        </button>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={11} md={6} style={{ order: 1 }}>
                  <Paper>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          title="Vehicle Card"
                          id={this.state.vehicle.id}
                          image={this.state.thumb}
                          style={{ height: 200 }}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {this.state.vehicle.vehicleName}
                          </Typography>
                          <Grid
                            container
                            spacing={1}
                            style={{ paddingLeft: '1em', marginTop: '10px' }}
                          >
                            <Grid item xs={6}>
                              <b>From</b>
                            </Grid>
                            <Grid item xs={6}>
                              {this.state.startDate}
                            </Grid>
                            <Grid item xs={6}>
                              <b>To</b>
                            </Grid>
                            <Grid item xs={6}>
                              {this.state.endDate}
                            </Grid>
                          </Grid>
                          <hr />
                          <Grid
                            container
                            spacing={1}
                            style={{ paddingLeft: '1em', marginTop: '10px' }}
                          >
                            <Grid item xs={6}>
                              <b>Payment Type</b>
                            </Grid>
                            <Grid item xs={6}>
                              {this.state.paymentType === 'payDeposit'
                                ? 'Pay Deposit'
                                : 'Pay in Full'}
                            </Grid>

                            <Grid item xs={6}>
                              <b>Number of Days</b>
                            </Grid>
                            <Grid item xs={6}>
                              {this.state.total_number_of_stay}
                            </Grid>
                          </Grid>
                          <hr />
                          <Grid
                            container
                            spacing={1}
                            style={{ paddingLeft: '1em', marginTop: '10px' }}
                          >
                            <Grid item xs={6}>
                              <b>Hire Fees</b>
                            </Grid>
                            <Grid item xs={6}>
                              {formatCurrency(this.state.booking.bookingCost)}
                            </Grid>

                            <Grid item xs={6}>
                              <b>Security Bond</b>
                            </Grid>
                            <Grid item xs={6}>
                              {formatCurrency(this.state.securityBond)}{' '}
                              (Refundable)
                            </Grid>

                            <Grid item xs={6}>
                              <b>Addon Amenities</b>
                            </Grid>
                            <Grid item xs={6}>
                              {formatCurrency(
                                this.state.booking.totalAddonsAmount
                              )}
                            </Grid>
                          </Grid>

                          <hr />

                          <Grid
                            container
                            spacing={1}
                            style={{ paddingLeft: '1em', marginTop: '10px' }}
                          >
                            <Grid item xs={6}>
                              <b>Booking + Service Fee</b>
                            </Grid>
                            <Grid item xs={6}>
                              {formatCurrency(this.state.booking.bookingFee)}
                            </Grid>

                            <Grid item xs={6}>
                              <b>Insurance Fee</b>
                            </Grid>
                            <Grid item xs={6}>
                              {formatCurrency(this.state.booking.insuranceFee)}
                            </Grid>
                          </Grid>
                        </CardContent>

                        <CardActions style={{ backgroundColor: '#ff8c3f' }}>
                          <h3 style={{ color: 'white' }}>TOTAL: </h3>
                          <h5 style={{ color: 'white' }}>
                            {formatCurrency(this.state.booking.totalAmount)}
                            {'   '}(including the refundable security bond of{' '}
                            {formatCurrency(this.state.securityBond)})
                          </h5>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </div>
        </React.Fragment>
      )
    }
  } //render
}

export default withRouter(ConfirmBooking)
