import React from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

const Marker = (props) => {
  return (
    <React.Fragment>
      <div
        style={{
          minWidth: '200px',
          height: 'auto',
          padding: '10px',
          borderRadius: '16px',
          color:
            Number(props.selectedMarker) === Number(props.keyNum) && props.show
              ? 'white'
              : props.textColor,
          border: '1px solid transparent',
          fontWeight: 800,
          fontSize: 'small',
          backgroundColor:
            Number(props.selectedMarker) === Number(props.keyNum) && props.show
              ? '#1f2351'
              : props.color,
        }}
      >
        {props.text}
      </div>
      {props.show && Number(props.selectedMarker) === Number(props.keyNum) && (
        <div
          style={{
            position: 'absolute',
            left: '-300px',
            top: '-120px',
            width: '25em',
          }}
        >
          <Card style={{ maxWidth: 500 }}>
            <CardActionArea
              href={`/vehicle/${props.vehicleId}`}
              style={{ textDecoration: 'none' }}
            >
              <CardMedia
                style={{ height: 140 }}
                image={props.imagePath}
                title="Vehicle Card"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {props.vehicleName}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  ${props.pricePerDay} / Day
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      )}
    </React.Fragment>
  )
}

export default Marker
