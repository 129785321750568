import fp from 'lodash/fp'
import { api_request } from '../../../Services/helpers'

export const fetchOwners = async (params = {}) =>
  await api_request('post', '/auth/owners', params, true)

export const fetchHirers = async (params = {}) =>
  await api_request('post', '/auth/hirers', params, true)

export const changeNewPassword = async (params = {}) =>
  await api_request('post', '/auth/change_user_password', params, true)

export const loginAs = async (params = {}) => {
  const result = await api_request('post', '/auth/loginAs', params, true)

  const defaultState = { all: {}, currentUser: {} }
  const user = await fp.merge(defaultState, { currentUser: result })
  await localStorage.setItem(
    'persist:root',
    JSON.stringify({ user: JSON.stringify(user) })
  )

  return true
}
