import React from 'react'

import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import Login from '../../Components/Login/Login'
import Footer from '../../Components/Footer/Footer'
import { withRouter } from 'react-router'
import './LoginPage.scss'

class LoginPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('login-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Login" link="/login" />
        <Login />
        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(LoginPage)
