import { api_request } from '../../Services/helpers'

export const fetch = async (
  data = {
    all: {},
    currentFAQ: {},
    sortBy: 'created_at',
    sortOrder: 'asc',
    page: 0,
    pageSize: 25,
    total: 0,
  }
) => await api_request('get', '/FAQs', data, false)
