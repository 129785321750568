import fp from 'lodash/fp'
import * as userService from '../../Services/user'

const defaultState = {
  all: {},
  currentUser: {},
}

export default {
  state: defaultState,

  reducers: {
    getUsers(state, payload) {
      return fp.merge(state, { all: payload })
    },
    async storeLoginUser(state, userInfo) {
      const user = await fp.merge(state, {
        currentUser: userInfo,
      })
      await localStorage.setItem(
        'persist:root',
        JSON.stringify({ user: JSON.stringify(user) })
      )
      return user
    },
    logoutUser() {
      localStorage.removeItem('persist:root')
      return defaultState
    },
  },
  effects: {
    // async fetchUsers() {
    //   const users = await userService.fetchUsers();
    //   this.getUsers(users);
    // },

    async postLogin(payload) {
      const loginResponse = await userService
        .postLogin(payload)
        .then((response) => response)
      if (loginResponse.status === 200) {
        await this.storeLoginUser(loginResponse.data)
      }
      return loginResponse
    },

    logout() {
      return this.logoutUser()
    },

    async createUser(payload) {
      const registerResponse = await userService.createUser(payload).then(
        (response) =>
          /*
        if (response.status === 200) this.storeLoginUser(response.data);
        */
          response
      )
      return registerResponse
    },
  },

  selectors: (slice, createSelector) => ({
    all() {
      return createSelector(slice, fp.get('all'))
    },
    currentUser() {
      return createSelector(slice, fp.get('currentUser'))
    },
    isHirer() {
      return createSelector(
        this.currentUser,
        (currentUser) => !fp.isEmpty(currentUser) && currentUser.isHirer
      )
    },
    isLoggedIn() {
      return createSelector(
        this.currentUser,
        (currentUser) => !fp.isEmpty(currentUser)
      )
    },

    allValues() {
      return createSelector(this.all, fp.values)
    },
  }),
}
