import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../../Redux/store'
import HomePage from '../HomePage/HomePage'
import LoginPage from '../LoginPage/LoginPage'
import ForgotPage from '../ForgotPage/ForgotPage'
import NewPasswordPage from '../ForgotPage/NewPasswordPage'
import UserLogoutComponent from '../User/logout'
import RegistrationPage from '../RegistrationPage/RegistrationPage'
import LatestNewsPage from '../LatestNewsPage/LatestNewsPage'
import AboutUsPage from '../AboutUsPage/AboutUsPage'
import ContactUsPage from '../ContactUsPage/ContactUsPage'
import FAQPage from '../FAQPage/FAQPage'
import BlankPage from '../BlankPage'
import ProductPage from '../ProductPage/ProductPage'
import SingleProductPage from '../SingleProductPage/SingleProductPage'
import HowDoesItWorkPage from '../HowDoesItWorkPage/HowDoesItWorkPage'
import HireEnquiryPage from '../HireEnquiryPage/HireEnquiryPage'
import AllVehiclesForHirePage from '../AllVehiclesForHirePage/AllVehiclesForHirePage'
import NewsPostPage from '../NewsPostPage/NewsPostPage'
import SingleFAQPage from '../SingleFAQPage/SingleFAQPage'
import VehicleDetailsPage from '../VehicleDetailsPage/VehicleDetailsPage'
import SearchResultPage from '../SearchResultPage'
import SearchResultsPage from '../SearchResultsPage/SearchResultsPage'
import VehiclesByTypePage from '../VehiclesByTypePage/VehiclesByTypePage'
import VehicleByStatePage from '../VehiclesByStatePage'
import BookingConfirmationPage from '../Booking/Confirmation'
import ConfirmBookingPage from '../ConfirmBooking/confirm-booking'
import ConfirmBookingSuccessPage from '../ConfirmBooking/confirm-booking-success'
import CheckoutPage from '../CheckoutPage/CheckoutPage'
import fp from 'lodash/fp'
import DashboardLayoutRoute, { DashboardLayout } from '../../Layouts/Dashboard/'
import DashboardPage from '../Dashboard/Dashboard/DashboardPage'
import LearnMorePage from '../Dashboard/LearnMore/LearnmorePage'
import MessageInboxPage from '../Dashboard/Messages/MessageInboxPage'
import ProfilePage from '../Dashboard/Profile/ProfilePage'
import HirersPage from '../Dashboard/Hirers/HirersPage'
import OwnersPage from '../Dashboard/Owners/OwnersPage'
import BookingsPage from '../Dashboard/Bookings/BookingsPage'
import CreateVehiclePage from '../Dashboard/Vehicles/CreateVehiclePage/CreateVehiclePage'
import VehicleListPage from '../Dashboard/Vehicles/VehicleListPage/VehicleListPage'
import VehicleDetailPage from '../Dashboard/Vehicles/VehicleDetailPage/VehicleDetailPage'
import VehicleDraftListPage from '../Dashboard/Vehicles/VehicleDraftListPage/VehicleDraftListPage'
import PaymentsPage from '../Dashboard/Payments/PaymentsPage'
import TransactionsPage from '../Dashboard/Transactions/TransactionsPage'
import ReadMessagePage from '../Dashboard/Messages/ReadMessage'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

import SupportAdminPage from '../Dashboard/SupportAdmin/SupportAdminPage'
import SupportUserPage from '../Dashboard/SupportUser/SupportUserPage'

import AddFaqsPage from '../Dashboard/Faqs/AddFaqsPage'
import FaqsPage from '../Dashboard/Faqs/FaqsPage'
import ReviewsPage from '../Dashboard/Reviews/ReviewsPage'

import LocationSearchPage from '../Dashboard/Searches/LocationSearchPage'
import CaravanSearchPage from '../Dashboard/Searches/CaravanSearchPage'

import RevenuesPage from '../Dashboard/Revenues/RevenuesPage'
import BankAccountPage from '../Dashboard/BankAccount/BankAccountPage'
import ContractPage from '../contractPage/ContractPage'

import PaymentBookingSuccessPage from '../ConfirmBooking/payment-success'

import './App.scss'
//import BrowserSupport, { detectBrowser } from 'react-browser-support';
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons'

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon])
library.add(...iconList)

const MEASUREMENT_ID = 'G-B1QXZGPYFN'
ReactGA.initialize(MEASUREMENT_ID)

export const App = (props) => {
  useEffect(() => {
    ReactGA.send('pageview', window.location.pathname + window.location.search)
  }, [])

  return <AppOld {...props} />
}

class AppOld extends React.Component {
  persistRoot =
    typeof window !== 'undefined'
      ? localStorage.getItem('persist:root')
      : undefined
  user = !fp.isNil(this.persistRoot)
    ? JSON.parse(fp.get('user', JSON.parse(this.persistRoot)))
    : {}
  currentUser = fp.get('currentUser', this.user)
  isLoggedIn = !fp.isEmpty(this.currentUser)

  render() {
    const currentUser = this.currentUser
    const isLoggedIn = this.isLoggedIn
    const isAdmin =
      currentUser && currentUser.userRole === 'Admin' ? true : false

    //owner_is_detail_complete
    ///currentUser.userRole = '';

    //is owner not completed his details yet. redirect to update page.
    //all users will need to fill this up.
    // if (
    //   currentUser &&
    //   currentUser.userRole === 'Owner' &&
    //   isLoggedIn &&
    //   !currentUser.ownerIsDetailComplete
    // ) {
    //   const _location =
    //     typeof window !== 'undefined' ? window.location.href : undefined
    //   if (_location?.search('/dashboard/user/') === -1) {
    //     //if not in user profile, force this user to update the form.
    //     //window.location.href = '/dashboard/user/' + currentUser.id + '?update=1'
    //     return (
    //       <Redirect to={'/dashboard/user/' + currentUser.id + '?update=1'} />
    //     )
    //   }
    // }

    //return ( <main> <h1>Hello world </h1></main>);

    return (
      <Provider store={store}>
        <Helmet>
          <meta property="og:url" content="https://www.cnchire.com.au" />
          <meta property="og:title" content="Caravan and Camping Hire" />
          <meta
            property="og:description"
            content="Caravan and Camping Hire AUS | Peer to Peer private Caravan Hire"
          />
          <meta
            property="og:image"
            content="https://cdn.cnchire.com.au/colored-badge.png"
          />
        </Helmet>
        <Switch>
          <Route exact path="/" render={() => <HomePage />} />
          <Route exact path="/logout" render={() => <UserLogoutComponent />} />
          <Route exact path="/login" render={() => <LoginPage />} />
          <Route exact path="/forgot-password" children={<ForgotPage />} />
          <Route
            exact
            path="/new-password/:id"
            children={<NewPasswordPage />}
          />
          <Route path="/register" children={<RegistrationPage />} />

          <Route exact path="/news" render={() => <LatestNewsPage />} />
          <Route exact path="/about-us" render={() => <AboutUsPage />} />
          <Route
            exact
            path="/contact-us"
            render={() => <ContactUsPage user={currentUser} />}
          />
          <Route exact path="/faqs" render={() => <FAQPage />} />
          <Route exact path="/blank" render={() => <BlankPage />} />
          <Route exact path="/rv-products" render={() => <ProductPage />} />
          <Route
            path="/item/:id"
            render={(props) => <SingleProductPage {...props} />}
          />
          <Route
            exact
            path="/how-does-it-work-for-owners"
            render={() => <HowDoesItWorkPage />}
          />
          <Route
            exact
            path="/hire-enquiry/:id"
            render={() => <HireEnquiryPage user={currentUser} />}
          />
          <Route
            exact
            path="/vehicles"
            render={() => <AllVehiclesForHirePage />}
          />
          <Route
            path="/news/:id"
            render={(props) => <NewsPostPage {...props} />}
          />
          <Route
            path="/faqs/:id"
            render={(props) => <SingleFAQPage {...props} />}
          />
          <Route
            path="/vehicle/:id"
            render={(props) => (
              <VehicleDetailsPage user={currentUser} {...props} />
            )}
          />
          <Route
            path="/listing/:slug"
            render={(props) => (
              <VehicleDetailsPage user={currentUser} {...props} />
            )}
          />
          <Route exact path="/car-hire" render={() => <HireEnquiryPage />} />
          <Route
            exact
            path="/vehicles/result"
            render={() => <SearchResultPage />}
          />
          <Route
            path="/search-results?state=:state"
            render={(props) => <VehicleByStatePage {...props} />}
          />
          <Route
            path="/search-results?vehicleType=:vehicleType"
            render={(props) => <VehiclesByTypePage {...props} />}
          />
          <Route
            path="/search-results"
            render={(props) => <SearchResultsPage {...props} />}
          />
          <Route
            exact
            path="/booking-confirmation"
            render={() => <BookingConfirmationPage />}
          />
          <Route
            exact
            path="/confirm-booking/:id"
            render={(props) => <ConfirmBookingPage {...props} />}
          />
          <Route
            exact
            path="/confirm-booking-success"
            render={(props) => <ConfirmBookingSuccessPage {...props} />}
          />
          <Route
            exact
            path="/confirm-payment-success"
            render={(props) => <PaymentBookingSuccessPage {...props} />}
          />
          <Route exact path="/checkout/:id" render={() => <CheckoutPage />} />
          <Route
            exact
            path="/contract/:id"
            isLoggedIn={isLoggedIn}
            user={currentUser}
            page_title="Contract Page"
            children={<ContractPage />}
          />

          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/bankdetails"
            page_title="Bank Details"
            component={BankAccountPage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/payment-details/:id"
            page_title="Pay Booking"
            component={PaymentsPage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/payment-details"
            page_title="Payment Details"
            component={PaymentsPage}
          />
          {/* <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/transactions"
            
            children={<TransactionsPage />}
          /> */}
          <Route
            path="/dashboard/transactions"
            render={(matchProps) => {
              return !isLoggedIn ? (
                <Redirect to="/login" />
              ) : (
                <DashboardLayout
                  component={TransactionsPage}
                  matchProps={matchProps}
                  isRefresh="false"
                  user={currentUser}
                  page_title="Transactions"
                ></DashboardLayout>
              )
            }}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/user/:id"
            page_title="User"
            component={ProfilePage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/vehicles/draft"
            page_title="Draft Vehicles"
            component={VehicleDraftListPage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/vehicle/update/:id"
            page_title="Update Vehicle"
            component={CreateVehiclePage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/vehicles/new"
            page_title="New Vehicle"
            component={CreateVehiclePage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/vehicles/:id"
            page_title="Vehicle Detail"
            component={VehicleDetailPage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/vehicles"
            page_title="Vehicles"
            component={VehicleListPage}
          />

          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/location-search"
              page_title="Location Searches"
              component={LocationSearchPage}
            />
          )}
          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/caravan-search"
              page_title="Caravan Searches"
              component={CaravanSearchPage}
            />
          )}

          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/support"
              page_title="Support"
              component={SupportAdminPage}
            />
          )}
          {!isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/support"
              page_title="Support"
              component={SupportUserPage}
            />
          )}

          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/hirers"
              page_title="Hirers"
              component={HirersPage}
            />
          )}
          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/owners"
              page_title="Owners"
              component={OwnersPage}
            />
          )}

          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/reviews"
              page_title="Reviews"
              component={ReviewsPage}
            />
          )}

          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/faqs/update/:id"
              page_title="Edit FAQ's"
              component={AddFaqsPage}
            />
          )}
          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/faqs/add"
              page_title="Add FAQ's"
              component={AddFaqsPage}
            />
          )}
          {isAdmin && (
            <DashboardLayoutRoute
              isLoggedIn={isLoggedIn}
              user={currentUser}
              path="/dashboard/faqs"
              page_title="FAQ's"
              component={FaqsPage}
            />
          )}

          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/revenues"
            page_title="Revenues"
            component={RevenuesPage}
          />

          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/bookings/pending"
            page_title="Pending Bookings"
            component={BookingsPage}
          />

          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/bookings/:id"
            page_title="Bookings"
            component={BookingsPage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/bookings"
            page_title="Bookings"
            component={BookingsPage}
          />

          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/messages/:id"
            page_title="Inbox"
            component={ReadMessagePage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/messages"
            page_title="Inbox"
            component={MessageInboxPage}
          />

          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/learn-more"
            page_title="Learn More"
            component={LearnMorePage}
          />
          <DashboardLayoutRoute
            isLoggedIn={isLoggedIn}
            user={currentUser}
            path="/dashboard/"
            page_title="Dashboard"
            component={DashboardPage}
          />
        </Switch>
      </Provider>
    )
  }
}

// export default App
