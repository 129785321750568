import React from 'react'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import AboutUs from '../../Components/AboutUs/AboutUs'
import PartnersAndMedia from '../../Components/PartnersAndMedia/PartnersAndMedia'
import Footer from '../../Components/Footer/Footer'
import { withRouter } from 'react-router'
import './AboutUsPage.scss'

class AboutUsPage extends React.Component {
  footerBackground = { background: '#F7F7F7' }

  componentDidMount() {
    document.body.classList.add('faq-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="About Us" link="/about-us" />
        <AboutUs />
        <PartnersAndMedia showPartnersAndMediaText={true} />
        <Footer footerContainerBackground={this.footerBackground} />
      </React.Fragment>
    )
  }
}

export default withRouter(AboutUsPage)
