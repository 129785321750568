import React from 'react'
import { withRouter } from 'react-router'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'

class ConditionOfUseComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  handleChange = async (e) => {
    var obj = {}
    obj[`${e.target.name}`] = e.target.value
    this.props.parentHandle(obj)
  }

  handleChangeCheckbox = async (e) => {
    var obj = {}
    obj[`${e.target.name}`] = e.target.checked
    this.props.parentHandle(obj)
  }

  render() {
    return (
      <>
        <div className="subcontent section-4">
          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Minimum Driver Age*:</b>
              </div>
              <div className="col-10">
                <input
                  type="number"
                  value={this.props.state.condition_min_driver_age || ''}
                  required="required"
                  className={
                    this.props.state.condition_min_driver_age_error
                      ? 'amount error'
                      : 'amount'
                  }
                  name="condition_min_driver_age"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Permitted Road Types:</b>
              </div>
              <div className="col-10">
                <select
                  value={this.props.state.condition_permitted_road_types || ''}
                  name="condition_permitted_road_types"
                  onChange={this.handleChange}
                >
                  <option>Any Road Types</option>
                  <option>Sealed/Surfaced Roads Only</option>
                </select>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Access/Permissions:</b>
              </div>
              <div className="col-10">
                <label>
                  <input
                    type="checkbox"
                    checked={
                      this.props.state.condition_beach_access ? 'checked' : ''
                    }
                    name="condition_beach_access"
                    value="1"
                    onChange={this.handleChangeCheckbox}
                  />{' '}
                  Beach Access{' '}
                </label>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-10">
                <label>
                  <input
                    type="checkbox"
                    checked={
                      this.props.state.condition_river_crossing ? 'checked' : ''
                    }
                    name="condition_river_crossing"
                    value="1"
                    onChange={this.handleChangeCheckbox}
                  />{' '}
                  River Crossings
                </label>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-10">
                <label>
                  <input
                    type="checkbox"
                    checked={
                      this.props.state.condition_outback_roads ? 'checked' : ''
                    }
                    name="condition_outback_roads"
                    value="1"
                    onChange={this.handleChangeCheckbox}
                  />{' '}
                  Outback Roads{' '}
                </label>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-10">
                <label>
                  <input
                    type="checkbox"
                    value="1"
                    checked={
                      this.props.state.vehicle_family_friendly ? 'checked' : ''
                    }
                    name="vehicle_family_friendly"
                    onChange={this.handleChangeCheckbox}
                  />{' '}
                  Family Friendly
                </label>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-10">
                <label>
                  <input
                    type="checkbox"
                    value="1"
                    checked={
                      this.props.state.vehicle_pet_friendly ? 'checked' : ''
                    }
                    name="vehicle_pet_friendly"
                    onChange={this.handleChangeCheckbox}
                  />{' '}
                  Pet Friendly
                </label>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Specific Terms:</b>
              </div>
              <div className="col-10">
                <textarea
                  name="condition_specific_terms"
                  onChange={this.handleChange}
                  defaultValue={this.props.state.condition_specific_terms || ''}
                ></textarea>
              </div>
            </div>
          </div>
        </div>{' '}
        {/*subcontent*/}
      </>
    )
  }
}

export default withRouter(ConditionOfUseComponent)
