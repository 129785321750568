const partnerAndMediaIcon1 =
  '//cdn.cnchire.com.au/caravanning-with-kids-logo.jpg'
const partnerAndMediaIcon2 = '//cdn.cnchire.com.au/cilinsurance.jpg'
const partnerAndMediaIcon3 = '//cdn.cnchire.com.au/campedia-logo-1.jpg'
const partnerAndMediaIcon4 = '//cdn.cnchire.com.au/savvy-logo.jpg'
const partnerAndMediaIcon5 = '//cdn.cnchire.com.au/website_logo-3.png'
const partnerAndMediaIcon6 = '//cdn.cnchire.com.au/tawk.jpg'
const partnerAndMediaIcon7 = '//cdn.cnchire.com.au/bb-caravan-repairs-logo.jpg'
const partnerAndMediaIcon8 = '//cdn.cnchire.com.au/caravanning-aus-logo-1.jpg'
const partnerAndMediaIcon9 = '//cdn.cnchire.com.au/everything-caravan-logo.jpg'
const partnerAndMediaIcon10 = '//cdn.cnchire.com.au/gorv-logo.jpg'
const partnerAndMediaIcon11 =
  '//cdn.cnchire.com.au/just-go-caravanning-logo.jpg'
const partnerAndMediaIcon12 = '//cdn.cnchire.com.au/learn-2-tow-logo-1.jpg'

const PartnersAndMedia = [
  {
    name: 'Caravaning With Kids',
    icon: partnerAndMediaIcon1,
    link: 'https://www.caravanningwithkids.com.au/',
  },
  {
    name: 'Cilinsurance',
    icon: partnerAndMediaIcon2,
    link: 'https://www.cilinsurance.com.au/',
  },
  {
    name: 'Campedia',
    icon: partnerAndMediaIcon3,
    link: 'http://campedia.com.au/',
  },
  {
    name: 'Savvy',
    icon: partnerAndMediaIcon4,
    link: 'https://www.savvylevel.com/',
  },
  {
    name: 'RV 4X4',
    icon: partnerAndMediaIcon5,
    link: 'https://www.rv4x4.net.au/',
  },
  {
    name: 'TAWK',
    icon: partnerAndMediaIcon6,
    link: 'https://www.travellingwithkids.com.au/',
  },
  {
    name: 'BB Caravan Repairs',
    icon: partnerAndMediaIcon7,
    link: 'http://www.caravanrepairsbrisbane.com/',
  },
  {
    name: 'Caravanning Aus',
    icon: partnerAndMediaIcon8,
    link: 'https://caravanningoz.com.au/',
  },
  {
    name: 'Everything Caravan',
    icon: partnerAndMediaIcon9,
    link: 'http://everythingcaravanandcamping.com/',
  },
  {
    name: 'Gorv',
    icon: partnerAndMediaIcon10,
    link: 'https://www.gorv.com.au/',
  },
  {
    name: 'Just Go Caravanning',
    icon: partnerAndMediaIcon11,
    link: 'http://www.justgocamping.com.au/',
  },
  {
    name: 'Learn 2 Tow',
    icon: partnerAndMediaIcon12,
    link: 'https://www.learn2tow.com.au/',
  },
]

export default PartnersAndMedia
