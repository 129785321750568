import Modal from 'react-bootstrap/Modal'
import React from 'react'
import * as bookingService from '../BookingService'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import './shared.scss'
import { formatCurrency } from 'shared/utils.js'

export default class BookingBondClaimPopup extends React.Component {
  constructor(props) {
    super(props)
    const date_of_return = new Date(moment().format('YYYY-MM-DD'))

    this.state = {
      booking: props.booking,
      showForm: false,
      date_of_return,
      total_claim_deduction: '',
      total_claim_deduction_display: '',
      item_cost_1: '',
      item_1: '',
      item_cost_2: '',
      item_2: '',
      item_cost_3: '',
      item_3: '',
      item_cost_4: '',
      item_4: '',
      item_cost_5: '',
      item_5: '',
      item_cost_6: '',
      item_6: '',
      item_cost_7: '',
      item_7: '',
      item_cost_8: '',
      item_8: '',
      item_cost_9: '',
      item_9: '',

      item_1_error: false,
      item_2_error: false,
      item_3_error: false,
      item_4_error: false,
      item_5_error: false,
      item_6_error: false,
      item_7_error: false,
      item_8_error: false,
      item_9_error: false,

      with_error: true,
    }
  }

  clickSubmit = async () => {
    let items = []
    for (let i = 1; i < 10; i++) {
      let item = this.state['item_' + i]
      let cost = this.state['item_cost_' + i]
      if (cost !== '') {
        items.push({ item, cost })
      }
    }

    let vehicle_name = this.state.booking.vehicleName
    let hirer_name =
      this.state.booking.hirerFirstName + ' ' + this.state.booking.hirerLastName
    let owner_name =
      this.state.booking.ownerFirstName + ' ' + this.state.booking.ownerLastName
    hirer_name = hirer_name.replace('null', '')
    owner_name = owner_name.replace('null', '')

    let params = {
      owner_name,
      vehicle_name,
      hirer_name,
      date_of_return: this.state.date_of_return,
      items: JSON.stringify(items),
      vehicle_id: this.state.booking.vehicleId,
      hirer_id: this.state.booking.hirerId,
      owner_id: this.state.booking.ownerId,
      booking_id: this.state.booking.id,
      total_claim_deduction: this.state.total_claim_deduction_display,
      status: 'bond-investigate',
    }

    await bookingService.updateBookingStatus(params)
    this.setState({ showForm: false })
    await localStorage.setItem(
      'message_flash',
      'Bond Claim has been submitted!'
    )
    window.location.href = '/dashboard/bookings'
  }

  openForm = async () => {
    this.setState({ showForm: true })
  }

  onSetState = async (obj) => {
    await this.setState(obj)

    let with_error = false

    //total
    let total = 0
    total =
      this.state.item_cost_1 !== ''
        ? parseInt(this.state.item_cost_1) + total
        : total
    total =
      this.state.item_cost_2 !== ''
        ? parseInt(this.state.item_cost_2) + total
        : total
    total =
      this.state.item_cost_3 !== ''
        ? parseInt(this.state.item_cost_3) + total
        : total
    total =
      this.state.item_cost_4 !== ''
        ? parseInt(this.state.item_cost_4) + total
        : total
    total =
      this.state.item_cost_5 !== ''
        ? parseInt(this.state.item_cost_5) + total
        : total
    total =
      this.state.item_cost_6 !== ''
        ? parseInt(this.state.item_cost_6) + total
        : total
    total =
      this.state.item_cost_7 !== ''
        ? parseInt(this.state.item_cost_7) + total
        : total
    total =
      this.state.item_cost_8 !== ''
        ? parseInt(this.state.item_cost_8) + total
        : total
    total =
      this.state.item_cost_9 !== ''
        ? parseInt(this.state.item_cost_9) + total
        : total

    //validation
    let item_1_error = false
    let item_2_error = false
    let item_3_error = false
    let item_4_error = false
    let item_5_error = false
    let item_6_error = false
    let item_7_error = false
    let item_8_error = false
    let item_9_error = false

    item_1_error =
      !(this.state.item_cost_1 === '' && this.state.item_1 === '') &&
      (this.state.item_cost_1 === '' || this.state.item_1 === '')
    item_2_error =
      !(this.state.item_cost_2 === '' && this.state.item_2 === '') &&
      (this.state.item_cost_2 === '' || this.state.item_2 === '')
    item_3_error =
      !(this.state.item_cost_3 === '' && this.state.item_3 === '') &&
      (this.state.item_cost_3 === '' || this.state.item_3 === '')
    item_4_error =
      !(this.state.item_cost_4 === '' && this.state.item_4 === '') &&
      (this.state.item_cost_4 === '' || this.state.item_4 === '')
    item_5_error =
      !(this.state.item_cost_5 === '' && this.state.item_5 === '') &&
      (this.state.item_cost_5 === '' || this.state.item_5 === '')
    item_6_error =
      !(this.state.item_cost_6 === '' && this.state.item_6 === '') &&
      (this.state.item_cost_6 === '' || this.state.item_6 === '')
    item_7_error =
      !(this.state.item_cost_7 === '' && this.state.item_7 === '') &&
      (this.state.item_cost_7 === '' || this.state.item_7 === '')
    item_8_error =
      !(this.state.item_cost_8 === '' && this.state.item_8 === '') &&
      (this.state.item_cost_8 === '' || this.state.item_8 === '')
    item_9_error =
      !(this.state.item_cost_9 === '' && this.state.item_9 === '') &&
      (this.state.item_cost_9 === '' || this.state.item_9 === '')

    with_error =
      item_1_error ||
      item_2_error ||
      item_3_error ||
      item_4_error ||
      item_5_error ||
      item_6_error ||
      item_7_error ||
      item_8_error ||
      item_9_error
        ? true
        : false

    if (!with_error && total === 0) {
      //wihout error from initial trap but with zero total?
      with_error = true
    }

    if (total > this.state.booking.bondAmount - 25) {
      //cannot go beyond the bond.
      with_error = true
    }

    this.setState({
      total_claim_deduction_display: total,
      item_1_error,
      item_2_error,
      item_3_error,
      item_4_error,
      item_5_error,
      item_6_error,
      item_7_error,
      item_8_error,
      item_9_error,
      with_error,
    })
  }

  form() {
    return (
      <>
        <button
          variant="primary"
          className="btn btn-danger"
          style={{ width: '200px' }}
          onClick={() => this.openForm()}
        >
          {' '}
          Withhold the Bond{' '}
        </button>

        <Modal
          show={this.state.showForm}
          onHide={() => this.setState({ showForm: false })}
          dialogClassName="bondclaim-modal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h2>BOND CLAIM DETAILS</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <div>
                <b>
                  OWNER CLEANING CHARGES SUMMARY GUIDE AS PER TERMS &amp;
                  CONDITIONS OF ACCOMMODATION
                </b>
                <ul className="notes-container">
                  <li>
                    $50 per hour for any additional cleaning over above normal
                    return cleaning (if left very dirty)
                  </li>
                  <li>
                    $300 min for smoking inside or any cigarette burns to indoor
                    or outdoor of caravan
                  </li>
                  <li>
                    Puncture repair at cost price, once having been repaired
                  </li>
                  <li>
                    Non return of items, Mirrors, Electric Brake Unit, Electrics
                    Cable adaptor
                  </li>
                  <li>
                    Items within Caravan listed on the inventory that are
                    broken, damaged or missing
                  </li>
                  <li>Damage to the Caravan both inside and outside</li>
                  <li>$200 for non emptying and/or cleaning of the Toilet</li>
                </ul>
              </div>

              <table border="1">
                <tbody>
                  <tr>
                    <td className="bold">Date Of Return Report</td>
                    <td>
                      <DatePicker
                        required
                        placeholderText="Date of return"
                        selected={this.state.date_of_return}
                        onChange={(date) =>
                          this.setState({ date_of_return: date })
                        }
                        dateFormat="yyyy-MM-dd"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="bold">Security Bond</td>{' '}
                    <td>{formatCurrency(this.state.booking.bondAmount)}</td>
                  </tr>
                  <tr>
                    <td className="bold">Caravan</td>{' '}
                    <td>{this.state.booking.vehicleName}</td>
                  </tr>
                  <tr>
                    <td className="bold">Name of Hirer</td>{' '}
                    <td>
                      {this.state.booking.hirerFirstName}{' '}
                      {this.state.booking.hirerLastName}{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="bold">Caravan Owners Name</td>
                    <td>
                      {this.state.booking.ownerFirstName}{' '}
                      {this.state.booking.ownerLastName}{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="bold">Total of Claim/deduction</td>{' '}
                    <td>
                      {formatCurrency(this.state.total_claim_deduction_display)}
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <p>
                To: Caravan and Camping Hire, please deduct the above amount
                from the Hirers bond and disperse to us forthwith for
                compensation for the below items caused by the hirer at return,
                we confirm that these items have been checked and witnessed:
              </p>

              <table border="1">
                <thead>
                  <tr>
                    <th>Itemised List of Bond Claim Items:</th>
                    <th>Cost Per Item:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={this.state.item_1_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_1}
                        onChange={(e) =>
                          this.onSetState({ item_1: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_1: e.target.value })
                        }
                        value={this.state.item_cost_1}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_2_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_2}
                        onChange={(e) =>
                          this.onSetState({ item_2: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_2: e.target.value })
                        }
                        value={this.state.item_cost_2}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_3_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_3}
                        onChange={(e) =>
                          this.onSetState({ item_3: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_3: e.target.value })
                        }
                        value={this.state.item_cost_3}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_4_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_4}
                        onChange={(e) =>
                          this.onSetState({ item_4: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_4: e.target.value })
                        }
                        value={this.state.item_cost_4}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_5_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_5}
                        onChange={(e) =>
                          this.onSetState({ item_5: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_5: e.target.value })
                        }
                        value={this.state.item_cost_5}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_6_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_6}
                        onChange={(e) =>
                          this.onSetState({ item_6: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_6: e.target.value })
                        }
                        value={this.state.item_cost_6}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_7_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_7}
                        onChange={(e) =>
                          this.onSetState({ item_7: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_7: e.target.value })
                        }
                        value={this.state.item_cost_7}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_8_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_8}
                        onChange={(e) =>
                          this.onSetState({ item_8: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_8: e.target.value })
                        }
                        value={this.state.item_cost_8}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr className={this.state.item_9_error ? 'error' : ''}>
                    <td>
                      <input
                        type="text"
                        value={this.state.item_9}
                        onChange={(e) =>
                          this.onSetState({ item_9: e.target.value })
                        }
                      />
                    </td>{' '}
                    <td>
                      <input
                        onChange={(e) =>
                          this.onSetState({ item_cost_9: e.target.value })
                        }
                        value={this.state.item_cost_9}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="bold">TOTAL BOND CLAIM</td>{' '}
                    <td>
                      {formatCurrency(this.state.total_claim_deduction_display)}{' '}
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <p>
                Caravan Owners Please note: Caravan and Camping Hire will
                forward any bond claim moneys if the above form is provided and
                can be proven should the hirer wish to dispute the claim, we
                inform the hirer to write to you directly via Caravan and
                Camping Hire.
              </p>

              <div className="pull-right">
                <button
                  className="btn btn-secondary"
                  onClick={() => this.setState({ showForm: false })}
                  style={{ float: 'right' }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => this.clickSubmit()}
                  style={{ float: 'right', marginRight: '10px' }}
                  disabled={this.state.with_error ? 'disabled' : null}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  render() {
    return <React.Fragment>{this.form()}</React.Fragment>
  }
}
