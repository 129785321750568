import { reducer as reduxFormReducer } from 'redux-form'
import { init } from '@rematch/core'
import selectPlugin from '@rematch/select'
import createLoadingPlugin from '@rematch/loading'
import persistPlugin from '@rematch/persist'

import user from './models/user'
import faq from './models/faq'
import news from './models/news'
import product from './models/product'
import vehicle from './models/vehicle'
import bookings from './models/bookings'
import messages from './models/messages'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem(_key) {
      return Promise.resolve()
    },
  }
}

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage()

const persistConfig = {
  key: 'persistplugin',
  whitelist: [
    'user',
    'faq',
    'news',
    'product',
    'vehicle',
    'bookings',
    'messages',
    'globalConfig',
  ], // persist these models
  throttle: 1000,
  version: 1,
  storage,
}

const store = init({
  redux: {
    reducers: {
      form: reduxFormReducer, // mounted under "form",
    },
  },
  models: {
    user,
    faq,
    news,
    product,
    vehicle,
    bookings,
    messages,
  },
  plugins: [
    selectPlugin(),
    createLoadingPlugin({}),
    persistPlugin(persistConfig),
  ],
})

export const { select } = store

export default store
