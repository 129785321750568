import React from 'react'
import { Icon } from 'semantic-ui-react'

import './NewsPost.scss'

const NewsPost = ({ news }) => {
  const splitDate = String(news.createdAt).split(' ')
  const date = `${splitDate[1]} ${splitDate[2]} ${splitDate[3]}`
  return (
    <React.Fragment>
      <div className="newsPostContainer">
        <div className="newsImageContainer">
          <img className="newsImage" src={news.imagePath} alt="" />
        </div>
        <div className="newsContent">
          <span className="newsContentDate">{date}</span>
          <span className="newsContentTitle">{news.title}</span>
          <span className="newsContentParagraph">{news.content}</span>
          <div className="newsContentSocicalMedia">
            <a
              href="https://www.facebook.com/caravanandcampinghire/"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon className="readMoreArrow" size="large" name="facebook f" />{' '}
            </a>
            <a
              href="https://twitter.com/caravancamphire/"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon className="readMoreArrow" size="large" name="twitter" />
            </a>

            <a
              href="/news"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon
                className="readMoreArrow"
                size="large"
                name="google plus g"
              />
            </a>

            <a
              href="/news"
              target={'_blank'}
              rel="noopener noreferrer"
              size="small"
              style={{ color: '#ff8c3f', textDecoration: 'none' }}
            >
              <Icon className="readMoreArrow" size="large" name="pinterest p" />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsPost
