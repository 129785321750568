import React from 'react'

import './SingleFAQ.scss'

const SingleFAQ = ({ faq }) => {
  return (
    <React.Fragment>
      <div className="singleFAQContainer">
        <div className="singleFAQContentBox">
          <span className="faqQuestion">{faq.faqQuestion}</span>
          <section
            className="faqAnswer"
            dangerouslySetInnerHTML={{ __html: faq.faqAnswer }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default SingleFAQ
