import React from 'react'

import './Membership.scss'
const securityIcon = '//cdn.cnchire.com.au/Security.png'
const licenseIcon = '//cdn.cnchire.com.au/License.png'
const lowerBadgeIcon = '//cdn.cnchire.com.au/lower-badge.jpg'
const assistanceIcon = '//cdn.cnchire.com.au/Assistance.png'
const supportTeamIcon = '//cdn.cnchire.com.au/Support team.png'

const Membership = () => {
  return (
    <React.Fragment>
      <div className="membershipContainer">
        <div className="lowerbadgeContainer">
          <img src={lowerBadgeIcon} alt="lower badge" />
        </div>

        <div className="membershipTitle">We Always Put Our Members First</div>
        <div className="membershipDesc">
          Why book with Caravan Camping Hire? We provide the following features
          when you hire a vehicle online.
        </div>
        <div className="membershipDesc">
          That means you can relax and enjoy your holiday with total peace of
          mind.
        </div>
        <div className="membershipBenefits">
          <div className="benefit">
            <img className="benefitIcon" alt="" src={securityIcon} />
            <span className="benefitText">Insurance Covering Both</span>
            <span className="benefitText">Owner and Hirer</span>
          </div>
          <div className="benefit">
            <img className="benefitIcon" alt="" src={licenseIcon} />
            <span className="benefitText">License Check On All Drivers</span>
          </div>
          <div className="benefit">
            <img className="benefitIcon" alt="" src={assistanceIcon} />
            <span className="benefitText">24/7 Rental Roadside Assistance</span>
          </div>
          <div className="benefit">
            <img className="benefitIcon" alt="" src={supportTeamIcon} />
            <span className="benefitText">Dedicated Customer</span>
            <span className="benefitText">Support Team</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Membership
