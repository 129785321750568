import React from 'react'
import { withRouter } from 'react-router'

import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import ConfirmBookingDetails from './components/ConfirmBooking/confirm-booking'
import Footer from 'Components/Footer/Footer'

class ConfirmBookingPage extends React.Component {
  footerBackground = { background: '#F7F7F7' }

  componentDidMount() {
    document.body.classList.add('confirm-booking-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Booking Details" link="/about-us" />
        <ConfirmBookingDetails />
        <Footer footerContainerBackground={this.footerBackground} />
      </React.Fragment>
    )
  }
}

export default withRouter(ConfirmBookingPage)
