import React from 'react'
import { withRouter } from 'react-router'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import ProductList from '../../Components/ProductList/ProductList'
import Footer from '../../Components/Footer/Footer'
import * as productService from '../../Services/product'
import './ProductPage.scss'

class ProductPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      products: false,
    }

    this.fetch()
  }

  async fetch() {
    const result = await productService.fetchProducts()
    const products = result.data

    console.log('products ', products)
    this.setState({ products })
  }

  componentDidMount() {
    document.body.classList.add('products-page')
  }

  render() {
    if (!this.state.products) {
      return <></>
    } else {
      return (
        <React.Fragment>
          <NavBar linkTextColor={{ color: '#000000' }} />
          <PageBanner title="RV, Breaking, & Towing Products" />
          <ProductList productList={this.state.products} />
          <Footer />
        </React.Fragment>
      )
    }
  }
}

export default withRouter(ProductPage)
