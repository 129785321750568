import React from 'react'
import { connect } from 'react-redux'
import store from '../../Redux/store'
import fp from 'lodash/fp'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import NewsPost from '../../Components/NewsPost/NewsPost'
import Footer from '../../Components/Footer/Footer'
import './NewsPostPage.scss'

class NewsPostPage extends React.Component {
  componentDidMount() {
    this.props.fetchNewsItem(this.props.match.params.id)
    document.body.classList.add('newspost-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="News Post" link="/news" />
        <NewsPost news={this.props.newsItem} />
        {/*<SubscriptionBanner />*/}
        <Footer />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchNewsItem: dispatch.news.fetchNewsItem,
})

const mapStateToProps = (state) => ({
  newsItem: store.select.news.currentNews(state),
})

export default fp.flow(connect(mapStateToProps, mapDispatchToProps))(
  NewsPostPage
)
