import React from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import './TransactionsPage.scss'
import * as transactionsService from './TransactionsService'
import { formatCurrency } from 'shared/utils.js'

class TransactionsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      default: this.props.user.userRole,
      screen: this.props.user.userRole,
      list: [],
    }

    this.fetchLatest()
  }

  async fetchLatest() {
    await transactionsService.fetch({}).then((result) => {
      console.log('DEBUGGING transaction list', result)

      if (result.success) {
        this.setState({ list: result.data })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  sendMessageHandler() {}

  render() {
    if (this.state.screen === 'Hirer') {
      return (
        <>
          <div className="transaction-container">
            <div className="payment-box-body">
              <table>
                <thead>
                  <tr>
                    <td>#</td>
                    <td>Booking #</td>
                    <td>Date</td>
                    <td>Amount</td>
                    <td>Description</td>
                    <td>Stripe Transaction ID</td>
                  </tr>
                </thead>

                <tbody>
                  {this.state.list.length > 0 &&
                    this.state.list.map((item, index) => {
                      return (
                        <tr
                          key={item.id}
                          className={index % 2 === 0 ? 'even' : 'odd'}
                        >
                          <td>{item.id}</td>
                          <td>
                            <a href={'/dashboard/bookings/' + item.bookingId}>
                              {item.bookingId}
                            </a>
                          </td>
                          <td>{moment(item.dateTransacted).format('LL')}</td>
                          <td>{formatCurrency(item.amount)}</td>
                          <td>{item.paymentType}</td>
                          <td>{item.stripeTransactionId}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )
    } else if (this.state.screen === 'Owner') {
      return <>Transactions</>
    } else if (this.state.screen === 'Admin') {
      return <>Transactions</>
    }
  }
}

export default withRouter(TransactionsPage)
