import React from 'react'
import { NotificationManager } from 'react-notifications'
import './PaymentsComponent.scss'
import * as paymentService from '../../Payments/PaymentsService'
import useAnalyticsEventTracker from 'Pages/App/useAnalyticsTracker'

export const NewCardPopup = (props) => {
  const tracker = useAnalyticsEventTracker('NewCard')
  return <NewCardPopupOld {...props} tracker={tracker} />
}
class NewCardPopupOld extends React.Component {
  constructor(props) {
    super(props)

    var d = new Date()
    var n = d.getFullYear()
    var i = 0

    const expiry_year_from = n
    const expiry_year_to = n + 10

    let expiry_year_options = []
    let expiry_month_options = []

    for (i = expiry_year_from; i <= expiry_year_to; i++) {
      expiry_year_options.push(i)
    }

    for (i = 1; i <= 12; i++) {
      expiry_month_options.push(i)
    }

    this.state = {
      months: [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      expiryMonth: 1,
      expiryYear: expiry_year_from,
      expiry_month_options,
      expiry_year_options,

      cardSelected: {},
      booked: {},
      expiry_year_from,
      expiry_year_to,

      step: 'browse',
      inputCardNumberError: false,
      inputCardNameError: false,
      inputBankNameError: false,
      inputCardExpiryError: false,
      inputSecurityCodeError: false,
      disabledSubmitButton: true,
      itemSelectedIndex: 0,
      inputCardExpiry: '',
      inputSecurityCode: '',

      cardProcessing: '',
      cardProcessingError: '',

      showAddPaymentDetailsForm: false,
      inputCardType: 'Visa',
      cardList: false,
    }
  }

  closeCardPopup = () => {
    this.props.tracker('closeCardPopup')
    this.props.closeCardPopup()
  }

  handleChange = (e) => {
    this.props.tracker('handleChange - ' + e.target.name, e.target.value)
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      function () {
        this.validateFields()
      }
    )
  }

  validateFields() {
    let params = {
      inputCardNumberError: false,
      inputCardNameError: false,
      inputBankNameError: false,
      inputCardExpiryError: false,
      inputSecurityCodeError: false,
      disabledSubmitButton: false,
    }

    if (
      !(this.state.inputCardNumber && this.state.inputCardNumber.length > 2)
    ) {
      params.inputCardNumberError = true
      params.disabledSubmitButton = true
    }

    if (!(this.state.inputCardName && this.state.inputCardName.length > 0)) {
      params.inputCardNameError = true
      params.disabledSubmitButton = true
    }

    let code = this.state.inputSecurityCode + ''

    if (!(code.length === 3 || code.length === 4)) {
      params.inputSecurityCodeError = true
      params.disabledSubmitButton = true
    }
    this.props.tracker('validateFields', params)
    console.log('is disabled??? ', params.disabledSubmitButton)
    this.setState(params)
  }

  async fetchLatest() {
    const params = {} // this.state.filterId ? { id: this.state.filterId }  : {};
    const result = await paymentService.fetchCards(params)
    if (result.success) {
      this.setState({ cardList: result.data, cardSelected: result.data[0] })
    }

    return result.data
  }

  addCardHandler = async () => {
    this.setState({
      cardProcessing: 'Please wait....',
      cardProcessingError: '',
    })
    const params = {
      card_number: this.state.inputCardNumber,
      name: this.state.inputCardName,
      card_type: this.state.inputCardType,
      expiry_year: this.state.expiryYear,
      expiry_month: this.state.expiryMonth,
      security: this.state.inputSecurityCode,
    }

    const result = await paymentService.addCard(params)
    this.setState({ cardProcessing: '' })

    if (result.success) {
      const cardData = await this.fetchLatest()
      NotificationManager.success('Successfully added your card', 'Success')
      this.props.closeCardPopupAfterComplete(cardData[0])
    } else {
      this.setState({ cardProcessingError: result.message })
    }
  }

  render() {
    return (
      <div className="PaymentPopup">
        <h1>New Payment Details</h1>

        {this.state.cardProcessing !== '' && (
          <div style={{ color: 'red' }}>{this.state.cardProcessing}</div>
        )}
        {this.state.cardProcessingError !== '' && (
          <div style={{ color: 'red' }}>{this.state.cardProcessingError}</div>
        )}

        <div className="row field-line">
          <div className="col-4">Card Number</div>
          <div className="col-8">
            <input
              className={
                this.state.inputCardNumberError
                  ? 'input-field error'
                  : 'input-field'
              }
              type="number"
              name="inputCardNumber"
              defaultValue={this.state.inputCardNumber}
              onChange={this.handleChange}
            />{' '}
          </div>
        </div>

        <div className="row  field-line">
          <div className="col-4">Name on the card</div>
          <div className="col-8">
            <input
              className={
                this.state.inputCardNameError
                  ? 'input-field error'
                  : 'input-field'
              }
              name="inputCardName"
              defaultValue={this.state.inputCardName}
              onChange={this.handleChange}
              type="text"
            />{' '}
          </div>
        </div>

        <div className="row  field-line">
          <div className="col-4">Card Type</div>
          <div className="col-8">
            <select
              className={
                this.state.inputCardTypeError
                  ? 'input-field error'
                  : 'input-field'
              }
              defaultValue={this.state.inputCardType}
              onChange={this.handleChange}
            >
              <option value="Visa">Visa</option>
              <option value="Master Card">Master Card</option>
              <option value="Discover Network">Discover Network</option>
              <option value="American Express">American Express</option>
            </select>{' '}
          </div>
        </div>

        <div className="row field-line">
          <div className="col-4">Card Expiry</div>
          <div className="col-8">
            <select
              name="expiryMonth"
              className="expMonth"
              defaultValue={this.state.expiryMonth}
              onChange={this.handleChange}
            >
              {this.state.expiry_month_options.length > 0 &&
                this.state.expiry_month_options.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {this.state.months[item]}
                    </option>
                  )
                })}
            </select>

            <select
              name="expiryYear"
              className="expYear"
              defaultValue={this.state.expiryYear}
              onChange={this.handleChange}
            >
              {this.state.expiry_year_options.length > 0 &&
                this.state.expiry_year_options.map((item, index) => {
                  return <option key={index}>{item}</option>
                })}
            </select>
          </div>
        </div>

        <div className="row field-line">
          <div className="col-4">3-4 Digit Security Code </div>
          <div className="col-8">
            <input
              className={
                this.state.inputSecurityCodeError
                  ? 'input-field error'
                  : 'input-field'
              }
              type="number"
              name="inputSecurityCode"
              defaultValue={this.state.inputSecurityCode}
              onChange={this.handleChange}
            />{' '}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button
              disabled={this.state.disabledSubmitButton}
              onClick={() => this.addCardHandler()}
              className="btn btn-primary"
            >
              Add Card
            </button>
            &nbsp;
            <button
              className="btn btn-secondary"
              onClick={() => this.closeCardPopup()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }
}
