import React from 'react'
import Rating from '@material-ui/lab/Rating'

import './Testimonial.scss'

const Testimonial = ({ id, name, location, message, rating }) => {
  const className = `testimonialCard${id}`

  return (
    <React.Fragment>
      <div className={className}>
        <Rating
          size="small"
          className="ratingContainer"
          name="read-only"
          value={rating}
          readOnly
        />
        <div className="messageBox">{message}</div>
        <div className="nameBox">{`- ${name}`}</div>
        <div className="locationBox">{`- ${location}`}</div>
      </div>
    </React.Fragment>
  )
}

export default Testimonial
