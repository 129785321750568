import fp from 'lodash/fp'

import * as newsService from '../../Services/news'

const defaultState = {
  all: {},
  currentNews: {},
}

export default {
  state: defaultState,

  reducers: {
    getNews(state, payload) {
      return fp.merge(state, { all: payload })
    },
    getNewsItem(state, newsInfo) {
      return fp.merge(state, {
        currentNews: newsInfo,
      })
    },
  },

  effects: {
    async fetchNews() {
      const newsList = await newsService.fetchNews()
      this.getNews(newsList)
    },
    async fetchNewsItem(id) {
      const newsInfo = await newsService.fetchNewsItem(id)
      this.getNewsItem(newsInfo.data[0])
    },
  },

  selectors: (slice, createSelector) => ({
    all() {
      return createSelector(slice, fp.get('all'))
    },
    allValues() {
      return createSelector(this.all, fp.values)
    },
    currentNews() {
      return createSelector(slice, fp.get('currentNews'))
    },
  }),
}
