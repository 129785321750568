import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const statusFailed = 'times-circle'
export const statusUnpaid = 'hourglass-start'
export const statusPartiallyPaid = 'exclamation-triangle'
export const statusOnHold = 'hand-paper'
export const statusPaid = 'money-check-alt'
export const statusOnHire = 'calendar-alt'
export const statusPendingInspection = 'search'
export const statusRefundBond = 'undo'
export const statusWithholdBond = 'redo'
export const statusBondRefunded = 'check-square'
export const statusBondPartiallyRefunded = 'share-square'
export const statusBondWithheld = 'hand-holding-usd'
export const statusBondFullyRefunded = 'sync-alt'
export const statusCancelled = 'ban'

export const getIcon = (status) => {
  switch (status) {
    case 'failed':
      return statusFailed
    case 'unpaid':
    case 'request-approval':
      return statusUnpaid
    case 'partial-payment':
      return statusPartiallyPaid
    case 'on-hold':
      return statusOnHold
    case 'processing':
    case 'paid':
      return statusPaid
    case 'onhire':
      return statusOnHire
    case 'bond-investigate':
      return statusPendingInspection
    case 'refund-bond':
      return statusRefundBond
    case 'withhold-bond':
      return statusWithholdBond
    case 'fully-refunded':
    case 'bond-fully-refunded':
      return statusBondFullyRefunded
    case 'partially-refunded':
    case 'bond-partially-refunded':
      return statusBondPartiallyRefunded
    case 'refunded':
    case 'bond-refunded':
      return statusBondRefunded
    case 'widthheld':
    case 'bond-widthheld':
      return statusBondWithheld
    case 'cancelled':
      return statusCancelled
    default:
      return ''
  }
}

export const statusTitle = (status) => {
  return status.replace('-', ' ').replace('partial payment', 'partially paid')
}

export const statusClass = (program_status) => {
  let status = program_status
  return status.replace(' ', '-')
}

export default class StatusIconText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let status = this.props.status.replace('-', ' ')

    switch (this.props.status) {
      case 'partial-payment':
        status = 'Partially Paid'
        break
      case 'onhire':
        status = 'On Hire'
        break
      default:
        const str = this.props.status.replace('-', ' ')
        status = str.charAt(0).toUpperCase() + str.slice(1)
    }

    return (
      <span
        className={'status status-text ' + statusClass(this.props.status)}
        title={statusTitle(this.props.status)}
      >
        <div className="row">
          <div className="col-3">
            <FontAwesomeIcon icon={getIcon(statusClass(this.props.status))} />{' '}
          </div>
          <div className="col-9">
            <span>{status}</span>
          </div>
        </div>
      </span>
    )
  }
}
