import React from 'react'
import { withRouter } from 'react-router'
import './VehicleListPage.scss'
import Rating from '@material-ui/lab/Rating'

class ThumbnailViewComponent extends React.Component {
  async draftVehicle(item) {
    this.props.draftVehicle(item)
  }

  componentDidMount() {
    document.body.classList.add('dashboard-vehicle-thumbnail-page')
  }

  render() {
    return (
      <>
        <div className="row">
          {this.props.list.length > 0 &&
            this.props.list
              .filter((x) => x.show === true)
              .map((item, index) => {
                let images = item.images ? JSON.parse(item.images) : []
                let thumb = 'https://cdn.cnchire.com.au/c6.png'
                if (item.thumbnail !== '') {
                  thumb = item.thumbnail
                } else if (images[0]) {
                  thumb = images[0].image
                }

                return (
                  <div key={item.id} className="col-3">
                    <div className="item-box">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: 'url(' + thumb + ')' }}
                      ></div>
                      <div className="item-box-content">
                        <div className="title">
                          <span className="oneline">{item.vehicleName}</span>

                          {!item.isApproved && (
                            <div className="pending-approval-status">
                              pending approval
                            </div>
                          )}

                          {item.activeBooked > 0 && (
                            <div
                              className="status"
                              title={'Active Booking: ' + item.activeBooked}
                            >
                              Booking: {item.activeBooked}{' '}
                            </div>
                          )}
                        </div>

                        <div className="content">{item.description}</div>
                        <div className="content">
                          <Rating
                            name="read-only"
                            value={item.score}
                            size="large"
                            style={{ color: '#ffc107' }}
                            readOnly
                          />
                        </div>

                        <div className="rate">${item.pricePerDay} per day</div>
                        <div className="buttons">
                          {/* ( this.props.role === 'Admin' || this.props.role === 'Owner' ) && ( 
                                            <button className="btn draft" onClick={() => this.draftVehicle(item) }>Draft</button>  )*/}

                          <button
                            className="btn"
                            onClick={() =>
                              (window.location.href =
                                '/dashboard/vehicles/' + item.id)
                            }
                          >
                            View
                          </button>
                          <button
                            className="btn"
                            onClick={() =>
                              (window.location.href =
                                '/dashboard/vehicle/update/' + item.id)
                            }
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
        </div>
      </>
    )
  }
}

export default withRouter(ThumbnailViewComponent)
