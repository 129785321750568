import React from 'react'
import * as userService from '../../../Services/user'
import { withRouter } from 'react-router'
import './ProfilePage.scss'
import queryString from 'query-string'

import SendPrivateMessageComponent from './components/SendPrivateMessage/SendPrivateMessage'
import ViewProfileComponent from './components/ViewProfile/ViewProfile'
import UpdateProfileComponent from './components/UpdateProfile/UpdateProfile'

class ProfilePage extends React.Component {
  constructor(props) {
    super(props)

    let parsed = queryString.parse(this.props.location.search)
    let { id } = this.props.match.params
    let default_screen = ''

    if (id.search('-message') > 0) {
      default_screen = 'send-private-message'
      id = id.replace('-message', '')
    } else {
      default_screen = 'user'
    }

    this.state = {
      screen: default_screen,
      parsed,
      update: false,
      me: this.props.user,
      user: false,
      inputSubject: '',
      inputMessage: '',
      canUpdate: false,
    }

    this.fetchLatest(id)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  componentDidMount() {
    document.body.classList.add('dashboard-profile-page')
  }

  fetchLatest(id) {
    userService.fetchUser(id).then((result) => {
      console.log('fetch profile user ----> ', result.data)
      if (result.success) {
        let user = result.data
        let canUpdate =
          this.state.me.id === user.id || this.state.me.userRole === 'Admin'
            ? true
            : false //user-account-owner or admin
        let update = (this.state.parsed.update || false) && canUpdate
        this.setState({ user, canUpdate, update })
      }
    })
  }

  clickCancel() {
    this.setState({ update: false, screen: 'user' })
  }

  render() {
    if (this.state.screen === 'send-private-message') {
      return (
        <SendPrivateMessageComponent
          user={this.state.user}
          clickCancel={() => this.clickCancel()}
        ></SendPrivateMessageComponent>
      )
    } else if (
      this.state.screen === 'user' &&
      this.state.user &&
      this.state.user.id > 0
    ) {
      return (
        <div className="user-container">
          <div className="rows">
            <div className="col-12">
              <div style={{ display: 'none' }}>
                {this.state.canUpdate ? (
                  <>
                    {this.state.update ? (
                      <button
                        className="send-private-btn btn btn-secondary pull-right"
                        onClick={() =>
                          (window.location.href =
                            '/dashboard/user/' + this.state.user.id + '')
                        }
                      >
                        Preview Profile
                      </button>
                    ) : (
                      <button
                        className="send-private-btn btn btn-primary pull-right"
                        onClick={() =>
                          (window.location.href =
                            '/dashboard/user/' +
                            this.state.user.id +
                            '?update=1')
                        }
                      >
                        Update Profile
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="send-private-btn btn btn-primary pull-right"
                    onClick={() =>
                      this.setState({ screen: 'send-private-message' })
                    }
                  >
                    Send Private Message
                  </button>
                )}
              </div>

              {this.state.update ? (
                <UpdateProfileComponent
                  user={this.state.user}
                  me={this.props.user}
                  clickCancel={() => this.clickCancel()}
                ></UpdateProfileComponent>
              ) : (
                <ViewProfileComponent
                  user={this.state.user}
                  me={this.props.user}
                ></ViewProfileComponent>
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return <>...</>
    }
  }
}

export default withRouter(ProfilePage)
