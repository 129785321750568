import React from 'react'
import { connect } from 'react-redux'
import store from '../../Redux/store'
import fp from 'lodash/fp'

import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import AllVehicles from 'Components/AllVehicles/AllVehicles'
import Footer from 'Components/Footer/Footer'

import './VehiclesByTypePage.scss'

const vehicleTypesList = {
  'camper-trailer': 'Camper Trailer',
  campervan: 'Campervan',
  caravan: 'Caravan',
  expander: 'Expander',
  motorhome: 'Motorhome',
  'pop-top': 'Pop Top',
  'toy-hauler': 'Toy Hauler',
  'motorhome-class-a': 'Motorhome Class A',
  'motorhome-class-c': 'Motorhome Class C',
}

class VehiclesByTypePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicleType: vehicleTypesList[this.props.match.params.vehicleType],
    }
  }

  componentDidMount() {
    document.body.classList.add('vehicle-by-type-page')
    this.props.fetchVehicleByType(this.state.vehicleType)
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title={this.state.vehicleType} link="/vehicles" />
        <AllVehicles
          vehicleList={this.props.vehicleList}
          title={`Search Our Large Range Of ${this.state.vehicleType}'s Available For Hire`}
          vehicleType={this.state.vehicleType}
        />
        <Footer />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchVehicleByType: dispatch.vehicle.fetchVehicleByType,
})

const mapStateToProps = (state) => ({
  vehicleList: store.select.vehicle.vehiclesByType(state),
})

export default fp.flow(connect(mapStateToProps, mapDispatchToProps))(
  VehiclesByTypePage
)
