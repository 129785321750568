import axios from 'axios'
import { api_request } from './helpers'

export const fetchUser = async (id) => {
  // const result = await axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/users/getinfo/${id}` }).get();
  const result = await api_request('get', `/auth/profile/${id}`, {}, true)
  return result || []
}

// export const fetchUsers = async () => {
//   const users = await axios
//     .create({ baseURL: `${process.env.REACT_APP_API_URL}/admin/users` })
//     .get();
//   return fp.flow(fp.getOr({}, "data"), camelizeKeys)(users);
// };

export const postLogin = async (payload) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/login`, payload)
      .then((response) => response)
  } catch (e) {
    console.log(`Login Error: ${e}`)
    return e
  }
}

export const createUser = async (payload) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/admin/users/create`, payload)
      .then((response) => response)
  } catch (e) {
    console.log(`Login Error: ${e}`)
    return e
  }
}
