import React from 'react'
import { Icon, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import store from '../../Redux/store'
import fp from 'lodash/fp'

import './Login.scss'
import queryString from 'query-string'
const loginImage = '//cdn.cnchire.com.au/loginImage.png'

class Login extends React.Component {
  message = ''

  constructor(props) {
    super(props)
    this.state = {
      isProcessing: false,
      username: '',
      password: '',
      invalidLogin: false,
    }

    const parsed = queryString.parse(props.location.search)
    this.message = parsed.message
    this.redirectUrl = parsed.redirectUrl
    console.log('message? --->', this.message)
  }

  handleClick(event) {
    event.preventDefault()
    this.setState({ isProcessing: true })

    const payload = {
      email: this.state.username,
      password: this.state.password,
    }

    this.props.postLogin(payload).then((response) => {
      console.log('postLogin', response)
      if (response.status === 200) {
        let lastVehicle = localStorage.getItem('last_vehicle_settings')

        if (lastVehicle) {
          let lastVehicleSettings = JSON.parse(lastVehicle) || {}

          if (
            lastVehicleSettings.lastLink &&
            lastVehicleSettings.isRegistered
          ) {
            window.location = lastVehicleSettings.lastLink
            return false
          }
        }
        if (this.redirectUrl) {
          window.location.href = this.redirectUrl
        } else {
          window.location.href = '/dashboard'
        }
      } else {
        this.setState({ invalidLogin: true })
        this.setState({ isProcessing: false })
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="loginContainer">
          <div className="imageFormContainer">
            <img className="loginImageIcon" alt="" src={loginImage} />

            <form
              className="loginFormContainer"
              onSubmit={(event) => this.handleClick(event)}
            >
              {this.message !== '' ? (
                <div className="loginErrorMessage">{this.message}</div>
              ) : (
                ''
              )}
              <div className="loginAccountTitle">Login Account</div>

              {this.state.invalidLogin && (
                <div className="loginErrorMessage">Invalid Email/Password.</div>
              )}

              <Input
                className="loginInputBox"
                iconPosition="left"
                placeholder="Email"
                onChange={(event) =>
                  this.setState({ username: event.target.value })
                }
              >
                <Icon name="user" />
                <input />
              </Input>
              <Input
                className="loginInputBox"
                iconPosition="left"
                placeholder="Password"
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
              >
                <Icon name="lock" />
                <input type="password" />
              </Input>
              <div className="functionContainer">
                <div className="ui checkbox rememberContainer">
                  <input type="checkbox" name="rememberMe" />
                  <label className="functionText">Remember</label>
                </div>
                <div className="forgetPasswordBox">
                  <Link to="/forgot-password" className="functionText">
                    Forgot Password
                  </Link>
                </div>
              </div>

              {this.state.isProcessing ? (
                <button className="loginSubmitButtonWait" type="button">
                  <span className="loginSubmitButtonLabel">Please Wait...</span>
                </button>
              ) : (
                <button className="loginSubmitButton" type="submit">
                  <span className="loginSubmitButtonLabel">Login</span>
                </button>
              )}

              <div className="signUpDetails">
                <span className="singUpDetailsText">
                  Don't have an account?
                </span>
                <span className="singUpDetailsText">
                  Sign Up as{' '}
                  <Link to="/register?type=owner" className="signUpDetailsLink">
                    Owner
                  </Link>
                  {' or '}
                  <Link to="/register?type=hirer" className="signUpDetailsLink">
                    Hirer
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// export default Login;

const mapDispatchToProps = (dispatch) => ({
  postLogin: dispatch.user.postLogin,
})

const mapStateToProps = (state) => ({
  users: store.select.user.allValues(state),
})

// export default fp.flow(connect(mapStateToProps, mapDispatchToProps))(Login);
export default withRouter(
  fp.flow(connect(mapStateToProps, mapDispatchToProps))(Login)
)
