import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import './HowDoesItWork.scss'
const image = '//cdn.cnchire.com.au/cnchire-news-story 1.png'
const hdiwBody1Image = '//cdn.cnchire.com.au/hdiwBody1.png'

const HowDoesItWork = () => {
  const [showButton1, setShowButton1] = useState(false)
  const [showButton2, setShowButton2] = useState(false)
  const [showButton3, setShowButton3] = useState(false)
  const [showButton4, setShowButton4] = useState(false)
  const [showButton5, setShowButton5] = useState(false)
  const [showButton6, setShowButton6] = useState(false)
  const [showButton7, setShowButton7] = useState(false)
  const [showButton8, setShowButton8] = useState(false)
  const [showButton9, setShowButton9] = useState(false)
  const [showButton10, setShowButton10] = useState(false)
  const [showButton11, setShowButton11] = useState(false)

  return (
    <React.Fragment>
      <div className="howDoesItWorkContainer">
        <div className="howDoesItWorkTitle">
          How Does It All Work For Owners
        </div>
        <img className="howDoesItWorkImage" alt="" src={image} />
        <div className="howDoesItWorkBox">
          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton1(!showButton1)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'HOW HIRING OUT YOURS ALL WORKS!'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton1 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>
          {showButton1 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'We are 100% Australian founded, owned and operated and we are the best in the business for Peer 2 Peer Economy Sharing for privately owned Caravan Hire, Camper Trailer Hire and Motorhome/Campervan Hiring all over Australia.  We are known as the most trusted, safest and reliable platform for Aussie Families to Hire Out their own vehicles who are earning $1000s every year BUT also for allowing 1000s of Aussie and Overseas families to Hire a vehicle so they can head off and experience the wonders of Caravan and Camping holidays across our amazing Australia without having to own their own Caravan/RV.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We are paying back tens of thousands of dollars to individual owners (our Hire Partners) every year just for Hiring our their Caravans, Campers and Motorhomes with Caravan and Camping Hire!! A large number of our Owners now have 3, 4 and 5 Hire Vehicles that they Hire Out with us all year round.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We are also helping to inject millions of dollars each year back into the Australian Caravan and Camping Tourism Industry which we a super proud about!'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'These Hire Partners below made over $10,000 net Hire Income in just 6 months'
                }
              </span>
              <img src={hdiwBody1Image} alt="" />
              <span className="hdiwBodyText">
                {
                  'One of our main business objectives is to help Australian Caravan, Camper & Motorhome owners in all states and locations earn extra money for their families by hiring out their Vehicles when they are otherwise just sitting around collecting dust, wasting space and not being used at all (which is exactly what around 80% to 90% of privately owned Caravans/RVs across Australia do!)'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We work extremely hard to ensure all of our Hire Partners/Owners feel 100% confident, reassured, well informed and safe about the entire process that involves seeing their beautiful mobile homes being hired out by suitably vetted and approved Hirers and potentially taken anywhere around Australia.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We provide the absolute best and unmatched customer service and customer care – you won’t find better because we are available 24/7 to handle all of the following for you:'
                }
              </span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>
                    {
                      'Booking Enquiries, all back and forward communications with Hirers'
                    }
                  </li>
                  <li>{'Paperwork and Hire Contracts'}</li>
                  <li>{'Bond payments and refunds'}</li>
                  <li>{'Hire fees payments'}</li>
                  <li>{'Quotations'}</li>
                  <li>
                    {
                      'Regular social media and emailed communications and much more for you'
                    }
                  </li>
                  <li>
                    {
                      'We are NOT ‘new kids on the block’ in this industry plus we own/hire out our own Vehicles'
                    }
                  </li>
                </ul>
              </span>
              <span className="hdiwBodyText">
                {
                  'We are highly experienced in the Caravanning/RV industry and are always available to chat with you 24/7 when possible and we will respond to your calls, emails or txts generally within 30 minutes.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'Our business is operated by real people that care about the industry and our community, we are not just by a flash website!'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We have absolutely all bases covered for your own peace of mind and have all the answers for your Insurance Cover, Towing & Hirer vetting process questions and much more'
                }
              </span>
            </div>
          )}
          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton2(!showButton2)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'HIRER VETTING PROCESS & SECURITY CHECKS'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton2 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton2 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'Before any Hire Vehicle is hired out to anyone, the following security measures are taken to verify the Hirers identification and credibility.'
                }
              </span>
              <span className="hdiwBodyText">
                {'We require from each Hirer:'}
              </span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>{'Copies of Drivers License'}</li>
                  <li>
                    {
                      'Copies of the towing vehicle comprehensive insurance policy'
                    }
                  </li>
                  <li>
                    {
                      'Proof of age to be 25 years or above (Some owners allow 21 years or above)'
                    }
                  </li>
                  <li>
                    {
                      'Checking of Towing vehicle tow hitch set up and towing capacity to ensure suitability'
                    }
                  </li>
                  <li>
                    {
                      '$500 to $3000 Security Bond payments depending on the type of Vehicle Hired'
                    }
                  </li>
                  <li>{'Digitally signed and completed Hire Contract'}</li>
                </ul>
              </span>

              <span className="hdiwBodyText">
                {'AND for added peace of mind:'}
              </span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>
                    {
                      'NO driver under the age of 25 is permitted to tow/hire any of our Caravans/RVs unless you as the owner allows this in your listing set up'
                    }
                  </li>
                  <li>
                    {
                      'All Hirers MUST have valid insurance on their towing vehicle'
                    }
                  </li>
                </ul>
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton3(!showButton3)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'WHY DO HIRERS PAY A BOND?'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton3 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton3 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'The $500 to $3000 BONDS that hirers pay upfront protect and cover the insurance excess required for any possible insurance claim that would need to be made if ever that unfortunate situation was to arise. We have a brilliant track record with regards to this not happening!'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'In the case of an Insurance Claim having to be made, the Hire Partner/Owner would be paid the Excess amount from the Hirers bond meaning that Hire Partner/Owner is never be out of pocket in this situation.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'The Bond also covers the cost of any minor repairs resulting from Hire outs if required.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We also have strict ‘cleaning inside and outside’ policies in our terms and conditions that state that the Hirers MUST ensure that the Vehicles are cleaned properly inside and outside before being returned otherwise they will forfeit portions of their bond for various non-cleaning compliance as per the binding Hire Contract'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'N.B – ALL Awning Related Damages ARE NOT covered by insurance claims and are paid for directly from the Bonds'
                }
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton4(!showButton4)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'QUALITY OF OUR HIRERS'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton4 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton4 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'The quality of our hirers has always been of the highest standard.  Our Hirers are usually trying before they buy their now RVs, have owned RVs before but have either sold up and looking for another model to upgrade to, or have downsized from a family van to one that is more suitable for a couple.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'So it is certainly worth mentioning to you that we attract a ‘standard’ of Hirer that can:'
                }
              </span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>
                    {
                      'Afford to pay up $200 or more per day to hire a Caravan/RV'
                    }
                  </li>
                  <li>{'Pay the required $500 to $3000 bond up front'}</li>
                  <li>
                    {'Provide suitably rated and reliable towing vehicles'}
                  </li>
                  <li>
                    {
                      'Show respect and care for all Hire Partners Hire Vehicles AND'
                    }
                  </li>
                  <li>
                    {
                      'Be highly appreciative couples or families who are wanting an amazing caravanning and camping holiday experience for much less'
                    }
                  </li>
                </ul>
              </span>
              <span className="hdiwBodyText">
                {
                  'We pride ourselves on our customer service and our number one priority is always YOU! We want to make your experiences with us, and our hirers as streamlined and stress free as possible. So here is what we do for you:'
                }
              </span>
              <span className="hdiwBodyText">
                <ol className="hdiwBodyUL">
                  <li>
                    {
                      'After you sign up and list your Vehicle, we edit, publish and promote your Caravan/RV online on a number of social media sites including Facebook, Instagram, Pinterest, Twitter and of course our own website.'
                    }
                  </li>
                  <li>
                    {
                      'We take care of all public booking enquiries, handle payments, bonds, money transfers to owners etc.'
                    }
                  </li>
                  <li>
                    {
                      'We help Caravan/RVowners source Portable Electric Brake units to purchase so they can be available for Hirers to book/use when towing'
                    }
                  </li>
                  <li>
                    {
                      'We collect copies of driver’s licenses, copies of towing vehicle details, insurance policy details and also complete vehicle registration checks to ensure validity'
                    }
                  </li>
                  <li>
                    {
                      'We handle all paperwork and contracts and keep in very close contact with all Caravan/RV owners on a regular basis about all enquiries and potential bookings etc.'
                    }
                  </li>
                  <li>
                    {
                      'We do absolutely everything possible to help Owners feel confident, safe and happy about hiring out their RVs'
                    }
                  </li>
                </ol>
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton5(!showButton5)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'WHAT ARE YOU REQUIRED TO DO FOR US?!'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton5 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton5 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'In order for us to get your Hire Vehicle listed and ready for people to view online to get bookings.'
                }
              </span>
              <span className="hdiwBodyText">
                <ol className="hdiwBodyUL">
                  <li>
                    {'Click “Sign Up” or “List My Vehicle” on the Home Page'}
                  </li>
                  <li>
                    {
                      'Complete the Owner Registration process and then complete Your Product/Listing process'
                    }
                  </li>
                  <li>
                    {
                      'Ensure your Hire Vehicle is well presented and readily available for potential hirers to view if required'
                    }
                  </li>
                  <li>
                    {
                      'Always ensure that your Hire Vehicle is registered, insured and kept clean and hygienic for all Hirers'
                    }
                  </li>
                  <li>
                    {
                      'Supply all standard home away from home cooking and eating equipment to be available as part of the hire packages where possible'
                    }
                  </li>
                  <li>
                    {
                      'ALL Hire Partners are asked to assist with promoting and advertising of their Hire Vehicles being available for Hire in their own local areas and states through their own social media channels. This helps so many more people know you have something for hire!'
                    }
                  </li>
                  <li>
                    {
                      'By this we mean share any ads that we post about your Hire units on Facebook etc, talk about it with others and raise the awareness of how Caravan and Camping Hire is helping Aussie families, both Hirers and Hire Partners'
                    }
                  </li>
                  <li>
                    {
                      'The Hire Partners are required to be the ones to meet and greet the hirers and show them how to set up, operate and pack down your own Hire Vehicles. We have Hire Vehicles all over Australia listed with us so we are unable to provide this type of service.'
                    }
                  </li>
                </ol>
              </span>
              <span className="hdiwBodyText">
                {
                  'We do all of the above with our own personally owned caravans, so we cannot advocate our processes and our service enough and how easy it is to earn a quite a lot of extra money on the side from hiring out your own RVs'
                }
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton6(!showButton6)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'SAMPLE LIST OF FEATURES/INCLUSIONS REQUIRED'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton6 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton6 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {'An example of what you would provide is shown below.'}
              </span>
              <span className="hdiwBodyText">{'Bob Brown – 0411 111 111'}</span>
              <span className="hdiwBodyText">
                {'6 Caravan Street, Caravan City VIC, 3805'}
              </span>
              <span className="hdiwBodyText">{'2012 Coromal F400 Camper'}</span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>{'Length – 15ft'}</li>
                  <li>{'Width – 8ft'}</li>
                  <li>{'Tare – 1150'}</li>
                  <li>{'ATM- 1540'}</li>
                  <li>{'Tow Ball Down Weight 120 from factory'}</li>
                </ul>
              </span>
              <span className="hdiwBodyText">{'Standard inclusions are:'}</span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>{'4 berth'}</li>
                  <li>{'1 x Queen Bed'}</li>
                  <li>{'1 x Double Bed'}</li>
                  <li>{'Roll Out awning'}</li>
                  <li>{'1 x Outdoor Table'}</li>
                  <li>{'4 x Camping Chairs'}</li>
                  <li>{'Bagged bed fly’s'}</li>
                  <li>{'118L 12v fridge'}</li>
                  <li>{'4bnr cooktop with grill'}</li>
                  <li>{'All cutlery, crockery & cookware'}</li>
                  <li>{'Microwave'}</li>
                  <li>{'200w solar panel & 2x 100ah battery’s'}</li>
                  <li>{'Radio sound system inside/ outside speakers'}</li>
                  <li>{'180l water storage'}</li>
                  <li>{'Gas/electric hot water system'}</li>
                  <li>{'12v water pump'}</li>
                  <li>{'External 12v outlet & External gas bayonet point'}</li>
                  <li>{'2 burner flat plate BBQ'}</li>
                </ul>
              </span>
              <span className="hdiwBodyText">
                {
                  'The van is setup to be self sufficient without power, which includes:'
                }
              </span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>{'2x100ah batteries'}</li>
                  <li>{'200watt solar'}</li>
                  <li>{'118l 12v fridge'}</li>
                  <li>{'180l water storage'}</li>
                  <li>{'External shower'}</li>
                </ul>
              </span>
              <span className="hdiwBodyText">
                {'Additional items available to hire are:'}
              </span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>{'Fully enclosed Annexe $100 per hire'}</li>
                  <li>{'19” TV/DVD unit $50 per hire'}</li>
                </ul>
              </span>
              <span className="hdiwBodyText">{'N.B:'}</span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>{'Single Axle Annex $100 per hire out to Owner'}</li>
                  <li>{'Double Axle Annex $150 per hire out to Owner'}</li>
                </ul>
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton7(!showButton7)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'ALL THE INSURANCE COVER DETAILS FOR YOUR HIRE VEHICLES'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton7 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton7 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'We have an outstanding and ongoing business affiliation with a very professional and supportive Australian Insurance Provider.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'AUS Insurance Services (AIS) allow Owners to have full “Hire Out” cover for their valuable and beautiful Vehicles. You can have both your standard Insurance cover policy with them as well as your ‘Hire Out’ cover policies'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'AUS Insurance Services (AIS) have designed and provide custom insurance packages exclusively for our CnC Hire owners which is based around a “365 x Day of the year Hire Out Cover’ BUT they also have optional 24/7 Roadside Assistance cover that can be included in your total Insurance package as well'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'Our Contacts at AIS are Kate or Jarred and please reference Our exclusive AIS referral code “AWS” to receive the best service and support. They are fantastic!'
                }
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton8(!showButton8)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'HIRE PARTNERS/OWNERS HIRE INCOME PAYMENTS'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton8 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton8 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'When your Hire Vehicle gets booked, confirmed and paid for by the Hirers, we then deposit your net Hire Income payment into your designated bank account within 48 Hours from when your Hire booking commenced. Payments are not made to owners until the Hirers have come and picked up the Hire Vehicles and everything is approved by the owners.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'So if you have a longer Hire Out you get your $1000s at the start of the Hire Out not at the end of the booking period.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'Once new Owners sign up and list their Hire Vehicles they are then emailed a comprehensive guide to how to Navigate the Owners dashboard on the website and much more.'
                }
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton9(!showButton9)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'MEMBERSHIP FEE & COMMISSION RATES'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton9 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton9 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {'IT IS FREE TO LIST YOUR HIRE VEHICLE AND JOIN OUR COMMUNITY!'}
              </span>
              <span className="hdiwBodyText">
                {
                  'Our commission rate is only 10% of the total hire amount that you earn from each hire out. The rest is all yours!! Owners who hire out multiple Vehicles with us can be eligible for commission rate discounts.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'So for RV Owners, if you earn $1000 we only take $150 leaving you with $850 for doing not very much at all!'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'Our fees and commission charges cover extensive print media and online advertising right across Australia, all year round.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We also handle ALL booking enquiries, ALL paperwork/hire agreements, ALL payments and bonds, ALL promotions and much more. Once you join our registered Hire Partner community you will enjoy everything that our unmatched customer service and customer care offers Owners/Hire Partners.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'Over the years we have been affiliated with these businesses below:'
                }
              </span>
              <span className="hdiwBodyText">
                <ul className="hdiwBodyUL">
                  <li>{'What’s Up Downunder'}</li>
                  <li>{'Discovery Parks/Top Parks'}</li>
                  <li>{'Parable Production/Ch 10 & 1HD'}</li>
                  <li>{'Caravanning Australia'}</li>
                  <li>{'GoRV'}</li>
                  <li>{'Everything Caravan and Camping'}</li>
                  <li>{'CIL Insurance'}</li>
                  <li>{'Aus Insurance Services'}</li>
                  <li>{'Caravanning with Kids'}</li>
                  <li>{'Learn 2 Tow'}</li>
                  <li>{'RV 4×4'}</li>
                  <li>{'Campedia'}</li>
                  <li>{'Four Hands in a Tin Can'}</li>
                  <li>{'Liquid Life and Leisure'}</li>
                  <li>{'Live2Camp'}</li>
                  <li>{'RVTrips'}</li>
                  <li>{'TAWK – Travel Australia with Kids'}</li>
                  <li>{'Many more!!'}</li>
                </ul>
              </span>
              <span className="hdiwBodyText">
                {
                  'Would you like to become an affiliate with Caravan and Camping Hire AUS?!!! Send us an email to find our more enquiries@cnchire.com.au'
                }
              </span>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton10(!showButton10)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'PORTABLE ELECTRIC BRAKE UNITS (PEBS)'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton10 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton10 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'We also sell our own custom designed Portable Electric Brake Units so that every single caravan/camper that is hired out is towed legally and with full insurance cover by using portable electric brake units if the towing vehicle is not already fitted with any form of hardwired electric braking system.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'Most Hire Vehicle Owners who have joined our community purchase their own portable electric brake units from us and then Hire them out as well.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'You need to have a Portable Electric Brake Unit (PEB) for any Hirers that do not have Electric Brakes already fitted in their towing vehicles. This is legal and insurance requirement.'
                }
              </span>
              <span className="hdiwBodyText">
                <ol className="hdiwBodyUL">
                  <li>{'Tekonsha – $400 inc GST plus postage & handling'}</li>
                </ol>
              </span>
              <span className="hdiwBodyText">
                {
                  'N.B – The PEBs include a 12 pin flat to 7 pin flat adaptor in that price as well'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'Owners choose their own Hire Fee for PEBS but most are generally hired out for $100 per caravan/camper hire per booking so after 4 or 5 bookings the Owner/Partners start earning even more money for themselves.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'You can purchase your own PEB and 1000s of other Caravanning related products via our online store at the link below'
                }
              </span>
              <div className="showButton10Div">
                <span className="hdiwBodyText">
                  {'CNC HIRE 10,000 PLUS PRODUCT '}
                  <Link to="/rv-products" className="hdiwBodyText">
                    {'ONLINE STORE'}
                  </Link>
                </span>
              </div>
            </div>
          )}

          <button
            className="howDoesItWorkButton"
            onClick={() => {
              setShowButton11(!showButton11)
            }}
          >
            <span className="howDoesItWorkButtonLabel">
              {'WHY JOIN THE ‘C N C HIRE AUS’ COMMUNITY?'}
            </span>
            <Icon
              className="howDoesItWorkButtonArrow"
              size="large"
              name={showButton11 ? 'chevron circle up' : 'chevron circle down'}
            />
          </button>

          {showButton11 && (
            <div className="hdiwBody1">
              <span className="hdiwBodyText">
                {
                  'We are the best in the business for Peer 2 Peer Caravan/RV/Camper Hiring all over Australia and we are putting $1000s and $1000s back into Caravan/RV/Camper Owners pockets all year round.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We provide the absolute best customer service and customer care – you won’t find better because we handle all of the booking enquiries, paperwork, hire agreements invoicing, bond, payments and much more for you and we are NOT new kids on the block for private Caravan/RV/Camper Hiring plus we own our own Caravans and getaway regularly ourselves.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We are highly experienced in the Caravanning/RV industry and are available to chat and assist you 24/7 and will respond to your enquiries within 30 minutes where possible.'
                }
              </span>
              <span className="hdiwBodyText">
                {
                  'We are very passionate about helping Aussie and Overseas families enjoy the Caravanning and Camping Industry and all that it has to offer'
                }
              </span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default HowDoesItWork
