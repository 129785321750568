import React from 'react'
import 'react-day-picker/lib/style.css'

const currentYear = 1984 //new Date().getFullYear();
const fromMonth = new Date(currentYear, 0)
const toMonth = new Date(currentYear + 10, 11)

function YearMonthForm({ onChange, nmonth, nyear, nday }) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const days = []
  for (let i = 1; i <= 31; i += 1) {
    days.push(i)
  }

  const years = []
  for (
    let i = fromMonth.getFullYear() - 50;
    i <= toMonth.getFullYear();
    i += 1
  ) {
    years.push(i)
  }

  const handleChange = function handleChange(e) {
    const { nyear, nmonth, nday } = e.target.form
    if (nyear.value !== '' && nmonth.value !== '' && nday.value !== '')
      onChange(nyear.value + '-' + nmonth.value + '-' + nday.value)
  }

  const add0 = function add0(i) {
    return i < 10 ? '0' + i : i
  }

  return (
    <form>
      <div className="row birthdaycomponent">
        <div className="col-3">
          <select name="nmonth" onChange={handleChange} defaultValue={nmonth}>
            <option value="">-</option>
            {months.map((month, i) => (
              <option key={month} value={i + 1}>
                {add0(i + 1)}
              </option>
            ))}
          </select>
        </div>
        <div className="col-4">
          <select name="nday" onChange={handleChange} defaultValue={nday}>
            <option value="">-</option>
            {days.map((day) => (
              <option key={day} value={day}>
                {add0(day)}
              </option>
            ))}
          </select>
        </div>
        <div className="col-4">
          <select name="nyear" onChange={handleChange} defaultValue={nyear}>
            <option value="">-</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </form>
  )
}

export default class BirthDayComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this)
    var month = '',
      day = '',
      year = ''

    if (this.props.value) {
      var dateObj = new Date(this.props.value)
      month = dateObj.getUTCMonth() + 1 //months from 1-12
      day = dateObj.getUTCDate()
      year = dateObj.getUTCFullYear()
    }

    ///console.log(" date is ", this.props.value , month, day, year);

    this.state = { month, day, year, date: '' }
  }

  handleYearMonthChange(date) {
    console.log('date is ---->', date)
    this.setState({ date })

    this.props.handleChange(date)
  }

  render() {
    return (
      <div className={' YearNavigation birthday-' + this.props.className}>
        <YearMonthForm
          onChange={this.handleYearMonthChange}
          nmonth={this.state.month}
          nyear={this.state.year}
          nday={this.state.day}
        />
      </div>
    )
  }
}
