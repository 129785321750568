import fp from 'lodash/fp'
import api from './api'

import { camelizeKeys } from './helpers'

const faqsApi = api('/FAQs')

export const fetchFAQs = async (data = {}) => {
  const faqs = await faqsApi.get('', { params: data })

  return fp.flow(fp.getOr({}, 'data.data'))(faqs)
}

export const fetchFAQItem = async (id) => {
  const faqDetails = await faqsApi.get(`/${id}`)

  return fp.flow(fp.getOr({}, 'data.data'), camelizeKeys)(faqDetails)
}
