import React from 'react'

import * as bookingService from '../BookingService'
import { NotificationManager } from 'react-notifications'
import BookingBondClaimPopup from './BookingBondClaim'
import { formatCurrency } from 'shared/utils.js'
const moment = require('moment')

export default class BookingActionButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      booking: {},
      processing: false,
    }
  }

  async orderStatusUpdate(action) {
    this.setState({ processing: true })
    const result = await bookingService.updateBookingStatus({
      status: action,
      booking_id: this.props.booking.id,
    })

    if (result.success) {
      await localStorage.setItem(
        'message_flash',
        'Booking status has been updated!'
      )
      window.location.href = '/dashboard/bookings'
    } else {
      this.setState({ processing: false })
      NotificationManager.error(
        'There seem to be a problem processing this action.',
        'Error'
      )
    }
  }

  async clickRefund() {
    this.setState({ processing: true })
    const result = await bookingService.refund(this.props.booking.id)
    if (result.success) {
      await localStorage.setItem('message_flash', 'Refund has been initiated!')
      window.location.href = '/dashboard/bookings'
    } else {
      this.setState({ processing: false })
      NotificationManager.error(
        'There seem to be a problem processing this action.',
        'Error'
      )
    }
  }

  async clickPayBondClaimAndReturnLeft() {
    this.setState({ processing: true })
    const result = await bookingService.payoutBondClaim(this.props.booking.id)
    if (result.success) {
      await localStorage.setItem('message_flash', 'Payout has been initiated!')
      window.location.href = '/dashboard/bookings'
    } else {
      this.setState({ processing: false })
      NotificationManager.error(
        'There seem to be a problem processing this action.',
        'Error'
      )
    }
  }

  async clickPayOwner() {
    this.setState({ processing: true })
    const result = await bookingService.payout(this.props.booking.id)
    if (result.success) {
      await localStorage.setItem('message_flash', 'Payout has been initiated!')
      window.location.href = '/dashboard/bookings'
    } else {
      this.setState({ processing: false })
      NotificationManager.error(
        'There seem to be a problem processing this action.',
        'Error'
      )
      this.props.modalError(result.message)
    }
  }

  async clickOpenBondClaim() {}

  countDays = (from, to) => {
    var date1 = new Date(from)
    var date2 = new Date(to)
    var diff = date2.getTime() - date1.getTime()
    return Math.floor(diff / (1000 * 3600 * 24))
  }

  render() {
    let isAdmin = this.props.user.userRole === 'Admin' ? true : false
    let booking = this.props.booking

    const today = moment().format('YYYY-MM-DD') // 2021-04-29
    const target = booking.startDate

    const count_days = this.countDays(today, target) //count days from now to booking commencement day
    const isWithin5days = count_days < 6 || count_days < 0 //or passed date?
    //( count_days < 6 && count_days > 0  ) || count_days < 0 ; //or passed date ?

    let output = ''

    switch (
      booking.status //these actions are for owner
    ) {
      case 'request-approved':
        output = (
          <button
            type="button"
            className="btn-action btn btn-warning btn-disabled"
            title="Awaiting Hirer Payment, No Action Required"
            disabled
          >
            <i className="far fa-clock fa-fw"></i> Awaiting Hirer Payment
          </button>
        )
        break
      case 'request-rejected':
        output = (
          <button
            type="button"
            className="btn-action btn btn-secondary btn-disabled"
            title="Booking Cancelled, No Further Action Required"
            disabled
          >
            <i className="far fa-times-circle fa-fw"></i> Request Rejected
          </button>
        )
        break
      case 'request-approval':
        output = (
          <>
            <button
              type="button"
              className="btn-action btn btn-danger  "
              title="Reject booking request"
              onClick={() => this.orderStatusUpdate('request-rejected')}
            >
              <i className="far fa-times-circle fa-fw"></i> Reject Request
            </button>
            <button
              type="button"
              className="btn-action btn btn-success "
              title="Approve booking request"
              onClick={() => this.orderStatusUpdate('request-approved')}
            >
              <i className="far fa-times-circle fa-fw"></i> Approve Request
            </button>
          </>
        )
        break

      case 'failed':
        output = (
          <button
            type="button"
            className="btn-action btn btn-danger btn-disabled"
            title="Payment Failed, Awaiting CnC Hire Review"
            disabled
          >
            <i className="far fa-times-circle fa-fw"></i> Payment Failed
          </button>
        )
        break
      case 'pending':
        output = (
          <button
            type="button"
            className="btn-action btn btn-warning btn-disabled"
            title="Awaiting Hirer Payment, No Action Required"
            disabled
          >
            <i className="far fa-clock fa-fw"></i> Awaiting Hirer Payment
          </button>
        )
        break
      case 'scheduled-payment':
        output = (
          <button
            type="button"
            className="btn-action btn btn-warning btn-disabled"
            title="Awaiting Hirer Payment, No Action Required"
            disabled
          >
            <i className="far fa-clock fa-fw"></i> Awaiting Hirer Payment
          </button>
        )
        break
      case 'partial-payment':
        output = (
          <button
            type="button"
            className="btn-action btn btn-warning btn-disabled"
            title="Awaiting Hirer Payment, No Action Required"
            disabled
          >
            <i className="far fa-clock fa-fw"></i> Awaiting Hirer Payment
          </button>
        )
        break
      case 'pending-deposit':
        output = (
          <button
            type="button"
            className="btn-action btn btn-warning btn-disabled"
            title="Awaiting Hirer Payment, No Action Required"
            disabled
          >
            <i className="far fa-clock fa-fw"></i> Awaiting Hirer Payment
          </button>
        )
        break
      case 'completed':
        output = (
          <button
            type="button"
            className="btn-action btn btn-success btn-disabled"
            title="Booking Complete, No Further Action Required"
            disabled
          >
            <i className="far fa-check-circle fa-fw"></i> Booking Completed
          </button>
        )
        break
      case 'processing':
      case 'paid':
        if (isWithin5days) {
          //isDateStartPassed
          output = (
            <button
              type="button"
              className="btn-action btn btn-info"
              disabled={this.state.processing}
              data-newstatus="onhire"
              onClick={() => this.orderStatusUpdate('onhire')}
              title="RV Picked Up/Delivered to Hirer"
            >
              <i className="far fa-calendar-alt fa-fw"></i> Mark On Hire
            </button>
          )
        } else {
          output = (
            <button
              type="button"
              className="btn-action btn btn-info btn-disabled"
              title="Pickup Start Date is not witin 5 days, please wait until closer to start date to commence hire"
              disabled
            >
              <i className="far fa-calendar-alt fa-fw"></i> Not within Date
              Range for Hire
            </button>
          )
        }
        break
      case 'onhire':
        if (booking.isPayoutTransfered !== 1) {
        } else {
        }
        switch (this.props.user.userRole) {
          case 'Admin':
            if (booking.isPayoutTransfered !== 1) {
              //not transfered yet?

              if (booking.version === 1) {
                output = (
                  <button
                    type="button"
                    disabled={this.state.processing}
                    className="btn-action btn btn-warning payout-alert-btn"
                    data-newstatus="bond-investigate"
                    onClick={() => this.clickPayOwner()}
                    title="Booking is OnHire - Pay this owner now!"
                  >
                    <i className="far fa-search fa-fw"></i>{' '}
                    {this.state.processing
                      ? 'Sending payout..'
                      : '[V1: Requires manual payout] Trigger Send Owner Payout email $' +
                        booking.totalEarnings}{' '}
                  </button>
                )
              } else {
                output = (
                  <button
                    type="button"
                    disabled={this.state.processing}
                    className="btn-action btn btn-warning payout-alert-btn"
                    data-newstatus="bond-investigate"
                    onClick={() => this.clickPayOwner()}
                    title="Booking is OnHire - Pay this owner now!"
                  >
                    <i className="far fa-search fa-fw"></i>{' '}
                    {this.state.processing
                      ? 'Sending payout..'
                      : 'Send Owner Payout - $' + booking.totalEarnings}{' '}
                  </button>
                )
              }
            } else {
              output = (
                <button
                  type="button"
                  className="btn-action btn btn-warning btn-disabled"
                  title="Awaiting for Admin, No Action Required"
                  disabled
                >
                  <i className="far fa-clock fa-fw"></i> Awaiting For Owner
                </button>
              )
            }
            break
          case 'Owner':
            if (booking.isPayoutTransfered === 1) {
              //money is already transfered?
              output = (
                <>
                  {' '}
                  <button
                    type="button"
                    className="btn-action btn btn-success"
                    data-newstatus="refund-bond"
                    disabled={this.state.processing}
                    onClick={() => this.orderStatusUpdate('refund')}
                    title="No Damage Found, Refund Bond in Full"
                  >
                    <i className="far fa-undo fa-fw"></i> Refund the Bond
                  </button>
                  <BookingBondClaimPopup
                    booking={booking}
                    disabled={this.state.processing}
                  ></BookingBondClaimPopup>{' '}
                </>
              )
            } else {
              output = (
                <button
                  type="button"
                  className="btn-action btn btn-warning btn-disabled"
                  title="Awaiting for Admin, No Action Required"
                  disabled
                >
                  <i className="far fa-clock fa-fw"></i> Owner Payment Pending
                </button>
              )
            }
            break
          default:
            output = (
              <button
                type="button"
                className="btn-action btn btn-warning btn-disabled"
                title="Awaiting for Admin, No Action Required"
                disabled
              >
                <i className="far fa-clock fa-fw"></i> Awaiting For Owner
              </button>
            )
        }

        break
      case 'withhold-bond': //version 1.
      case 'bond-investigate': //bond claim for damages.  version 2.
        if (isAdmin) {
          //check if payout is already processed

          if (booking.version === 1) {
            output = (
              <div className="btn-action btn btn-warning payout-alert-btn">
                V1: Bond claim feature is only available in version 2 derrived
                booking. Please trigger manual status update to complete if
                claim is already settle.
              </div>
            )
          } else {
            output = (
              <button
                type="button"
                disabled={this.state.processing}
                className="btn-action btn btn-warning payout-alert-btn"
                data-newstatus="bond-investigate"
                onClick={() => this.clickPayBondClaimAndReturnLeft()}
                title="Booking is OnHire - Pay this owner now!"
              >
                <i className="far fa-search fa-fw"></i>
                {this.state.processing
                  ? 'Processing..'
                  : 'Pay Bond Claim - ' +
                    formatCurrency(booking.bondClaimAmount - 25) +
                    ' & Refund bond balance left - ' +
                    formatCurrency(
                      booking.bondAmount - booking.bondClaimAmount
                    )}
              </button>
            )
          }
        } else {
          output = (
            <button
              type="button"
              className="btn-action btn btn-warning btn-disabled"
              title="Awaiting for Admin, No Action Required"
              disabled
            >
              <i className="far fa-clock fa-fw"></i> Awaiting CnC Bond Claim
              Review
            </button>
          )
        }
        break
      case 'refund-bond': //version 1
      case 'refund': //no claim, owner selected refund. version 2
        if (isAdmin && booking.isRefunded !== 1) {
          if (booking.version === 1) {
            output = (
              <button
                type="button"
                className="btn-action btn btn-success payout-alert-btn "
                title="Bond Ready for Refund"
                onClick={() => this.clickRefund()}
                disabled={this.state.processing}
              >
                <i className="far fa-check-circle fa-fw"></i>
                {this.state.processing
                  ? 'Returning bond..'
                  : '[V1: Requires manual refund] Trigger Refund bond - $' +
                    booking.bondAmount}
              </button>
            )
          } else {
            output = (
              <button
                type="button"
                className="btn-action btn btn-success payout-alert-btn "
                title="Bond Ready for Refund"
                onClick={() => this.clickRefund()}
                disabled={this.state.processing}
              >
                <i className="far fa-check-circle fa-fw"></i>
                {this.state.processing
                  ? 'Returning bond..'
                  : 'Refund bond - $' + booking.bondAmount}
              </button>
            )
          }
        } else {
          output = (
            <button
              type="button"
              className="btn-action btn btn-success btn-disabled"
              title="Pending CnC Hire Review, No Further Action Required"
              disabled
            >
              <i className="far fa-check-circle fa-fw"></i> Awaiting CnC Refund
              Review
            </button>
          )
        }

        //output =  (<button type="button" className="btn-action btn btn-danger btn-disabled" title="Pending CnC Hire Review, No Further Action Required" disabled><i className="far fa-check-circle fa-fw"></i> Awaiting CnC Hire Review</button>);

        break
      case 'bond-refunded':
      case 'bond-partially-re':
      case 'bond-withheld':
        output = (
          <button
            type="button"
            className="btn-action btn btn-success btn-disabled"
            title="Booking Complete, No Further Action Required"
            disabled
          >
            <i className="far fa-check-circle fa-fw"></i> Booking Completed
          </button>
        )
        //return (<button type="button" className="btn-action btn btn-success btn-disabled" title="Booking Complete, No Further Action Required" disabled><i className="far fa-check-circle fa-fw"></i> Booking Completed</button>);
        //return (<button type="button" className="btn-action btn btn-success btn-disabled" title="Booking Complete, No Further Action Required" disabled><i className="far fa-check-circle fa-fw"></i> Booking Completed</button>);
        break
      case 'refunded':
        output = (
          <button
            type="button"
            className="btn-action btn btn-primary btn-disabled"
            title="Booking Cancelled or Refunded, No Further Action Required"
            disabled
          >
            <i className="far fa-sync-alt fa-fw"></i> Booking Cancelled/Refunded
          </button>
        )
        break
      case 'cancelled':
        output = (
          <button
            type="button"
            className="btn-action btn btn-secondary btn-disabled"
            title="Booking Cancelled, No Further Action Required"
            disabled
          >
            <i className="far fa-times-circle fa-fw"></i> Booking Cancelled
          </button>
        )
        break
      default:
    }

    return <> {output} </>
  }
}
