import React from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import './BookingsPage.scss'
import * as bookingService from './BookingService'
import Pagination from '@material-ui/lab/Pagination'
import { formatCurrency } from 'shared/utils.js'
import BookingDetailPopup from './shared/BookingDetailPopup'
import StatusIcon from './shared/StatusIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HirerBookingActionButton from './shared/HirerBookingActionButton'

class HirerBookingsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filterId: this.props.filterId,
      booking: [],
      default: this.props.screen,
      screen: this.props.screen,
      bookingList: [],
      showConfirmForm: false,
      confirmType: '',
      user: this.props.user,
      meta: null,
      search: '',
      filter: 1,
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-hirer-bookings-page')
  }

  async fetchLatest() {
    let params = this.state.filterId ? { id: this.state.filterId } : {}

    let meta = { filter: this.state.filter }

    if (this.state.meta !== null) {
      meta = { ...meta, ...this.state.meta }
    }

    if (this.state.search !== '') {
      meta = { ...meta, search: this.state.search }
    }

    params = { filter: meta, ...params }

    const result = await bookingService.fetch(params)
    if (result.success) {
      this.setState({ bookingList: result.data })
      this.setState({ meta: result.meta })
    }
  }

  clickPayNow = (item) => {
    this.setState({ booking: item })

    localStorage.setItem('booked', JSON.stringify(item))

    //TODO: add the item id to the local storage.
    window.location.href = '/dashboard/payment-details/pay'
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  async refreshAndAllert() {
    await this.fetchLatest() //refresh data in the display. TODO: improve this if time permits
    NotificationManager.success('Booking status has been updated!', 'Success')
  }

  clickConfirmStatusUpdate = () => {
    //NotificationManager.error('There was a problem booking at the moment.', 'Error', 5000, () => { });
    bookingService
      .updateBookingStatus({
        booking_id: this.state.booking.id,
        status: this.state.confirmType,
      })
      .then((result) => {
        //TODO: validate if successful and sync and message success
        //console.log("update status result " , result);
        this.refreshAndAllert()
      })

    this.setState({ showConfirmForm: false })
  }

  clickConfirmForm = (item, type) => {
    this.setState({ booking: item })
    this.setState({ showConfirmForm: true })
    this.setState({ confirmType: type })
  }

  clickCloseCancelForm = () => {
    this.setState({ showConfirmForm: false })
  }

  clickComplete = (item) => {}

  sendMessageHandler() {}

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    console.log('clicked pagination', page, this.state.meta)
    //*
    await this.setState({ meta: _meta })
    await this.fetchLatest()
    //*/
  }

  clickFilter = async (t) => {
    let _meta = this.state.meta
    _meta.count = 0
    await this.setState({ meta: _meta })

    await this.setState({ filter: t })
    this.fetchLatest()
  }

  render() {
    const list =
      this.state.bookingList.length > 0
        ? this.state.bookingList.map((item, index) => {
            return (
              <tr key={item.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                <td>{item.id}</td>
                <td>
                  <StatusIcon status={item.status}></StatusIcon>
                </td>
                <td>{moment(item.startDate).format('LL')}</td>
                <td>{moment(item.endDate).format('LL')}</td>
                <td>
                  <a href={'/dashboard/user/' + item.ownerId}>
                    {item.ownerFirstName} {item.ownerLastName}
                  </a>
                </td>
                <td>
                  <a href={'/dashboard/vehicles/' + item.vehicleId}>
                    {item.vehicleName}
                  </a>
                </td>
                <td>{formatCurrency(item.bookingCost)}</td>

                <td>
                  <BookingDetailPopup id={item.id}></BookingDetailPopup>
                </td>
                <td>
                  <button
                    variant="primary"
                    className="btn btn-danger chat-button"
                    onClick={() =>
                      (window.location.href =
                        '?n=d1805440-3cac-487a-8aad-e0d9d2ccd685&b=d1805440-3cac-487a-8aad-e0d9d2ccd685&chat=' +
                        item.hirerId +
                        '-' +
                        item.vehicleId)
                    }
                  >
                    <FontAwesomeIcon icon="comment-alt" />
                    <span></span>
                  </button>
                </td>
                <td>
                  <HirerBookingActionButton
                    booking={item}
                    user={this.props.user}
                  ></HirerBookingActionButton>
                </td>
              </tr>
            )
          })
        : null

    return (
      <>
        <div>
          <div className="filter-selection">
            Filter:
            <span
              className={this.state.filter === 1 ? 'active' : ''}
              onClick={() => this.clickFilter(1)}
            >
              {' '}
              All{' '}
            </span>{' '}
            |
            <span
              className={this.state.filter === 12 ? 'active' : ''}
              onClick={() => this.clickFilter(12)}
            >
              Start Date
            </span>{' '}
            |
            <span
              className={this.state.filter === 6 ? 'active' : ''}
              onClick={() => this.clickFilter(6)}
            >
              Pending Bookings
            </span>{' '}
            |
            <span
              className={this.state.filter === 9 ? 'active' : ''}
              onClick={() => this.clickFilter(9)}
            >
              Cancelled
            </span>{' '}
            |
            <span
              className={this.state.filter === 11 ? 'active' : ''}
              onClick={() => this.clickFilter(11)}
            >
              Completed
            </span>
          </div>
          <br />
          <br />

          <table width="100%">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Start</th>
                <th>End</th>
                <th>Owner</th>
                <th>Vehicle</th>
                <th>Total</th>
                <th>View Details</th>
                <th>Chat</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
        <div style={{ float: 'right', marginTop: '20px' }}>
          {this.state.meta !== null && (
            <Pagination
              count={this.state.meta.count}
              variant="outlined"
              shape="rounded"
              onChange={this.clickPagination}
              size={'large'}
            />
          )}
        </div>
      </>
    )
  }
}

export default withRouter(HirerBookingsPage)
