import fp from 'lodash/fp'
import deepMapKeys from 'deep-map-keys'
import axios from 'axios'

const DEBUG = true

export const camelizeKeys = (obj) => deepMapKeys(obj, fp.camelCase)
export const toSnakeCase = (data) => deepMapKeys(data, fp.snakeCase)
export const normalize = fp.flow(fp.getOr({}, 'data'), camelizeKeys)

export const access_token = async () => {
  const persistRoot = localStorage.getItem('persist:root')
  const user = !fp.isNil(persistRoot)
    ? JSON.parse(fp.get('user', JSON.parse(persistRoot)))
    : {}
  const currentUser = fp.get('currentUser', user)
  const access_token = !fp.isEmpty(currentUser)
    ? currentUser.access_token
    : null /// currentUser.access_token
  return access_token
}

/*
AxiosRequestConfig {
  url?: string;
  method?: Method;
  baseURL?: string;
  transformRequest?: AxiosTransformer | AxiosTransformer[];
  transformResponse?: AxiosTransformer | AxiosTransformer[];
  headers?: any;
  params?: any;
  paramsSerializer?: (params: any) => string;
  data?: any;
  timeout?: number;
  timeoutErrorMessage?: string;
  withCredentials?: boolean;
  adapter?: AxiosAdapter;
  auth?: AxiosBasicCredentials;
  responseType?: ResponseType;
  xsrfCookieName?: string;
  xsrfHeaderName?: string;
  onUploadProgress?: (progressEvent: any) => void;
  onDownloadProgress?: (progressEvent: any) => void;
  maxContentLength?: number;
  validateStatus?: (status: number) => boolean;
  maxRedirects?: number;
  socketPath?: string | null;
  httpAgent?: any;
  httpsAgent?: any;
  proxy?: AxiosProxyConfig | false;
  cancelToken?: CancelToken;
} */

function _success(response) {
  if (DEBUG) console.log('[api request-success]', response)

  if (
    response.data.message &&
    response.data.message.search('Invalid token') > -1
  ) {
    const message = encodeURI('Your loggedin session has expired.')
    window.location.href = `/login?message=${message}`
  }

  return response.data
}

function _error(error) {
  if (DEBUG) console.log('[api request-error]', error.response)
  return {
    success: false,
    error: error.response.data?.validation || 'No error provided',
    message: error.response.data?.message || 'No message provided',
  }
}

export const api_request = async (
  method = 'get',
  path = '',
  data = {},
  isAuthed = false,
  ignoreAuth = false
) => {
  const _api_url = `${process.env.REACT_APP_API_URL}${path}`
  let _config = {}

  if (isAuthed) {
    const _token = await access_token()
    _config = isAuthed
      ? {
          headers: {
            Authorization: `Basic ${_token}`,
          },
        }
      : {}
  }

  if (DEBUG) console.log('[api request] ', path, method, data)

  let result

  try {
    switch (method.toLowerCase()) {
      case 'get':
        result = await axios.get(_api_url, _config).then(_success).catch(_error)
        break
      case 'post':
        result = await axios
          .post(_api_url, data, _config)
          .then(_success)
          .catch(_error)
        break
      case 'delete':
        result = await axios
          .delete(_api_url, _config)
          .then(_success)
          .catch(_error)
        break
      case 'put':
        result = await axios
          .put(_api_url, data, _config)
          .then(_success)
          .catch(_error)
        break
      default:
        result = await axios.get(_api_url, _config).then(_success).catch(_error)
    }

    return result
  } catch (error) {
    console.log('UI error is ', error)
    return { data: { success: false, message: error } }
  }
}
