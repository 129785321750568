import React from 'react'
import { withRouter } from 'react-router-dom'
import * as chatService from './chatService'
import moment from 'moment'
import './chat.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ChatComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      started_length: 0,
      fetched: false,
      chats: [],
      message: '',
      ...props,
    }
  }

  scrollToBottom = () => {
    try {
      this.el.scrollIntoView({ behavior: 'smooth' })
    } catch (e) {}
  }

  componentDidMount() {
    this.fetchChat()
  }

  async fetchChat() {
    const params = {
      hirer_id: this.props.hirer_id,
      vehicle_id: this.props.vehicle_id,
    }
    const result = await chatService.fetch(params)
    if (result.success) {
      let chats = result.data
      chats.reverse()
      this.setState({
        chats,
        fetched: true,
        started_length: result.data.length,
      })
    }
  }

  async clickSend() {
    console.log('clicked')
    if (this.state.message !== '') {
      const params = {
        hirer_id: this.props.hirer_id,
        vehicle_id: this.props.vehicle_id,
        message: this.state.message,
      }
      let result = await chatService.send(params)

      if (result.success) {
        let chats = result.data.chats
        chats.reverse()
        this.setState({ chats })
        this.setState({ message: '' })
      }
    }
  }

  guest() {
    return (
      <div className="chat-box">
        <a href="/login">Login to chat</a>
      </div>
    )
  }

  async clickCreateAndChat() {
    if (this.state.chats.length === 0) {
      await this.setState({ message: '-blank-' })
      await this.clickSend()
    }
    window.location.href =
      '/dashboard/?n=d1805440-3cac-487a-8aad-e0d9d2ccd685&b=d1805440-3cac-487a-8aad-e0d9d2ccd685&chat=' +
      this.props.hirer_id +
      '-' +
      this.props.vehicle_id
  }

  chatbox() {
    const myId = this.props.user ? this.props.user.id : -1

    const chats = this.state.chats.length ? (
      this.state.chats.map((x, index) => {
        return (
          <div className={'chat-item chat-box-item ' + x.userType} key={index}>
            <div className="row">
              {myId !== x?.userId ? (
                <>
                  <div
                    className="col-2 avatar"
                    title={`${x.by} - ${x.userType},${moment(x.time).format(
                      'LL'
                    )}`}
                  >
                    <FontAwesomeIcon icon="user-circle" />
                  </div>
                  <div
                    className="col-10"
                    title={`${x.by} - ${x.userType},${moment(x.time).format(
                      'LL'
                    )}`}
                  >
                    <div className="message">{x.message}</div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="col-10"
                    title={
                      x.by +
                      ' ' +
                      ' - ' +
                      x.userType +
                      ' ' +
                      moment(x.time).format('LL')
                    }
                  >
                    <div className="message mymessage">{x.message}</div>
                  </div>
                  <div
                    className="col-2 avatar me"
                    title={
                      x.by +
                      ' ' +
                      ' - ' +
                      x.userType +
                      ' ,' +
                      moment(x.time).format('LL')
                    }
                  >
                    <FontAwesomeIcon icon="user-circle" />
                  </div>
                </>
              )}
            </div>
          </div>
        )
      })
    ) : (
      <div className="start-chatting">
        Chat with the vehicle{' '}
        {this.props.user.userRole === 'Owner' ? 'Hirer' : 'Owner'}!
      </div>
    )

    //console.log("this.state.chats.length !== this.state.len", this.state.chats.length !== this.state.len,  this.state.chats.length, this.state.len );
    if (this.state.message === '') {
      //this.state.chats.length !== this.state.len
      setTimeout(() => {
        this.scrollToBottom()
      }, 100)
    }

    if (this.props.is_vehicle_page) {
      return (
        <div className="chat-with-only">
          <button
            className="btn btn-primary"
            onClick={() => this.clickCreateAndChat()}
          >
            <FontAwesomeIcon icon="comments" /> &nbsp; Chat with the vehicle
            owner!
          </button>
        </div>
      )
    } else {
      return (
        <div
          className={
            this.props.is_vehicle_page
              ? 'vehicle-page-chat chat-box'
              : 'chat-box'
          }
        >
          <div className="chat-body">
            <React.Fragment>
              {chats}
              {this.props.is_vehicle_page ? null : (
                <div
                  ref={(el) => {
                    this.el = el
                  }}
                />
              )}
            </React.Fragment>
          </div>

          <div className="chat-footer">
            <div className="left">
              <button onClick={() => this.clickSend()} className="send-btn">
                Send
              </button>
            </div>
            <div className="right">
              <textarea
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
                placeholder="enter text"
              ></textarea>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    return <React.Fragment> {this.chatbox()} </React.Fragment>
  }
}

export default withRouter(ChatComponent)
