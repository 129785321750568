import React from 'react'
import Rating from '@material-ui/lab/Rating'

import './ReviewCard.scss'

const ReviewCard = ({ name, date, rating, comment }) => {
  return (
    <React.Fragment>
      <div className="reviewCardBox">
        <div className="reviewCardBoxRow">
          <div className="reviewCardNameDateBox">
            <span className="reviewCardName">{name}</span>
            <span className="reviewCardDate">{date}</span>
          </div>
          <Rating
            name="read-only"
            value={rating}
            size="medium"
            style={{ color: '#ffc107' }}
            readOnly
          />
        </div>
        <span className="reviewCardComment">{comment}</span>
        <div className="reviewCardDivider" />
      </div>
    </React.Fragment>
  )
}

export default ReviewCard
