import React from 'react'

export default class HirerBookingActionButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      booking: props.booking,
    }
  }

  clickPayNow() {
    if (this.state.booking.step === 1) {
      //this hasnt passed booking form yet
      window.location.href = '/confirm-booking/' + this.state.booking.uuid
    } else {
      window.location.href = '/contract/' + this.state.booking.uuid
    }
  }

  render() {
    let booking = this.props.booking
    switch (booking.status) {
      case 'request-rejected':
        return (
          <button
            type="button"
            className="btn-action btn btn-secondary btn-disabled"
            title="Booking Cancelled, No Further Action Required"
            disabled
          >
            <i className="far fa-times-circle fa-fw"></i> Request Rejected
          </button>
        )
      case 'request-approval':
        return (
          <button
            type="button"
            className="btn-action btn btn-warning btn-disabled"
            title="Pending Owner Approval"
            disabled
          >
            <i className="far fa-check-circle fa-fw"></i> Awaiting Owner
            Approval{' '}
          </button>
        )
      case 'onhire':
        if (booking.isPayoutTransfered === 0) {
          return (
            <button
              type="button"
              className="btn-action btn btn-warning btn-disabled"
              title="OnHire, No Further Action Required"
              disabled
            >
              <i className="far fa-check-circle fa-fw"></i> Awaiting for CnC
              OnHire Review
            </button>
          )
        } else {
          return (
            <button
              type="button"
              className="btn-action btn btn-success btn-disabled"
              title="OnHire, No Further Action Required"
              disabled
            >
              <i className="far fa-check-circle fa-fw"></i> On Hire
            </button>
          )
        }

      case 'request-approved':
      case 'unpaid':
      case 'partial-payment':
        return (
          <button
            type="button"
            className="btn-action btn btn-danger "
            title="Pay Now"
            onClick={() => this.clickPayNow()}
          >
            <i className="far fa-times-circle fa-fw"></i> Pay Now
          </button>
        )
      case 'processing':
      case 'paid':
        return (
          <button
            type="button"
            className="btn-action btn btn-warning btn-disabled"
            title="Awaiting for Owner, No Action Required"
            disabled
          >
            <i className="far fa-clock fa-fw"></i> Awaiting for Owner
          </button>
        )

      case 'completed':
      case 'bond-refunded':
      case 'bond-partially-re':
      case 'bond-withheld':
        return (
          <button
            type="button"
            className="btn-action btn btn-success btn-disabled"
            title="Booking Complete, No Further Action Required"
            disabled
          >
            <i className="far fa-check-circle fa-fw"></i> Booking Completed
          </button>
        )

      default:
        return null
    }
  }
}
