import React, { useState, useEffect } from 'react'
import Pagination from '@material-ui/lab/Pagination'

import './LatestNews.scss'
import NewsCard from '../NewsCard/NewsCard'

const LatestNews = ({ newsList }) => {
  const [newsCompilation, setNewsCompilation] = useState([])
  const [totalPageCount, setTotalPageCount] = useState(1)

  useEffect(() => {
    console.log('newsList ', newsList)

    setNewsCompilation(newsList[0])
    if (newsList[0] && newsList[0].length > 0)
      setTotalPageCount(Math.ceil(newsList[0].length / 6))
  }, [newsList])

  // Get current posts
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(6)
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  let itemsToRender
  let currentPosts
  if (newsCompilation !== true) {
    currentPosts = newsList.slice(indexOfFirstPost, indexOfLastPost)
    itemsToRender = currentPosts.map((item, index) => {
      //const splitDate = item.createdAt.split(' ');
      const date = item.createdAt //`${splitDate[1]} ${splitDate[2]} ${splitDate[3]}`;

      return (
        <NewsCard
          newsTitle={item.title}
          newsSecondaryText={item.content}
          imageURL={item.imagePath}
          date={date}
          key={index}
          newsId={item.id}
        />
      )
    })
  }

  const handleListChange = (event, page) => {
    event.preventDefault()
    setCurrentPage(page)
    currentPosts = newsCompilation.slice(indexOfFirstPost, indexOfLastPost)
    itemsToRender = currentPosts.map((item, index) => {
      const splitDate = item.createdAt.split(' ')
      const date = `${splitDate[1]} ${splitDate[2]} ${splitDate[3]}`
      return (
        <NewsCard
          newsTitle={item.title}
          newsSecondaryText={item.content}
          imageURL={item.imagePath}
          date={date}
          key={index}
          newsId={item.id}
        />
      )
    })
  }

  return (
    <React.Fragment>
      <div className="latestNewsContainer">
        <div className="newsContainer">{itemsToRender}</div>
        <Pagination
          count={totalPageCount}
          variant="outlined"
          shape="rounded"
          onChange={handleListChange}
          size={'large'}
        />
      </div>
    </React.Fragment>
  )
}

export default LatestNews
