import React from 'react'
import ReactGA from 'react-ga4'

const useAnalyticsEventTracker = (category = 'Booking') => {
  const eventTracker = (action = 'click', label = '') => {
    if (typeof label === 'Object') {
      label = JSON.stringify(label)
    }
    ReactGA.event({ category, action, label })
  }
  return eventTracker
}
export default useAnalyticsEventTracker
