import React from 'react'
import { withRouter } from 'react-router'
import './DashboardBoxes.scss'

class DashboardBoxesComponent extends React.Component {
  constructor(props) {
    super(props)
    let { id } = this.props.match.params
    this.state = {
      id,
      stats: this.props.data,
    }
  }

  clickPillBox = (tab) => {
    if (tab === 'earnings') {
      window.location.href = '/dashboard/revenues'
    } else if (tab === 'pending-booking') {
      window.location.href = '/dashboard/bookings/pending'
    } else if (tab === 'caravans') {
      window.location.href = '/dashboard/caravan-search'
    } else if (tab === 'locations') {
      window.location.href = '/dashboard/location-search'
    } else if (tab === 'bookings') {
      window.location.href = '/dashboard/bookings'
    } else if (tab === 'vehicles') {
      window.location.href = '/dashboard/vehicles'
    } else if (tab === 'hirers') {
      window.location.href = '/dashboard/hirers'
    } else if (tab === 'owners') {
      window.location.href = '/dashboard/owners'
    }
  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  render() {
    if (!this.state.stats) {
      return <>Stats...</>
    } else {
      return (
        <>
          <div className="boxes">
            <div className="row">
              {this.state.stats.length > 0 &&
                this.state.stats.map((item, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <div
                        className={item.tab + ' box-item'}
                        onClick={() => this.clickPillBox(item.tab)}
                      >
                        <div className="box-value">
                          {this.numberWithCommas(item.count)}
                        </div>

                        <div className="box-caption">{item.caption}</div>
                      </div>
                    </div>
                  )
                })}
            </div>

            <div className="clearfix"></div>
          </div>
        </>
      )
    }
  }
}

export default withRouter(DashboardBoxesComponent)
