import { api_request } from 'Services/helpers'

//draft list
export const fetch = async (data = {}) => {
  return await api_request('post', '/auth/vehicle/draft', data, true)
}

//undraft
export const undraft = async (id) => {
  return await api_request('put', `/auth/vehicle/enable/${id}`, id, true)
}

//draft
export const draft = async (id) => {
  return await api_request('put', `/auth/vehicle/disable/${id}`, id, true)
}
