import React from 'react'
import './AboutUs.scss'

function AboutUs() {
  return (
    <React.Fragment>
      <div className="aboutUsContainer">
        <div className="aboutUsTitleBox">
          <span className="aboutUsTitle">Caravan and Camping For Hire</span>
          <span className="aboutUsTitle2">
            Caravan/Motorhome/Camper Trailer Hiring
          </span>
        </div>
        <div className="aboutUsBody">
          <img
            alt=""
            src="//cdn.cnchire.com.au/AboutUsImage.png"
            className="aboutUsImage"
          />
          <div className="aboutUsBodyText">
            <span className="aboutUsBodyText1">Why CNC Hire?</span>
            <div className="aboutUsBodyTexBox">
              <span className="aboutUsBodyText2">We Provide:</span>
              <span className="aboutUsBodyText2">
                1. Amazing opportunities for anyone to experience Aussie
                Caravan/Camping holidays by hiring any one of our listed Hire
                Vehicles
              </span>
              <span className="aboutUsBodyText2">
                2. Opportunities for Caravan/Camper/Motorhome owners make extra
                $$ from hiring out their RVs instead of them sitting idle for up
                to 90% of the year{' '}
              </span>
              <ul>
                <li className="aboutUsBodyText3">
                  We currently have over 350 beautiful Caravans/RVs/Camper
                  Trailers listed for Hire all over Australia
                </li>
                <li className="aboutUsBodyText3">
                  Anyone can enjoy the wonderful experiences of a Caravanning or
                  Camping Holiday without having to own their own luxury
                  Caravan/RV
                </li>
                <li className="aboutUsBodyText3">
                  Many registered owners are earning up to $20000 net Hire
                  Income each year just by Hiring Out theirs and you can do the
                  same!!
                </li>
                <li className="aboutUsBodyText3">
                  This is an astounding 30% return on their investments. Far
                  better returns than interest rates with the banks!
                </li>
                <li className="aboutUsBodyText3">
                  Many owners alway purchase brand new Caravans/RVs just to Hire
                  them out through Caravan and Camping Hire Australia
                </li>
                <li className="aboutUsBodyText3">
                  They are effectively using them as ‘Mobile Investment
                  Properties’ and getting great returns every year{' '}
                </li>
                <li className="aboutUsBodyText3">
                  {
                    'We have all the detailed TAX & INSURANCE information for you'
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutUs
