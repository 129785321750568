import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  CardMedia,
  TableHead,
  TableRow,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import './index.scss'

const useStyles = makeStyles({
  tableHeader: {
    border: '2px solid #FF8C3F',
    background: 'rgba(224, 235, 237, 0.4)',
    boxSizing: 'border-box',
    borderRadius: '3px',
  },
  tableMenu: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
  media: {
    width: '112px',
    height: '84px',
  },
  button: {
    width: '182px',
    height: '50px',
    background: '#FF8C3F',
    boxShadow: '7px 5px 23px rgba(255, 140, 63, 0.2)',
    borderRadius: '3px',
    marginLeft: '1em',
    color: 'white',
  },
  cartTotalText: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '127.5%',
  },
})

const BookingConfirmation = () => {
  const rows = [
    {
      vehicleId: 1,
      image:
        'https://cnchire.com.au/wp-content/uploads/2018/12/4709-E512D0CD-4105-41AB-8EF1-5B321A4FFB3E-500x374.jpeg',
      vehicleName: 'Toyota Hiace Automatic High Top Campervan',
      price: 30,
      quantity: 1,
      startDate: '08-20-2020',
      endDate: '08-25-2020',
    },
  ]
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className="bookingConfirmationContainer">
        <div className="confirmationTitleBox">
          <span className="confirmationTitle">Shopping Cart</span>
        </div>
        <div className="bookingConfirmationBody">
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableMenu}>Image</TableCell>
                <TableCell className={classes.tableMenu} align="left">
                  Product Name
                </TableCell>
                <TableCell className={classes.tableMenu} align="left">
                  Booking Date
                </TableCell>
                <TableCell className={classes.tableMenu} align="right">
                  Price
                </TableCell>
                <TableCell className={classes.tableMenu} align="right">
                  Quantity
                </TableCell>
                <TableCell className={classes.tableMenu} align="right">
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <CardMedia className={classes.media} image={row.image} />
                  </TableCell>
                  <TableCell align="left">{row.vehicleName}</TableCell>
                  <TableCell align="left">
                    {row.startDate} - {row.endDate}
                  </TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">30</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="couponBody">
          <form noValidate autoComplete="off">
            <TextField id="outlined-basic" label="Coupon" variant="outlined" />
            <Button className={classes.button}>Apply Coupon</Button>
          </form>
        </div>
        <div className="cartTotal">
          <div className="cartTotalBody">
            <div className="cartTotalTitle">Cart Total</div>
            <hr />
            <Grid container spacing={3}>
              <Grid item xs={6} className={classes.cartTotalText}>
                Subtotal
              </Grid>
              <Grid item xs={6} className={classes.cartTotalText}>
                $30
              </Grid>
              <Grid item xs={6} className={classes.cartTotalText}>
                Booking + Service Fee:
              </Grid>
              <Grid item xs={6} className={classes.cartTotalText}>
                $59.92
              </Grid>
              <Grid item xs={6} className={classes.cartTotalText}>
                Total
              </Grid>
              <Grid item xs={6} className={classes.cartTotalText}>
                $89.0
              </Grid>
            </Grid>
            <hr />
            <form noValidate autoComplete="off">
              <Button className={classes.button}>Proceed to Checkout</Button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BookingConfirmation
