import { api_request } from '../../../Services/helpers'

export const fetch = async (params = {}) =>
  await api_request('get', '/FAQs', params, false)

export const create = async (params = {}) =>
  await api_request('post', '/auth/faqs/create', params, true)

export const update = async (id, params = {}) =>
  await api_request('post', `/auth/faqs/update/${id}`, params, true)

export const remove = async (id) =>
  await api_request('delete', `/auth/faqs/${id}`, {}, true)
