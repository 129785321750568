import React from 'react'
import { withRouter } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-day-picker/lib/style.css'
import './UnavailabilityComponent.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

class UnavailabilityCompoent extends React.Component {
  //unability
  constructor(props) {
    super(props)

    this.state = {
      unavailability: [],
    }
  }

  _setState = async (obj) => {
    await this.setState(obj)
    this.props.parentHandle(obj)
  }

  componentDidMount() {
    if (this.props.unavailability) {
      let unavailability = this.props.unavailability.filter((x) => x)
      this.setState({ unavailability })
    } else {
      this.clickAddMore()
    }
  }

  async validateDates() {
    let unavailability = this.state.unavailability
    let goodavailability = []
    let has_missing = false

    for (let i = 0; i < unavailability.length; i++) {
      let x = unavailability[i]

      if (x.from !== '' && x.to !== '') {
        if (!this.isValidDateFormat(x.from) || !this.isValidDateFormat(x.to)) {
          x.error = 'Invalid date'
        } else {
          if (this.isValidDateRange(x.from, x.to)) {
            x.error = 'Invalid Date Range'
          }
        }

        goodavailability.push(x) //include even with error, we will not process it in the frontend anyway
      } else {
        x.error = 'Missing date.'
        has_missing = true
      }
    }

    if (goodavailability.length > 0 && has_missing === false) {
      this._setState({ unavailability: goodavailability })
    }
  }

  isValidDateFormat(date) {
    return /^\d{4}-\d{1,2}-\d{1,2}$/.test(date)
  }

  isValidDateRange(from, to) {
    let _to = new Date(to)
    let _from = new Date(from)

    let days = (_to - _from) / (1000 * 3600 * 24) + 1

    if (days < 0) {
      return true
    } else {
      return false
    }
  }

  async handleChangeDate(_date, fromto, index) {
    let date = ''

    try {
      date = moment(_date).format('YYYY-MM-DD')
    } catch (e) {
      date = _date
    }

    try {
      let unavailability = this.state.unavailability

      console.log('changeFrom 1')
      if (this.isValidDateFormat(date)) {
        console.log('changeFrom 2')
        unavailability[index][fromto] = moment(date).format('YYYY-MM-DD')
        unavailability[index].error = ''
      } else {
        console.log('changeFrom 3')
        unavailability[index][fromto] = date
        unavailability[index].error = 'Invalid date'
      }

      if (unavailability[index].error === '') {
        let _to = new Date(unavailability[index].to)
        let _from = new Date(unavailability[index].from)
        if (this.isValidDateRange(_from, _to)) {
          unavailability[index].error = 'Invalid Date Range'
        }
      }

      await this.setState({ unavailability })
      this.validateDates()
    } catch (e) {}
  }

  async clickDelete(index) {
    let unavailability = this.state.unavailability
    let new_list = []
    delete unavailability[index]

    for (let i = 0; i < unavailability.length; i++) {
      let x = unavailability[i]
      if (x) {
        new_list.push(x)
      }
    }

    await this.setState({ unavailability: new_list })
    this.validateDates()
  }

  async clickAddMore() {
    let unavailability = this.state.unavailability
    let new_list = []

    unavailability.forEach((x) => {
      new_list.push(x)
    })

    new_list.push({ from: '', to: '', error: '' })

    this.setState({ unavailability: new_list })
  }

  setDate(date) {
    var new_date = ''
    try {
      if (this.isValidDateFormat(date)) {
        new_date = new Date(date)
      }

      return new_date
    } catch (e) {
      return new_date
    }
  }

  render() {
    return (
      <>
        <div className="unavailable-container">
          <div className="unavailable-item">
            <div className="row">
              <div className="col-5 item">From</div>
              <div className="col-5 item">To</div>
              <div className="col-2 item"></div>
            </div>
          </div>

          {this.state.unavailability.length > 0 &&
            this.state.unavailability.map((item, index) => {
              return (
                <div key={index} className="unavailable-item">
                  <div
                    className={
                      item && item.error !== false && item.error !== ''
                        ? 'row item-error'
                        : 'row'
                    }
                  >
                    <div className="col-5 item">
                      <FontAwesomeIcon
                        icon="calendar"
                        className="calendar-icon"
                      />
                      <DatePicker
                        className="form__form-group-datepicker"
                        placeholderText="From"
                        selected={this.setDate(item.from)}
                        onChange={(date) =>
                          this.handleChangeDate(date, 'from', index)
                        }
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>

                    <div className="col-5 item">
                      <FontAwesomeIcon
                        icon="calendar"
                        className="calendar-icon"
                      />
                      <DatePicker
                        className="form__form-group-datepicker"
                        placeholderText="To"
                        selected={this.setDate(item.to)}
                        onChange={(date) =>
                          this.handleChangeDate(date, 'to', index)
                        }
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>

                    <div className="col-2 item">
                      <FontAwesomeIcon
                        icon="trash"
                        className="delete-range-btn"
                        onClick={() => this.clickDelete(index)}
                      />{' '}
                    </div>

                    {item.error !== '' ? (
                      <div className="error-message">{item && item.error}</div>
                    ) : null}
                  </div>
                </div>
              )
            })}

          <button
            className="btn btn-secondary"
            onClick={() => this.clickAddMore()}
          >
            Add More
          </button>
        </div>
      </>
    )
  }
}

export default withRouter(UnavailabilityCompoent)
