import fp from 'lodash/fp'

import * as vehicleService from '../../Services/vehicle'

const defaultState = {
  all: {},
  currentVehicle: {},
  currentVehicleConditionsOfUse: {},
  currentVehicleDetails: {},
  currentVehicleTowingDetails: {},
  currentVehicleAmenities: {},
  currentVehicleAdditionalAmenities: {},
  vehiclesByLocation: {},
  vehiclesByType: {},
  vehiclesByState: {},
  vehiclesSearch: {},
}

export default {
  state: defaultState,

  reducers: {
    getVehicles(state, payload) {
      return fp.assign(state, { all: payload })
    },
    getVehicle(state, vehicleInfo) {
      return fp.merge(state, {
        currentVehicle: vehicleInfo,
      })
    },
    getVehicleConditionsOfUse(state, vehicleConditionsOfUse) {
      return fp.merge(state, {
        currentVehicleConditionsOfUse: vehicleConditionsOfUse,
      })
    },
    getVehicleDetails(state, vehicleDetails) {
      return fp.merge(state, {
        currentVehicleDetails: vehicleDetails,
      })
    },
    getVehicleTowingDetails(state, vehicleTowingDetails) {
      return fp.merge(state, {
        currentVehicleTowingDetails: vehicleTowingDetails,
      })
    },
    getVehicleAmenities(state, vehicleAmenities) {
      return fp.assign(state, {
        currentVehicleAmenities: vehicleAmenities,
      })
    },
    getVehicleAdditionalAmenities(state, vehicleAdditionalAmenities) {
      return fp.assign(state, {
        currentVehicleAdditionalAmenities: vehicleAdditionalAmenities,
      })
    },
    getVehicleByLocation(state, payload) {
      return fp.assign(state, { vehiclesByLocation: payload })
    },
    getVehicleByType(state, payload) {
      return fp.assign(state, { vehiclesByType: payload })
    },
    getVehicleByState(state, payload) {
      return fp.assign(state, { vehiclesByState: payload })
    },
    getVehiclesSearch(state, payload) {
      return fp.assign(state, { vehiclesSearch: payload })
    },
  },

  effects: {
    async fetchVehicles() {
      const vehicleList = await vehicleService.fetchVehicles()
      this.getVehicles(vehicleList.data)
    },
    async fetchVehicle(id) {
      const vehicleInfo = await vehicleService.fetchVehicle(id)
      this.getVehicle(vehicleInfo.data)
      const vehicleConditionsOfUse =
        await vehicleService.fetchVehicleConditionsOfUse(id)
      this.getVehicleConditionsOfUse(vehicleConditionsOfUse.data[0])
    },
    async fetchVehicleConditionsOfUse(id) {
      const vehicleConditionsOfUse =
        await vehicleService.fetchVehicleConditionsOfUse(id)
      this.getVehicleConditionsOfUse(vehicleConditionsOfUse.data[0])
    },
    async fetchVehicleDetails(id) {
      const vehicleDetails = await vehicleService.fetchVehicleDetails(id)
      this.getVehicleDetails(vehicleDetails.data[0])
    },
    async fetchVehicleTowingDetails(id) {
      const vehicleTowingDetails =
        await vehicleService.fetchVehicleTowingDetails(id)
      this.getVehicleTowingDetails(vehicleTowingDetails.data[0])
    },
    async fetchVehicleAmenities(id) {
      const vehicleAmenities = await vehicleService.fetchVehicleAmenities(id)
      this.getVehicleAmenities(vehicleAmenities.data)
    },
    async fetchVehicleAdditionalAmenities(id) {
      const vehicleAdditionalAmenities =
        await vehicleService.fetchVehicleAdditionalAmenities(id)
      this.getVehicleAdditionalAmenities(vehicleAdditionalAmenities.data)
    },
    async fetchVehicleByLocation(location) {
      const locationParams = { location: { like: location } }
      const vehicleList = await vehicleService.fetchVehicleByLocation(
        locationParams
      )
      this.getVehicleByLocation(vehicleList.data)
    },
    async fetchVehicleByType(vehicleType) {
      const vehicleList = await vehicleService.fetchVehicleByType(vehicleType)
      this.getVehicleByType(vehicleList.data)
    },
    async fetchVehiclesByState(vehicleState) {
      const vehicleList = await vehicleService.fetchVehiclesByState(
        vehicleState
      )
      this.getVehiclesSearch(vehicleList.data)
    },
    async searchVehicle(params) {
      const vehicleList = await vehicleService.searchVehicle(params)
      this.getVehiclesSearch(vehicleList.data)
    },
  },

  selectors: (slice, createSelector) => ({
    all() {
      return createSelector(slice, fp.get('all'))
    },
    allValues() {
      return createSelector(this.all, fp.values)
    },
    currentVehicle() {
      return createSelector(slice, fp.get('currentVehicle'))
    },
    currentVehicleConditionsOfUse() {
      return createSelector(slice, fp.get('currentVehicleConditionsOfUse'))
    },
    currentVehicleDetails() {
      return createSelector(slice, fp.get('currentVehicleDetails'))
    },
    currentVehicleTowingDetails() {
      return createSelector(slice, fp.get('currentVehicleTowingDetails'))
    },
    currentVehicleAmenities() {
      return createSelector(slice, fp.get('currentVehicleAmenities'))
    },
    currentVehicleAdditionalAmenities() {
      return createSelector(slice, fp.get('currentVehicleAdditionalAmenities'))
    },
    vehiclesByLocation() {
      return createSelector(slice, fp.get('vehiclesByLocation'))
    },
    vehiclesByType() {
      return createSelector(slice, fp.get('vehiclesByType'))
    },
    vehiclesByState() {
      return createSelector(slice, fp.get('vehiclesByState'))
    },
    vehiclesSearch() {
      return createSelector(slice, fp.get('vehiclesSearch'))
    },
  }),
}
