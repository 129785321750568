import React from 'react'
import { withRouter } from 'react-router'
import 'react-datepicker/dist/react-datepicker.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CurrencyInput from 'react-currency-input-field'
import DayPicker, { DateUtils } from 'react-day-picker'
import * as BookingService from 'Services/bookings'
import 'react-day-picker/lib/style.css'
import { formatCurrency } from 'shared/utils.js'
import moment from 'moment'
import UnavailabilityComponent from './UnavailabilityComponent'

class AvailabilityComponent extends React.Component {
  constructor(props) {
    super(props)

    console.log('state change availability component???', this.props.state)
    let option_min_hire_durations = [
      { item: '1 Day', value: 1 },
      { item: '2 Days', value: 2 },
      { item: '3 Days', value: 3 },
      { item: '4 Days', value: 4 },
      { item: '5 Days', value: 5 },
      { item: '6 Days', value: 6 },
      { item: '7 Days', value: 7 },
      { item: '8 Days', value: 8 },
      { item: '9 Days', value: 9 },
      { item: '10 Days', value: 10 },
      { item: '11 Days', value: 11 },
      { item: '12 Days', value: 12 },
      { item: '13 Days', value: 13 },
      { item: '14 Days', value: 14 },
    ]

    let option_max_hire_durations = [
      { item: '3 Months', value: 90 },
      { item: '1 Fortnight', value: 14 },
      { item: '1 Month', value: 30 },
      { item: '1 Week', value: 7 },
      { item: '6 Months', value: 180 },
      { item: '12 Months', value: 360 },
      { item: '18 Months', value: 540 },
    ]

    this.state = {
      option_min_hire_durations,
      option_max_hire_durations,
    }
  }

  _setState = async (obj) => {
    await this.setState(obj)
    await this.props.parentHandle(obj)
  }

  parentHandle = async (obj) => {
    console.log('availabilityComponent inside', obj)
    this._setState(obj)
  }

  componentDidMount() {
    this.getRateRuleDefault()
  }

  handleRuleChange = async (e) => {
    var isRuleDate = e.target.value === 'Date Range' ? true : false
    var obj = {}
    obj[`${e.target.name}`] = e.target.value
    await this._setState(obj)
    await this._setState({ isRuleDate })
    this.checkAddRuleButtonState()
  }

  handleSelectChange = async (e) => {
    var obj = {}
    obj[`${e.target.name}`] = e.target.value
    await this._setState(obj)
  }

  /*set selectable option and default value*/
  getRateRuleDefault = async () => {
    let _rules_select = this.props.state.rate_rules_selections
    let _rate_rules = this.props.state.rate_rules

    console.log(' rate rules selection ---------> ', this.props.state.rate_rules)

    //make sure every select options are active initially
    for (let i = 0; i < _rules_select.length; i++) {
      _rules_select[i].active = true
    }

    if (_rate_rules && _rate_rules.length > 0) {
      //mark which items that are taken as inactive or false
      for (let j = 0; j < _rate_rules.length; j++) {
        for (let i = 0; i < _rules_select.length; i++) {
          if (
            _rate_rules[j].deleted !== true &&
            _rate_rules[j].rule === _rules_select[i].item
          ) {
            _rules_select[i].active = false
          }
        }
      }
    }

    //get default based on the first active item from the menu
    for (let i = 0; i < _rules_select.length; i++) {
      if (_rules_select[i].active === true) {
        await this._setState({ select_rate_rule: _rules_select[i].value })

        if (_rules_select[i].value === 'Date Range') {
          await this._setState({ isRuleDate: true })
        }

        break
      }
    }

    //update selection with the new data
    this._setState({ rate_rules_selections: _rules_select })
  }

  clickRemoveRule = async (index) => {
    let rules = this.props.state.rate_rules
    rules[index]['deleted'] = true
    let countOkey = 0

    for (let item of rules) {
      if (item['deleted'] !== true) {
        countOkey++
      }
    }

    if (countOkey === 0) {
      rules = []
    }

    await this._setState({ rate_rules: rules })
    this.getRateRuleDefault()
  }

  clickRunRateDate = async () => {
    let obj = BookingService.processRates(
      this.props.state.run_from,
      this.props.state.run_to,
      this.props.state.rate_rules,
      this.props.state.price_per_day
    )
    this._setState(obj)
  }

  checkAddRuleButtonState = () => {
    if (this.props.state.isRuleDate) {
      this._setState({
        rate_rule_button_active:
          this.props.state.price_rate_rule > 0 &&
          this.props.state.from !== undefined &&
          this.props.state.to !== undefined
            ? true
            : false,
      })
    } else if (this.props.state.select_rate_rule !== '-1') {
      this._setState({
        rate_rule_button_active: this.props.state.price_rate_rule > 0 ? true : false,
      })
    }
  }

  changeRule = async (value) => {
    await this._setState({ price_rate_rule: value })
    this.checkAddRuleButtonState()
  }

  changedRuleDate = async (date) => {
    await this._setState({ rate_rule_date: date })
    this.checkAddRuleButtonState()
  }

  changeDailyRate = async (value) => {
    await this._setState({ price_per_day: value })
  }

  clickAddRule = async () => {
    if (this.props.state.price_rate_rule > 0) {
      let _rules_select = this.props.state.rate_rules_selections
      let _rules_select_index = _rules_select.findIndex(
        (x) => x.item === this.props.state.select_rate_rule
      )
      let selected_rule_option = _rules_select[_rules_select_index]
      let _applied_rule = this.props.state.select_rate_rule

      if (selected_rule_option.type === 'Date Range') {
        _applied_rule =
          moment(this.props.state.from).format('MM/DD/YYYY') +
          '-' +
          moment(this.props.state.to).format('MM/DD/YYYY')
      }

      let rules = this.props.state.rate_rules.length > 0 ? this.props.state.rate_rules : []
      rules = [
        {
          order: selected_rule_option.order,
          type: selected_rule_option.type,
          rule: _applied_rule,
          rate: this.props.state.price_rate_rule,
        },
        ...rules,
      ]

      rules = rules.sort(function (a, b) {
        return a.order - b.order
      })

      await this._setState({ price_rate_rule: '' })
      await this._setState({ rate_rules: rules })
      await this._setState({ rate_rules_selections: _rules_select })
      await this._setState({ rate_rule_button_active: false })
      await this._setState({ isRuleDate: false })
      this.getRateRuleDefault()
    }
  }

  handleDayClick = async (day) => {
    const range = DateUtils.addDayToRange(day, this.props.state)
    await this._setState(range)
    this.checkAddRuleButtonState()
  }

  handleRunDayClick = async (day) => {
    const range = DateUtils.addDayToRange(day, {
      from: this.props.state.run_from,
      to: this.props.state.run_to,
    })
    await this._setState({ run_from: range.from, run_to: range.to })
  }

  extractRateRuleFormat = (item) => {
    let rule = item.rule

    if (item.type === 'Date Range') {
      let n = rule.split('-')
      let _from = moment(n[0]).format('DD/MM/YYYY')
      let _to = moment(n[1]).format('DD/MM/YYYY')

      rule = _from + '-' + _to
    }

    return rule
  }

  render() {
    return (
      <>
        <div className="subcontent section-0">
          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Unavailability Dates:</b>
              </div>
              <div className="col-10">
                <UnavailabilityComponent
                  parentHandle={this.parentHandle}
                  unavailability={this.props.state.unavailability}
                ></UnavailabilityComponent>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Status:</b>
              </div>
              <div className="col-10">
                {this.props.state.me.userRole === 'Admin' ? (
                  <select
                    name="status"
                    value={this.props.state.status}
                    onChange={this.handleSelectChange}
                  >
                    <option>active</option>
                    <option>draft</option>
                    <option>offline</option>
                    <option>pending</option>
                  </select>
                ) : (
                  <>
                    {this.props.state.is_approved ? (
                      <select
                        name="status"
                        value={this.props.state.status}
                        onChange={this.handleSelectChange}
                      >
                        <option>draft</option>
                        <option>active</option>
                        <option>offline</option>
                      </select>
                    ) : (
                      <span>Pending Approval</span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Minimum Hire Duration:</b>
              </div>
              <div className="col-10">
                <select
                  name="minimum_hire_duration"
                  value={this.props.state.minimum_hire_duration}
                  onChange={this.handleSelectChange}
                >
                  {this.state.option_min_hire_durations.length > 0 &&
                    this.state.option_min_hire_durations.map((item, index) => {
                      return (
                        <option key={index} value={item.value} checked>
                          {item.item}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Maximum Hire Duration:</b>
              </div>
              <div className="col-10">
                <select
                  name="maximum_hire_duration"
                  value={this.props.state.maximum_hire_duration}
                  onChange={this.handleSelectChange}
                >
                  {this.state.option_max_hire_durations.length > 0 &&
                    this.state.option_max_hire_durations.map((item, index) => {
                      return (
                        <option key={index} value={item.value} checked>
                          {item.item}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Insurance Bond:</b>
              </div>
              <div className="col-10">
                <select
                  name="security_bond"
                  className="date-applied"
                  defaultValue={this.props.state.security_bond}
                  onChange={this.handleSelectChange}
                >
                  {this.props.state.insurance_bond_selections.length > 0 &&
                    this.props.state.insurance_bond_selections.map((item, index) => {
                      if (item.active)
                        return (
                          <option key={index} value={item.value}>
                            {item.item}
                          </option>
                        )
                      else return <></>
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Base Rate Per Day*:</b>
              </div>
              <div className="col-10">
                $
                <CurrencyInput
                  id="price_per_day"
                  name="price_per_day"
                  placeholder="0.00"
                  allowDecimals={true}
                  decimalsLimit={2}
                  className={
                    this.props.state.price_per_day_error ? 'error number' : 'number'
                  }
                  value={this.props.state.price_per_day || ''}
                  onValueChange={(value, name) => this.changeDailyRate(value)}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Rate Rules:</b>
              </div>
              <div className="col-10">
                <table>
                  <tbody>
                    <tr>
                      <td>Rule Applied</td>

                      {this.props.state.isRuleDate && (
                        <>
                          <td className="date-td title">Enter Date Range</td>
                        </>
                      )}

                      <td style={{ paddingLeft: '10px' }}>Rate</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td width="200">
                        <select
                          name="select_rate_rule"
                          className="date-applied"
                          value={this.props.state.select_rate_rule}
                          onChange={this.handleRuleChange}
                        >
                          {this.props.state.rate_rules_selections.length > 0 &&
                            this.props.state.rate_rules_selections.map(
                              (item, index) => {
                                if (item.active) {
                                  return (
                                    <option key={index} value={item.value}>
                                      {item.item}
                                    </option>
                                  )
                                }
                              }
                            )}
                        </select>
                      </td>

                      {this.props.state.isRuleDate && (
                        <>
                          <td className="date-td">
                            <DayPicker
                              className="Selectable"
                              numberOfMonths={this.props.state.numberOfMonths}
                              selectedDays={[
                                this.props.state.from,
                                { from: this.props.state.from, to: this.props.state.to },
                              ]}
                              onDayClick={this.handleDayClick}
                            />
                          </td>
                        </>
                      )}

                      <td style={{ paddingLeft: '10px' }}>
                        <CurrencyInput
                          id="price_rate_rule"
                          name="price_rate_rule"
                          placeholder="0.00"
                          className="number"
                          allowDecimals={true}
                          decimalsLimit={2}
                          value={this.props.state.price_rate_rule || ''}
                          onValueChange={(value, name) =>
                            this.changeRule(value)
                          }
                        />

                        <button
                          disabled={!this.props.state.rate_rule_button_active}
                          className="btn btn-secondary"
                          onClick={() => this.clickAddRule()}
                        >
                          Add Rate Rule
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {this.props.state.rate_rules && this.props.state.rate_rules.length > 0 && (
                  <div className="rule-rates">
                    <ul>
                      {this.props.state.rate_rules &&
                        this.props.state.rate_rules.length > 0 &&
                        this.props.state.rate_rules.map((item, index) => {
                          if (item.deleted !== true) {
                            return (
                              <li key={index}>
                                <FontAwesomeIcon icon="check-square" /> &nbsp;
                                {this.extractRateRuleFormat(item)} - $
                                {item.rate} &nbsp;
                                <span
                                  className="delete-btn"
                                  title="Delete rate rule"
                                >
                                  <FontAwesomeIcon
                                    icon="times"
                                    onClick={() => this.clickRemoveRule(index)}
                                  />
                                </span>
                              </li>
                            )
                          }
                        })}
                    </ul>
                  </div>
                )}

                {this.props.state.price_per_day > 0 &&
                  this.props.state.rate_rules &&
                  this.props.state.rate_rules.length > 0 && (
                    <div className="calculator-box">
                      <h4>RATE CALCULATOR</h4>
                      <br />

                      <i>Enter booking date range </i>
                      <DayPicker
                        className="Selectable"
                        numberOfMonths={this.props.state.runNumberOfMonhts}
                        selectedDays={[
                          this.props.state.run_from,
                          { from: this.props.state.run_from, to: this.props.state.run_to },
                        ]}
                        onDayClick={this.handleRunDayClick}
                      />

                      {this.props.state.computed_total > 0 && (
                        <>
                          <div style={{ clear: 'both', width: '400px' }}>
                            <b>Rate Breakdown: </b>
                            <table>
                              <thead>
                                <tr>
                                  <td style={{ width: 'auto' }}>
                                    <b>Date</b>
                                  </td>
                                  <td className="td-rate">
                                    <b>Rate</b>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.state.computed_rates &&
                                  this.props.state.computed_rates.length > 0 &&
                                  this.props.state.computed_rates.map(
                                    (item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{item.date}</td>
                                          <td className="td-rate">
                                            {formatCurrency(item.rate)}
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )}
                              </tbody>

                              <tfoot>
                                <tr>
                                  <td>
                                    <b>TOTAL:</b>
                                  </td>
                                  <td className="td-rate">
                                    {formatCurrency(this.props.state.computed_total)}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </>
                      )}

                      <br />
                      <button
                        className="btn btn-secondary"
                        onClick={() => this.clickRunRateDate()}
                      >
                        Calculate
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>{' '}
        {/*subcontent*/}
      </>
    )
  }
}

export default withRouter(AvailabilityComponent)
