import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import * as dashboardService from './DashboardService'
import { NotificationContainer } from 'react-notifications'
import { NotificationManager } from 'react-notifications'
import { ChatList as ChatListComponent } from './components/chat/ChatListComponent'
import queryString from 'query-string'
import './style.scss'

export const DashboardLayout = ({
  user,
  page_title,
  component: Component,
  matchProps,
  ...rest
}) => {
  const [showSidebar, setShowSidebar] = useState(true)
  const [isDefaultOpened, setDefaultOpened] = useState(null)

  const [dashboardStats, setStatData] = useState({
    notificationCount: 0,
    pmCount: 0,
    notifications: [],
  })

  const topStateUpdate = () => {
    const flash = localStorage.getItem('message_flash')

    if (flash) {
      localStorage.removeItem('message_flash')
      NotificationManager.success(flash)
    }

    dashboardService.getDashboardStats().then((result) => {
      if (result.success) {
        const data = result.data
        //TODO transfer this to appropriate section
        if (data.message === 'Invalid token') {
          window.localStorage.removeItem('persist:root')
          const message = encodeURI('Your session has expired.')
          window.location.href = `/login/?message=` + message
        } else {
          setStatData({
            notificationCount: data.notifications.length,
            pmCount: data.pmCount,
            notifications: data.notifications,
          })
        }
      } else {
        window.localStorage.removeItem('persist:root')
      }
    })
  }

  useEffect(() => {
    const parsed = queryString.parse(matchProps.location.search)
    if (parsed.chat) {
      setDefaultOpened(parsed.chat)
    }

    topStateUpdate()
  }, [matchProps.location.search]) // << super important array, PREVENTS REACT INFINITE LOOP - NOTE TO SELF - james

  return (
    <>
      <NotificationContainer />
      <div className="dashboard-container-layout row">
        <Sidebar showSidebar={showSidebar} user={user} />
        <div className={showSidebar ? 'main col-10' : 'main col-12 expanded'}>
          <Topbar
            dashboardStats={dashboardStats}
            user={user}
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
            page_title={page_title}
          />
          <Component
            user={user}
            setStatData={setStatData}
            dashboardStats={dashboardStats}
            page_title={page_title}
            {...matchProps}
          />

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div className="footer row">
        <div className="col-12">
          <ChatListComponent
            isDefaultOpened={isDefaultOpened}
            user={user}
          ></ChatListComponent>
        </div>
      </div>
    </>
  )
}

const DashboardLayoutRoute = ({
  component,
  user: currentUser,
  isLoggedIn,
  ...rest
}) => {
  if (
    currentUser?.userRole === 'Owner' &&
    isLoggedIn &&
    !currentUser.ownerIsDetailComplete
  ) {
    const _location =
      typeof window !== 'undefined' ? window.location.href : undefined

    if (_location?.search('/dashboard/user/') === -1) {
      //if not in user profile, force this user to update the form.
      //window.location.href = '/dashboard/user/' + currentUser.id + '?update=1'
      return <Redirect to={'/dashboard/user/' + currentUser.id + '?update=1'} />
    }
  }
  return (
    <Route
      {...rest}
      isLoggedIn={isLoggedIn}
      user={currentUser}
      render={(matchProps) => {
        return !isLoggedIn ? (
          <Redirect to="/login" message="Not logged in" />
        ) : (
          <DashboardLayout
            component={component}
            matchProps={matchProps}
            isRefresh="false"
            user={currentUser}
            page_title={rest.page_title}
          />
        )
      }}
    />
  )
}

export default DashboardLayoutRoute
