import React from 'react'
import { Icon, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import * as ForgotPageService from './ForgotPageService'

import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import Footer from '../../Components/Footer/Footer'

import './ForgotPage.scss'

class NewPasswordPage extends React.Component {
  message = ''

  constructor(props) {
    super(props)

    const id = props.match.params.id

    this.state = {
      expiredCode: false,
      code: id,
      isProcessing: false,
      password: '',
      confirm_password: '',
      error_message: '',
    }

    this.fetch()
  }

  fetch = async () => {
    let validation = await ForgotPageService.validate({
      code: this.state.code,
    })
    if (validation.success) {
      this.setState({ email: validation.data.email })
    } else {
      this.setState({ expiredCode: true })
    }
  }

  handleClick = async (event) => {
    event.preventDefault()
    if (
      this.state.password !== '' &&
      this.state.password === this.state.confirm_password
    ) {
      let params = {
        code: this.state.code,
        email: this.state.email,
        password: this.state.password,
      }
      let result = await ForgotPageService.changepassword(params)
      if (result.success) {
        const message = encodeURI('You have successfully reset your password.')
        window.location.href = '/login?message=' + message
      } else {
        this.setState({
          error_message: 'There was an error processing your password update.',
        })
      }
    } else if (this.state.password === '') {
      this.setState({ error_message: 'Invalid password.' })
    } else {
      this.setState({ error_message: 'Confirm password didnt match!' })
    }
  }

  render() {
    return (
      <>
        <React.Fragment>
          <NavBar linkTextColor={{ color: '#000000' }} />
          <PageBanner title="New Password" link="/new-password" />

          <div className="loginContainer">
            <div className="imageFormContainer">
              <img
                className="loginImageIcon"
                alt=""
                src="//cdn.cnchire.com.au/loginImage.png"
              />

              <form
                className="loginFormContainer"
                onSubmit={(event) => this.handleClick(event)}
              >
                {this.state.expiredCode ? (
                  <>
                    <div
                      className="loginAccountTitle"
                      style={{ textAlign: 'center' }}
                    >
                      This request link is expired or invalid.
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.error_message !== '' ? (
                      <div className="loginErrorMessage">
                        {this.state.error_message}
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="loginAccountTitle">
                      Enter your new password
                    </div>

                    {this.state.invalidLogin && (
                      <div className="loginErrorMessage">
                        Invalid Email/Password.
                      </div>
                    )}

                    <Input
                      className="loginInputBox"
                      iconPosition="left"
                      placeholder="Password"
                      onChange={(event) =>
                        this.setState({ password: event.target.value })
                      }
                      type="password"
                    >
                      <Icon name="user" />
                      <input />
                    </Input>

                    <Input
                      className="loginInputBox"
                      iconPosition="left"
                      placeholder="Confirm Password"
                      type="password"
                      onChange={(event) =>
                        this.setState({ confirm_password: event.target.value })
                      }
                    >
                      <Icon name="user" />
                      <input />
                    </Input>

                    {this.state.isProcessing ? (
                      <button className="loginSubmitButtonWait" type="button">
                        <span className="loginSubmitButtonLabel">
                          Please Wait...
                        </span>
                      </button>
                    ) : (
                      <button className="loginSubmitButton" type="submit">
                        <span className="loginSubmitButtonLabel">Change</span>
                      </button>
                    )}

                    <div className="signUpDetails">
                      <span className="singUpDetailsText">
                        Don't have an account?
                      </span>
                      <span className="singUpDetailsText">
                        Sign Up as{' '}
                        <Link
                          to="/register?type=owner"
                          className="signUpDetailsLink"
                        >
                          Owner
                        </Link>
                        {' or '}
                        <Link
                          to="/register?type=hirer"
                          className="signUpDetailsLink"
                        >
                          Hirer
                        </Link>
                      </span>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </React.Fragment>
        <Footer />
      </>
    )
  }
}

export default withRouter(NewPasswordPage)
