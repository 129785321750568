import React from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import NavBar from 'Components/NavBar/NavBar'
import PageBanner from 'Components/PageBanner/PageBanner'
import Pagination from '@material-ui/lab/Pagination'
import AllVehicles from 'Components/AllVehicles/AllVehicles'
import Footer from 'Components/Footer/Footer'
import './SearchResultsPage.scss'
import * as searchResultService from './SearchResultService'
import fp from 'lodash/fp'
import useAnalyticsEventTracker from 'Pages/App/useAnalyticsTracker'

const vehicleTypesList = {
  act: 'Australian Capital Territory',
  nsw: 'New South Wales',
  nt: 'Northern Territory',
  qld: 'Queensland',
  sa: 'South Australia',
  tas: 'Tasmania',
  vic: 'Victoria',
  wa: 'Western Australia',
}

const SearchResultsPage = (props) => {
  const tracker = useAnalyticsEventTracker('searchResults')
  return <SearchResultsPageOld {...props} tracker={tracker} />
}
class SearchResultsPageOld extends React.Component {
  constructor(props) {
    super(props)
    const parsed = queryString.parse(this.props.location.search)

    this.state = {
      isReady: false,
      isSearchedByState: false,
      searchType: 'all',
      vehicleList: [],
      lat: parsed.lat,
      lng: parsed.lng,
      vehicleType: parsed.vehicleType,
      searched_location: parsed.location,
      start_date: parsed.start_date,
      end_date: parsed.end_date,

      minPrice: parsed.minPrice || false,
      maxPrice: parsed.maxPrice || false,
      minYear: parsed.minYear || false,
      maxYear: parsed.maxYear || false,
      keyword: parsed.keyword || false,

      numberOfTravellers: parsed.numberOfTravellers,
      vehicleState: vehicleTypesList[parsed.state],
      stateCode: parsed.state,
      hireType: parsed.hireType,
      meta: {},
    }

    console.log('location is  ', this.state.searched_location)
  }

  componentDidMount() {
    document.body.classList.add('search-result-page')
    this.initVehicleSearch()
  }

  initVehicleSearch = async () => {
    let params = {}

    if (this.state.vehicleState) {
      let meta = this.state.meta
      params = { state: this.state.stateCode, filter: meta, ...params }

      console.log('NEW PARAM SEARCH ', params)

      const result = await searchResultService.searchByState(params)
      this.props.tracker('vehicleSearch - State', params)
      if (result.success && result.data) {
        console.log('result state search is ', result)
        this.setState({ vehicleList: result.data })
        this.setState({ meta: result.meta })
      }

      this.setState({ searchType: 'state' })
    } else {
      if (
        this.state.searched_location !== '' &&
        this.state.searched_location !== undefined
      ) {
        this.setState({ searchType: 'location' })
      }

      if (this.state.searched_location) {
        params = {
          location: this.state.searched_location,
          lng: this.state.lng,
          lat: this.state.lat,
        }
      }

      if (this.state.vehicleType) {
        params = { vehicleType: this.state.vehicleType, ...params }
      }

      if (this.state.numberOfTravellers) {
        params = fp.merge(params, {
          numberOfTravellers: this.state.numberOfTravellers,
        })
      }

      if (this.state.hireType) {
        params = fp.merge(params, {
          hireType: this.state.hireType,
        })
      }

      if (this.state.minPrice) {
        params = fp.merge(params, {
          minPrice: Number(this.state.minPrice.replace('$', '')),
        })
      }

      if (this.state.maxPrice) {
        params = fp.merge(params, {
          maxPrice: Number(this.state.maxPrice.replace('$', '')),
        })
      }

      if (this.state.minYear) {
        params = fp.merge(params, {
          minYear: Number(this.state.minYear),
        })
      }

      if (this.state.maxYear) {
        params = fp.merge(params, {
          maxYear: Number(this.state.maxYear),
        })
      }

      if (this.state.keyword) {
        params = fp.merge(params, {
          keyword: this.state.keyword,
        })
      }

      if (this.state.start_date) {
        params = fp.merge(params, {
          start_date: this.state.start_date,
        })
      }

      if (this.state.end_date) {
        params = fp.merge(params, {
          end_date: this.state.end_date,
        })
      }

      let meta = this.state.meta
      params = { ...meta, ...params }
      this.props.tracker('vehicleSearch', params)
      const result = await searchResultService.search(params)

      if (result.success && result.data) {
        this.setState({ vehicleList: result.data, meta: result.meta })
      }
    }

    this.setState({ isReady: true })
  }

  componentDidUpdate(prevProps) {
    if (this.props.searched_location !== prevProps.searched_location) {
      this.props.history.push('/temp')
      this.props.history.goBack()
    }
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page - 1
    console.log('clicked pagination', page - 1, this.state.meta)
    this.setState({ vehicles: [] })
    await this.setState({ meta: _meta })
    await this.initVehicleSearch()
  }

  render() {
    const pageBannerTitle = this.state.vehicleState
      ? this.state.vehicleState
      : this.state.vehicleType && this.state.searched_location === undefined
      ? this.state.vehicleType
      : 'Search Results'

    const count =
      Math.ceil(this.state.meta.total / this.state.meta.pageSize) || 1

    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title={pageBannerTitle} />

        {this.state.isReady && (
          <>
            <AllVehicles
              searchType={this.state.searchType}
              vehicleList={this.state.vehicleList}
              vehicleType={this.state.vehicleType}
              title={'Search Our Large Range Of Available Vehicles For Hire'}
              searched_location={this.state.searched_location}
              lng={this.state.lng}
              lat={this.state.lat}
              dateRange={{
                from: this.state.start_date,
                to: this.state.end_date,
              }}
            />

            <div className="vehiclePaginationContainer">
              <Pagination
                count={count}
                variant="outlined"
                shape="rounded"
                onChange={this.clickPagination}
                size={'large'}
              />
            </div>
          </>
        )}
        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(SearchResultsPage)
