import React from 'react'
import { withRouter } from 'react-router'
import * as reviewService from './ReviewPageService'
import './ReviewPage.scss'
import Pagination from '@material-ui/lab/Pagination'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'

class ReviewPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      state: 'list',
      list: [],
      search: '',
      meta: null,
      item: false,
      filter: 1, //1- all , 2 approved, 3 -pending
    }

    this.fetchLatest()
  }

  async clickUnApprove(id) {
    let result = await reviewService.unapprove(id)
    if (result.success) {
      await this.fetchLatest()
      NotificationManager.success(
        'Success message',
        'The review was unapproved.'
      )
    }
  }

  async clickApprove(id) {
    let result = await reviewService.approve(id)
    if (result.success) {
      await this.fetchLatest()
      NotificationManager.success('Success message', 'The review was approved.')
    }
  }

  async fetchLatest() {
    let params = {}
    if (this.state.meta !== null) {
      let meta = { filter: this.state.filter, ...this.state.meta }
      params = { filter: meta, ...params }
    }

    let result = await reviewService.fetch(params)
    console.log('result reviews ================> ', result)

    if (result.success) {
      this.setState({ list: result.data, meta: result.meta })
    }
  }

  clickFilter = async (t) => {
    let _meta = this.state.meta
    _meta.count = 0
    await this.setState({ meta: _meta })

    await this.setState({ filter: t })
    this.fetchLatest()
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    //*
    await this.setState({ meta: _meta })
    await this.fetchLatest()
    //*/
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    return (
      <>
        <div className="reviews-container">
          <div className="filter-selection">
            Filter:
            <span
              className={this.state.filter === 1 ? 'active' : ''}
              onClick={() => this.clickFilter(1)}
            >
              All
            </span>{' '}
            |
            <span
              className={this.state.filter === 2 ? 'active' : ''}
              onClick={() => this.clickFilter(2)}
            >
              Approved
            </span>{' '}
            |
            <span
              className={this.state.filter === 3 ? 'active' : ''}
              onClick={() => this.clickFilter(3)}
            >
              Pending
            </span>
          </div>

          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Date Reviewed</th>
                <th>Author</th>
                <th style={{ width: '400px' }}>Comment</th>
                <th>Vehicle</th>
                <th>Rating</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length > 0 &&
                this.state.list.map((item, index) => {
                  let images = item.vehicleImages
                    ? JSON.parse(item.vehicleImages)
                    : []
                  let thumb = item.vehicleThumbnail
                    ? item.vehicleThumbnail
                    : images[0]
                    ? images[0].image
                    : ''

                  return (
                    <tr
                      key={item.id}
                      className={index % 2 === 0 ? 'even' : 'odd'}
                    >
                      <td>{item.id}</td>
                      <td>{moment(item.createdAt).format('LL')}</td>
                      <td>
                        <a href={'/dashboard/user/' + item.userId}>
                          {item.firstName} {item.lastName}
                        </a>
                      </td>
                      <td>{item.comment}</td>
                      <td>
                        <a href={'/vehicle/' + item.vehicleId}>
                          <img alt="thumb" src={thumb} className="thumbnail" />
                        </a>
                      </td>
                      <td>{item.rate}</td>
                      <td>{item.isApproved ? 'approved' : 'pending'} </td>
                      <td>
                        {item.isApproved ? (
                          <button
                            className="btn btn-secondary"
                            onClick={() => this.clickUnApprove(item.id)}
                          >
                            Unapprove{' '}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.clickApprove(item.id)}
                          >
                            Approve{' '}
                          </button>
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div style={{ float: 'right', marginTop: '20px' }}>
            {this.state.meta !== null && (
              <Pagination
                count={this.state.meta.count}
                variant="outlined"
                shape="rounded"
                onChange={this.clickPagination}
                size={'large'}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(ReviewPage)
