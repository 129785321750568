import React from 'react'
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-bnb-gallery/dist/style.css'
import { BrowserRouter } from 'react-router-dom'
import { hydrateRoot, createRoot } from 'react-dom/client'
import { App } from 'Pages/App/App'
// import * as serviceWorker from "./serviceWorker";

const container = document.getElementById('root')
const fragment = (
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
)
const ssr =
  typeof window != 'undefined' &&
  window.location &&
  window.location.search === '?ssr'
if (ssr) {
  // console.log(window.location.search)
  hydrateRoot(container, fragment)
} else {
  // console.log('NO SSR')
  const root = createRoot(container)
  root.render(fragment)
}
// serviceWorker.unregister();
