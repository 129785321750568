import React from 'react'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import Footer from '../../Components/Footer/Footer'
import { Input } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import * as _api from './ContactUsPageService'
import { NotificationManager } from 'react-notifications'
import './ContactUsPage.scss'

const phoneIcon = '//cdn.cnchire.com.au/phoneIcon.png'
const mailIcon = '//cdn.cnchire.com.au/mailIcon.png'
const ContactUsImage = '//cdn.cnchire.com.au/ContactUsImage.png'
class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)

    console.log(' users is ', this.props.user)
    let user_id = this.props.user ? this.props.user.id : 0

    let first_name = user_id > 0 ? this.props.user.firstName : ''
    let last_name = user_id > 0 ? this.props.user.lastName : ''
    let email = user_id > 0 ? this.props.user.email : ''

    this.state = {
      first_name_error: false,
      email_error: false,
      message_error: false,
      user_id,
      comment: '',
      email,
      phone: '',
      last_name,
      first_name,
    }
  }

  componentDidMount() {
    document.body.classList.add('contactus-page')
  }

  async clickSendContact() {
    var is_error = false

    if (this.state.first_name === '') {
      this.setState({ first_name_error: true })
      is_error = true
    } else {
      this.setState({ first_name_error: false })
    }

    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    var email = this.state.email

    if (!email || !email.match(mailformat)) {
      this.setState({ email_error: true })
      is_error = true
    } else {
      this.setState({ email_error: false })
    }

    if (this.state.comment === '') {
      this.setState({ message_error: true })
      is_error = true
    } else {
      this.setState({ message_error: false })
    }

    if (is_error) {
      NotificationManager.error(
        'Please complete the forms to continue',
        'Error'
      )
    } else {
      let params = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        user_id: this.state.user_id,
        email: this.state.email,
        phone: this.state.phone,
        comment: this.state.comment,
      }
      console.log('click sendContact params =====> ', params)
      let result = await _api.create(params)

      if (result.success) {
        if (this.state.user_id > 0) {
          await this.setState({ comment: '' })
        } else {
          await this.setState({
            comment: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
          })
        }
        NotificationManager.success('Sucessfully sent your message!')
      } else {
        NotificationManager.error(
          'There was an error processing your message!',
          'Error'
        )
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner title="Contact Us" link="/contact-us" />

        <div className="contactUsContainer">
          <div className="contactUsIconBox">
            <div className="contactUsIconWrapper"></div>

            <div className="contactUsIconWrapper">
              <img className="contactUsIcon" alt="" src={phoneIcon} />
              <span className="contactUsIconDetails1">Phone</span>
              <span className="contactUsIconDetails2"> 0467 745 986 </span>
            </div>

            <div className="contactUsIconWrapper">
              <img className="contactUsIcon" alt="" src={mailIcon} />
              <span className="contactUsIconDetails1">Email</span>
              <span className="contactUsIconDetails2">
                enquiries@caravanandcampinghire.com
              </span>
            </div>
          </div>

          <div className="contactUsBody">
            <img className="contactUsImage" alt="" src={ContactUsImage} />
            <div className="contactUsBodyFormContainer">
              <span className="contactUsBodyTitle">
                <span className="contactUsBodyTitleGet">Get</span>
                {' in Touch'}
              </span>
              <span className="contactUsBodyMessage">
                The team at Caravan And Camping Hire are committed to ensuring
                your experience with us is as hassle-free as possible.
                Contacting us is easy and simple – either fill in our contact
                form below, or contact us via our social media channels.
              </span>

              <div className="contactUsForm">
                <div className="contactUsFormRow">
                  <Input
                    className={
                      this.state.first_name_error
                        ? 'contactUsFormInputBox error'
                        : 'contactUsFormInputBox'
                    }
                    placeholder="First Name"
                    value={this.state.first_name}
                    onChange={(event) =>
                      this.setState({ first_name: event.target.value })
                    }
                  >
                    <input />
                  </Input>
                  <Input
                    className="contactUsFormInputBox"
                    value={this.state.last_name}
                    onChange={(event) =>
                      this.setState({ last_name: event.target.value })
                    }
                    placeholder="Last Name (Optional)"
                  >
                    <input />
                  </Input>
                </div>
                <div className="contactUsFormRow">
                  <Input
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    value={this.state.email}
                    className={
                      this.state.email_error
                        ? 'contactUsFormInputBox error'
                        : 'contactUsFormInputBox'
                    }
                    placeholder="Email"
                  >
                    <input />
                  </Input>
                  <Input
                    onChange={(event) =>
                      this.setState({ phone: event.target.value })
                    }
                    value={this.state.phone}
                    className="contactUsFormInputBox"
                    placeholder="Contact Number (Optional)"
                  >
                    <input />
                  </Input>
                </div>
                <div className="contactUsFormRow">
                  <textarea
                    className={
                      this.state.message_error
                        ? 'contactUsFormMessageInputBox error'
                        : 'contactUsFormMessageInputBox'
                    }
                    value={this.state.comment}
                    onChange={(event) =>
                      this.setState({ comment: event.target.value })
                    }
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="contactUsFormLastRow">
                  <button
                    className="contactUsSendButton"
                    onClick={() => this.clickSendContact()}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(ContactUsPage)
