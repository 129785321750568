import React from 'react'

import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import HowDoesItWork from '../../Components/HowDoesItWork/HowDoesItWork'
import Footer from '../../Components/Footer/Footer'
import { withRouter } from 'react-router'
import './HowDoesItWorkPage.scss'

class HowDoesItWorkPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('how-does-it-work-page')
  }

  render() {
    return (
      <React.Fragment>
        <NavBar linkTextColor={{ color: '#000000' }} />
        <PageBanner
          title="How Does It Work"
          link="/how-does-it-work-for-owners"
        />
        <HowDoesItWork />
        <Footer />
      </React.Fragment>
    )
  }
}

export default withRouter(HowDoesItWorkPage)
