import fp from 'lodash/fp'
import moment from 'moment'
import api from './api'
import { camelizeKeys, api_request } from './helpers'

export const count_days = (_from, _end) => {
  const start = moment(_from)
  const end = moment(_end)
  return end.diff(start, 'days') + 1
}

export const calculate_booking_numbers = (
  vehicleAdditionalAmenities,
  start,
  end,
  rateRules,
  price,
  securityBond
) => {
  let amenities_total = 0
  if (vehicleAdditionalAmenities && vehicleAdditionalAmenities.length > 0) {
    for (const item of vehicleAdditionalAmenities) {
      if (item.checked) {
        amenities_total += parseFloat(item.price)
      }
    }
  }
  const rateBreakdown = processRates(start, end, rateRules, price)
  console.log('check rateBreakDown ==>', rateBreakdown)
  let total = rateBreakdown.computed_total + amenities_total + securityBond
  const subtotal = total
  const insuranceFee = 5 * count_days(start, end) // 5per day
  const aupercentage = 0.0175
  const fee = 35 /// flat rate booking fee is $35.00 TODO: configurable in admin
  const orderAmount = total
  let surcharge = orderAmount * aupercentage
  surcharge -= surcharge / 11
  const bookingServiceFee = fee + surcharge // + 1.75% of the total amount
  total = total + bookingServiceFee + insuranceFee

  total = Number(total).toFixed(2)

  const commission_rate = 10 // 10% - new site. old site is 15%
  const prod_commission = 0.9 // 15% is the default deductible commission. owner gets 90%.

  const bookingCost = rateBreakdown.computed_total

  const total_booking_cost = bookingCost + amenities_total
  const total_earnings =
    total_booking_cost * prod_commission + insuranceFee * 0.9 // +90% insurance fee
  const total_commission = total_booking_cost
  total_booking_cost * prod_commission + insuranceFee * 0.1 // cnc gets 15 comission from booking cost and get 10% from insurance fee

  return {
    commission: commission_rate,
    total_commission,
    total_earnings,
    subtotal,
    bookingFee: bookingServiceFee,
    insuranceFee,
    total,
    amenitiesFee: amenities_total,
    rateBreakdown,
    totalPriceApplied: rateBreakdown.computed_total,
  }
}

export const get_exclude_dates = async (id) =>
  await api_request('get', `/vehicles/get_exclude_dates/${id}`, {}, false)

export const getDateRate = (date, rate_rules, rate) => {
  const _date = new Date(date)
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const today = days[_date.getDay()]
  const monthStart = new Date(_date)
  monthStart.setDate(0)
  const offset = ((monthStart.getDay() + 1) % 7) - 1 // -1 is for a week starting on Monday
  const weekNumber = Math.ceil((_date.getDate() + offset) / 7)

  const monthName = moment(_date).format('MMMM')

  if (rate_rules && rate_rules.length > 0) {
    for (const item of rate_rules) {
      if (item.deleted !== true) {
        if (item.type === 'Date Range') {
          const array = item.rule.split('-')
          const _from = new Date(array[0])
          const _to = new Date(`${array[1]} 23:00:00`)

          if (_date >= _from && _date <= _to) {
            // in between our daterange
            rate = item.rate
            break
          }
        }

        if (item.type === 'Day' && today === item.rule) {
          rate = item.rate
          break
        }

        if (item.type === 'Week' && `Week ${weekNumber}` === item.rule) {
          rate = item.rate
          break
        }

        if (item.type === 'Month' && monthName === item.rule) {
          rate = item.rate
          break
        }
      }
    }
  }

  return rate
}

export const processRates = (from, to, rate_rules, price_per_day) => {
  const run_from = moment(from).format('YYYY-MM-DD')
  const run_to = moment(to).format('YYYY-MM-DD')
  const start = new Date(run_from)
  const end = new Date(run_to)
  let loop = new Date(start)
  let total = 0
  const computed_rates = []

  while (loop <= end) {
    const this_date = moment(loop).format('YYYY-MM-DD')
    const this_rate = getDateRate(this_date, rate_rules, price_per_day)

    total += Number(this_rate)
    computed_rates.push({
      date: moment(this_date).format('MMMM DD'),
      rate: this_rate,
    })

    const newDate = loop.setDate(loop.getDate() + 1)
    loop = new Date(newDate)
  }

  return { computed_rates, computed_total: total }
}

export const updateBookingStatus = async (data = {}) => {
  const result = await api_request('post', '/auth/booking/status', data, true)
  return fp.flow(fp.getOr({}, 'data'))(result)
}

export const fetchAuthedBookings = async (data = {}) => {
  const result = await api_request('post', '/auth/bookings', data, true)
  return fp.flow(fp.getOr({}, 'data'))(result)
}

export const fetchBookings = async (data = {}) => {
  const bookingsApi = api('/api/public/bookings')
  const bookings = await bookingsApi.get('', { params: data })
  return fp.flow(fp.getOr({}, 'data.data'))(bookings)
}

export const fetchBooking = async (id) => {
  const bookingsApi = api('/api/public/bookings')
  const faqDetails = await bookingsApi.get(`/${id}`)

  return fp.flow(fp.getOr({}, 'data.data'), camelizeKeys)(faqDetails)
}

export const createBooking = async (payload) =>
  await api_request('post', '/auth/bookings/create', payload, true)
