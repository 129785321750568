import React from 'react'
import './Footer.scss'
import LocationItems from '../Items/LocationItems'
import SocialMediaItems from '../Items/SocialMediaItems'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const logoImage = '//cdn.cnchire.com.au/logo.png'

const Footer = ({ footerContainerBackground }) => {
  const backgroundContainer = footerContainerBackground
    ? footerContainerBackground
    : { background: 'rgba(247, 247, 247, 0.1)' }

  return (
    <React.Fragment>
      <NotificationContainer />
      <div className="footerContainer" style={backgroundContainer}>
        <div className="footerDetails">
          <div className="logoBox">
            <img className="logo" alt="" src={logoImage} />
          </div>
          <div className="destinationBox">
            <span className="destinationTitle">DESTINATIONS</span>
            {LocationItems.map((item, index) => {
              return (
                <a key={index} className="destination" href={item.link}>
                  {item.name}
                </a>
              )
            })}
          </div>
          <div className="supportBox">
            <span className="supportTitle">SUPPORT</span>
            <a className="supportItem" href="/faqs">
              FAQs
            </a>
            <a className="supportItem" href="/contact-us">
              Contact
            </a>
            <a className="supportItem" href="/login">
              Login
            </a>
            <a className="supportItem" href="/forgot-password">
              Forgot Password
            </a>
            <a className="supportItem" href="/register">
              Sign Up
            </a>
          </div>
          <div className="followBox">
            <span className="followBoxTitle">FOLLOW US</span>
            <div className="socialMediaContainer">
              {SocialMediaItems.map((item, index) => {
                return (
                  <a key={index} className="socialMediaLink" href={item.link}>
                    <img alt="" className="socialMediaIcon" src={item.icon} />
                  </a>
                )
              })}
            </div>
            <span className="socialMediaTerms">
              {'Terms & Conditions | Privacy Policy'}
            </span>
          </div>
        </div>
        <div className="copyrightBox">
          <span className="copyrightText">
            {'Copyright © 2023 Caravan & Camping Hire | ABN: 23 668 331 717'}
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
