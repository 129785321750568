import React from 'react'
import { withRouter } from 'react-router'
import 'react-datepicker/dist/react-datepicker.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-day-picker/lib/style.css'
import { NotificationManager } from 'react-notifications'
import * as CreateVehicleService from '../CreateVehicleService'

class BasicInfoComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  handleChange = (e) => {
    var obj = {}
    obj[`${e.target.name}`] = e.target.value
    this.props.parentHandle(obj)
  }

  onPrimaryChange = (image) => {
    let images = this.props.state.images
    images.forEach((_image) => {
      _image.primary = _image.image === image ? true : false
    })
    this.props.parentHandle({ images })
  }

  setType = (type, checked) => {
    let obj = {}
    obj[type] = checked
    this.props.parentHandle(obj)
  }

  deleteItem = (item) => {
    let images = this.props.state.images.filter((x) => item.image !== x.image)
    this.props.parentHandle({ images })
  }

  clickDeleteItem = async (item) => {
    const original_images = this.props.state.images
    await this.deleteItem(item)

    if (!this.props.state.is_new && !this.props.state.isErrored) {
      //is update and form is okey
      //update it silently
      const params = await this.props.prepareParams()
      const result2 = await CreateVehicleService.update(
        this.props.state.id,
        params
      )
      if (result2.success) {
        NotificationManager.success('Image has been deleted.')
      } else {
        this.props.parentHandle({ images: original_images }) //put it back
        NotificationManager.success('Error Deleting the image.')
      }
    } else {
      NotificationManager.success('Image has been deleted.')
    }
  }

  onFileChange = async (e) => {
    this.props.parentHandle({ isUploading: true })
    const files = Array.from(e.target.files)
    this.props.parentHandle({ isUploading: false })

    const formData = new FormData()

    files.forEach((file, i) => {
      formData.append(i, file)
    })

    console.log('onFileChange triggered')

    const result = await CreateVehicleService.upload(formData)

    if (result.data && result.data.error) {
      NotificationManager.error(result.data.error)
    } else if (result.data && result.data.success) {
      let images = this.props.state.images
      let primary = images.length === 0 ? true : false

      images.push({ image: result.data.imageUrl, primary })
      this.props.parentHandle({ images: images })
    } else {
      NotificationManager.error(
        'There was an error uploading your file. Please try again'
      )
    }

    this.props.parentHandle({ isUploading: false })

    return {}
  }

  render() {
    return (
      <>
        <div className="subcontent section-1">
          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Name*:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  className={this.props.state.vehicle_name_error ? 'error' : ''}
                  value={this.props.state.vehicle_name || ''}
                  placeholder="Enter vehicle name"
                  name="vehicle_name"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Type:</b>
              </div>
              <div className="col-10">
                <select
                  name="vehicle_type"
                  value={this.props.state.vehicle_type}
                  onChange={this.handleChange}
                >
                  <option>Uncategorized</option>
                  <option>Camper Trailer</option>
                  <option>Campervan</option>
                  <option>Caravan</option>
                  <option>Expander</option>
                  <option>Fifth Wheel</option>
                  <option>Miscellaneous Trailer</option>
                  <option>Motorhome Class A</option>
                  <option>Motorhome Class B</option>
                  <option>Motorhome Class C</option>
                  <option>Pop Top</option>
                  <option>Toy Hauler</option>
                </select>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Year Manufactured*:</b>
              </div>
              <div className="col-10">
                <input
                  type="number"
                  min="1900"
                  max="2099"
                  value={this.props.state.vehicle_year_manufactured || ''}
                  className={
                    this.props.state.vehicle_year_manufactured_error
                      ? 'error date'
                      : 'date'
                  }
                  name="vehicle_year_manufactured"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Make*:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.props.state.vehicle_make || ''}
                  className={this.props.state.vehicle_make_error ? 'error' : ''}
                  name="vehicle_make"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Model*:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.props.state.vehicle_model || ''}
                  className={
                    this.props.state.vehicle_model_error ? 'error' : ''
                  }
                  name="vehicle_model"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Sleeps*:</b>
              </div>
              <div className="col-10">
                <input
                  type="number"
                  value={this.props.state.vehicle_sleeps || ''}
                  className={
                    this.props.state.vehicle_sleeps_error
                      ? 'number error'
                      : 'number'
                  }
                  name="vehicle_sleeps"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Length*:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.props.state.vehicle_length || ''}
                  className={
                    this.props.state.vehicle_length_error ? 'error' : ''
                  }
                  name="vehicle_length"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Width*:</b>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  value={this.props.state.vehicle_width || ''}
                  className={
                    this.props.state.vehicle_width_error ? 'error' : ''
                  }
                  name="vehicle_width"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Hire Type:</b>
              </div>
              <div className="col-10">
                <label>
                  <input
                    type="checkbox"
                    value="true"
                    checked={this.props.state.hire_type_1 || false}
                    name="hire_type_1"
                    onChange={(event) =>
                      this.setType('hire_type_1', event.target.checked)
                    }
                  />{' '}
                  Hirer Self Tow with own Tow Vehicle
                </label>{' '}
                <br />
                <label>
                  <input
                    type="checkbox"
                    value="true"
                    checked={this.props.state.hire_type_2 || false}
                    name="hire_type_2"
                    onChange={(event) =>
                      this.setType('hire_type_2', event.target.checked)
                    }
                  />{' '}
                  Hirer Self Drive (for Campervans and Motorhomes only)
                </label>{' '}
                <br />
                <label>
                  <input
                    type="checkbox"
                    value="true"
                    checked={this.props.state.hire_type_3 || false}
                    name="hire_type_3"
                    onChange={(event) =>
                      this.setType('hire_type_3', event.target.checked)
                    }
                  />{' '}
                  Owner Delivery and Pick Up to Site
                </label>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Vehicle Images*:</b>
              </div>

              <div className="col-10">
                <div
                  className={
                    this.props.state.images_error
                      ? 'error thumbnails'
                      : 'thumbnails'
                  }
                >
                  <div className="thumbnails">
                    {this.props.state.images &&
                      this.props.state.images.length > 0 &&
                      this.props.state.images.map((item, index) => {
                        return (
                          <div key={index} className="thumbnail-item">
                            <div
                              className="delete-thumb"
                              onClick={() => {
                                this.clickDeleteItem(item)
                              }}
                              title="Delete this image"
                            >
                              <FontAwesomeIcon icon="minus-circle" />
                            </div>
                            <a
                              href={item.image}
                              rel="noopener noreferrer"
                              title="Click to open image in new window"
                              target="_blank"
                            >
                              <img alt="thumbnail" src={item.image} />
                            </a>

                            <label className="primary-box">
                              <input
                                type="radio"
                                name="primary"
                                checked={item.primary}
                                onChange={() =>
                                  this.onPrimaryChange(item.image)
                                }
                              />{' '}
                              Primary
                            </label>
                          </div>
                        )
                      })}
                  </div>

                  <label
                    htmlFor="myFile"
                    className="upload-button"
                    title="Upload new image"
                  >
                    {' '}
                    <FontAwesomeIcon icon="upload" /> &nbsp; Upload File
                  </label>
                  <input
                    type="file"
                    name="myFile"
                    id="myFile"
                    accept="image/*"
                    onChange={this.onFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="line-item">
            <div className="row">
              <div className="col-2">
                <b>Description*:</b>
              </div>
              <div className="col-10">
                <textarea
                  name="description"
                  className={this.props.state.description_error ? 'error' : ''}
                  onChange={this.handleChange}
                  defaultValue={this.props.state.description || ''}
                ></textarea>
              </div>
            </div>
          </div>
        </div>{' '}
        {/*subcontent*/}
      </>
    )
  }
}

export default withRouter(BasicInfoComponent)
