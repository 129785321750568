import React from 'react'

import './EarningsBanner.scss'
const atmIcon = '//cdn.cnchire.com.au/atm icon.png'
const userIcon = '//cdn.cnchire.com.au/user icon.png'
const calendarIcon = '//cdn.cnchire.com.au/Calendar icon.png'

const EarningsBanner = () => {
  return (
    <React.Fragment>
      <div className="earningsBanner">
        <div className="earningsTitle">
          Earn Big $$ Renting out Your Caravan, Motorhome or Camper
        </div>
        <div className="earningsContainer">
          <div className="earnings">
            <span className="earningsDescription">
              Earn hundreds, possibly thousands per week
            </span>
            <div className="iconBox">
              <img className="earningsIcon" alt="" src={atmIcon} />
            </div>
          </div>
          <div className="earnings">
            <span className="earningsDescription">
              You have full control and can choose who you hire to
            </span>
            <div className="iconBox">
              <img className="earningsIcon" alt="" src={userIcon} />
            </div>
          </div>
          <div className="earnings">
            <span className="earningsDescription">
              Choose when your caravan is available and ready for hire
            </span>
            <div className="iconBox">
              <img className="earningsIcon" alt="" src={calendarIcon} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EarningsBanner
