import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

const FAQCategoryButton = ({ categoryItem, faqs }) => {
  const [showFaqItemBox, setShowFaqItemBox] = useState(false)
  return (
    <React.Fragment>
      <div className="faqCategoryWrapper">
        <button
          className="faqButton"
          onClick={() => setShowFaqItemBox(!showFaqItemBox)}
        >
          <span className="faqButtonLabel">{categoryItem}</span>
          <Icon
            className="faqButtonArrow"
            size="large"
            name={showFaqItemBox ? 'chevron circle up' : 'chevron circle down'}
          />
        </button>
        {showFaqItemBox && (
          <div className="accountProfileBox">
            <ul className="faqList">
              {faqs
                .filter((item) => item.category === categoryItem)
                .map((item) => {
                  return (
                    <li className="faqListItem" key={item.id}>
                      <Link
                        to={`/faqs/${item.id}`}
                        style={{
                          textDecoration: 'none',
                          color: '#000000',
                        }}
                      >
                        {item.faqQuestion}
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default FAQCategoryButton
