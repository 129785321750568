import axios from 'axios'
import humps from 'humps'
import fp from 'lodash/fp'

export default (mainRoutePath) => {
  const apiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${mainRoutePath}`,
    responseType: 'json',
    responseEncoding: 'utf8',
    transformResponse: [
      ...axios.defaults.transformResponse,
      (data) => humps.camelizeKeys(data),
    ],
    transformRequest: [
      (data) => humps.decamelizeKeys(data),
      ...axios.defaults.transformRequest,
    ],
  })

  apiInstance.interceptors.request.use((config) => {
    const sortingParams = ['sortBy', 'sortOrder']

    const sortingData = fp.flow(fp.pick(sortingParams), (param) => {
      if (fp.isEmpty(param)) {
        return {}
      }
      return {
        sortBy: fp.snakeCase(param.sortBy),
        sortOrder: fp.lowerCase(param.sortOrder),
      }
    })(config.params)

    const transformedParams = {
      ...humps.decamelizeKeys(config.params),
      ...humps.decamelizeKeys(sortingData),
    }

    // eslint-disable-next-line no-param-reassign
    config.params = transformedParams

    return config
  })

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      throw error
    }
  )

  return apiInstance
}
