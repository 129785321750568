import React from 'react'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatCurrency } from 'shared/utils.js'
import BookingDetailPopup from '../../../Bookings/shared/BookingDetailPopup'
import StatusIcon from '../../../Bookings/shared/StatusIcon'

import './LatestBooking.scss'

const _avatar = 'https://cdn.cnchire.com.au/dashboard/avatar.svg'

class LatestBookingComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userRole: this.props.userRole,
      stats: this.props.data,
    }
  }

  componentDidMount() {
    this.fetchLatest()
  }

  async clickPayNow(item) {
    localStorage.setItem('booked', JSON.stringify(item))
    window.location.href = '/dashboard/payment-details/pay'
  }

  async fetchLatest() {
    let data = this.state.stats
    var list = []

    //console.log("fetcHLatest latest booking", data);
    for (var item of data) {
      var images = item.vehicleImages ? JSON.parse(item.vehicleImages) : []

      let thumb = 'https://cdn.cnchire.com.au/c6.png'

      if (item.vehicleThumbnail !== '') {
        thumb = item.vehicleThumbnail
      } else if (images[0]) {
        thumb = images[0].image
      }

      list.push({ more: false, thumb, ...item })
    }

    this.setState({ list })
  }

  toggleItem = (item) => {
    var list = this.state.list

    for (var _item of list) {
      if (_item.id === item.id) {
        _item.more = !_item.more
      }
    }

    this.setState({ list })
  }

  render() {
    if (!this.state.list) {
      return <>Latest booking...</>
    } else {
      return (
        <>
          <div className="latest-booking">
            <h4>Latest Bookings</h4>

            {this.state.list.length > 0 ? (
              <>
                {!this.props.isProfile && (
                  <div className="description">
                    Monitor Bookings. Tracking sales, and Booking status here.
                  </div>
                )}

                <div className="list-outer-box">
                  <div className="list-head">
                    <div className="row">
                      <div className="col-4">Vehicle</div>
                      <div className="col-2">Owner</div>
                      <div className="col-3">Hirer</div>
                      <div className="col-1">Status</div>
                      <div className="col-1">Amount</div>
                      <div className="col-1"></div>
                    </div>
                  </div>

                  <div className="list-body">
                    {this.state.list.length > 0 &&
                      this.state.list.map((item, index) => {
                        return (
                          <div className="row" key={index}>
                            <label
                              className="label-for-mobile"
                              style={{ display: 'none' }}
                            >
                              Booking
                            </label>

                            <div className="col-4">
                              <div className="vehicle-thumb-container">
                                <label
                                  className="label-for-mobile amount"
                                  style={{ display: 'none' }}
                                >
                                  Amount:{' '}
                                </label>
                                <div
                                  className="thumbnail"
                                  style={{
                                    backgroundImage: 'url(' + item.thumb + ')',
                                  }}
                                ></div>

                                <div className="detail">
                                  <label
                                    className="label-for-mobile vehicle"
                                    style={{ display: 'none' }}
                                  >
                                    Vehicle:{' '}
                                  </label>
                                  <div className="vehicle-name">
                                    {item.vehicleName}
                                  </div>
                                  <div className="view-detail">
                                    <a href={'/dashboard/bookings/' + item.id}>
                                      {' '}
                                      <FontAwesomeIcon icon="arrow-down" /> View
                                      Detail
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-2">
                              <label
                                className="label-for-mobile owner"
                                style={{ display: 'none' }}
                              >
                                Owner:{' '}
                              </label>
                              <div
                                className="avatar"
                                onClick={() => {
                                  window.location.href =
                                    '/dashboard/user/' + item.ownerId
                                }}
                                style={{
                                  backgroundImage: 'url(' + _avatar + ')',
                                }}
                              ></div>
                              <div
                                className="user-detail"
                                onClick={() => {
                                  window.location.href =
                                    '/dashboard/user/' + item.ownerId
                                }}
                              >
                                {item.ownerFirstName} <br />
                                <span className="small">
                                  {/*Booked Date: 12/12/2014 */}
                                </span>
                              </div>
                            </div>
                            <div className="col-3">
                              <label
                                className="label-for-mobile hirer"
                                style={{ display: 'none' }}
                              >
                                Hirer:{' '}
                              </label>
                              <div
                                className="avatar"
                                onClick={() => {
                                  window.location.href =
                                    '/dashboard/user/' + item.hirerId
                                }}
                                style={{
                                  backgroundImage: 'url(' + _avatar + ')',
                                }}
                              ></div>

                              <div
                                className="user-detail"
                                onClick={() => {
                                  window.location.href =
                                    '/dashboard/user/' + item.hirerId
                                }}
                              >
                                {item.hirerFirstName} <br />
                                <span className="small">
                                  Booked Date: {item.dateRequested}{' '}
                                </span>
                              </div>
                            </div>
                            <div className="col-1">
                              <label
                                className="label-for-mobile status"
                                style={{ display: 'none' }}
                              >
                                Status:{' '}
                              </label>
                              <StatusIcon status={item.status}></StatusIcon>
                            </div>
                            <div className="col-1">
                              {formatCurrency(item.totalAmount)}
                            </div>
                            <div className="col-1">
                              <BookingDetailPopup
                                id={item.id}
                              ></BookingDetailPopup>
                            </div>
                          </div>
                        )
                      })}
                  </div>

                  <div
                    className="more-searches"
                    title="More Bookings"
                    onClick={() => {
                      window.location.href = '/dashboard/bookings'
                    }}
                  >
                    <FontAwesomeIcon icon="angle-double-right" />
                  </div>
                </div>
              </>
            ) : (
              <div className="description">
                <>No data matched in this search. </>
              </div>
            )}
          </div>
        </>
      )
    }
  }
}

export default withRouter(LatestBookingComponent)
