import React from 'react'
import * as userService from '../Owners/UserService'
import Pagination from '@material-ui/lab/Pagination'
import moment from 'moment'
import './HirersPage.scss'
import PasswordChangeAction from '../Owners/shared/PasswordChange'

export default class HirersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      search: '',
      meta: null,
      filter: 1, //1 - all, 2 -with booking, 3 - without booking
    }
    this.fetchLatest()
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  componentDidMount() {
    document.body.classList.add('dashboard-hirers-page')
  }

  fetchLatest = async () => {
    let params = this.state.filterId ? { id: this.state.filterId } : {}
    let meta = { filter: this.state.filter }

    if (this.state.meta !== null) {
      meta = { ...meta, ...this.state.meta }
    }

    if (this.state.search !== '') {
      meta = { ...meta, search: this.state.search }
    }

    params = { filter: meta, ...params }

    const result = await userService.fetchHirers(params)

    if (result.success) {
      this.setState({ list: result.data })
      this.setState({ meta: result.meta })
    }
  }

  clickPagination = async (e, page) => {
    let _meta = this.state.meta
    _meta.page = page
    //*
    await this.setState({ meta: _meta })
    await this.fetchLatest()
    //*/
  }

  clickFilter = async (t) => {
    let _meta = this.state.meta
    _meta.count = 0
    await this.setState({ meta: _meta })

    await this.setState({ filter: t })
    this.fetchLatest()
  }

  clickOnLogin = async (email) => {
    await userService.loginAs({ email })
    window.location.href = '/dashboard'
  }

  onSearch = async (event) => {
    let search = event.target.value
    await this.setState({ search })
    if (search.length > 3) {
      this.fetchLatest()
    }
  }

  render() {
    return (
      <>
        <div className="hirers-container">
          <input
            type="text"
            className="search-box"
            value={this.state.search}
            onChange={(event) => this.onSearch(event)}
            placeholder="Search User"
          />

          <div className="filter-selection">
            Filter:
            <span
              className={this.state.filter === 1 ? 'active' : ''}
              onClick={() => this.clickFilter(1)}
            >
              All
            </span>{' '}
            |
            <span
              className={this.state.filter === 2 ? 'active' : ''}
              onClick={() => this.clickFilter(2)}
            >
              With Booking
            </span>{' '}
            |
            <span
              className={this.state.filter === 3 ? 'active' : ''}
              onClick={() => this.clickFilter(3)}
            >
              {' '}
              Without booking{' '}
            </span>
          </div>

          <table>
            <thead>
              <tr>
                <td>#</td>
                <td>Name</td>
                <td>Username</td>
                <td>Email</td>
                <td>Address</td>
                <td>Booking</td>
                <td>Date Registered</td>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length > 0 &&
                this.state.list.map((item, index) => {
                  return (
                    <tr
                      key={item.id}
                      className={index % 2 === 0 ? 'even' : 'odd'}
                    >
                      <td>{item.id}</td>
                      <td>
                        <a href={'/dashboard/user/' + item.id}>
                          {item.firstName} {item.lastName}
                        </a>
                      </td>
                      <td>{item.username}</td>
                      <td>
                        <a href={'mailto:' + item.email}>{item.email}</a>
                      </td>
                      <td>{item.address}</td>
                      <td>{item.bookingCount}</td>
                      <td>{moment(item.createdAt).format('LL')}</td>
                      <td>
                        <PasswordChangeAction
                          user={item}
                        ></PasswordChangeAction>
                        <button
                          className="btn btn-secondary login-btn"
                          onClick={() => this.clickOnLogin(item.email)}
                        >
                          Login as {item.username || item.email}
                        </button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div style={{ float: 'right', marginTop: '20px' }}>
            {this.state.meta !== null && (
              <Pagination
                count={this.state.meta.count}
                variant="outlined"
                shape="rounded"
                onChange={this.clickPagination}
                size={'large'}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}
