import React from 'react'
import { withRouter } from 'react-router'
import NavBar from '../../Components/NavBar/NavBar'
import PageBanner from '../../Components/PageBanner/PageBanner'
import LatestNews from '../../Components/LatestNews/LatestNews'
import Footer from '../../Components/Footer/Footer'

import './LatestNewsPage.scss'

import * as newsService from '../../Services/news'

class LatestNewsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      list: false,
    }
    this.fetch()
  }

  componentDidMount() {
    document.body.classList.add('LatestNews-page')
  }

  async fetch() {
    const result = await newsService.fetchNews()
    const list = result.data
    this.setState({ list })
  }

  render() {
    if (!this.state.list) {
      return <></>
    } else {
      return (
        <React.Fragment>
          <NavBar linkTextColor={{ color: '#000000' }} />
          <PageBanner title="Latest News" link="/news" />
          <LatestNews newsList={this.state.list} />
          {/*<SubscriptionBanner />*/}
          <Footer />
        </React.Fragment>
      )
    }
  }
}

export default withRouter(LatestNewsPage)
