import React from 'react'
import { withRouter } from 'react-router'
import * as faqsService from './FaqsPageService'
import './AddFaqsPage.scss'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { NotificationManager } from 'react-notifications'

class AddFaqsPage extends React.Component {
  constructor(props) {
    super(props)

    let { id } = this.props.match.params

    this.state = {
      category_list: [
        'Account & Profile',
        'For Hirers/Travellers',
        'Cancellation Terms and Conditions',
        'For Owners',
        'Insurance Cover Details',
        'Portable Electric Brake Unit Hire',
        'Hirers/Travellers',
      ],
      id,
      faq_question: '',
      faq_answer: '',
      category: '',
      list: [],
    }

    this.fetchLatest()
  }

  componentDidMount() {
    document.body.classList.add('dashboard-faq-page')
  }

  async fetchLatest() {
    let params = {}
    if (this.state.meta !== null) {
      params = { filter: this.state.meta, ...params }
    }
    const result = await faqsService.fetch(params)

    let list = []
    for (let item of result.data) {
      list.push({ isOpened: false, ...item })
    }

    if (result.data.length > 0) {
      this.setState({ category: result.data[0].category })
    }

    if (this.state.id) {
      let i = result.data.findIndex((x) => x.id === Number(this.state.id))
      let subject = result.data[i]
      let obj = {
        faq_question: subject.faqQuestion,
        faq_answer: subject.faqAnswer || '',
        category: subject.category,
      }

      this.setState(obj)
      console.log('obj ', obj)
    }

    if (result.success) {
      this.setState({ list })
      this.setState({ meta: result.meta })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  clickItem = (item) => {
    let list = this.state.list

    for (let _item of list) {
      _item.isOpened = item.id === _item.id ? !_item.isOpened : false
    }

    this.setState({ list })
  }

  clickSubmit = async () => {
    let is_new = this.state.id ? false : true
    if (this.state.faq_answer === '' || this.state.faq_question === '') {
      NotificationManager.error('Please complete all the fields.')
    } else {
      let params = {
        faq_question: this.state.faq_question,
        faq_answer: this.state.faq_answer,
        category: this.state.category,
      }

      let result

      if (is_new) {
        result = await faqsService.create(params)
      } else {
        result = await faqsService.update(this.state.id, params)
      }

      if (result.success) {
        await localStorage.setItem(
          'message_flash',
          'Successfully ' + (is_new ? 'added' : 'saved') + '.'
        )
        window.location.href = '/dashboard/faqs'
      } else {
        NotificationManager.error('Error creating this faq.')
      }
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    return (
      <>
        <div className="add-form-container">
          <div className="input-item">
            Category: <br />
            <select
              name="category"
              value={this.state.category}
              onChange={this.handleChange}
            >
              {this.state.category_list.length > 0 &&
                this.state.category_list.map((item, index) => {
                  return <option key={index}>{item}</option>
                })}
            </select>
          </div>

          <div className="input-item">
            Question: <br />
            <input
              type="text"
              name="faq_question"
              value={this.state.faq_question}
              onChange={this.handleChange}
            />
          </div>

          <div className="input-item">
            Answer: <br />
            <CKEditor
              editor={ClassicEditor}
              data={this.state.faq_answer}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                //console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                this.setState({ faq_answer: data })
                //console.log( { event, editor, data } );
              }}
              onBlur={(event, editor) => {
                //console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                //console.log( 'Focus.', editor );
              }}
            />
            <div className="button-container">
              <button
                className="btn btn-secondary cancel-btn"
                onClick={() => (window.location.href = '/dashboard/faqs')}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary submit-btn"
                onClick={() => this.clickSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(AddFaqsPage)
